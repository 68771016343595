

import React, { useEffect, useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Vortex } from 'react-loader-spinner';
import { Box, Stack, Typography } from '@mui/material';


const EditEmployee = () => {
  const { id } = useParams(); // Single ID from URL
  const [employee, setEmployee] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [countryCodes, setCountryCodes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [role, setRole] = useState([]);
  const [member, setMembers] = useState([]);
  const navigate = useNavigate();



  useEffect(() => {
    const fetchEmployeeData = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        console.error('No token found');
        setError('No token found');
        return;
      }

      try {
        const response = await fetch(`https://api.myntask.com/employee/edit/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        setEmployee(data[0]); // Assuming the API returns an array, we take the first item.
        setIsLoading(false);
      } catch (err) {
        console.error('Error fetching employee data:', err);
        setError('Failed to fetch employee data. Please try again later.');
        setIsLoading(false);
      }
    };


    fetchEmployeeData();
  }, [id]);








  useEffect(() => {
    const fetchData = async (url, setter) => {
      const token = localStorage.getItem('authToken');
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setter(data || []);
      } catch (error) {
        console.error(`Error fetching data from ${url}:`, error);
        setter([]);
      }
    };




    fetchData('https://api.myntask.com/employee/designation', setDesignations);
    fetchData('https://api.myntask.com/home/country_list', setCountries);
    fetchData('https://api.myntask.com/employee/department', setDepartments);
    fetchData('https://api.myntask.com/employee/member', setMembers);
    fetchData('https://api.myntask.com/employee/roles', setRole);
  }, []);








  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEmployee((prevEmployee) => ({
      ...prevEmployee,
      [name]: value,
    }));
  };

  const sanitizeValue = (value) => (value === undefined || value === '' ? null : value);


  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('authToken');

    // Create FormData object
    const formData = new FormData();
    formData.append('user_id', id); // Include the ID
    formData.append('employee_id', sanitizeValue(employee.employee_id));
    formData.append('name', sanitizeValue(employee.employee_name));
    formData.append('designation_id', sanitizeValue(employee.designation_id));
    formData.append('department_id', sanitizeValue(employee.department_id));
    formData.append('country_id', sanitizeValue(employee.country_id));
    formData.append('mobile', sanitizeValue(employee.mobile));
    formData.append('gender', sanitizeValue(employee.gender));
    formData.append('joining_date', employee.joining_date ? employee.joining_date.split('T')[0] : null);
    formData.append('date_of_birth', employee.date_of_birth ? employee.date_of_birth.split('T')[0] : null);
    formData.append('reporting_to', sanitizeValue(employee.reporting_to));
    formData.append('role_id', sanitizeValue(employee.role_id));
    formData.append('address', sanitizeValue(employee.address));
    formData.append('about_me', sanitizeValue(employee.about_me));
    formData.append('login', sanitizeValue(employee.login));
    formData.append('email_notifications', sanitizeValue(employee.email_notifications));
    formData.append('probation_end_date', employee.probation_end_date ? employee.probation_end_date.split('T')[0] : null);
    formData.append('notice_period_start_date', employee.notice_period_start_date ? employee.notice_period_start_date.split('T')[0] : null);
    formData.append('notice_period_end_date', employee.notice_period_end_date ? employee.notice_period_end_date.split('T')[0] : null);
    formData.append('employment_type', sanitizeValue(employee.employment_type));
    formData.append('marital_status', sanitizeValue(employee.marital_status));
    formData.append('status', sanitizeValue(employee.status));
    formData.append('country_phonecode', sanitizeValue(employee.country_phonecode));

    // Append the image file if selected
    if (employee.user_image instanceof File) {
      formData.append('image', employee.user_image); // The actual file
    }

    setIsLoading(true);

    try {
      const response = await fetch('https://api.myntask.com/employee/update', {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData, // Send FormData
      });

      console.log('Response status:', response.status);

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error response from server:', errorText);
        throw new Error(`Error ${response.status}: ${errorText}`);
      }

      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Employee details updated successfully!',
      });
      console.log('Employee details updated successfully!');
      setIsLoading(false);
      navigate('/employee');
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to update employee details.',
      });
      console.error('Error updating employee data:', err);
      setError(`Failed to update employee data. ${err.message}`);
    }
  };



  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setEmployee((prev) => ({ ...prev, user_image: file })); // Save the file
    }
  };

  //..........Loading Spinner ..........................................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }



  const handleCancel = () => {
    navigate('/employee');
  };




  return (
    <div className='main-container'>

      <div className="top-controls">
        <h1 className='title'>Edit Team Member
          <div className="underline"></div>
        </h1>
      </div>
      {employee && (
        <div>
          <section className="section">
            <h3 className="sectionTitle">Personal Details</h3>
            <div className="formRow">
              <div className="formColumn">
                <div className="formGroup">
                  <label className="label">Name:</label>
                  <input
                    type="text"
                    name="employee_name"
                    value={employee.employee_name}
                    onChange={handleInputChange}
                    className="input"
                    placeholder="Enter name"
                  />
                </div>
                <div className="formGroup">
                  <label className="label">Email:</label>
                  <input
                    type="email"
                    name="email"
                    value={employee.email}
                    onChange={handleInputChange}
                    className="input"
                    placeholder="Enter email address"
                  />
                </div>
                <div className="formGroup">
                  <label className="label">Mobile:</label>
                  <input
                    type="text"
                    name="mobile"
                    value={employee.mobile}
                    onChange={handleInputChange}
                    className="input"
                    placeholder="Enter mobile number"
                  />
                </div>
                <div className="formGroup">
                  <label className="label">Country:</label>
                  <select
                    name="country_id"
                    value={employee.country_id || ''}
                    onChange={handleInputChange}
                    className="input select-box"  // Add 'select-box' class to control dropdown width
                  >
                    <option value="">Select Country</option>
                    {countries.map((country) => (
                      <option key={country.id} value={country.id}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="formColumn">
                <div className="formGroup">
                  <label className="label">Gender:</label>
                  <select
                    name="gender"
                    value={employee.gender || ''}
                    onChange={handleInputChange}
                    className="input"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                </div>
                <div className="formGroup">
                  <label className="label">Date of Birth:</label>
                  <input
                    type="date"
                    name="date_of_birth"
                    value={employee.date_of_birth ? employee.date_of_birth.split('T')[0] : ''}
                    onChange={handleInputChange}
                    className="input"
                    placeholder="Select date of birth"
                  />
                </div>
                <div className="formGroup">
                  <label className="label">Address:</label>
                  <input
                    type="text"
                    name="address"
                    value={employee.address}
                    onChange={handleInputChange}
                    className="input"
                    placeholder="Enter address"
                  />
                </div>
                <div className="formGroup">
                  <label className="label">Marital Status:</label>
                  <select
                    name="marital_status"
                    value={employee.marital_status || ''}
                    onChange={handleInputChange}
                    className="input"
                  >
                    <option value="">Select Marital Status</option>
                    <option value="single">Single</option>
                    <option value="married">Married</option>
                    <option value="divorced">Divorced</option>
                  </select>
                </div>
              </div>
            </div>
          </section>




          <section className="section">
            <h3 className="sectionTitle">Employment Info</h3>
            <div className="formRow">
              <div className="formColumn">
                <div className="formGroup">
                  <label className="label">Designation:</label>
                  <select
                    name="designation_id"
                    value={employee.designation_id || ''}
                    onChange={handleInputChange}
                    className="input"
                  >
                    <option value="">Select Designation</option>
                    {designations.map((designation) => (
                      <option key={designation.id} value={designation.id}>
                        {designation.name}
                      </option>
                    ))}
                  </select>
                </div>


                <div className="formGroup">
                  <label className="label">Department:</label>
                  <select
                    name="department_id"
                    value={employee.department_id || ''}
                    onChange={handleInputChange}
                    className="input"
                  >
                    <option value="">Select Department</option>
                    {departments.map((department) => (
                      <option key={department.id} value={department.id}>
                        {department.department}
                      </option>
                    ))}
                  </select>
                </div>


                <div className="formGroup">
                  <label className="label">Role:</label>
                  <select
                    name="role_id"
                    value={employee.role_id || ''}
                    onChange={handleInputChange}
                    className="input"
                  >
                    <option value="">Select Role</option>
                    {role.map((role) => (
                      <option key={role.id} value={role.id}>
                        {role.role_name}
                      </option>
                    ))}
                  </select>
                </div>


                <div className="formGroup">
                  <label className="label">Login:</label>
                  <select
                    name="login"
                    value={employee.login || ''}
                    onChange={handleInputChange}
                    className="input"
                  >
                    <option value="">Select Login Status</option>
                    <option value="enable">Enable</option>
                    <option value="disable">Disable</option>
                  </select>
                </div>
              </div>


              <div className="formColumn">
                <div className="formGroup">
                  <label className="label">Email Notifications:</label>
                  <select
                    name="email_notifications"
                    value={employee.email_notifications === '0' ? 'disable' : 'enable'}
                    onChange={(e) => {
                      const value = e.target.value === 'disable' ? '0' : '1';
                      handleInputChange({ target: { name: 'email_notifications', value } });
                    }}
                    className="input"
                  >
                    <option value="">Select Login Status</option>
                    <option value="enable">Enable</option>
                    <option value="disable">Disable</option>
                  </select>
                </div>


                <div className="formGroup">
                  <label className="label">Employment Type:</label>
                  <select
                    name="employment_type"
                    value={employee.employment_type || ''}
                    onChange={handleInputChange}
                    className="input"
                  >
                    <option value="">Select Employment Type</option>
                    <option value="full-time">Full-Time</option>
                    <option value="part-time">Part-Time</option>
                    <option value="contract">Contract</option>
                  </select>
                </div>


                <div className="formGroup">
                  <label className="label">Status:</label>
                  <select
                    name="status"
                    value={employee.status || ''}
                    onChange={handleInputChange}
                    className="input"
                  >
                    <option value="">Select Status</option>
                    <option value="active">Active</option>
                    <option value="deactive">Deactive</option>
                  </select>
                </div>
              </div>
            </div>
          </section>








          <section className="section">
            <h3 className="sectionTitle">Important Dates</h3>
            <div className="formRow">
              <div className="formColumn">

                <div className="formGroup">
                  <label className="label">Joining Date:</label>
                  <input
                    type="date"
                    name="joining_date"
                    value={employee.joining_date ? employee.joining_date.split('T')[0] : ''}
                    onChange={handleInputChange}
                    className="input"
                  />
                </div>
                <div className="formGroup">
                  <label className="label">Probation End Date:</label>
                  <input
                    type="date"
                    name="probation_end_date"
                    value={employee.probation_end_date ? employee.probation_end_date.split('T')[0] : ''}
                    onChange={handleInputChange}
                    className="input"
                  />
                </div>
              </div>
              <div className="formColumn">
                <div className="formGroup">
                  <label className="label">Notice Period Start Date:</label>
                  <input
                    type="date"
                    name="notice_period_start_date"
                    value={employee.notice_period_start_date ? employee.notice_period_start_date.split('T')[0] : ''}
                    onChange={handleInputChange}
                    className="input"
                  />
                </div>
                <div className="formGroup">
                  <label className="label">Notice Period End Date:</label>
                  <input
                    type="date"
                    name="notice_period_end_date"
                    value={employee.notice_period_end_date ? employee.notice_period_end_date.split('T')[0] : ''}
                    onChange={handleInputChange}
                    className="input"
                  />
                </div>
              </div>
            </div>

          </section>


          <section className="section">
            <h3 className="sectionTitle">Additional Detail</h3>
            {/* Dropdown for Reporting To (Members) */}
            <div className="formGroup">
              <label className="label">Reporting To:</label>
              <select
                name="reporting_to"
                value={employee.reporting_to || ''}
                onChange={handleInputChange}
                className="input"
              >
                <option value="">Select Member</option>
                {member.map((member) => (
                  <option key={member.id} value={member.id}>
                    {member.member_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="formRow">
              <div className="formColumn">
                <div className="formGroup">
                  <label className="label">About Me:</label>
                  <textarea
                    name="about_me"
                    value={employee.about_me}
                    onChange={handleInputChange}
                    className="input"
                    placeholder="Tell us about yourself"
                  />
                </div>
              </div>
              <div className="formColumn">
                <div className="formGroup">
                  <label className="label">Profile:</label>
                  <div className="profileContainer" style={{ position: 'relative' }}>
                    {employee.user_image ? (
                      <img
                        src={employee.user_image}
                        alt="User Profile"
                        className="profileImage"
                        style={{
                          width: '300px',
                          height: '150px',
                          objectFit: 'cover',
                          border: '1px solid #ccc',
                          backgroundColor: '#f4f4f4',
                          borderRadius: '8px',   // Optional, for rounded corners
                        }}
                      />
                    ) : (
                      <div className="placeholder" style={{
                        width: '300px',
                        height: '150px',
                        border: '1px solid #ccc',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#f4f4f4',
                      }}>
                        No Image
                      </div>
                    )}

                    {/* Hidden file input */}
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}  // Handle file selection
                      id="fileInput"
                      style={{ display: 'none' }}
                    />

                    {/* Edit Icon */}
                    <label htmlFor="fileInput" className="editIcon" style={{
                      position: 'absolute',
                      bottom: '10px',
                      right: '10px',
                      backgroundColor: '#fff',
                      borderRadius: '50%',
                      padding: '8px',
                      cursor: 'pointer',
                      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)'
                    }}>
                      <FaEdit style={{ color: '#555', fontSize: '18px' }} />  {/* Edit icon */}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
            <button className='color-button' onClick={handleSubmit}>Update Employee</button>
            <button className="color-button" onClick={handleCancel} >Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};




export default EditEmployee;








