import { GridMoreVertIcon } from '@mui/x-data-grid';
import React, { useEffect, useRef, useState } from 'react';
import { formatDate } from '../Components/MainFunction';
import { useNavigate } from 'react-router-dom';
import './../Components/Main.css';
import { Pagination, } from '@mui/material';
import { BiSortAlt2 } from "react-icons/bi";
import Swal from 'sweetalert2';
import SkeletonLoader from '../SkeletonLoader/SkeletonLoaderExpense';
import { useLocation } from 'react-router-dom';

const Expense = () => {
  const [expenses, setExpenses] = useState([]);
  const [filteredExpenses, setFilteredExpenses] = useState([]);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [statusOptions, setStatusOptions] = useState(["pending", "approved", "rejected", "paid"]);
  const [dropdownOpenIndex, setDropdownOpenIndex] = useState(null); // To track the open dropdown
  const [openDropdown, setOpenDropdown] = useState(null);
  const [selectedExpenseIds, setSelectedExpenseIds] = useState([]); // Track selected expenses
  const [bulkAction, setBulkAction] = useState(''); // Track bulk action (Edit or Delete)
  const [permissions, setPermissions] = useState({}); // Track permissions from API response
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [employees, setEmployees] = useState([]);
  const [projects, setProjects] = useState([]);
  const [employeeId, setEmployeeId] = useState('');
  const [projectId, setProjectId] = useState('');
  const [sortField, setSortField] = useState(""); // Field to sort
  const [sortOrder, setSortOrder] = useState("asc"); // Sort order 'asc' or 'desc'
  const [sortedExpenses, setSortedExpenses] = useState([]); // Define sortedExpenses
  const [isPaidModalOpen, setIsPaidModalOpen] = useState(false); // Modal state
  const [selectedExpenseForPaid, setSelectedExpenseForPaid] = useState(null); // Store the selected expense for Paid
  const [paymentMethod, setPaymentMethod] = useState('cash');
  const [receipt, setReceipt] = useState(null);
  const [paidAmount, setPaidAmount] = useState(0);  // For storing paid amount
  const [transactionNo, setTransactionNo] = useState(''); // For storing transaction referen
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false); // Add loading state
  const [hoveredItemId, setHoveredItemId] = useState(null); // State to track which item is hovered
  const PAGE_SIZE = 15;
  const navigate = useNavigate(); // Initialize the useNavigate hook
  const dropdownRef = useRef(null); // Ref for the dropdown menu
  const [itemName, setItemName] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [isLoadingApply, setIsLoadingApply] = useState(false); // Loading state for apply button

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialStatusFilter = queryParams.get('status') || '';
  const [statusFilter, setStatusFilter] = useState(initialStatusFilter);


  // Fetch expenses function
  // const fetchExpenses = async () => {
  //   const token = localStorage.getItem('authToken');
  //   if (!token) {
  //     setError('No token found');
  //     return;
  //   }


  //   try {
  //     console.log('Fetching employees...');
  //     const employeeResponse = await fetch('https://api.myntask.com/employee/member', {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });


  //     if (!employeeResponse.ok) {
  //       throw new Error(`Failed to fetch employees: ${employeeResponse.status}`);
  //     }


  //     const employees = await employeeResponse.json();
  //     console.log('Employees fetched:', employees);
  //     setEmployees(employees);
  //     console.log('Fetching projects...');
  //     const projectResponse = await fetch('https://api.myntask.com/task/project_list', {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });


  //     if (!projectResponse.ok) {
  //       throw new Error(`Failed to fetch projects: ${projectResponse.status}`);
  //     }


  //     const projects = await projectResponse.json();
  //     console.log('Projects fetched:', projects);
  //     setProjects(projects);


  //     const queryParams = new URLSearchParams({
  //       start_date: startDate || '',
  //       end_date: endDate || '',
  //       status: statusFilter || '',
  //       employee_id: employeeId || '',
  //       project_id: projectId || '',
  //     }).toString();


  //     console.log(`Fetching expenses with query: ${queryParams}`);
  //     const response = await fetch(`https://api.myntask.com/expenses/list?${queryParams}`, {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });


  //     if (!response.ok) {
  //       throw new Error(`Failed to fetch expenses: ${response.status}`);
  //     }


  //     const data = await response.json();
  //     console.log('Expenses fetched:', data);
  //     setExpenses(data.data);
  //     setFilteredExpenses(data.data);
  //     setPermissions(data.permission);
  //     setError(null); // Clear error if successful
  //     setIsLoading(false);
  //   } catch (err) {
  //     console.error('Error fetching expenses:', err.message);
  //     setError('Failed to load expenses.');
  //     setIsLoading(false);
  //   }
  // };


  // Fetch data function
  const fetchData = async (page) => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('No token found');
      return;
    }

    try {
      const queryParams = new URLSearchParams({
        page: page,
        page_size: PAGE_SIZE,
        start_date: startDate || '',
        end_date: endDate || '',
        status: statusFilter || '',
        employee_id: employeeId || '',
        project_id: projectId || '',
      }).toString();

      const [employeeResponse, projectResponse, expenseResponse] = await Promise.all([
        fetch('https://api.myntask.com/employee/member', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }),
        fetch('https://api.myntask.com/task/project_list', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }),
        fetch(`https://api.myntask.com/expenses/list?${queryParams}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }),
      ]);

      if (!employeeResponse.ok || !projectResponse.ok || !expenseResponse.ok) {
        throw new Error('Failed to fetch data');
      }

      const employees = await employeeResponse.json();
      console.log('Employees fetched:', employees);
      setEmployees(employees);

      console.log('Fetching projects...');
      const projects = await projectResponse.json();
      console.log('Projects fetched:', projects);
      setProjects(projects);

      const data = await expenseResponse.json();
      console.log('Expenses fetched:', data);

      setEmployees(employees);
      setProjects(projects);
      setExpenses(data.data);
      setFilteredExpenses(data.data);
      setPermissions(data.permission);
      setError(null);
      setIsLoading(false);
    } catch (err) {
      console.error('Error fetching data:', err.message);
      setError('Failed to load data.');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, statusFilter, startDate, endDate, employeeId, projectId]);


    // Function to clear all filters except currentPage
    const clearFilters = () => {
      setStatusFilter('');
      setStartDate('');
      setEndDate('');
      setEmployeeId('');
      setProjectId('');
    };


  const fetchStatusOptions = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('No token found');
      return;
    }


    try {
      const response = await fetch('https://api.myntask.com/expenses/status', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });


      if (!response.ok) {
        throw new Error('Failed to fetch status options');
      }


      const data = await response.json();
      setStatusOptions(data);
    } catch (err) {
      console.error('Error fetching status options:', err);
      setError('Failed to load status options.');
    }
  };




  // useEffect(() => {
  //   fetchExpenses();
  // }, []);




  useEffect(() => {
    fetchStatusOptions();
  }, []);


  // const applyFilters = () => {
  //   fetchExpenses(); // Re-fetch expenses with the new filter values
  // };




  // Debounced search function
  // const handleSearchChange = debounce((e) => {
  //   setSearchTerm(e.target.value);
  //   setCurrentPage(1); // Reset to first page on search
  // }, 300);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page on search
  };


  // Sorting function
  const handleSort = (field) => {
    const newSortOrder = sortField === field && sortOrder === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortOrder(newSortOrder);
  };




  // Sorting the expenses
  useEffect(() => {
    const sorted = [...filteredExpenses].sort((a, b) => {
      let fieldA = a[sortField] || "";
      let fieldB = b[sortField] || "";


      if (sortField === 'price') {
        fieldA = parseFloat(fieldA.replace(/[^0-9.-]+/g, ""));
        fieldB = parseFloat(fieldB.replace(/[^0-9.-]+/g, ""));
      }


      // Handle sorting for purchase_date
      if (sortField === 'purchase_date') {
        fieldA = new Date(fieldA);
        fieldB = new Date(fieldB);
      }


      if (fieldA < fieldB) return sortOrder === "asc" ? -1 : 1;
      if (fieldA > fieldB) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });


    setSortedExpenses(sorted);
  }, [filteredExpenses, sortField, sortOrder]);




  // Filtering the expenses by search term
  useEffect(() => {
    if (searchTerm) {
      setFilteredExpenses(
        expenses.filter((item) =>
          (item.item_name && item.item_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (item.price && item.price.toString().includes(searchTerm)) ||
          (item.purchase_from && item.purchase_from.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (item.project_name && item.project_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (item.purchase_date && item.purchase_date.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (item.status && item.status.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (item.designation_name && item.designation_name.toLowerCase().includes(searchTerm.toLowerCase()))
        )
      );
    } else {
      setFilteredExpenses(expenses);
      console.log('filtered search data is', filteredExpenses);
    }
  }, [searchTerm, expenses]);




  // Pagination logic
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };




  const totalPages = Math.ceil(sortedExpenses.length / PAGE_SIZE);
  const paginatedExpenses = sortedExpenses.slice(
    (currentPage - 1) * PAGE_SIZE,
    currentPage * PAGE_SIZE
  );




  //  const handleStatusChange = async (expenseId, newStatus) => {
  //    const token = localStorage.getItem('authToken');


  //    console.log('expense id is', expenseId);


  //    if (!token) {
  //      setError('No token found');
  //      Swal.fire('Error', 'No token found', 'error');
  //      return;
  //    }


  //    // Check if the status is "paid" and call handlePaidClick instead
  //    if (newStatus === 'paid') {
  //      handlePaidClick(expenseId);
  //      return;
  //    }


  //    try {
  //      const requestBody = {
  //        expense_id: expenseId,
  //        status: newStatus,
  //      };


  //      const response = await fetch('https://api.myntask.com/expenses/status_update', {
  //        method: 'PUT',
  //        headers: {
  //          'Content-Type': 'application/json',
  //          Authorization: `Bearer ${token}`,
  //        },
  //        body: JSON.stringify(requestBody),
  //      });


  //      if (!response.ok) {
  //        throw new Error('Failed to update status');
  //      }


  //      // Show success alert
  //      Swal.fire({
  //        icon: 'success',
  //        title: 'Success',
  //        text: 'Status updated successfully',
  //        timer: 2000,
  //        showConfirmButton: false,
  //      });


  //      fetchExpenses(); // Re-fetch the expenses after a successful update
  //    } catch (err) {
  //      console.error('Error updating status:', err);
  //      setError('Failed to update status.');


  //      // Show error alert
  //      Swal.fire('Error', 'Failed to update status', 'error');
  //    }
  //  };



  const handleStatusFilterChange = (value) => {
    setStatusFilter(value);
    // Update the URL with the selected status
    navigate(`?status=${value}`, { replace: true });
  };

  // Reset status filter on component mount
  useEffect(() => {
    const initialStatusFilter = queryParams.get('status') || '';
    setStatusFilter(initialStatusFilter);
  }, []);


  const handleStatusChange = async (selectedExpenseIds, newStatus) => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('No token found');
      Swal.fire('Error', 'No token found', 'error');
      return;
    }

    try {
      const requestBody = {
        expense_id: selectedExpenseIds,
        status: newStatus,
      };

      const response = await fetch('https://api.myntask.com/expenses/status_update', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });

      console.log("Request Body ", requestBody);
      if (!response.ok) {
        throw new Error('Failed to update status');
      }

      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Status updated successfully',
        timer: 2000,
        showConfirmButton: false,
      });
      fetchData();
      // fetchExpenses(); // Re-fetch the expenses after a successful update
    } catch (err) {
      console.error('Error updating status:', err);
      setError('Failed to update status.');
      Swal.fire('Error', 'Failed to update status', 'error');
    }
  };



  const handlePaidClick = (expenseId) => {
    const selectedExpense = expenses.find(expense => expense.expenses_id === expenseId);
    if (selectedExpense) {
      setPaidAmount(selectedExpense.price.replace('₹', '').replace(',', '').trim()); // Set the paid amount to the price from the selected expense
      setItemName(selectedExpense.item_name);
    }
    setSelectedExpenseForPaid(expenseId); // Store the selected expense
    setSelectedExpenseIds([]);// Clear any previously selected bulk expenses
    setDropdownOpenIndex(null); // Close dropdown after an action is selected
    setIsPaidModalOpen(true); // Open the modal
  };




  // const handleClosePaidModal = () => {
  //   setIsPaidModalOpen(false); // Close the modal
  // };


  const handleClosePaidModal = () => {
    setPaidAmount(0); // Clear paid amount
    setPaymentMethod('cash'); // Reset payment method to default
    setTransactionNo(''); // Clear transaction number
    setIsPaidModalOpen(false); // Close the modal
  };



  const handleReceiptChange = (e) => {
    setReceipt(e.target.files[0]); // Store uploaded receipt file
  };




  const handleConfirmPaid = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      Swal.fire('Error', 'No token found', 'error');
      return;
    }


    // Validate transaction number for Credit Card or Bank Transfer
    if ((paymentMethod === 'credit' || paymentMethod === 'bank_transfer') && !transactionNo) {
      Swal.fire('Error', 'Transaction Ref. No. is required for this payment method.', 'error');
      return;
    }

    // Show confirmation SweetAlert
    const confirmPayment = await Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to mark this expense as paid?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, mark as paid!',
      cancelButtonText: 'No, cancel',
      reverseButtons: true,
    });

    // If user cancels, exit the function
    if (!confirmPayment.isConfirmed) return;

    // Set loading state to true
    setLoading(true);

    try {
      const formData = new FormData();
      const expenseIdsToSend = selectedExpenseIds.length > 0
        ? selectedExpenseIds
        : [selectedExpenseForPaid];

      expenseIdsToSend.forEach(expenseId => {
        formData.append('expense_id[]', expenseId);
      });
      formData.append('paid_amount', paidAmount);
      formData.append('transaction_no', transactionNo);
      formData.append('payment_method', paymentMethod);
      formData.append('status', 'paid');
      if (receipt) {
        formData.append('receipt', receipt);
      }

      console.log("Submitting Payment Data:", Object.fromEntries(formData.entries()));

      const response = await fetch('https://api.myntask.com/expenses/expense_paid', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to mark as paid');
      }

      // Close the modal first
      setIsPaidModalOpen(false);

      // Show success notification
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Marked as paid successfully',
        timer: 1500,
        showConfirmButton: false,
      });

      await fetchData(currentPage);
    } catch (err) {
      console.error('Error marking as paid:', err);
      Swal.fire('Error', 'Failed to mark as paid', 'error');
    } finally {
      // Stop loader
      setLoading(false);
    }
  };




  const Loader = () => (
    <div className="loader-overlay">
      <div className="loader">Loading...</div>
    </div>
  );




  const handleDeleteExpense = async (expenseId) => {
    const token = localStorage.getItem('authToken');


    if (!token) {
      setError('No token found');
      Swal.fire('Error', 'No token found', 'error');
      return;
    }


    // Ask for confirmation using SweetAlert before deleting
    const confirmDelete = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to delete this expense?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel',
      reverseButtons: true
    });


    // If user cancels, exit the function
    if (!confirmDelete.isConfirmed) return;


    try {
      const response = await fetch('https://api.myntask.com/expenses/delete', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ expense_id: expenseId }),
      });


      if (!response.ok) {
        throw new Error('Failed to delete expense');
      }


      // Update the expense lists
      setExpenses((prevExpenses) => prevExpenses.filter((expense) => expense.expenses_id !== expenseId));
      setFilteredExpenses((prevFiltered) => prevFiltered.filter((expense) => expense.expenses_id !== expenseId));


      // Show success alert
      Swal.fire('Deleted!', 'Expense has been deleted successfully.', 'success');
    } catch (err) {
      console.error('Error deleting expense:', err);
      setError('Failed to delete expense.');
      Swal.fire('Error', 'Failed to delete expense. Please try again.', 'error');
    }
  };




  // Handling changes to the checkbox selection
  const handleCheckboxChange = (expenseId) => {
    setSelectedExpenseIds((prevSelected) =>
      prevSelected.includes(expenseId)
        ? prevSelected.filter((id) => id !== expenseId)
        : [...prevSelected, expenseId]
    );
  };




  const handleStatusDropdown = (event) => {
    const newStatus = event.target.value;
    setSelectedStatus(newStatus);
    setBulkAction(''); // Clear bulk action when status is selected
    setOpenDropdown(null); // Close any open dropdown

    if (newStatus === 'paid') {
      // Fetch selected expenses from state
      const selectedExpenses = expenses.filter(expense => selectedExpenseIds.includes(expense.expenses_id));

      // Check if all selected expenses are approved
      const allApproved = selectedExpenses.every(expense => expense.current_status === 'approved');

      if (!allApproved) {
        Swal.fire('Error', 'You can only mark approved expenses as paid.', 'error');
        return;
      }

      // Calculate total paid amount and item names
      const totalAmount = selectedExpenses.reduce((sum, expense) => sum + parseFloat(expense.price.replace('₹', '').replace(',', '')), 0);
      const itemNames = selectedExpenses.map(expense => expense.item_name).join(", ");

      // Set sum and item names in state
      setPaidAmount(totalAmount.toFixed(2)); // Set total sum of amounts
      setItemName(itemNames); // Set concatenated item names

      setIsPaidModalOpen(true); // Open the modal
    }
  };




  const handleApplyBulkAction = async () => {
    if (!bulkAction && !selectedStatus) {
      Swal.fire('Error', 'Please select an action or status to apply.', 'error');
      return;
    }

    setIsLoadingApply(true); // Show loading state

    try {
      // Perform bulk action (edit or delete)
      if (bulkAction) {
        for (const expenseId of selectedExpenseIds) {
          await handleActionClick(bulkAction.toLowerCase(), expenseId);
        }
      }

      // Perform bulk status update in ONE API CALL
      if (selectedStatus) {
        const selectedExpenses = expenses.filter(expense => selectedExpenseIds.includes(expense.expenses_id));

        // Check if all selected expenses are approved when marking as paid
        if (selectedStatus === 'paid') {
          const allApproved = selectedExpenses.every(expense => expense.current_status === 'approved');

          if (!allApproved) {
            Swal.fire('Error', 'You can only mark approved expenses as paid.', 'error');
            return;
          }
        }

        await handleStatusChange(selectedExpenseIds, selectedStatus);
      }

      // Show success message with the number of selected expenses
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: `[${selectedExpenseIds.length}] expense${selectedExpenseIds.length > 1 ? 's' : ''} ${selectedStatus} successfully.`,
        timer: 2000,
        showConfirmButton: false,
      });
      setSelectedExpenseIds([]); // Clear selected expenses
      setBulkAction(''); // Reset dropdown
      setSelectedStatus(''); // Reset status selection
    } catch (error) {
      console.error('Error applying bulk action:', error);
      Swal.fire('Error', 'Failed to apply bulk action.', 'error');
    } finally {
      setIsLoadingApply(false); // Hide loading state
    }
  };



  // Function to handle individual actions
  const handleActionClick = (action, expenseId) => {
    console.log(`Handling action: ${action} for expense ID: ${expenseId}`); // Debugging statement
    setDropdownOpenIndex(null); // Close dropdown after an action is selected


    if (action === 'view') {
      navigate(`/view-expense/${expenseId}`);
    } else if (action === 'edit') {
      navigate(`/edit-expense/${expenseId}`);
    } else if (action === 'delete') {
      handleDeleteExpense(expenseId);
    }
  };


  // Function to toggle dropdowns
  const toggleTopDropdown = (index) => {
    // If the same dropdown is clicked, close it; otherwise, open the new one
    setOpenDropdown(prevIndex => (prevIndex === index ? null : index));
  };


  // Toggle dropdown for individual actions
  const toggleDropdown = (index) => {
    console.log('Toggling dropdown for index:', index); // Debugging statement
    setDropdownOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };




  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpenIndex(null);
      }
    };


    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);




  // if (isLoading) {
  //   return <SkeletonLoader />;
  // }




  const currentStatusColors = {
    pending: 'rgba(255, 165, 0, 0.6)', // Brighter orange with 60% opacity
    approved: 'rgba(0, 0, 255, 0.6)', // Brighter blue with 60% opacity
    rejected: 'rgba(255, 0, 0, 0.6)', // Brighter red with 60% opacity
    paid: 'rgba(0, 128, 0, 0.6)', // Brighter green with 60% opacity
  };




  const handleItemNameClick = (expenseId) => {
    navigate(`/view-expense/${expenseId}`);
  };




  // Function to convert table data to CSV format
  const convertToCSV = (data) => {
    const headers = [
      'Employee Name',
      'Item Name',
      'Price',
      'Purchase From',
      'Purchase Date',
      'Project',
      'Status',
    ];


    const rows = data.map((item) => [
      item.name,
      item.item_name,
      item.price,
      item.purchase_from,
      item.purchase_date,
      item.project_name,
      item.status,
    ]);


    const csvContent = [
      headers.join(','), // Add headers
      ...rows.map((row) => row.join(',')), // Add rows
    ].join('\n'); // Join each row with newline character


    return csvContent;
  };




  // Function to trigger CSV download
  const handleExport = () => {
    const csvData = convertToCSV(filteredExpenses);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'expenses.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Remove the link element after the download
  };




  const toTitleCase = (str) => {
    return str ? str.toLowerCase().charAt(0).toUpperCase() + str.slice(1).toLowerCase() : '';
  };



  const getFilteredStatusOptions = (selectedExpenses) => {
    const allApproved = selectedExpenses.every(expense => expense.current_status === 'approved');

    if (allApproved) {
      // Include "paid" in the options if all selected expenses are approved
      return statusOptions;
    } else {
      // Exclude "paid" from the options if any selected expense is not approved
      return statusOptions.filter((status) => status !== 'paid');
    }
  };

  const isAnyExpensePaid = (selectedExpenseIds) => {
    return expenses.some(
      (expense) => selectedExpenseIds.includes(expense.expenses_id) && expense.current_status === 'paid'
    );
  };



  return (
    <div className='main-container'>

      <div className="top-controls">
        <h1 className='title'>Expense List
          <div className="underline"></div>
        </h1>
        <div className="buttons-row">
          {selectedExpenseIds.length > 0 && !isAnyExpensePaid(selectedExpenseIds) && (
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>

              {/* there is no need to delete and edit multiple expenses at once so I have commented this code */}

              {/* <select onChange={handleBulkActionChange} value={bulkAction} className="color-button">
                <option value="">Select Action</option>
                {permissions.edit_expenses === "all" && <option value="edit">Edit</option>}
                {permissions.delete_expenses === "all" && <option value="delete">Delete</option>}
              </select> */}

              <select onChange={handleStatusDropdown} value={selectedStatus} className="color-button">
                {/* <option value="">Select Status</option>
                {statusOptions.map((status) => (
                  <option key={status} value={status}>{status.charAt(0).toUpperCase() + status.slice(1)}
                  </option> */}
                <option value="">Select Status</option>
                {getFilteredStatusOptions(
                  expenses.filter(expense => selectedExpenseIds.includes(expense.expenses_id))
                ).map((status) => (
                  <option key={status} value={status}>
                    {status.charAt(0).toUpperCase() + status.slice(1)}
                  </option>
                ))}
              </select>
              <button onClick={handleApplyBulkAction} className="color-button" disabled={isLoadingApply}>
                {isLoadingApply ? 'Loading...' : 'Apply'}
              </button>
            </div>
          )}
         <button className="color-button" onClick={handleExport}>
            Export
          </button>
          {permissions.add_expenses === "all" && (
            <button className="color-button" onClick={() => navigate('/add-expense')}>
              Add Expense
            </button>
          )}
        </div>
      </div>


      <div className="top-controls">
        <div className="filters-and-search">
          
          {/* Clear Filters Button */}
      <button className='border-button' onClick={clearFilters}>Clear Filters</button>

          <input
            type="date"
            value={startDate}
            className="border-button"
            onChange={(e) => {
              setStartDate(e.target.value);
              // applyFilters();
              fetchData(currentPage);
            }}
          />
          <input
            type="date"
            value={endDate}
            onChange={(e) => {
              setEndDate(e.target.value);
              // applyFilters();
              fetchData(currentPage);
            }}
            className="border-button"
          />
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="searchbar"
          />
        </div>
      </div>


      <table>
        <thead>
          <tr>
            <th>#</th>
            <th onClick={() => handleSort('purchase_date')}>
              Purchased on
              <BiSortAlt2 style={{ cursor: 'pointer', marginLeft: '2px' }} />
            </th>
            <th>
              <div>
                <select
                  className="header-filter"
                  onChange={(e) => setEmployeeId(e.target.value)}
                  value={employeeId}
                >
                  <option value="">Employees</option>
                  {employees.map((employee) => (
                    <option key={employee.id} value={employee.id}>
                      {employee.member_name}
                    </option>
                  ))}
                </select>
              </div>
            </th>
            <th>
              <div>
                <select
                  className="header-filter"
                  onChange={(e) => setProjectId(e.target.value)}
                  value={projectId}
                >
                  <option value=""> Projects</option>
                  {projects.map((project) => (
                    <option key={project.id} value={project.id}>
                      {project.project_name}
                    </option>
                  ))}
                </select>
              </div>
            </th>
            <th>Item Name</th>
            <th>Amount</th>
            <th>Purchase From</th>
            <th>
              <div>
                <select
                  className="header-filter"
                  onChange={(e) => handleStatusFilterChange(e.target.value)}// Directly update state
                  value={statusFilter}
                >
                  <option value="">Status</option>
                  {statusOptions.map((statusOption) => (
                    <option key={statusOption} value={statusOption}>
                      {statusOption.charAt(0).toUpperCase() + statusOption.slice(1)}
                    </option>
                  ))}
                </select>
              </div>
            </th>

            <th>Action</th>
          </tr>
        </thead>


        <tbody>
          {isLoading ? (

            <tr>
              <td colSpan="9">
                <SkeletonLoader /> {/* Your skeleton loader component */}
              </td>
            </tr>

          ) : (
            paginatedExpenses.map((item, index) => (
              <tr key={item.expenses_id}>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedExpenseIds.includes(item.expenses_id)}
                    onChange={() => handleCheckboxChange(item.expenses_id)}
                  />
                </td>
                <td>{formatDate(item.purchase_date)}</td>
                <td
                  onClick={() => handleItemNameClick(item.expenses_id)}
                  style={{ cursor: 'pointer', position: 'relative' }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src={item.user_avatar || 'default-image-url.jpg'}
                      alt={item.name ? toTitleCase(item.name) : 'user'}
                      className="image"
                    />
                    <div>
                      <span
                        style={{ cursor: 'pointer' }}
                        // onClick={() => navigate(`/profile/${item.user_id}`)}
                        onClick={() => handleItemNameClick(item.expenses_id)}
                      >
                        {toTitleCase(item.name) || 'N/A'}
                      </span>
                      <p style={{ margin: 0, fontSize: '12px', color: '#777' }}>
                        {toTitleCase(item.designation_name) || 'N/A'}
                      </p>
                    </div>
                  </div>
                </td>
                <td
                  onClick={() => handleItemNameClick(item.expenses_id)}
                  style={{ cursor: 'pointer', position: 'relative' }}
                >
                  {toTitleCase(item.project_name)}
                </td>
                <td
                  onClick={() => handleItemNameClick(item.expenses_id)}
                  style={{ cursor: 'pointer', position: 'relative' }}
                  onMouseEnter={() => setHoveredItemId(item.expenses_id)} // Set hovered item ID
                  onMouseLeave={() => setHoveredItemId(null)} // Reset hovered item ID
                >
                  {toTitleCase(item.item_name)}
                  {hoveredItemId === item.expenses_id && (
                    <div
                      style={{
                        position: 'absolute',
                        bottom: '60%', // Adjust this value for fine-tuning (lower it for closer placement)
                        left: '40%',
                        transform: 'translateX(-40%)',
                        backgroundColor: '#333',
                        color: '#fff',
                        padding: '5px 10px',
                        borderRadius: '4px',
                        // whiteSpace: 'nowrap',
                        zIndex: 1000,
                        marginBottom: '5px', // Add some spacing between the tooltip and the item
                      }}
                    >
                      {item.description || 'No description available'}
                    </div>
                  )}
                </td>
                <td>
                  {item.price}
                </td>

                <td>
                  {toTitleCase(item.purchase_from)}
                </td>
                <td style={{ textAlign: 'center' }}>
                  <div
                    style={{
                      backgroundColor: currentStatusColors[item.current_status?.toLowerCase()] || 'transparent',
                      color: 'white', // Ensure text is readable against the more opaque background
                      borderRadius: '4px',
                      padding: '5px', // Add padding for better aesthetics
                    }}
                  >
                    {toTitleCase(item.current_status)}
                  </div>
                </td>


                {/* Conditionally render UpdateStatus cell */}
                {/* {statusOptions && statusOptions.length > 0 && (
               <td>
                 <div style={{ display: 'flex', alignItems: 'center' }}>
                   <span
               className="status-dot"
               style={{ backgroundColor: statusColors[item.status] }}
             ></span>
                   <select
                     value={item.status}
                     onChange={(e) => handleStatusChange(item.expenses_id, e.target.value)}
                     className="table-filter"
                   >
                     {statusOptions.map((statusOption, statusIndex) => (
                       <option key={statusIndex} value={statusOption}>
                         {toTitleCase(statusOption)}
                       </option>
                     ))}
                   </select>
                 </div>
               </td>
             )} */}


                <td>
                  <div className="action-container">
                    <div className="action-dots" onClick={() => toggleDropdown(index)}>
                      <GridMoreVertIcon />
                    </div>
                    {dropdownOpenIndex === index && (
                      <div className="action-menu" ref={dropdownRef}>
                        <div
                          className="action-item"
                          onClick={() => handleActionClick('view', item.expenses_id)}
                        >
                          View
                        </div>
                        {(permissions.edit_expenses === 'all' || permissions.edit_expenses === 'both' ||
                          permissions.edit_expenses === 'owned' || permissions.edit_expenses === 'added') && item.status !== 'paid' && item.current_status !== 'approved' && (
                            <div
                              className="action-item"
                              onClick={() => handleActionClick('edit', item.expenses_id)}
                            >
                              Edit
                            </div>
                          )}
                        {(permissions.delete_expenses === 'all' || permissions.delete_expenses === 'both' ||
                          permissions.delete_expenses === 'owned' || permissions.delete_expenses === 'added') &&
                          item.status !== 'paid' && (
                            <div
                              className="action-item"
                              onClick={() => handleActionClick('delete', item.expenses_id)}
                            >
                              Delete
                            </div>
                          )}
                        {(permissions.paid_expenses === 'both' || permissions.paid_expenses === 'owned' ||
                          permissions.paid_expenses === 'all') && item.status !== 'paid' && (item.current_status || '').toLowerCase() === 'approved' && (
                            <div
                              className="action-item"
                              onClick={() => handlePaidClick(item.expenses_id)}
                            >
                              + Paid
                            </div>
                          )}
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>


      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          shape="rounded"
        />
      </div>


      {isPaidModalOpen && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0,0,0,0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1200
        }}>
          <div style={{
            backgroundColor: '#fff',
            padding: '30px',
            borderRadius: '10px',
            width: '450px',
            textAlign: 'center',
            boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
            position: 'relative'
          }}>
            <h3 style={{ fontSize: '20px', marginBottom: '10px' }}>Confirm Payment</h3>
            <p style={{ color: '#666', marginBottom: '20px' }}>
              Please confirm the payment details below.
            </p>
            <p style={{ fontWeight: '450', marginBottom: '20px', textAlign: 'left' }}>
              Item Name : {itemName}
            </p>
            <div style={{ marginBottom: '15px', textAlign: 'left' }}>
              <label style={{ fontWeight: '600', marginBottom: '5px', display: 'block' }}>
                Paid Amount (₹):
              </label>
              <input
                type="number"
                value={paidAmount}
                readOnly
                onChange={(e) => setPaidAmount(e.target.value)}
                className="field"
              />
            </div>


            <div style={{ marginBottom: '15px', textAlign: 'left' }}>
              <label style={{ fontWeight: '600', marginBottom: '5px', display: 'block' }}>
                Payment Method:
              </label>
              <select
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
                className="drop"
              >
                <option value="cash">Cash</option>
                <option value="credit">Credit Card</option>
                <option value="bank_transfer">Bank Transfer</option>
              </select>
            </div>


            {/* Conditionally render Transaction Ref. No. field */}
            {(paymentMethod === 'credit' || paymentMethod === 'bank_transfer') && (
              <div style={{ marginBottom: '15px', textAlign: 'left' }}>
                <label style={{ fontWeight: '600', marginBottom: '5px', display: 'block' }}>
                  Transaction Ref. No.:
                </label>
                <input
                  type="text"
                  value={transactionNo}
                  onChange={(e) => setTransactionNo(e.target.value)}
                  placeholder="Enter Transaction ID / UTR Number"
                  className='field'
                />
              </div>
            )}


            <div style={{ marginBottom: '15px', textAlign: 'left' }}>
              <label style={{ fontWeight: '600', marginBottom: '5px', display: 'block' }}>
                Upload Payment Advice:
              </label>
              <input
                type="file"
                onChange={handleReceiptChange}
                className='field'
              />
            </div>


            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '20px'
            }}>
              <button
                onClick={handleConfirmPaid}
                disabled={loading}
                style={{
                  backgroundColor: '#4caf50',
                  color: '#fff',
                  padding: '10px 15px',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  width: '48%'
                }}
              >
                Mark as Paid
              </button>
              <button
                onClick={handleClosePaidModal}
                style={{
                  backgroundColor: '#f44336',
                  color: '#fff',
                  padding: '10px 15px',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  width: '48%'
                }}
              >
                Cancel
              </button>
              {loading && <Loader />}
            </div>
          </div>
        </div>
      )}


    </div>
  );
};





export default Expense;





