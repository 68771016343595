// src/screens/CandidateDB.js
import React from 'react';


const CandidateDB = () => {
 return (
   <div>
     <h1>CandidateDB</h1>
     <p>Welcome to the CandidateDB section!</p>
   </div>
 );
};




export default CandidateDB;

