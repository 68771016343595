import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const EditPayment = () => {
  const { purchase_id } = useParams(); // Get purchase_id from the route
  const [formData, setFormData] = useState({
    project_id: '',
    category_id: '',
    sub_category_id: '',
    vendor_id: '',
    amount: '',
    tax: '',
    other_expenses: '',
    total_amount: '',
    expected_delivery_date: '',
    priority: '',
    payment_type: '',
    vendor_location: '',
    vendor_type: '',
    upload_Invoice: ''
  });


  const [projects, setProjects] = useState([]); // List of projects for dropdown
  const [categories, setCategories] = useState([]); // List of categories for dropdown
  const [vendors, setVendors] = useState([]); // List of vendors for dropdown
  const [subCategories, setSubCategories] = useState([]); // List of subcategories for dropdown
  

  // Fetch payment details when the component loads
  useEffect(() => {
    const token = localStorage.getItem('authToken');
    const fetchPaymentDetails = async () => {
      try {
        const response = await fetch(`https://api.myntask.com/purchaseApproval/edit/${purchase_id}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`, // Replace with actual token
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch payment details');
        }

        const data = await response.json();
        setFormData(data);
      } catch (error) {
        console.error('Error fetching payment details:', error);
      }
    };

    fetchPaymentDetails();
  }, [purchase_id]);


      const fetchCategories = async () => {
          const token = localStorage.getItem('authToken');
          try {
              const response = await fetch('https://api.myntask.com/purchaseApproval/listcategory', {
                  method: 'GET',
                  headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Bearer ${token}`,
                  },
              });
  
              if (!response.ok) {
                  throw new Error(`Network response was not ok. Status: ${response.status}`);
              }
  
              const data = await response.json();
              setCategories(data); // Assuming the response is an array of categories
          } catch (error) {
              console.error('Error fetching categories:', error);
          }
      };
  
      useEffect(() => {
          const token = localStorage.getItem('authToken');
          const fetchProjects = async () => {
  
              console.log('Fetching projects...');
              try {
                  const response = await fetch('https://api.myntask.com/task/project_list', {
                      method: 'GET',
                      headers: {
                          Authorization: `Bearer ${token}`,
                          'Content-Type': 'application/json',
                      },
                  });
                  if (!response.ok) {
                      throw new Error(`HTTP error! status: ${response.status}`);
                  }
                  const data = await response.json();
                  setProjects(data || []);
              } catch (error) {
                  console.error('Error fetching projects:', error);
                  setProjects([]);
              }
          };
  
  
          const fetchVendors = async () => {
              try {
                  const response = await fetch('https://api.myntask.com/purchase_order/vendor_company', {
                      headers: {
                          Authorization: `Bearer ${token}`,
                      },
                  });
  
                  if (!response.ok) {
                      throw new Error(`HTTP error! status: ${response.status}`);
                  }
  
                  const data = await response.json();
                  if (Array.isArray(data)) {
                      setVendors(data);
                  } else {
                      console.error('API response is not an array:', data);
                      setVendors([]); // Fallback
                  }
              } catch (error) {
                  console.error('Error fetching vendors:', error);
              }
          };
  
          fetchProjects();
          fetchCategories();
          fetchVendors();
      }, []);


  useEffect(() => {
    const fetchSubCategories = async () => {
      const token = localStorage.getItem("authToken");
      if (!formData.category_id) return;
      try {
        const response = await fetch(`https://api.myntask.com/purchaseApproval/listsubcategory/${formData.category_id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (!response.ok) throw new Error("Failed to fetch subcategories");
        const data = await response.json();
        setSubCategories(data || []);
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      }
    };

    fetchSubCategories();
  }, [formData.category_id]);


  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

   // Handle Category Change
   const handleCategoryChange = (e) => {
    const selectedCategoryId = e.target.value;
    setFormData((prev) => ({
      ...prev,
      category_id: selectedCategoryId,
      sub_category_id: "", // Reset subcategory when category changes
    }));
  };

  // Handle Subcategory Change
  const handleSubCategoryChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      sub_category_id: e.target.value,
    }));
  };


  // Handle file input change
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFormData((prevData) => ({
        ...prevData,
        upload_Invoice: file, // Store file instead of Base64
      }));
    }
  };

  // Debugging: Check if the file is updating in state
  useEffect(() => {
    console.log("Updated upload_Invoice:", formData.upload_Invoice);
  }, [formData.upload_Invoice]);

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (!purchase_id) {
      console.error("Error: purchase_id is missing!");
      return;
    }
  
    try {
      const token = localStorage.getItem("authToken");
      const formDataToSend = new FormData();
  
      // Append form fields but rename "upload_Invoice" to "upload_invoice"
      Object.entries(formData).forEach(([key, value]) => {
        if (key === "upload_Invoice") {
          formDataToSend.append("upload_invoice", value); // Fix name here
        } else {
          formDataToSend.append(key, value);
        }
      });
  
      console.log("Sending data:");
      for (let [key, value] of formDataToSend.entries()) {
        console.log(`${key}:`, value);
      }
  
      const response = await fetch(
        `https://api.myntask.com/purchaseApproval/update/${purchase_id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formDataToSend, // Send FormData directly
        }
      );
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const responseData = await response.json();
      console.log("Purchase updated successfully:", responseData);
    } catch (error) {
      console.error("Error updating purchase:", error);
    }
  };
  

  return (
    <div className="main-container-body">
      {/* <div className="top-controls">
        <h1 className='title'>Edit Payment
          <div className="underline"></div>
        </h1>
      </div> */}
      
      <div className="add-lead-form" style={{maxWidth: '1000px'}}>
        <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Edit Payment</h1>

        <div className="lead-section">
          <div className="lead-section-heading">Basic Information</div>
          <div className="lead-form-group">
                    <label className="lead-form-label">Project Name:</label>
          <select
            className="lead-form-input"
            id="project"
            name="project_id"
            value={formData.project_id} // Ensures selected project matches fetched data
            onChange={handleChange}
            required
          >
            <option value="">Select Project</option>
            {projects.map((project) => (
              <option key={project.id} value={project.id}>
                {project.project_name}
              </option>
            ))}
          </select>
        </div>

        <div className="lead-form-row">
        <div className="lead-form-group">
        <label className='lead-form-label'>Payment Type:</label>
          <select className='lead-form-input' name="payment_type" value={formData.payment_type} onChange={handleChange} required>
            <option value="">Select Payment Type</option>
            <option value="advance">Advance</option>
            <option value="full">Full</option>
          </select>
        </div>

        <div className="lead-form-group">
          <label className='lead-form-label'>Expected Delivery Date:</label>
          <input type="date" className="lead-form-input" name="expected_delivery_date" value={formData.expected_delivery_date} onChange={handleChange} required />
        </div>
        </div>

        <div className="lead-form-row">
           {/* Category Dropdown */}
      <div className="lead-form-group">
        <label className='lead-form-label'>Category:</label>
        <select
          className="lead-form-input"
          id="category"
          name="category_id"
          value={formData.category_id || ""}
          onChange={handleCategoryChange}
          required
        >
          <option value="">Select Category</option>
          {categories.map((category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </select>
      </div>

      <div className="lead-form-group">
        <label className='lead-form-label'>Subcategory:</label>
        <select
          className="lead-form-input"
          id="subcategory"
          name="sub_category_id"
          value={formData.sub_category_id || ""}
          onChange={handleSubCategoryChange}
          required
          disabled={!formData.category_id} // Disable if no category selected
        >
          <option value="">Select Subcategory</option>
          {subCategories.map((sub) => (
            <option key={sub.id} value={sub.id}>
              {sub.name}
            </option>
          ))}
        </select>
      </div>
      </div>
      </div>


      <div className="lead-section">
      <div className="lead-section-heading">Vendor Details</div>
        <div className="lead-form-row">
        {/* Vendor Dropdown */}
<div className="lead-form-group">
  <label className='lead-form-label'>Vendor Name:</label>
  <select
    className="lead-form-input"
    id="vendor"
    name="vendor_id"
    value={formData.vendor_id} 
    onChange={handleChange}
    required
  >
    <option value="">Select Vendor</option>
    {vendors.map((vendor) => (
      <option key={vendor.id} value={vendor.id}>
        {vendor.company_name}
      </option>
    ))}
  </select>
</div>

<div className="lead-form-group">
          <label className='lead-form-label'>Vendor Type:</label>
          <select className="lead-form-input" name="vendor_type" value={formData.vendor_type} onChange={handleChange} required>
            <option value="">Select Vendor Type</option>
            <option value="regular">Regular</option>
            <option value="new_vendor">New</option>
          </select>
        </div>
        </div>

        <div className="lead-form-group">
          <label className='lead-form-label'>Vendor Location:</label>
          <input type="text" className="lead-form-input" name="vendor_location" value={formData.vendor_location} onChange={handleChange} required />
        </div>
        </div>
         
        <div className="lead-form-group">
          <label className='lead-form-label'>Priority:</label>
          <select className="lead-form-input" name="priority" value={formData.priority} onChange={handleChange} required>
            <option value="">Select Priority</option>
            <option value="high">High</option>
            <option value="medium">Medium</option>
            <option value="low">Low</option>
          </select>
        </div>

        <div className="lead-section">
        <div className="lead-section-heading">Amount Details</div>
      <div className="lead-form-row">
        <div className="lead-form-group">
          <label className='lead-form-label'>Amount:</label>
          <input type="number" className="lead-form-input" name="amount" value={formData.amount} onChange={handleChange} required />
        </div>

        <div className="lead-form-group">
          <label className='lead-form-label'>Tax:</label>
          <input type="number" className="lead-form-input" name="tax" value={formData.tax} onChange={handleChange} />
        </div>
        </div>

        <div className="lead-form-row">
        <div className="lead-form-group">
          <label className='lead-form-label'>Other Expenses:</label>
          <input type="number" className="lead-form-input" name="other_expenses" value={formData.other_expenses} onChange={handleChange} />
        </div>

        <div className="lead-form-group">
          <label className='lead-form-label'>Total Amount:</label>
          <input type="number" className="lead-form-input" name="total_amount" value={formData.total_amount} onChange={handleChange} required />
        </div>
        </div>
        </div>


        <div className="lead-form-group">
  <label className='lead-form-label'>Invoice:</label>
  {formData.upload_Invoice && (
    <img src={formData.upload_Invoice} alt="Invoice Preview" width="100" height="100" />
  )}
  <input type="file" accept="image/*" onChange={handleFileChange} />
</div>


        <button type="submit" className="submit-button">Update Payment</button>
      </div>
    </div>
  
  );
};

export default EditPayment;
