import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  Box,
  Stack,
  Typography,
} from '@mui/material';
import { Vortex } from 'react-loader-spinner';


const EditProject = () => {
  const { id } = useParams(); // Extract the project ID from the URL
  const [projectData, setProjectData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [categories, setCategories] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [clients, setClients] = useState([]);
  const statuses = ['not started', 'in progress', 'completed', 'on hold']; // Example statuses
  const [members, setMembers] = useState([]);
  const [isAssignDropdownOpen, setIsAssignDropdownOpen] = useState(false); // Dropdown visibility toggle
  const [departments, setDepartments] = useState([]);
  const navigate = useNavigate();
  const [dropdownState, setDropdownState] = useState({
    clientAccess: false,
    clientNotification: false,
    manualTimelog: false,
  });


  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('authToken'); // Retrieve token from localStorage
      if (!token) {
        setError('No authentication token found.');
        setLoading(false);
        return;
      }


      try {


        // Fetch categories
        const categoryResponse = await fetch('https://api.myntask.com/project/category', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });


        if (!categoryResponse.ok) {
          throw new Error(`Network response was not ok. Status: ${categoryResponse.status}`);
        }


        const categoryData = await categoryResponse.json();
        setCategories(categoryData);


        // Fetch currencies
        const currencyResponse = await fetch('https://api.myntask.com/company/currency', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });


        if (!currencyResponse.ok) {
          throw new Error(`Network response was not ok. Status: ${currencyResponse.status}`);
        }


        const currencyData = await currencyResponse.json();
        setCurrencies(currencyData);


        // Fetch clients
        const clientResponse = await fetch('https://api.myntask.com/project/client', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });


        if (!clientResponse.ok) {
          throw new Error(`Network response was not ok. Status: ${clientResponse.status}`);
        }


        const clientData = await clientResponse.json();


        // Ensure clientData is an array
        if (Array.isArray(clientData)) {
          setClients(clientData); // Set the data only if it's an array
        } else {
          setError('Unexpected data format for clients.');
          setClients([]); // Clear the data if not an array
        }


        // Fetch departments
        const departmentResponse = await fetch('https://api.myntask.com/employee/department', {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
          },
      });

      if (!departmentResponse.ok) {
          throw new Error(`Network response was not ok. Status: ${departmentResponse.status}`);
      }

      const departmentData = await departmentResponse.json();
      setDepartments(departmentData);


      } catch (error) {
        console.error(error); // Log the actual error for debugging
        setError('Failed to fetch data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };


    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchProjectData = async () => {
      const token = localStorage.getItem('authToken'); // Get token from localStorage
      try {
        const projectResponse = await fetch(`https://api.myntask.com/project/edit/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`, // Include token in the header
          },
        });

        if (!projectResponse.ok) {
          throw new Error(`Network response was not ok. Status: ${projectResponse.status}`);
        }

        const projectData = await projectResponse.json();

        // Ensure `assign_to` is an array of strings
        const updatedProjectData = {
          ...projectData[0],
          assign_to: Array.isArray(projectData[0].assign_to)
            ? projectData[0].assign_to.map(String) // Convert all IDs to strings for consistency
            : [],
        };

        setProjectData(updatedProjectData);
        console.log('coming data is ',projectData);
      } catch (error) {
        console.error('Error fetching project data:', error);
      }
    };

    fetchProjectData();
  }, [id]);


  useEffect(() => {
    // Fetch members when component mounts
    const fetchMembers = async () => {
      const token = localStorage.getItem('authToken'); // Get token from localStorage
      try {
        const response = await fetch('https://api.myntask.com/project/member_list', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`, // Attach the token in the request header
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch members');
        }
        const data = await response.json();
        setMembers(data); // Set members state with fetched data
      } catch (error) {
        console.error('Error fetching members:', error);
      }
    };

    fetchMembers();
  }, []);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProjectData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const handleStatusChange = (e) => {
    setProjectData((prevData) => ({
      ...prevData,
      public: Number(e.target.value), // Convert "0" or "1" (string) to number 0 or 1
    }));
  };
  


  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    setProjectData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  // Ensure projectData is never null or undefined
  const safeProjectData = projectData || {};  // Fallback to empty object if projectData is null or undefined
  // const assignedMembers = safeProjectData.assign_to || [];  // Default to an empty array if assign_to is not set

  // Handle toggle for dropdown visibility
  const handleDropdownToggle = () => {
    setIsAssignDropdownOpen((prev) => !prev);
  };

  const handleMultiSelect = (memberId) => {
    if (!projectData) return;

    const selectedMembers = Array.isArray(projectData.assign_to)
      ? projectData.assign_to.includes(memberId)
        ? projectData.assign_to.filter((id) => id !== memberId) // Remove if already selected
        : [...projectData.assign_to, memberId] // Add if not selected
      : [memberId]; // Initialize if empty

    setProjectData({
      ...projectData,
      assign_to: selectedMembers,
    });
  };
  

  const handleClientAccessChange = (e) => {
    setProjectData((prevData) => ({
      ...prevData,
      client_access: e.target.value === "enable" ? 1 : 0, // Convert "enable" to 1, "disable" to 0
    }));
  };
  


  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission

    const token = localStorage.getItem('authToken'); // Retrieve token from localStorage

    if (!token) {
      setError('No authentication token found.');
      Swal.fire({
        icon: 'error',
        title: 'Authentication Error',
        text: 'No authentication token found.',
        timer: 3000,
        showConfirmButton: false,
        toast: true,
        position: 'top-end',
      });
      return;
    }

    const updatedData = {
      project_id: id,
      project_short_code: projectData.project_short_code || '',
      project_name: projectData.project_name || '',
      start_date: projectData.start_date || '',
      deadline: projectData.deadline || '',
      category_id: projectData.category_id || null,
      client_id: projectData.client_id || null,
      department_id: projectData.department_id || '',
      project_summary: projectData.project_summary || '',
      // Ensure `assign_to` is always sent as an array
      assign_to: Array.isArray(projectData.assign_to) ? projectData.assign_to : [],
      status: projectData.status || '',
      currency_id: projectData.currency_id || '',
      project_budget: projectData.project_budget || null,
      client_access: projectData.client_access, // Sends 1 for "Enable", 0 for "Disable"
      public: projectData.public, // Sends 1 for Public, 0 for Private
      allow_client_notification: projectData.allow_client_notification === 'enable' ? 'enable' : 'disable',
      manual_timelog: projectData.manual_timelog === 'enable' ? 'enable' : 'disable',
      total_points: projectData.total_points || null,
    };

    console.log('Data being sent to API:', updatedData); // Log the data before sending

    try {
      const response = await fetch('https://api.myntask.com/project/update', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Include token in the header
        },
        body: JSON.stringify(updatedData),
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok. Status: ${response.status}`);
      }

      const responseData = await response.json(); // Parse JSON response body
      console.log('Server response:', responseData);

      Swal.fire({
        icon: 'success',
        title: 'Project Updated',
        text: 'Project updated successfully!',
        timer: 3000,
        showConfirmButton: false,
        toast: true,
        position: 'top-end',
      });
      navigate(-1);
      // Optionally, update the UI or state based on the response
      // setProjectData(responseData.updatedProject); // Example: update the state with the updated project data
    } catch (error) {
      console.error('Failed to update project:', error);

      Swal.fire({
        icon: 'error',
        title: 'Update Failed',
        text: 'Failed to update project. Please try again later.',
        timer: 3000,
        showConfirmButton: false,
        toast: true,
        position: 'top-end',
      });

      setError('Failed to update project. Please try again later.');
    }
  };


  useEffect(() => {
    // Adjust the timer duration here (e.g., 3000ms = 3 seconds)
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    // Cleanup the timer on component unmount
    return () => clearTimeout(timer);
  }, []);
  //..........Loading Spinner ..........................................
  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }


  const handleCancel = () => {
    navigate(-1);
  };


  // if (error) {
  //   return (
  //     <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
  //       <Typography variant="h6" color="error">
  //         {error}
  //       </Typography>
  //     </Box>
  //   );
  // }



  return (
    <div className='formContainer'>

      <h3 className="center-heading">Edit Project</h3>
      {projectData && (
        <form onSubmit={handleSubmit}>
          <div className="employeeForm">
            <div className="container1">
              <label className="label">Project Short Code</label>
              <input
                type="text"
                name="project_short_code"
                value={projectData.project_short_code || ''}
                onChange={handleInputChange}
                className="field"
              />
            </div>

            <div className="container1">
              <label className="label">Project Name <span className="required">*</span></label>
              <input
                type="text"
                name="project_name"
                value={projectData.project_name || ''}
                onChange={handleInputChange}
                className="field"
              />
            </div>

            <div className="container1">
              <label className="label">Start Date <span className="required">*</span></label>
              <input
                type="date"
                name="start_date"
                value={
                  projectData.start_date && !isNaN(new Date(projectData.start_date))
                    ? new Date(projectData.start_date).toISOString().split('T')[0]
                    : ''
                }
                onChange={handleInputChange}
                className="field"
              />
            </div>

            <div className="container1">
              <label className="label">Deadline <span className="required">*</span></label>
              <input
                type="date"
                name="deadline"
                value={
                  projectData.deadline && !isNaN(new Date(projectData.deadline))
                    ? new Date(projectData.deadline).toISOString().split('T')[0]
                    : ''
                }
                onChange={handleInputChange}
                className="field"
              />
            </div>


            <div className="container1">
              <label className="label">Category <span className="required">*</span></label>
              <select
                name="category_id"
                value={projectData.category_id || ''}
                onChange={handleInputChange}
                className="drop"
              >
                <option value="">None</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.category_name}
                  </option>
                ))}
              </select>
            </div>

            <div className="container1">
              <label className="label">Currency <span className="required">*</span></label>
              <select
                name="currency_id"
                value={projectData.currency_id || ''}
                onChange={handleInputChange}
                className="drop"
              >
                <option value="">None</option>
                {currencies.map((currency) => (
                  <option key={currency.id} value={currency.id}>
                    {currency.currency_code} ({currency.currency_symbol})
                  </option>
                ))}
              </select>
            </div>

            <div className="container1">
              <label className="label">Client <span className="required">*</span></label>
              <select
                name="client_id"
                value={projectData.client_id || ''}
                onChange={handleInputChange}
                className="drop"
              >
                <option value="">None</option>
                {clients.map((client) => (
                  <option key={client.client_id} value={client.client_id}>
                    {client.client_name}
                  </option>
                ))}
              </select>
            </div>

            <div className="container1">
            <label className="label">Department <span className="required">*</span></label>
                <select
                    name="department_id"
                    value={projectData.department_id}
                    onChange={handleInputChange}
                    className="drop"
                    required
                >
                    <option value="">Select Department</option>
                    {departments.map((department) => (
                        <option key={department.id} value={department.id}>
                            {department.department}
                        </option>
                    ))}
                </select>
            </div>



            <div className="container1">
              <label className="label">Assign To <span className="required">*</span></label>
              <div
                className="custom-dropdown"
                onClick={() => setIsAssignDropdownOpen((prev) => !prev)}
              >
                <div className="selected-category">
                  {Array.isArray(projectData.assign_to) && projectData.assign_to.length > 0
                    ? members
                      .filter((member) => projectData.assign_to.includes(member.member_id.toString()))
                      .map((selectedMember) => selectedMember.member_name)
                      .join(", ")
                    : "Select Members"}
                </div>


                {isAssignDropdownOpen && (
                  <div className="dropdown-options">
                    {members.map((member) => (
                      <div key={member.id} style={{
                        padding: '10px',
                        display: 'flex',
                        alignContent: 'flex-start',
                        gap: '8px'
                      }}>
                        <input
                          type="checkbox"
                          id={`member-${member.member_id}`}
                          value={member.member_id}
                          checked={
                            Array.isArray(projectData.assign_to) &&
                            projectData.assign_to.includes(member.member_id.toString())
                          }
                          onChange={() => handleMultiSelect(member.member_id.toString())}
                        />
                        <label htmlFor={`member-${member.id}`}>{member.member_name}</label>
                      </div>
                    ))}
                  </div>

                )}
              </div>
            </div>



            <div className="container1">
              <label className="label">Status </label>
              <select
                name="status"
                value={projectData.status || ''}
                onChange={handleDropdownChange}
                className="drop"
              >
                {statuses.map((status) => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </select>
            </div>

            <div className="container1">
              <label className="label">Project Budget</label>
              <input
                type="number"
                name="project_budget"
                value={projectData.project_budget || ''}
                onChange={handleInputChange}
                className="field"
              />
            </div>

            <div className="container1">
              <label className="label">Total Points</label>
              <input
                type="number"
                name="total_points"
                value={projectData.total_points || ''}
                onChange={handleInputChange}
                className="field"
              />
            </div>

            <div className="container1">
  <label className="label">Client Access</label>
  <select
    name="client_access"
    value={projectData.client_access === 1 ? "enable" : "disable"} // Show correct value
    onChange={handleClientAccessChange} // Use a new function
    className="drop"
  >
    <option value="enable">Enable</option>
    <option value="disable">Disable</option>
  </select>
</div>


            <div className="container1">
              <label className="label">Allow Client Notification</label>
              <select
                name="allow_client_notification"
                value={projectData.allow_client_notification || 'disable'}
                onChange={handleDropdownChange}
                className="drop"
              >
                <option value="enable">Enable</option>
                <option value="disable">Disable</option>
              </select>
            </div>

            <div className="container1">
              <label className="label">Manual Timelog</label>
              <select
                name="manual_timelog"
                value={projectData.manual_timelog || 'disable'}
                onChange={handleDropdownChange}
                className="drop"
              >
                <option value="enable">Enable</option>
                <option value="disable">Disable</option>
              </select>
            </div>

            <div className="container1">
  <label className="label">Visibility</label>
  <div>
    <input
      type="radio"
      name="public"
      value={1} // Set value as number 1
      checked={projectData.public === 1} // Check if public is 1
      onChange={handleStatusChange}
    />{' '}
    Public
    <input
      type="radio"
      name="public"
      value={0} // Set value as number 0
      checked={projectData.public === 0} // Check if public is 0
      onChange={handleStatusChange}
      style={{ marginLeft: '20px' }}
    />{' '}
    Private
  </div>
</div>


          </div>

          <div className="container1" style={{ margin: '15px 0px' }}>
            <label className="label">Project Summary</label>
            <textarea
              name="project_summary"
              value={projectData.project_summary || ''}
              onChange={handleInputChange}
              className='text'
            />
          </div>


          <div style={{ display: 'flex', justifyContent: 'end', marginTop: '20px', gap: '20px' }}>
            <button type='button' className='color-button' onClick={handleCancel}>
              Cancel
            </button>
            <button
              onClick={handleSubmit}
              className='color-button'
            >
              Save
            </button>
          </div>
        </form>
      )}
    </div>

  );
};




export default EditProject;
