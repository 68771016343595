import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import { FaInstagram, FaTwitter, FaFacebook } from 'react-icons/fa';
import { Margin } from '@mui/icons-material';

const ProfileInfo = ({ info, Appreciation, Tickets, late_attendance, leave_taken, total_task, total_project, handleEditClick, handleViewAllProjects, userId }) => {
  const [projects, setProjects] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [error, setError] = useState('');
  const navigate = useNavigate(); // Add this line

  useEffect(() => {
    const fetchProjectsAndTasks = async () => {
      const token = localStorage.getItem('authToken');
      const projectUrl = `https://api.myntask.com/project/list/${userId}`;
      const taskUrl = `https://api.myntask.com/task/list/${userId}`;

      console.log('Fetching Project URL:', projectUrl);
      console.log('Fetching Task URL:', taskUrl);

      if (!token) {
        console.error('No auth token found');
        setError('No token found');
        return;
      }

      try {
        // Fetch projects
        const projectResponse = await fetch(projectUrl, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!projectResponse.ok) {
          throw new Error(`Error: ${projectResponse.statusText}`);
        }

        const projectData = await projectResponse.json();
        console.log('Fetched projects:', projectData);
        setProjects(projectData.projects);

        // Fetch tasks
        const taskResponse = await fetch(taskUrl, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!taskResponse.ok) {
          throw new Error(`Error: ${taskResponse.statusText}`);
        }

        const taskData = await taskResponse.json();
        console.log('Fetched tasks:', taskData);
        setTasks(taskData);

      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to fetch data. Please try again later.');
      }
    };

    fetchProjectsAndTasks();
  }, [userId]);

  const pieData = {
    labels: ['Total Task', 'Total Project', 'Late Attendance', 'Leave Taken'],
    datasets: [
      {
        label: 'Summary Data',
        data: [total_task, total_project, late_attendance, leave_taken],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
      },
    ],
  };

  const pieOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.leftPanel}>
        <div style={styles.profileCard}>
          {info.image ? (
            <img src={info.image} alt={info.user_name || 'User'} style={styles.profileImage} />
          ) : (
            <div style={styles.placeholderImage}>No Image</div>
          )}
          <div style={styles.profileContent}>
            <h2 style={styles.profileName}>{info.user_name}</h2>
            <p style={styles.profileTitle}>{info.designation}</p>
            <p style={styles.profileRole}>{info.role_name}</p>

            <div style={styles.stats}>
              <div style={styles.statItem}>
                <strong style={styles.statValue}>{total_task}</strong>
                <span style={styles.statLabel}>Total Task</span>
              </div>
              <div style={styles.statItem}>
                <strong style={styles.statValue}>{late_attendance}</strong>
                <span style={styles.statLabel}>Late Attendance</span>
              </div>
              <div style={styles.statItem}>
                <strong style={styles.statValue}>{total_project}</strong>
                <span style={styles.statLabel}>Total Projects</span>
              </div>
            </div>

            <div style={styles.actionButtons}>
              <button onClick={() => navigate('/message')} style={styles.messageButton}>
                Message
              </button>

            </div>
          </div>
        </div>

        {/* Task List Rendering */}
        <div style={styles.sectionCard}>
          <div style={styles.taskListHeader}>
            <h3 style={styles.sectionTitle}>Tasks List</h3>
          </div>
          {error && <p style={styles.errorText}>{error}</p>}
          {tasks.length > 0 ? (
            <ul style={styles.taskList}>
              {tasks.slice(0, 5).map((task) => (
                <li key={task.task_id} style={styles.taskItem}>
                  <div style={styles.taskDetails}>
                    <span style={styles.taskCode}>{task.code || 'No Code'}</span>
                    <span style={styles.taskTitle}>{task.task || 'Untitled Task'}</span>
                    <span style={styles.taskStatus}>{task.status || 'No Status'}</span>
                    <span style={styles.taskProject}>{task.project_name || 'No Project'}</span>
                  </div>
                </li>
              ))}
            </ul>
          ) : !error ? (
            <p style={styles.noDataText}>No tasks available.</p>
          ) : null}
        </div>
      </div>

      <div style={styles.rightPanel}>
        <div style={styles.sectionCard}>
          <h3 style={styles.sectionTitle}>Summary Chart</h3>
          <div style={styles.summaryContent}>
            <div style={styles.summaryChart}>
              <Pie data={pieData} options={pieOptions} />
            </div>
          </div>
        </div>

        <div style={styles.sectionCard}>
          <div style={styles.projectListHeader}>
            <h3 style={styles.sectionTitle}>Projects List</h3>
          </div>
          {error && <p style={styles.errorText}>{error}</p>}
          {projects.length > 0 ? (
            <ul style={styles.projectList}>
              {projects.slice(0, 6).map((project) => (
                <li key={project.project_id} style={styles.projectItem}>
                  <div style={styles.projectDetails}>
                    <span style={styles.projectCode}>{project.code || 'N/A'}</span>
                    <span style={styles.projectName}>{project.project_name}</span>
                    <span style={styles.projectDeadline}>{project.deadline || 'No deadline specified'}</span>
                  </div>
                </li>
              ))}
            </ul>
          ) : !error ? (
            <p style={styles.noDataText}>No projects available.</p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

// CSS-in-JS styles
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '20px',
    gap: '20px',
    width: '100%',
    boxSizing: 'border-box',
  },
  leftPanel: {
    flex: '1',
    minWidth: '300px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#b5d1f5',
    borderRadius: '12px',
    padding: '20px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.05)',
  },
  profileCard: {
    textAlign: 'center',
    width: '100%',
  },
  profileImage: {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    objectFit: 'cover',
    marginBottom: '15px',
  },
  profileContent: {
    textAlign: 'center',
  },
  profileName: {
    fontSize: '1.4em',
    fontWeight: 'bold',
    color: '#333',
    marginBottom: '10px',
  },
  profileTitle: {
    fontSize: '1.1em',
    color: '#777',
    marginBottom: '5px',
  },
  profileRole: {
    fontSize: '0.9em',
    color: '#999',
    marginBottom: '15px',
  },
  stats: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '15px',
    width: '100%',
  },
  statItem: {
    textAlign: 'center',
    flex: '1',
  },
  statValue: {
    fontSize: '1.4em',
    fontWeight: 'bold',
    color: '#333',
  },
  statLabel: {
    display: 'block',
    color: '#777',
    fontSize: '0.8em',
  },
  sectionCard: {
    backgroundColor: 'rgba(119, 104, 104, 0.05)',
    borderRadius: '8px',
    border: '1px solid #ddd',
    padding: '20px',
    boxShadow: '0 4px 10px rgba(178, 163, 163, 0.05)',
    width: '100%',
    marginBottom: '20px',
    marginTop: '20px',
    height: '100%',
  },
  taskListHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '15px',
  },
  viewAllButton: {
    backgroundColor: '#007bff',
    color: '#ffffff',
    border: 'none',
    padding: '6px 12px',
    borderRadius: '20px',
    cursor: 'pointer',
    fontWeight: 'bold',
    transition: 'background-color 0.3s ease',
    fontSize: '0.9em',
  },
  taskList: {
    listStyleType: 'none',
    padding: 0,
  },
  taskItem: {
    marginBottom: '10px',
    padding: '10px',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    boxShadow: '0 1px 4px rgba(0, 0, 0, 0.05)',
  },
  taskDetails: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    gap: '15px'
  },
  taskCode: {
    flex: '1',
    fontSize: '0.75em', // Reduced font size
    fontWeight: 'bold',
    color: '#555',
  },
  taskTitle: {
    flex: '2',
    fontSize: '0.75em', // Reduced font size
    fontWeight: 'bold',
    color: '#333',
    textAlign: 'center',

  },
  taskStatus: {
    flex: '1',
    fontSize: '0.75em', // Reduced font size
    color: '#007bff',
    textAlign: 'center',
  },
  taskDeadline: {
    flex: '1',
    fontSize: '0.75em', // Reduced font size
    color: '#d9534f',
    textAlign: 'right',
  },
  taskProject: {
    flex: '2',
    fontSize: '0.75em', // Reduced font size
    color: '#555',
    textAlign: 'center',
  },
  rightPanel: {
    flex: '2',
    minWidth: '300px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  projectListHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '15px',
  },
  projectList: {
    listStyleType: 'none',
    padding: 0,
  },
  projectItem: {
    marginBottom: '10px',
    padding: '10px',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    boxShadow: '0 1px 4px rgba(0, 0, 0, 0.05)',
  },
  projectDetails: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  projectCode: {
    flex: '1',
    fontSize: '0.9em',
    fontWeight: 'bold',
    color: '#555',
  },
  projectName: {
    flex: '2',
    fontSize: '0.9em',
    fontWeight: 'bold',
    color: '#333',
    textAlign: 'center',
  },
  projectDeadline: {
    flex: '1',
    fontSize: '0.9em',
    color: '#d9534f',
    textAlign: 'right',
  },
  viewAllButton: {
    backgroundColor: '#007bff',
    color: '#ffffff',
    border: 'none',
    padding: '6px 12px',
    borderRadius: '20px',
    cursor: 'pointer',
    fontWeight: 'bold',
    transition: 'background-color 0.3s ease',
    fontSize: '0.9em',
  },
  errorText: {
    color: '#d9534f',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  noDataText: {
    textAlign: 'center',
    color: '#777',
    fontSize: '0.9em',
  },
};

export default ProfileInfo;
