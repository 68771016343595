import React, { useState, useEffect } from "react";
import { AppBar, Tabs, Tab, Typography, Box, Grid } from "@mui/material";
import LeadReport from "../Components/LeadReport";
import DealReport from "../Components/DealReport";
import ProposalReport from "../Components/ProposalReport";
import CRMOverviewReport from "../Components/CRMOverviewReport";

const CRMReports = () => {
    const [reportData, setReportData] = useState({
        leads: [],
        deals: [],
        proposals: [],
        revenue: [],
        conversion: [],
        monthly_revenue: [],
    });
    const [value, setValue] = useState(0);

    useEffect(() => {
        // Simulating fetching report data
        setReportData({
            leads: [
                { name: "Total Leads", value: 150 },
                { name: "New Leads", value: 45 },
                { name: "Converted Leads", value: 30 },
                { name: "Lost Leads", value: 20 },
            ],
            deals: [
                { name: "Total Deals", value: 100 },
                { name: "Won Deals", value: 60 },
                { name: "Lost Deals", value: 40 },
            ],
            proposals: [
                { name: "Total Proposals Sent", value: 80 },
                { name: "Accepted Proposals", value: 50 },
                { name: "Rejected Proposals", value: 30 },
            ],
            revenue: [
                { name: "Jan", revenue: 5000 },
                { name: "Feb", revenue: 7000 },
                { name: "Mar", revenue: 6500 },
                { name: "Apr", revenue: 8000 },
            ],
            conversion: [
                { name: "Leads to Deals", value: 40 },
                { name: "Deals Closed", value: 60 },
            ],
            monthly_revenue: [
                { month: "Jan", revenue: 20000 },
                { month: "Feb", revenue: 15000 },
                { month: "Mar", revenue: 20000 },
                { month: "Apr", revenue: 25000 },
                { month: "May", revenue: 30000 },
                { month: "June", revenue: 30000 },
                { month: "July", revenue: 40000 },
                { month: "Aug", revenue: 45000 },
                { month: "Sept", revenue: 50000 },
                { month: "Oct", revenue: 45000 },
                { month: "Nov", revenue: 60000 },
                { month: "Dec", revenue: 65000 },
            ],
        });
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: "100%" }}>
            <div className="top-control">
                <h1>CRM Report</h1>
            </div>
            <AppBar position="static" color="default">
                <Tabs value={value} onChange={handleChange} textColor="inherit" indicatorColor="secondary">
                    <Tab label="Overview" />
                    {/* <Tab label="Lead" />
                    <Tab label="Deal" />
                    <Tab label="Proposal" /> */}
                </Tabs>
            </AppBar>
            <Grid container spacing={3} p={3}>
                {value === 0 && <CRMOverviewReport reportData={reportData} />}
                {value === 1 && <LeadReport reportData={reportData} />}
                {value === 2 && <DealReport reportData={reportData} />}
                {value === 3 && <ProposalReport reportData={reportData} />}
            </Grid>
        </Box>
    );
};

export default CRMReports;