import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { MdDelete } from "react-icons/md";
import './../Screens/AddEmployee.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import { Box, Modal, Stack, Typography } from '@mui/material';
import iconImage from '../assets/Icon.png';
import './../Screens/css/Designation.css';
import { Vortex } from 'react-loader-spinner';
import defaultimage from '../assets/vector.jpeg';


const AddProjectTask = () => {
    const { projectId } = useParams(); // Get projectId from URL
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const projectName = queryParams.get("name"); // Extract projectName
    const navigate = useNavigate();

    const [taskData, setTaskData] = useState({
        title: '',
        task_category_id: '',
        project_id: '',
        start_date: '',
        due_date: '',
        description: '',
        status_id: '',
        priority: '',
        private: 0,
        billable: 0,
        label_id: '',
        milestone_id: '',
        estimate_minutes: '',
        task_point: '',
        assigned_to: '',
        dependent_task_id: '',
        repeat: 0, // New input for repeat
        repeat_count: '',
        repeat_type: 'day', // Default repeat type
        repeat_cycles: '',
        file: null
    });




    const [categories, setCategories] = useState([]);
    const [projects, setProjects] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [labels, setLabels] = useState([]);
    const [milestones, setMilestones] = useState([]);
    const [members, setMembers] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState("");
    const [isDependent, setIsDependent] = useState(false);
    const [dependentTasks, setDependentTasks] = useState([]);
    const [selectedDependentTask, setSelectedDependentTask] = useState('');
    const [isAssignDropdownOpen, setIsAssignDropdownOpen] = useState(false);
    const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
    const [isDropdown, setIsDropdown] = useState(false);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [validationErrors, setValidationErrors] = useState({});



    const handleValidation = () => {
        const errors = {};
        if (!taskData.title.trim()) errors.title = " *";
        if (!taskData.start_date) errors.start_date = " *";
        if (!taskData.due_date) errors.due_date = " *";
        if (!taskData.task_category_id) errors.task_category_id = " *";
        // if (!taskData.project_id) errors.project_id = " *";
        if (taskData.assigned_to.length === 0) errors.assigned_to = "";
        if (!taskData.status_id) errors.status_id = " *";
        if (!taskData.priority) errors.priority = " *";

        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };


    const fetchDependentTasks = async () => {
        const token = localStorage.getItem("authToken");
        // const projectId = taskData.project_id; // Get project ID from taskData
        console.log('project id is', projectId);
        try {
            const response = await fetch(`https://api.myntask.com/task/task_dependent_list/${projectId}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            });


            if (!response.ok) {
                throw new Error("Failed to fetch dependent tasks");
            }

            const result = await response.json();
            setDependentTasks(result); // Set the dependent tasks
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching dependent tasks:", error);
            alert("Failed to fetch dependent tasks. Please select a project.");
            setIsLoading(false);
        }
    };



    const openModal = () => {
        setIsModalOpen(true);
    };


    // Close the modal
    const closeModal = () => {
        setIsModalOpen(false);
        setNewCategoryName(""); // Clear the input input when closing modal
    };



    // Handle the form submission for adding a new category
    const handleAddCategory = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem("authToken");


        const data = {
            name: newCategoryName,
        };

        setIsLoading(true);

        try {
            const response = await fetch("https://api.myntask.com/task/task_category_add", {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });


            if (!response.ok) {
                throw new Error("Failed to add category");
            }


            const result = await response.json();
            console.log("Category added successfully");


            const newCategory = {
                id: Date.now(), // This can be changed to whatever logic you have for generating IDs
                category_name: newCategoryName
            };

            setCategories((prevCategories) => [...prevCategories, newCategory]);


            // Show success SweetAlert notification
            Swal.fire({
                icon: "success",
                title: "Category added successfully",
                showConfirmButton: false,
                timer: 1500,
            })


            // Close the modal after successful submission
            closeModal();
            setIsLoading(false);

        } catch (error) {
            console.error("Error adding category:", error);
            Swal.fire({
                icon: "error",
                title: "Error adding category",
                text: error.message,
                showConfirmButton: false,
                timer: 1500,
            })
        }
        setIsLoading(false);
    };




    // Handle the category deletion
    const handleDeleteCategory = async (categoryId) => {
        const token = localStorage.getItem("authToken");
        console.log('category id is', categoryId);
        try {
            const response = await fetch(`https://api.myntask.com/task/task_category_delete/${categoryId}`, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            });




            if (!response.ok) {
                throw new Error("Failed to delete category");
            }

            const result = await response.json();
            console.log("Category deleted successfully");

            // Remove the deleted category from the list
            setCategories(categories.filter((category) => category.id !== categoryId));


            Swal.fire({
                icon: "success",
                title: "Category deleted successfully!",
                showConfirmButton: false,
                timer: 1500,
            })


        } catch (error) {
            console.error("Error deleting category:", error);
            Swal.fire({
                icon: "error",
                title: "Error deleting category",
                text: "Please try again",
                showConfirmButton: false,
                timer: 1500,
            })
        }
    };





    useEffect(() => {
        const fetchData = async (url, setter) => {
            const token = localStorage.getItem('authToken');
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setter(data || []);
                setIsLoading(false);
            } catch (error) {
                console.error(`Error fetching data from ${url}:`, error);
                setError('Failed to fetch data');
                setIsLoading(false);
                setter([]);
            }
        };


        fetchData('https://api.myntask.com/task/task_category', setCategories);
        fetchData('https://api.myntask.com/task/project_list', setProjects);
        fetchData('https://api.myntask.com/task/task_status', setStatuses);
        fetchData('https://api.myntask.com/employee/member', setMembers);
    }, []);




    useEffect(() => {
       
            const fetchLabels = async () => {
                const token = localStorage.getItem('authToken');
                try {
                    const response = await fetch(`https://api.myntask.com/task/task_label/${projectId}`, {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    });
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    const data = await response.json();
                    setLabels(data || []);
                } catch (error) {
                    console.error('Error fetching labels:', error);
                    setLabels([]);
                }
            };




            const fetchMilestones = async () => {
                const token = localStorage.getItem('authToken');
                try {
                    const response = await fetch(`https://api.myntask.com/project/milestone_list/${projectId}`, {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    });
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    const data = await response.json();
                    setMilestones(data || []);
                } catch (error) {
                    console.error('Error fetching milestones:', error);
                    setError('Failed to fetch milestones');
                    setMilestones([]);
                }
            };


            fetchLabels();
            fetchMilestones();
        
    }, [projectId]);




    const handleDependentChange = () => {
        setIsDependent((prev) => !prev);
        if (!isDependent) {
            fetchDependentTasks(); // Fetch tasks when checkbox is checked
        } else {
            setDependentTasks([]); // Clear dependent tasks when unchecked (optional)
            setSelectedDependentTask(null); // Reset selected dependent task when unchecked
            setTaskData((prevData) => ({ ...prevData, dependent_task_id: null })); // Clear dependent task ID in taskData
        }
    };





    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setTaskData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? (checked ? 1 : 0) : value,
        }));
    };




    const handleMultiSelect = (memberId) => {
        setTaskData((prevData) => {
            const updatedAssignedTo = prevData.assigned_to.includes(memberId)
                ? prevData.assigned_to.filter((id) => id !== memberId) // Unselect if already selected
                : [...prevData.assigned_to, memberId]; // Add member if not selected


            return { ...prevData, assigned_to: updatedAssignedTo };
        });
    };



    const handleChangeCategory = (categoryId) => {
        setTaskData((prevData) => ({
            ...prevData,
            task_category_id: categoryId,
        }));
        setIsCategoryDropdownOpen(false); // Close the category dropdown after selection
    };





    const handleFileChange = (e) => {
        setTaskData((prevData) => ({
            ...prevData,
            file: e.target.files[0], // Capture the file
        }));
    };




    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!handleValidation()) {
            Swal.fire({
                icon: 'error',
                text: 'Please fill in all required fields!',
            });
            return;
        }

        const token = localStorage.getItem('authToken');
        const taskDataToSend = {
            ...taskData,
            label_id: taskData.label_id || null,
            milestone_id: taskData.milestone_id || null,
            dependent_task_id: taskData.dependent_task_id || null,
            task_category_id: taskData.task_category_id || null,
            project_id: projectId || null,
            status_id: taskData.status_id || null,
            due_date: taskData.due_date || null,
        };


        console.log('Task data being sent:', taskDataToSend);
        setIsLoading(true);

        try {
            const response = await fetch('https://api.myntask.com/task/save', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(taskDataToSend),
            });


            if (!response.ok) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Failed to save task',
                });
                return;
            }

            // Success Swal notification
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Task saved successfully',
                showConfirmButton: false,
                timer: 1500
            });
            console.log('Task saved successfully!');


            // Reset the task data
            setTaskData({
                title: '',
                task_category_id: '',
                project_id: '',
                start_date: '',
                due_date: '',
                description: '',
                status_id: '',
                priority: '',
                private: 0,
                billable: 0,
                label_id: '',
                milestone_id: '',
                estimate_minutes: '',
                task_point: '',
                assigned_to: '',
                dependent_task_id: '',
                repeat: 0,
                repeat_count: '',
                repeat_type: 'day',
                repeat_cycles: '',
                file: null,
            });


            // Navigate back to the task page
            navigate(-1);

            setIsLoading(false);

        } catch (error) {
            console.error('Error saving task:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'An error occurred while saving the task. Please try again.',
            });
        }
        setIsLoading(false);
    };




    const handleClose = () => {
        navigate(-1);
    };




    //..........Loading Spinner ..........................................

    useEffect(() => {
        // Adjust the timer duration here (e.g., 3000ms = 3 seconds)
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 300);

        // Cleanup the timer on component unmount
        return () => clearTimeout(timer);
    }, []);


    if (isLoading) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    backgroundColor: "#f5f5f5",
                }}
            >
                <Stack alignItems="center">
                    <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
                    <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
                        Loading....
                    </Box>
                </Stack>
            </Box>
        );
    }

    if (error) {
        return (
            <Typography
                color="error"
                sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
            >
                {error}
            </Typography>
        );
    }



    return (
        <div className="main-container">
            <ToastContainer />
            <div className="top-controls">
                <h1 className='title'>Add Task 
                    <div className="underline"></div>
                </h1>
            </div>
            <section className="section">
                <h3 className="sectionTitle">Task Info</h3>

                <div className="formRow">
                    <div className="formColumn">
                        <div className="formGroup">
                            <label className='label'>Title<span className="required">*</span></label>
                            <input
                                className='input'
                                type="text"
                                id="title"
                                name="title"
                                value={taskData.title}
                                onChange={handleChange}
                                placeholder="Enter task title"
                                required
                            />
                            {validationErrors.title && <p className="error-message">{validationErrors.title}</p>}
                        </div>


                        <div className="formGroup">
                            <label className="label">Category <span className="required">*</span></label>
                            <div className="categoryDropdownContainer" style={{ border: 'none' }}>
                                <div
                                    className="custom-dropdown"
                                    style={{ marginRight: '10px' }}
                                    onClick={() => setIsCategoryDropdownOpen(!isCategoryDropdownOpen)}
                                >
                                    <div className="selected-category">
                                        {taskData.task_category_id
                                            ? categories.find((cat) => cat.id === taskData.task_category_id)?.category_name || "Select Category"
                                            : "Select Category"}
                                    </div>


                                    {isCategoryDropdownOpen && (
                                        <div className="dropdown-options">
                                            {categories.map((category) => (
                                                <div key={category.id} className="dropdown-item">
                                                    <div style={{ width: '85%', padding: '4px' }} onClick={() => handleChangeCategory(category.id)}>
                                                        {category.category_name}
                                                    </div>
                                                    <button
                                                        className="delete-button"
                                                        onClick={() => handleDeleteCategory(category.id)}
                                                    >
                                                        <MdDelete />
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <button className="color-button" onClick={openModal}>
                                    Add
                                </button>
                            </div>
                            {validationErrors.task_category_id && <p className="error">{validationErrors.task_category_id}</p>}
                        </div>


                        <div className="formGroup">
                            <label className='label'>Project <span className="required">*</span></label>
                            {/* <select
                                className="input select-box"
                                id="project_id"
                                name="project_id"
                                value={taskData.project_id}
                                onChange={handleChange}
                            >
                                <option value="" disabled>Select Project</option>
                                {projects.map((project) => (
                                    <option key={project.id} value={project.id}>
                                        {project.project_name}
                                    </option>
                                ))}
                            </select> */}
                             <input
  className="input"
  type="text" // Change to text to display the project name
  id="project_name"
  name="project_name"
  value={projectName} // Show project name
  disabled // Make it read-only
  placeholder="Project Name"
/>
                            {/* {validationErrors.project_id && <p className="error">{validationErrors.project_id}</p>} */}
                        </div>


                        <div className="formGroup">
                            <label className="label">Assign To <span className="required">*</span></label>
                            <div
                                className="custom-dropdown"
                                onClick={() => setIsAssignDropdownOpen((prev) => !prev)}
                            >
                                <div className="selected-category">
                                    {taskData.assigned_to.length > 0
                                        ? members
                                            .filter((member) => taskData.assigned_to.includes(member.id))
                                            .map((selectedMember) => selectedMember.member_name)
                                            .join(", ")
                                        : "Select Members"}
                                </div>


                                {isAssignDropdownOpen && (
                                    <div className="dropdown-options">
                                        {members.map((member) => (
                                            <div
                                                key={member.id}
                                                onClick={() => handleMultiSelect(member.id)} // Clicking the row will select/unselect
                                                style={{
                                                    padding: '10px',
                                                    display: 'flex',
                                                    alignContent: 'flex-start',
                                                    gap: '8px'
                                                }}
                                            >
                                                <input
                                                    type="checkbox"
                                                    id={`member-${member.id}`}
                                                    value={member.id}
                                                    checked={taskData.assigned_to.includes(member.id)}
                                                    onClick={(e) => e.stopPropagation()} // Prevents double toggle when clicking the checkbox directly
                                                    onChange={() => handleMultiSelect(member.id)} // For keyboard accessibility
                                                />
                                                <img
                                                    src={member.member_image || defaultimage} // Assuming each member object has an image_url property
                                                    alt={member.member_name}
                                                    style={{
                                                        width: '30px',
                                                        height: '30px',
                                                        borderRadius: '50%'
                                                    }}
                                                />

                                                <label htmlFor={`member-${member.id}`}>
                                                    {member.member_name}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {validationErrors.assigned_to && <p className="error">{validationErrors.assigned_to}</p>}
                            </div>
                        </div>
                    </div>


                    <div className="formColumn">
                        <div className="formGroup">
                            <label className='label'>Start Date<span className="required">*</span></label>
                            <input
                                className="input"
                                type="date"
                                id="start_date"
                                name="start_date"
                                value={taskData.start_date}
                                onChange={handleChange}
                                placeholder="Select start date"
                                required
                            />
                            {validationErrors.start_date && <p className="error">{validationErrors.start_date}</p>}
                        </div>


                        <div className="formGroup">
                            <label className='label'>Due Date <span className="required">*</span></label>
                            <input
                                className="input"
                                type="date"
                                id="due_date"
                                name="due_date"
                                value={taskData.due_date}
                                onChange={handleChange}
                                placeholder="Select due date"
                            />
                            {validationErrors.due_date && <p className="error">{validationErrors.due_date}</p>}
                        </div>


                        <div className="formGroup">
                            <label className='label'>Description</label>
                            <textarea
                                className="input"
                                type="text"
                                id="description"
                                rows={10}
                                name="description"
                                value={taskData.description}
                                onChange={handleChange}
                                placeholder="Enter task description"
                            />
                        </div>
                    </div>
                </div>
            </section>




            <section className="section">
                <h3 className="sectionTitle">Other Details</h3>

                <div className="formRow">
                    <div className="formColumn">
                        <div className="formGroup">
                            <label className='label'>Status<span className="required">*</span></label>
                            <select
                                className="input"
                                id="status_id"
                                name="status_id"
                                value={taskData.status_id}
                                onChange={handleChange}
                                required
                            >
                                <option value="" disabled>Select Status</option>
                                {statuses.map((status) => (
                                    <option key={status.id} value={status.id}>
                                        {status.status}
                                    </option>
                                ))}
                            </select>
                            {validationErrors.status_id && <p className="error">{validationErrors.status_id}</p>}
                        </div>

                        <div className="formGroup">
                            <label className='label'>Priority <span className="required">*</span></label>
                            <select
                                className="input"
                                id="priority"
                                name="priority"
                                value={taskData.priority}
                                onChange={handleChange}
                            >
                                <option value="" disabled>Select Priority</option>
                                <option value="high">High</option>
                                <option value="medium">Medium</option>
                                <option value="low">Low</option>
                            </select>
                            {validationErrors.priority && <p className="error">{validationErrors.priority}</p>}
                        </div>

                        <div className="formGroup">
                            <label className='label'>Label</label>
                            <select
                                className="input"
                                id="label_id"
                                name="label_id"
                                value={taskData.label_id}
                                onChange={handleChange}
                            >
                                <option value="" disabled>Select Label</option>
                                {labels.map((label) => (
                                    <option key={label.id} value={label.id}>
                                        {label.label_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="formGroup">
                            <label className='label'>Milestone</label>
                            <select
                                className="input"
                                id="milestone_id"
                                name="milestone_id"
                                value={taskData.milestone_id}
                                onChange={handleChange}
                            >
                                <option value="" disabled>Select Milestone</option>
                                {milestones.map((milestone) => (
                                    <option key={milestone.id} value={milestone.id}>
                                        {milestone.milestone_title}
                                    </option>
                                ))}
                            </select>
                        </div>


                        <div className="formGroup">
                            <label className="label">  Is Dependent Task
                            </label>
                            <input
                                type="checkbox"
                                name="dependent"
                                checked={isDependent} // Reflects the state of the dependent checkbox
                                onChange={handleDependentChange}
                                className="checkboxInput"
                            />
                        </div>
                        {isDependent && (
                            <div className="formGroup">
                                <label className="label">Select Dependent Task</label>
                                <div className="custom-dropdown">
                                    <div
                                        className="selected-category"
                                        onClick={() => setIsDropdown(!isDropdown)}
                                    >
                                        {selectedDependentTask
                                            ? dependentTasks.find((task) => task.id === selectedDependentTask)?.name || "Select Task"
                                            : "Select Task"}
                                    </div>

                                    {isDropdown && (
                                        <div className="dropdown-options">
                                            {dependentTasks.map((task) => (
                                                <div key={task.id} className="dropdown-item">
                                                    <span
                                                        onClick={() => {
                                                            // Update selectedDependentTask state
                                                            setSelectedDependentTask(task.id);
                                                            setIsDropdown(false); // Close dropdown




                                                            // Update taskData with the dependent task ID
                                                            setTaskData((prevData) => ({
                                                                ...prevData,
                                                                dependent_task_id: task.id, // Store the dependent task ID
                                                            }));

                                                            // Debugging log to confirm selection
                                                            console.log('Selected dependent task ID:', task.id);
                                                        }}
                                                    >
                                                        {task.name}
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}


                        <div className="formGroup">
                            <label className="label"> Private
                            </label>
                            <input
                                type="checkbox"
                                name="private"
                                checked={taskData.private === 1} // Check if taskData.private equals 1
                                onChange={handleChange}
                                className="checkboxInput"
                            />
                        </div>
                    </div>


                    <div className="formColumn">
                        <div className="formGroup">
                            <label className='label'>Estimate Minutes</label>
                            <input
                                className="input"
                                type="number"
                                id="estimate_minutes"
                                name="estimate_minutes"
                                value={taskData.estimate_minutes}
                                onChange={handleChange}
                                placeholder="Enter estimated minutes"  // Placeholder added
                            />
                        </div>

                        <div className="formGroup">
                            <label className='label'>Task Points</label>
                            <input
                                className="input"
                                type="number"
                                id="task_point"
                                name="task_point"
                                value={taskData.task_point}
                                onChange={handleChange}
                                placeholder="Enter task points"  // Placeholder added
                            />
                        </div>

                        <div className="formGroup">
                            <label className='label'>Upload File</label>
                            <input
                                type="file"
                                onChange={handleFileChange}
                                className="input select-box"
                                placeholder="Choose a file"  // Placeholder added (optional for file inputs)
                            />
                        </div>

                        {/* Repeat Checkbox */}
                        <div className="formGroup">
                            <label className="label">Repeat</label>
                            <input
                                type="checkbox"
                                name="repeat"
                                checked={taskData.repeat === 1} // Assuming repeat is 1 for checked
                                onChange={handleChange}
                                className="checkboxInput"
                            />
                        </div>


                        {/* Repeat inputs that appear when the checkbox is checked */}
                        {taskData.repeat === 1 && (
                            <>
                                <div className="formGroup">
                                    <label className="label">Repeat every</label>
                                    <div className="categoryDropdownContainer">
                                        <input
                                            type="number"
                                            name="repeat_count"
                                            value={taskData.repeat_count}
                                            onChange={handleChange}
                                            className="input"
                                            placeholder="Enter repeat count"  // Placeholder added
                                        />
                                        <select
                                            name="repeat_type"
                                            value={taskData.repeat_type}
                                            onChange={handleChange}
                                            className="drop"
                                        >
                                            <option value="day">Day(s)</option>
                                            <option value="week">Week(s)</option>
                                            <option value="month">Month(s)</option>
                                        </select>
                                    </div>
                                </div>


                                <div className="formGroup">
                                    <label className="label">Cycles</label>
                                    <input
                                        type="number"
                                        name="repeat_cycles"
                                        value={taskData.repeat_cycles}
                                        onChange={handleChange}
                                        className="input"
                                        placeholder="Enter number of cycles"  // Placeholder added
                                    />
                                </div>
                            </>
                        )}


                        <div className="formGroup">
                            <label className="label">Billable</label>
                            <input
                                type="checkbox"
                                name="billable"
                                checked={taskData.billable === 1} // Check if taskData.billable equals 1
                                onChange={handleChange}
                                className="checkboxInput"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <div className="form-button-group-deal">
                <button className="color-button" onClick={handleClose}>
                    Cancel
                </button>
                <button onClick={handleSubmit} className="color-button">
                    Save Task
                </button>
            </div>




            <Modal
                open={isModalOpen}
                onClose={closeModal}
            >
                <Box className="designation-modal">
                    {/* Header with title and close button */}
                    <div className="designation-header">
                        <h1 className="designation-title">Add New Category</h1>
                        <img
                            src={iconImage} // Replace 'iconImage' with the path or import for Icon.png
                            alt="Close"
                            className="designation-close"
                            onClick={closeModal}
                        />
                    </div>


                    {/* Form Section */}
                    <div className="designation-form">
                        <label className="designation-label" htmlFor="newCategoryName">
                            Category Name:
                        </label>
                        <input
                            type="text"
                            id="newCategoryName"
                            name="newCategoryName"
                            value={newCategoryName}
                            className="designation-input"
                            onChange={(e) => setNewCategoryName(e.target.value)}
                        />


                        <button onClick={handleAddCategory} className="designation-save">
                            Add Category
                        </button>

                    </div>
                </Box>
            </Modal>

        </div>
    );
};




export default AddProjectTask;
