// src/Appreciation.js
import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
  Paper,
} from '@mui/material';

const Appreciation = () => {
  const [appreciations, setAppreciations] = useState([]);
  const [newAppreciation, setNewAppreciation] = useState({
    name: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewAppreciation((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    if (newAppreciation.name && newAppreciation.message) {
      setAppreciations((prev) => [...prev, newAppreciation]);
      setNewAppreciation({ name: '', message: '' });
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Appreciation
      </Typography>

      <Paper sx={{ p: 2, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Send Appreciation
        </Typography>
        <TextField
          fullWidth
          label="Name"
          name="name"
          value={newAppreciation.name}
          onChange={handleChange}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="Message"
          name="message"
          value={newAppreciation.message}
          onChange={handleChange}
          multiline
          rows={4}
          sx={{ mb: 2 }}
        />
        <Button variant="contained" onClick={handleSubmit}>
          Submit
        </Button>
      </Paper>

      <Typography variant="h6" gutterBottom>
        Appreciation Messages
      </Typography>
      <List>
        {appreciations.map((appreciation, index) => (
          <React.Fragment key={index}>
            <ListItem>
              <ListItemText
                primary={appreciation.name}
                secondary={appreciation.message}
              />
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default Appreciation;
