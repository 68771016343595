
import React, { useEffect, useState, useRef } from 'react';
import { formatDate } from "../Components/MainFunction";
import { useNavigate } from 'react-router-dom';
import { GridMoreVertIcon } from '@mui/x-data-grid';
import Swal from 'sweetalert2';
import { Vortex } from 'react-loader-spinner';
import { Box, Stack, Typography } from '@mui/material';
const DealProposal = ({ id }) => {

    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const [data, setData] = useState([]);
    const [actionMenuVisible, setActionMenuVisible] = useState(null);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [canceledProposals, setCanceledProposals] = useState(new Set());
    const [statusFilter, setStatusFilter] = useState('all');
    const actionMenuRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            // If the action menu is open and the click is outside the menu, close it
            if (actionMenuRef.current && !actionMenuRef.current.contains(event.target)) {
                setActionMenuVisible(null); // Close the dropdown
            }
        };

        // Add event listener when the component mounts
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);



    const fetchProposal = async () => {
        const token = localStorage.getItem('authToken');

        if (!token) {
            console.error('No token found, please login first.');
            return;
        }

        try {
            const response = await fetch(`https://api.myntask.com/proposal/list?deal_id=${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch proposal');
            }

            const result = await response.json();
            console.log('Proposal data:', result);
            setData(result);

            const canceledIds = result
                .filter((proposal) => proposal.status === 'declined')
                .map((proposal) => proposal.id);

            setCanceledProposals(new Set(canceledIds));
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching Proposal:', error);
            setError("Failed to fetch Proposal");
            setIsLoading(false);
        }
    };


    useEffect(() => {

        fetchProposal();
    }, [id]);

    const toTitleCase = (str) => {
        if (!str) return ""; // Return an empty string if the input is null or undefined
        return str
            .toLowerCase() // Convert to lowercase
            .split(' ') // Split the string into words
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
            .join(' '); // Join the words back into a string
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };


    const handleStatusFilterChange = (e) => {
        setStatusFilter(e.target.value); // Update the status filter
    };



    const filteredData = data.filter((proposal) => {
        const matchesSearchTerm =
            proposal.company_name.toLowerCase().includes(searchTerm.toLowerCase())

        const matchesStatusFilter =
            statusFilter === 'all' || proposal.status === statusFilter;

        return matchesSearchTerm && matchesStatusFilter;
    });


    const toggleActionMenu = (id) => {
        setActionMenuVisible((prevVisible) => (prevVisible === id ? null : id));
    };

    const handleDownload = async (id) => {
        const token = localStorage.getItem('authToken');
        console.log('order id', id);
        if (!token) {
            Swal.fire({
                icon: 'error',
                title: 'Authentication Error',
                text: 'No authentication token found. Please log in.',
                toast: true,
                position: 'top-end',
                timer: 3000,
                showConfirmButton: false,
            });
            return;
        }
        setIsDownloading(true); // Set loading state to true
        try {
            const response = await fetch(`https://api.myntask.com/proposal/download/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to download Proposal');
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Proposal_${id}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            Swal.fire({
                icon: 'success',
                title: 'Download Successful',
                text: `Proposal ${id} has been downloaded.`,
                toast: true,
                position: 'top-end',
                timer: 3000,
                showConfirmButton: false,
            });
        } catch (error) {
            console.error('Error downloading estimate:', error);

            Swal.fire({
                icon: 'error',
                title: 'Download Error',
                text: 'Failed to download estimate. Please try again later.',
                toast: true,
                position: 'top-end',
                timer: 3000,
                showConfirmButton: false,
            });
        } finally {
            setIsDownloading(false); // Reset loading state
        }
    };



    const deleteProposal = async (id) => {

        setIsLoading(true);
        try {
            const authToken = localStorage.getItem("authToken"); // Get the authToken from local storage

            if (!authToken) {
                console.error("Auth token not found");
                return;
            }

            const url = `https://api.myntask.com/proposal/delete/${id}`;

            const response = await fetch(url, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${authToken}`, // Include the authToken in the headers
                },
            });

            if (response.ok) {
                const data = await response.json();
                console.log("Proposal deleted successfully", data);
                fetchProposal();
                Swal.fire("Deleted!", "The Proposal has been deleted.", "success");
                // Add any additional logic, such as updating the UI
            } else {
                console.error("Failed to delete the Proposal");
                Swal.fire("Error", "Failed to delete the Proposal.", "error");
            }
            setIsLoading(false);
        } catch (error) {
            console.error("Error deleting Proposal:", error);
            Swal.fire("Error", "Something went wrong!", "error");
        }
        setIsLoading(false);
    };

    const confirmDelete = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                deleteProposal(id); // Call the delete function if confirmed
            }
        });
    };


    const handleStatusChange = async (proposalId, newStatus) => {
        const token = localStorage.getItem('authToken');

        if (!token) {
            console.error('No token found, please login first.');
            return;
        }

        try {
            // Update the status in the backend
            const response = await fetch('https://api.myntask.com/proposal/status_update', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    status: newStatus,
                    proposal_id: proposalId,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to update status');
            }

            const result = await response.json();
            console.log('Status updated successfully:', result);

            // Update the local state to reflect the new status
            setData((prevData) =>
                prevData.map((proposal) =>
                    proposal.id === proposalId ? { ...proposal, status: newStatus } : proposal
                )
            );

            // If the status is "canceled", add the estimate ID to canceledEstimates
            if (newStatus === 'declined') {
                setCanceledProposals((prev) => new Set([...prev, proposalId]));
            } else {
                // If the status is not "canceled", remove the estimate ID from canceledEstimates
                setCanceledProposals((prev) => {
                    const newCanceled = new Set(prev);
                    newCanceled.delete(proposalId);
                    return newCanceled;
                });
            }

            Swal.fire("Success!", "Status updated successfully.", "success");
        } catch (error) {
            console.error('Error updating status:', error);
            Swal.fire("Error", "Failed to update status.", "error");
        }
    };



    //..........Cancel Proposal start..................................................
    useEffect(() => {
        // Load canceled estimates from localStorage
        const storedCanceledProposals = JSON.parse(localStorage.getItem('canceledProposals')) || [];
        setCanceledProposals(new Set(storedCanceledProposals));

        fetchProposal();
    }, []);
    //..........end.......................................................................



    const handleEditProposal = (id) => {
        navigate(`/EditProposal/${id}`);
    }


    const handleViewProposal = (id) => {
        navigate(`/ViewProposal/${id}`);
    }


    const currentStatusColors = {
        waiting: 'rgba(255, 165, 0, 0.6)', // Brighter orange with 60% opacity
        approved: 'rgba(0, 0, 255, 0.6)', // Brighter blue with 60% opacity
        rejected: 'rgba(255, 0, 0, 0.6)', // Brighter red with 60% opacity
        declined: 'rgba(128, 128, 128, 0.6)', // Grey with 60% opacity
    };



    //..........Loading Spinner ..........................................
    if (isLoading) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    backgroundColor: "#f5f5f5",
                }}
            >
                <Stack alignItems="center">
                    <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
                    <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
                        Loading....
                    </Box>
                </Stack>
            </Box>
        );
    }



    return (
        <>
            <div className="main-container">
                <div className="top-controls" style={{ gap: '1rem' }}>
                    <h1 className='title'>Proposal
                        <div className="underline"></div>
                    </h1>


                    <div className="buttons-row">
                        <button className="color-button" onClick={() => navigate(`/add-deal-proposal/${id}`)}>
                            Add Proposal
                        </button>

                    </div>
                </div>

                <div className="top-controls">

                </div>

                <table>
                    <thead>
                        <tr>
                            <th style={{ width: '2vw' }}>S.No</th>
                            <th style={{ width: '20vw' }}>Company Name</th>
                            <th style={{ width: '15vw' }}>Client Name</th>
                            <th style={{ width: '10vw' }}>Amount</th>
                            <th style={{ width: '10vw' }}> Created On</th>
                            <th style={{ width: '10vw' }}>Valid Till</th>
                            <th style={{ width: '10vw' }}>
                                <div>
                                    <select
                                        value={statusFilter}
                                        onChange={handleStatusFilterChange}
                                        className="header-filter"
                                    >
                                        <option value="all">Status</option>
                                        <option value="waiting">Pending</option>
                                        <option value="approved">Approved</option>
                                        <option value="rejected">Rejected</option>
                                        <option value="declined">Canceled</option>
                                    </select>
                                </div>
                            </th>
                            <th style={{ width: '5vw' }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.map((proposal, index) => (
                            <tr key={proposal.id} className={canceledProposals.has(proposal.id) ? 'canceled' : ''}>
                                <td>{index + 1}</td>
                                <td>{toTitleCase(proposal.company_name)}</td>
                                <td>{toTitleCase(proposal.client_name) || "N/A"}</td>
                                <td>{proposal.total}</td>
                                <td>{formatDate(proposal.created_at)}</td>
                                <td>{formatDate(proposal.valid_till)}</td>

                                <td>
                                    <select
                                        value={proposal.status}
                                        onChange={(e) => handleStatusChange(proposal.id, e.target.value)}
                                        disabled={proposal.status === 'declined'} // Disable dropdown if estimate is canceled
                                        style={{
                                            width: '120px',
                                            padding: '10px',
                                            textAlign: 'center',
                                            border: 'none',
                                            backgroundColor: currentStatusColors[proposal.status], // Apply color here
                                            color: 'white', // Text color
                                            cursor: 'pointer',
                                            borderRadius: '4px', // Optional: Add rounded corners
                                        }}
                                    >
                                        <option value="waiting">Pending</option>
                                        <option value="approved">Approved</option>
                                        <option value="rejected">Rejected</option>
                                        <option value="declined">Canceled</option>
                                    </select>
                                </td>

                                <td>
                                    <div className="action-container">
                                        <div className="action-dots" onClick={() => toggleActionMenu(proposal.id)}>
                                            <GridMoreVertIcon />
                                        </div>
                                        {actionMenuVisible === proposal.id && (
                                            <div className="action-menu" ref={actionMenuRef}>
                                                {proposal.status === 'declined' ? (
                                                    <div className="action-item" onClick={() => handleStatusChange(proposal.id, 'waiting')}>
                                                        Restore Proposal
                                                    </div>
                                                ) : (
                                                    <>
                                                        {/* <div
                          className="action-item"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent row click
                            handleDownload(proposal.id);
                          }}
                        >
                          {isDownloading ? 'Loading...' : 'Download'}
                        </div> */}
                                                        <div className='action-item'>
                                                            Send
                                                        </div>

                                                        <div className='action-item' onClick={() => handleViewProposal(proposal.id)}>
                                                            View
                                                        </div>

                                                        <div className='action-item' onClick={() => handleEditProposal(proposal.id)}>
                                                            Edit
                                                        </div>
                                                        <div
                                                            className="action-item"
                                                            onClick={() => confirmDelete(proposal.id)}
                                                        >
                                                            Delete
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

            </div>
        </>
    );
};

export default DealProposal;
