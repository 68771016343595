// src/ShiftRoaster.js
import React, { useState } from 'react';
import { 
  Box, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Typography, 
  Button, 
  Modal, 
  TextField, 
  MenuItem 
} from '@mui/material';

const employees = [
  { id: 1, name: 'John Doe' },
  { id: 2, name: 'Jane Smith' },
  { id: 3, name: 'Mark Wilson' },
];

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

const ShiftRoaster = () => {
  const [shifts, setShifts] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [selectedShift, setSelectedShift] = useState({ day: '', employeeId: '', shiftTime: '' });

  const handleOpenModal = (day, employeeId) => {
    setSelectedShift({ day, employeeId, shiftTime: shifts[`${day}-${employeeId}`] || '' });
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSaveShift = () => {
    setShifts(prev => ({
      ...prev,
      [`${selectedShift.day}-${selectedShift.employeeId}`]: selectedShift.shiftTime,
    }));
    setOpenModal(false);
  };

  const handleChange = (e) => {
    setSelectedShift(prev => ({ ...prev, shiftTime: e.target.value }));
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Shift Roaster
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Employee</TableCell>
              {days.map((day) => (
                <TableCell key={day}>{day}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {employees.map((employee) => (
              <TableRow key={employee.id}>
                <TableCell>{employee.name}</TableCell>
                {days.map((day) => (
                  <TableCell key={`${day}-${employee.id}`}>
                    <Button
                      variant="outlined"
                      onClick={() => handleOpenModal(day, employee.id)}
                    >
                      {shifts[`${day}-${employee.id}`] || 'Assign Shift'}
                    </Button>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Shift Assignment Modal */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box 
          sx={{ 
            p: 3, 
            backgroundColor: 'white', 
            margin: 'auto', 
            marginTop: '5%', 
            width: '30%', 
            borderRadius: '8px' 
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            Assign Shift
          </Typography>
          <Box sx={{ mt: 2 }}>
            <TextField
              select
              fullWidth
              label="Select Shift Time"
              value={selectedShift.shiftTime}
              onChange={handleChange}
              helperText="Please select a shift time"
            >
              <MenuItem value="Morning">Morning</MenuItem>
              <MenuItem value="Afternoon">Afternoon</MenuItem>
              <MenuItem value="Evening">Evening</MenuItem>
              <MenuItem value="Night">Night</MenuItem>
            </TextField>
          </Box>
          <Button
            variant="contained"
            onClick={handleSaveShift}
            sx={{ mt: 2 }}
          >
            Save
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default ShiftRoaster;
