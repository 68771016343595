import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import Modal from '@mui/material/Modal';
import './Holiday.css';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';
import iconImage from '../assets/Icon.png';
import { Vortex } from 'react-loader-spinner';
import { Box, Stack } from '@mui/material';




function HolidayCalendar() {
  const [holidays, setHolidays] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [holidayData, setHolidayData] = useState({
    date: '',
    occasion: '',
    department_id: [],
    designation_id: [],
    employment_type: [],
  });








  //...............................Add holiday Modal ..............................................................................




  // Example employment types
  const employmentTypes = [
    { id: 'full_time', name: 'Full-Time' },
    { id: 'part_time', name: 'Part-Time' },
  ];




  // Fetch departments and designations from the API
  useEffect(() => {
    const token = localStorage.getItem('authToken');




    const fetchDepartments = async () => {
      try {
        const response = await fetch('https://api.myntask.com/employee/department', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        console.log('Departments fetched:', data);
        setDepartments(data);
      } catch (error) {
        console.error('Error fetching departments:', error);
      }
    };




    const fetchDesignations = async () => {
      try {
        const response = await fetch('https://api.myntask.com/employee/designation', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        console.log('Designations fetched:', data);
        setDesignations(data);
      } catch (error) {
        console.error('Error fetching designations:', error);
      }
    };




    fetchDepartments();
    fetchDesignations();
  }, []);








  const handleChange = (e) => {
    const { name, value } = e.target;
    setHolidayData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };








  const handleMultiSelectChange = (e, field) => {
    const { value } = e.target; // e.target.value will be the selected array for multiple selects
    setHolidayData((prev) => ({
      ...prev,
      [field]: value, // Set selected values directly as an array
    }));
  };








  const handleSave = async () => {
    const token = localStorage.getItem('authToken');
    const apiUrl = 'https://api.myntask.com/holiday/add';




    const body = {
      date: holidayData.date,
      occassion: holidayData.occasion,
      department_id: holidayData.department_id,
      designation_id: holidayData.designation_id,
      employment_type: holidayData.employment_type,
    };
    console.log(body); // just for checking




    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });




      if (response.ok) {
        const contentType = response.headers.get('content-type');




        if (contentType && contentType.includes('application/json')) {
          const result = await response.json();
          console.log('Holiday saved successfully:', result);
        } else {
          const result = await response.text(); // Handle non-JSON response
          console.log('Holiday saved successfully:', result);
        }




        // Close the modal
        setShowModal(false);




        // Show success message
        Swal.fire({
          icon: 'success',
          title: 'Holiday saved successfully!',
          showConfirmButton: false,
          timer: 1500,
        });




        // Refresh the calendar events
        fetchHolidays(new Date().getMonth() + 1);
      } else {
        console.error('Failed to save holiday:', await response.text());
        Swal.fire({
          icon: 'error',
          title: 'Failed to save holiday',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      console.error('Error saving holiday:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error saving holiday',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };




  const handleCancel = () => {
    setShowModal(false);
    setHolidayData({
      date: '',
      occasion: '',
      department_id: [],
      designation_id: [],
      employment_type: [],
    })




  };
  //...........................Modal end. ............................................................................












  // Function to fetch holidays from the API
  const fetchHolidays = async (month) => {
    const token = localStorage.getItem('authToken');
    if (!token) return;

    const apiUrl = `https://api.myntask.com/holiday/list?month=${month}`;
    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${token}` }
      });

      const data = await response.json();
      const formattedHolidays = data.map(holiday => ({
        id: holiday.id,
        title: holiday.occassion,
        date: holiday.date.split('-').reverse().join('-'),
        allDay: true,
      }));

      setHolidays(formattedHolidays);
      setIsLoading(false);
    } catch (error) {
      console.error('Failed to fetch holidays:', error);
      setIsLoading(false);
    }
  };




  useEffect(() => {
    fetchHolidays(new Date().getMonth() + 1);
  }, []);








  const handleDeleteHoliday = async (holidayId) => {
    const token = localStorage.getItem('authToken');
    if (!token) return;




    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!'
    });




    if (result.isConfirmed) {
      const apiUrl = `https://api.myntask.com/holiday/delete/${holidayId}`;
      try {
        const response = await fetch(apiUrl, {
          method: 'DELETE',
          headers: { 'Authorization': `Bearer ${token}` }
        });




        if (response.ok) {
          Swal.fire('Deleted!', 'Your holiday has been deleted.', 'success');
          fetchHolidays(new Date().getMonth() + 1);
        } else {
          Swal.fire('Error!', 'There was an error deleting your holiday.', 'error');
        }
      } catch (error) {
        Swal.fire('Error!', 'There was an error deleting your holiday.', 'error');
      }




    }
  };




  const handleMonthChange = (arg) => {
    fetchHolidays(arg.view.currentStart.getMonth() + 1);
  };




  const handleShowAddHoliday = () => {
    setHolidayData({
      date: '',
      occasion: '',
      department_id: [],
      designation_id: [],
      employment_type: [],
    })
    setShowModal(true);
  }




  const handleModalClose = () => {
    setShowModal(false);
  }


  //..........Loading Spinner ..........................................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }

  // if (error) {
  //   return (
  //     <Typography
  //       color="error"
  //       sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
  //     >
  //       {error}
  //     </Typography>
  //   );
  // }



  return (
    <>
      <div className="main-container">
        <div className="top-controls">
          <h1 className='title'>Holiday Calendar
            <div className="underline"></div>
          </h1>




          <div className="buttons-row">
            <button className="color-button" onClick={handleShowAddHoliday}>Add Holiday</button>
            {/* <button className="color-button" onClick={handleOpenDeleteModal}>Delete Holiday</button> */}
            <div className="delete-holiday-container" style={{ position: 'relative' }}>
              <button className="color-button" onClick={() => setDropdownVisible(!dropdownVisible)}>
                Delete Holiday
              </button>
              {dropdownVisible && (
                <div className="delete-holiday-dropdown" style={{
                  position: 'absolute',
                  top: '100%',  // Position it below the button
                  left: 0,
                  zIndex: 10,
                  width: '150px',
                  backgroundColor: 'white',
                  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                  borderRadius: '8px'
                }}>
                  {holidays.length > 0 ? (
                    holidays.map((holiday) => (
                      <div
                        key={holiday.id}
                        className="dropdown-item"
                        style={{ display: 'flex', justifyContent: 'space-between' }}
                      >
                        <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{holiday.title}</div>
                        <div
                          onClick={() => {
                            handleDeleteHoliday(holiday.id);
                            setDropdownVisible(false);
                          }}




                        >
                          <DeleteIcon style={{ color: 'red' }} />
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="dropdown-item">No holidays available</div>
                  )}
                </div>
              )}
            </div>








          </div>
        </div>




        <div className="holiday">
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            events={holidays}
            datesSet={handleMonthChange}
            dayCellDidMount={(info) => {
              const date = new Date(info.date);
              if (date.getDay() === 0) { // Sunday
                info.el.style.backgroundColor = '#e9f5f3'; // Change to your desired color
              }
            }}
            height="parent"
            contentHeight="auto"
          />
        </div>




      </div>








      <Modal open={showModal} onClose={handleModalClose}>
        <div className="modal-content-deal" style={{ width: '500px', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>
          <img
            src={iconImage} // Replace 'iconImage' with the path or import for Icon.png
            alt="Close"
            style={{
              position: "absolute",
              top: "16px",
              right: "16px",
              border: "none",
              cursor: "pointer",
            }}
            onClick={handleCancel}
          />




          <h2 className='modal-title-deal'>Add Holiday :</h2>




          <div className="form-ex">
            <div className="form-field-group-deal" style={{ marginTop: '20px' }}>
              <label className="form-label-deal">Date<span className="required">*</span></label>
              <input
                type="date"
                name='date'
                value={holidayData.date}
                onChange={handleChange}
                className="form-input-deal"
                required
              />
            </div>




            {/* <TextField




            type="date"
            name="date"
            value={holidayData.date}
            onChange={handleChange}
            fullWidth
            margin="normal"
          /> */}




            <div className="form-field-group-deal" style={{ marginTop: '20px' }}>
              <label className="form-label-deal">Occassion :<span className="required">*</span></label>
              <input
                label="Occasion"
                name="occasion"
                value={holidayData.occasion}
                onChange={handleChange}
                className="form-input-deal"
                required
              />
            </div>












            {/* Departments dropdown */}
            <div className="form-field-group-deal" style={{ marginTop: '20px' }}>
              <label className="form-label-deal">Department :</label>
              <select
                name='department_id'
                value={holidayData.department_id}
                onChange={(e) => handleMultiSelectChange(e, 'department_id')}
                className="form-input-deal"
                required
              >
                <option value="">Select Department</option>
                {departments.map((dept) => (
                  <option key={dept.id} value={dept.id}>
                    {dept.department}
                  </option>
                ))}
              </select>
            </div>












            {/* Designations dropdown */}




            {/* <div className="form-field-group-deal" style={{ marginTop: '20px' }}>
              <label className="form-label-deal">Designation :</label>
              <select
                name='designation_id'
                value={holidayData.designation_id}
                onChange={(e) => handleMultiSelectChange(e, 'designation_id')}
                className="form-input-deal"
                required
              >
                <option value="">Select Designation</option>
                {designations.map((designation) => (
                  <option key={designation.id} value={designation.id}>
                    {designation.name}
                  </option>
                ))}
              </select>
            </div> */}
















            {/* Employment types dropdown */}




            <div className="form-field-group-deal" style={{ marginTop: '20px' }}>
              <label className="form-label-deal">Employment Type :</label>
              <select
                name='employment_type'
                value={holidayData.employment_type}
                onChange={(e) => handleMultiSelectChange(e, 'employment_type')}
                className="form-input-deal"
                required
              >
                <option value="">Select </option>
                {employmentTypes.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </select>
            </div>




            {/* <TextField
            label="Employment Type"
            name="employment_type"
            select
            multiple
            value={holidayData.employment_type}
            onChange={(e) => handleMultiSelectChange(e, 'employment_type')}
            fullWidth
            margin="normal"
            SelectProps={{
              multiple: true,
            }}
          >
            {employmentTypes.map((type) => (
              <MenuItem key={type.id} value={type.id}>
                {type.name}
              </MenuItem>
            ))}
          </TextField> */}
          </div>




          <div className="form-button-group-deal" >
            <button onClick={handleSave} className='submit-button-deal'>
              Save
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}




export default HolidayCalendar;


















