import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { Chrono } from 'react-chrono';

const AddFollowUpForm = ({ dealId, onFollowUpAdded }) => {
    const [remark, setRemark] = useState('');
    const [nextFollowUpDate, setNextFollowUpDate] = useState('');
    const [nextFollowUpTime, setNextFollowUpTime] = useState('');
    const [sendReminder, setSendReminder] = useState(false);
    const [remindTime, setRemindTime] = useState('');
    const [remindType, setRemindType] = useState('');
    const [followUpDetails, setFollowUpDetails] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchFollowUpDetails = async () => {
        if (!dealId) return;
        setLoading(true);
        setFollowUpDetails([]);
        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch(`https://api.myntask.com/leads/follow_up_list/${dealId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch follow-up details');
            }

            const data = await response.json();
            setFollowUpDetails(data);
        } catch (error) {
            console.error('Error fetching follow-up details:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (dealId) {
            fetchFollowUpDetails();
        }
    }, [dealId]);

    const resetFields = () => {
        setRemark('');
        setNextFollowUpDate('');
        setNextFollowUpTime('');
        setSendReminder(false);
        setRemindTime('');
        setRemindType('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Combine date and time into a single string
        const nextFollowUpDateTime = `${nextFollowUpDate} ${nextFollowUpTime}`;

        const payload = {
            deal_id: dealId,
            remark,
            next_follow_up_date: nextFollowUpDateTime,
            send_reminder: sendReminder ? 1 : null,
            remind_time: sendReminder ? parseInt(remindTime) : null,
            remind_type: sendReminder ? remindType : null,
        };

        try {
            const token = localStorage.getItem('authToken');
            if (!token) {
                Swal.fire({
                    icon: 'error',
                    title: 'Authentication Error',
                    text: 'Please log in again.',
                    timer: 1500,
                    showConfirmButton: false,
                });
                return;
            }

            const response = await fetch('https://api.myntask.com/leads/add_follow_up', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error(`HTTP Error: ${response.status}`);
            }

            const responseData = await response.json();

            if (responseData.code === 'WARN_DATA_TRUNCATED') {
                throw new Error(responseData.sqlMessage || 'Data truncation error. Check input values.');
            }

            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Follow-up added successfully!',
                timer: 1500,
                showConfirmButton: false,
            });

            await fetchFollowUpDetails();
            resetFields();
            if (onFollowUpAdded) {
                onFollowUpAdded(responseData);
            }

        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.message || 'Failed to add follow-up. Please try again.',
                timer: 2500,
                showConfirmButton: true,
            });
            console.error('Error adding follow-up:', error);
        }
    };

    const timelineItems = followUpDetails.map((followUp) => {
        const createdAt = new Date(followUp.created_at);
        const nextFollowUpDateTime = new Date(followUp.next_follow_up_date);
        const nextFollowUpDate = nextFollowUpDateTime.toLocaleDateString('en-GB');
        const nextFollowUpTime = nextFollowUpDateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

        return {
            title: createdAt.toLocaleDateString(),
            cardTitle: (
                <div>
                    <div style={{ fontWeight: 'bold' }}>{nextFollowUpDate}</div>
                    <div style={{ color: 'gray', fontSize: '0.8rem' }}>{nextFollowUpTime}</div>
                </div>
            ),
            cardDetailedText: followUp.remark,
        };
    }).reverse();

    return (
        <>
            <div className='top-controls'>
                <h2>Follow-Up</h2>
            </div>
            <div style={formStyle}>
                {timelineItems.length > 0 ? (
                    <div style={{ height: '300px', overflowY: 'auto', marginBottom: '20px' }}>
                        <Chrono
                            items={timelineItems}
                            mode="VERTICAL"
                            theme={{
                                primary: '#4cd964',
                                secondary: '#b1dff0',
                                cardBgColor: '#dec8c8',
                                titleColor: '#333',
                            }}
                            cardWidth={300}
                            cardHeight={40}
                            scrollable
                            hideControls
                        />
                    </div>
                ) : (
                    <p style={{ textAlign: 'center', marginBottom: '20px' }}>No follow-up details available.</p>
                )}

                <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                    <div style={{ marginBottom: '15px' }}>
                        <label style={labelStyle}>Remark</label>
                        <textarea
                            value={remark}
                            onChange={(e) => setRemark(e.target.value)}
                            required
                            placeholder="Enter your remark"
                            style={inputStyle}
                        ></textarea>
                    </div>

                    <div style={{ display: 'flex', gap: '15px', marginBottom: '15px' }}>
                        <div style={{ flex: 1 }}>
                            <label style={labelStyle}>Next Follow-Up Date</label>
                            <input
                                type="date"
                                value={nextFollowUpDate}
                                onChange={(e) => setNextFollowUpDate(e.target.value)}
                                required
                                style={inputStyle}
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <label style={labelStyle}>Start Time</label>
                            <input
                                type="time"
                                value={nextFollowUpTime}
                                onChange={(e) => setNextFollowUpTime(e.target.value)}
                                required
                                style={inputStyle}
                            />
                        </div>
                    </div>

                    <div style={sendReminderStyle}>
                        <label style={labelStyle}>Send Reminder</label>
                        <input
                            type="checkbox"
                            id='sendReminder'
                            checked={sendReminder}
                            onChange={() => setSendReminder((prev) => !prev)}
                        />
                    </div>

                    {sendReminder && (
                        <div style={{ display: 'flex', gap: '15px', marginBottom: '15px' }}>
                            <div style={{ flex: 1 }}>
                                <label style={labelStyle}>Remind Before</label>
                                <input
                                    type="number"
                                    value={remindTime}
                                    onChange={(e) => setRemindTime(e.target.value)}
                                    placeholder="e.g., 2"
                                    style={inputStyle}
                                />
                            </div>
                            <div style={{ flex: 1 }}>
                                <label style={labelStyle}>Reminder Type<span style={{ color: 'red' }}>*</span></label>
                                <select
                                    value={remindType}
                                    onChange={(e) => setRemindType(e.target.value)}
                                    required
                                    style={inputStyle}
                                >
                                    <option value="">Select Type</option>
                                    <option value="hour">Hour</option>
                                    <option value="day">Day</option>
                                </select>
                            </div>
                        </div>
                    )}

                    <div style={modalActionsStyle}>
                        <button type="submit" className='color-button'>Save</button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default AddFollowUpForm;

const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    backgroundColor: '#ffffff',
    padding: '20px',
    borderRadius: '8px',
    border: '1px solid #ccc',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    maxWidth: '50%',
    margin: 'auto',
    marginTop: '20px',
};

const labelStyle = {
    fontWeight: 'bold',
    marginBottom: '5px',
};

const inputStyle = {
    width: '100%',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    boxSizing: 'border-box',
};

const sendReminderStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    marginBottom: '15px'
};

const modalActionsStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px',
};