import React, { useState, useEffect, useRef } from "react";
import { Typography, Box, Stack, } from "@mui/material";
import { Line, Doughnut, Bar } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import { Vortex } from 'react-loader-spinner';
import { Business, AttachMoney, People, TrendingUp, LocalActivity, Receipt } from "@mui/icons-material";
import { formatDate } from '../Components/MainFunction';
import CRM_Reports from "./CRM_Reports";
import { icon } from "leaflet";

const CRMDashboard = () => {
  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [upcomingFollowups, setUpcomingFollowups] = useState([]);
  const [recentActivities, setRecentActivities] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showChart, setShowChart] = useState(true); // Default to showing the chart
  const [leadConversionFilter, setLeadConversionFilter] = useState("This Month");
  const [dealConversionFilter, setDealConversionFilter] = useState("This Month");
  const [clientConversionFilter, setClientConversionFilter] = useState("This Month");
  const [leadConversionDropdownOpen, setLeadConversionDropdownOpen] = useState(false);
  const [dealConversionDropdownOpen, setDealConversionDropdownOpen] = useState(false);
  const [clientConversionDropdownOpen, setClientConversionDropdownOpen] = useState(false);
  const [dateFilter, setDateFilter] = useState("This Month");
  const [isDateFilterOpen, setIsDateFilterOpen] = useState(false);
  const [dateRangeDisplay, setDateRangeDisplay] = useState("");

  const dropdownRef = useRef(null);
  const leadConversionDropdownRef = useRef(null);
  const clientConversionDropdownRef = useRef(null);
  const dealConversionDropdownRef = useRef(null);
  const dateFilterRef = useRef(null);


  // Function to get date ranges based on filter selection......................................................................
  const getDateRange = (filter) => {
    const today = new Date();
    let startDate, endDate;

    switch (filter) {
      case "This Week":
        const day = today.getDay(); // Day of week (0-6)
        startDate = new Date(today);
        startDate.setDate(today.getDate() - day);
        endDate = new Date(today);
        endDate.setDate(today.getDate() + (6 - day));
        break;
      case "This Month":
        startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        break;
      case "This Quarter":
        const quarter = Math.floor(today.getMonth() / 3);
        startDate = new Date(today.getFullYear(), quarter * 3, 1);
        endDate = new Date(today.getFullYear(), quarter * 3 + 3, 0);
        break;
      case "This Year":
        startDate = new Date(today.getFullYear(), 0, 1);
        endDate = new Date(today.getFullYear(), 11, 31);
        break;
      default:
        startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    }

    // Format dates as YYYY-MM-DD for API
    const format = (date) => date.toISOString().split('T')[0];

    // Set display text for the date range
    const displayFormat = (date) => date.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' });
    setDateRangeDisplay(`${displayFormat(startDate)} - ${displayFormat(endDate)}`);

    return {
      start: format(startDate),
      end: format(endDate)
    };
  };
  // ..........................................................end................................................................


  const fetchDashboardData = async (filter = dateFilter) => {
    const token = localStorage.getItem("authToken");
    const { start, end } = getDateRange(filter);
    try {
      const response = await fetch(`https://api.myntask.com/dashboard/crm_dashboard?start_date=${start}&end_date=${end}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch dashboard data");
      }
      const data = await response.json();

      console.log("API Data Received++++++++++++++++++crm+++++++++:", data); // Verify API response
      setDashboardData(data);
      setUpcomingFollowups(data?.upcoming_follow_up_list || []);
      setRecentActivities(data?.recent_activities || []); // Assuming API returns recent activities
      setIsLoading(false);
    } catch (err) {
      console.error("Error fetching dashboard data:", err);
      setError("Failed to load dashboard data. Please try again later.");
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchDashboardData();
  }, []);

  // Handle date filter change
  const handleDateFilterChange = (filter) => {
    setDateFilter(filter);
    setIsDateFilterOpen(false);
    fetchDashboardData(filter);
  };

  // Handle dropdown toggle
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleLeadConversionDropdown = () => {
    setLeadConversionDropdownOpen(!leadConversionDropdownOpen);
  };

  const toggleDealConversionDropdown = () => {
    setDealConversionDropdownOpen(!dealConversionDropdownOpen);
  };

  const toggleClientConversionDropdown = () => {
    setClientConversionDropdownOpen(!clientConversionDropdownOpen);
  };

  const toggleDateFilterDropdown = () => {
    setIsDateFilterOpen(!isDateFilterOpen);
  };



  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
      if (leadConversionDropdownRef.current && !leadConversionDropdownRef.current.contains(event.target)) {
        setLeadConversionDropdownOpen(false);
      }
      if (dealConversionDropdownRef.current && !dealConversionDropdownRef.current.contains(event.target)) {
        setDealConversionDropdownOpen(false);
      }
      if (clientConversionDropdownRef.current && !clientConversionDropdownRef.current.contains(event.target)) {
        setClientConversionDropdownOpen(false);
      }
      if (dateFilterRef.current && !dateFilterRef.current.contains(event.target)) {
        setIsDateFilterOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Handle navigation when a dropdown item is clicked
  const handleQuickAction = (route) => {
    navigate(route); // Navigate to the specified route
    setIsDropdownOpen(false); // Close the dropdown
  };

  // Quick Actions Menu Items
  const quickActions = [
    { label: "Add Lead", route: "/addLead" },
    { label: "Add Client", route: "/addClient" },
    { label: "Add Proposal", route: "/AddProposal" },
    { label: "Add Estimate", route: "/addestimate" },
  ];


  // Loading Spinner
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }

  const handleCardClick = (route) => {
    navigate(route);
  };

  // Chart Data
  const leadSourceLabels = dashboardData?.lead_source_counts?.map((item) => item.source_name) || [];
  const leadSourceValues = dashboardData?.lead_source_counts?.map((item) => item.total) || [];
  const doughnutChartData = {
    labels: leadSourceLabels,
    datasets: [
      {
        data: leadSourceValues,
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4CAF50", "#9C27B0", "#FF5733"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4CAF50", "#9C27B0", "#FF5733"],
      },
    ],
  };

  const lineChartData = {
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: "Monthly Sales",
        data: dashboardData?.monthly_sales || [0, 0, 0, 0, 0, 0],
        fill: false,
        borderColor: "#42A5F5",
        tension: 0.1,
      },
    ],
  };



  const leadConversionData = {
    labels: ["New Leads", "Converted Leads", "Lost Leads"],
    datasets: [
      {
        label: "Lead Conversion",
        data: [
          (dashboardData?.lead_conversions?.total_new || 0) +
          (dashboardData?.lead_conversions?.total_converted || 0) +
          (dashboardData?.lead_conversions?.total_lost || 0),       // New Leads
          dashboardData?.lead_conversions?.total_converted || 0,  // Converted Leads
          dashboardData?.lead_conversions?.total_lost || 0         // Lost Leads
        ],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      },
    ],
  };

  const dealConversionData = {
    labels: ["New Deals", "Converted Deals", "Lost Deals"],
    datasets: [
      {
        label: "Deal Conversion",
        data: [
          dashboardData?.deal_conversions?.total_new || 0,        // New Deals
          dashboardData?.deal_conversions?.total_converted || 0,  // Converted Deals
          dashboardData?.deal_conversions?.total_lost || 0         // Lost Deals
        ],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      },
    ],
  };

  const clientConversionData = {
    labels: ["New Clients", "Active Clients", "Inactive Clients"],
    datasets: [
      {
        label: "Client Conversion",
        data: [
          dashboardData?.total_client?.total_monthly || 0,
          dashboardData?.total_client?.total_active || 0,
          dashboardData?.total_client?.total_inactive || 0
        ],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      },
    ],
  };

  const barChartData = {
    labels: ["Lead", "Deal", "Client", "Client Visit"],
    datasets: [
      {
        label: "Totals",
        data: [
          dashboardData?.total_leads?.total_all || 0,
          dashboardData?.total_deals?.total_all || 0,
          dashboardData?.total_client?.total_all || 0,
          dashboardData?.total_client_visit?.total_all || 0,
        ],
        backgroundColor: ["#42A5F5", "#FF6384", "#36A2EB", "#FFCE56"],
      },
    ],
  };

  return (
    <div className="main-container" style={{ position: 'relative' }}>
      <div className="top-controls">
        <h1 className="title">CRM Dashboard</h1>
        <div className="dropdown-container" style={{ display: 'flex', alignItems: 'center', gap: '10px', }}>
          <div ref={dateFilterRef} style={{ position: "relative" }}>
            <button
              onClick={toggleDateFilterDropdown}
              style={{
                padding: "8px 16px",
                backgroundColor: "#3b82f6",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                fontSize: '14px',
                fontWeight: '500',
                transition: 'all 0.2s ease'
              }}
            >
              {dateFilter} {dateRangeDisplay && `(${dateRangeDisplay})`}
              <span style={{
                marginLeft: '5px',
                transition: 'transform 0.2s ease',
                transform: isDateFilterOpen ? 'rotate(180deg)' : 'rotate(0deg)'
              }}>
                ▼
              </span>
            </button>

            {isDateFilterOpen && (
              <div
                style={{
                  position: "absolute",
                  top: "100%",
                  right: 0,
                  backgroundColor: "#3b82f6",
                  borderRadius: "4px",
                  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
                  zIndex: 1000,
                  minWidth: "180px",
                  border: "1px solid #e0e0e0",
                  transform: isDateFilterOpen ? 'scaleY(1)' : 'scaleY(0)',
                  transformOrigin: 'top',
                  opacity: isDateFilterOpen ? 1 : 0,
                  transition: 'transform 0.2s ease, opacity 0.15s ease',
                  pointerEvents: isDateFilterOpen ? 'auto' : 'none'
                }}
              >
                {["This Week", "This Month", "This Quarter", "This Year"].map((filter, index) => (
                  <div
                    key={index}
                    onClick={() => handleDateFilterChange(filter)}
                    style={{
                      width: "100%",
                      padding: "8px 16px",
                      cursor: "pointer",
                      color: "#fff",
                      fontSize: "14px",
                      fontWeight: "500",
                      backgroundColor: "transparent",
                      border: "none",
                      textAlign: "left",
                      transition: 'background-color 0.2s ease',
                      "&:hover": {
                        backgroundColor: "#f5f5f5",
                      },
                    }}
                  >
                    {filter}
                  </div>
                ))}
              </div>
            )}
          </div>

          <div ref={dropdownRef} style={{ position: "relative" }}>

            {/* <button>
              <a href="/crm-reports" style={{ textDecoration: 'none', color: 'white' }}>CRM Reports</a>
            </button> */}
            {/* Dropdown Button */}
            <button
              onClick={toggleDropdown}
              // className="color-button"
              style={{
                padding: "8px 16px",
                backgroundColor: "#3b82f6",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                fontSize: '14px',
                fontWeight: '500',
                transition: 'all 0.2s ease'
              }}
            >
              Quick Action
              <span style={{
                marginLeft: '5px',
                transition: 'transform 0.2s ease',
                transform: isDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)'
              }}>
                ▼
              </span>
            </button>

            {/* Dropdown List */}
            {isDropdownOpen && (
              <div
                style={{
                  position: "absolute",
                  top: "100%",
                  right: 0,
                  backgroundColor: "#3b82f6",
                  borderRadius: "4px",
                  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
                  zIndex: 1000,
                  minWidth: "135px",
                  border: "1px solid #e0e0e0",
                  transform: isDropdownOpen ? 'scaleY(1)' : 'scaleY(0)',
                  transformOrigin: 'top',
                  opacity: isDropdownOpen ? 1 : 0,
                  transition: 'transform 0.2s ease, opacity 0.15s ease',
                  pointerEvents: isDropdownOpen ? 'auto' : 'none'
                }}
              >
                {quickActions.map((action, index) => (
                  <div
                    key={index}
                    onClick={() => handleQuickAction(action.route)}
                    style={{
                      width: "100%",
                      padding: "8px 16px",
                      cursor: "pointer",
                      color: "#fff",
                      fontSize: "14px",
                      fontWeight: "500",
                      backgroundColor: "transparent",
                      border: "none",
                      textAlign: "left",
                      transition: 'background-color 0.2s ease',
                      "&:hover": {
                        backgroundColor: "#f5f5f5",
                      },
                    }}
                  >
                    {action.label}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        className="card-grid-container"
      // style={{
      //   display: "grid",
      //   gridTemplateColumns: "repeat(4, minmax(250px, 1fr))",
      //   gap: "20px",
      // }}
      >
        {[
          {
            label: "Lead",
            value: dashboardData?.total_leads?.total_all || 0,
            weeklyCount: dashboardData?.total_leads?.total_weekly || 0,
            previousWeekToTotalRatio: dashboardData?.total_leads?.previousWeekToTotalRatio || "0%",
            route: "/lead-contact",
            icon: <TrendingUp fontSize="large" style={{ color: "#42A5F5" }} />,
          },
          {
            label: "Deal",
            value: dashboardData?.total_deals?.total_all || 0,
            weeklyCount: dashboardData?.total_deals?.total_weekly || 0,
            previousWeekToTotalRatio: dashboardData?.total_deals?.previousWeekToTotalRatio || "0%",
            route: "/deals",
            icon: <Business fontSize="large" style={{ color: "#FF6384" }} />,
          },
          {
            label: "Client",
            value: dashboardData?.total_client?.total_all || 0,
            weeklyCount: dashboardData?.total_client?.total_weekly || 0,
            previousWeekToTotalRatio: dashboardData?.total_client?.previousWeekToTotalRatio || "0%",
            route: "/clients",
            icon: <People fontSize="large" style={{ color: "#36A2EB" }} />,
          },
          {
            label: "Client Visit",
            value: dashboardData?.total_client_visit?.total_all || 0,
            weeklyCount: dashboardData?.total_client_visit?.total_weekly || 0,
            previousWeekToTotalRatio: dashboardData?.total_client_visit?.previousWeekToTotalRatio || "0%",
            route: "/Client-Visit",
            icon: <LocalActivity fontSize="large" style={{ color: "#FFCE56" }} />,
          },
          {
            label: "Revenue",
            value: `$${dashboardData?.total_revenue || 0}`,
            weeklyCount: dashboardData?.weekly_revenue || 0,
            previousWeekToTotalRatio: dashboardData?.previousWeekToTotalRatio || "0%",
            icon: <AttachMoney fontSize="large" style={{ color: "#4CAF50" }} />,
          },
          {
            label: "Conversion Rate",
            value: `${dashboardData?.conversion_rate || 0}%`,
            weeklyCount: dashboardData?.weekly_conversion_rate || 0,
            previousWeekToTotalRatio: dashboardData?.previousWeekToTotalRatio || "0%",
          },
          {
            label: "Proposal",
            value: dashboardData?.proposal?.total_all || 0,
            weeklyCount: dashboardData?.proposal?.total_weekly || 0,
            previousWeekToTotalRatio: dashboardData?.proposal?.weeklyToPreviousWeekRatio || "0%",
          },
          {
            label: "Estimate",
            value: dashboardData?.estimate?.total_all || 0,
            weeklyCount: dashboardData?.estimate?.total_weekly || 0,
            previousWeekToTotalRatio: dashboardData?.estimate?.weeklyToPreviousWeekRatio || "0%",
            icon: <Receipt fontSize="large" style={{ color: "#FF5733" }} />,
          }
        ].map((card, index) => (
          <div
            key={index}
            onClick={() => handleCardClick(card.route)}
            style={{
              padding: "20px",
              border: "1px solid #e0e0e0",
              borderRadius: "10px",
              cursor: "pointer",
              gap: '10px',
              backgroundColor: "#fff",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              transition: "transform 0.2s, box-shadow 0.2s",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            onMouseOver={(e) =>
              (e.currentTarget.style.boxShadow = "0 6px 10px rgba(0, 0, 0, 0.15)")
            }
            onMouseOut={(e) =>
              (e.currentTarget.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.1)")
            }
          >
            {card.icon}
            <p style={{ fontSize: "20px", fontWeight: "500", flex: 1 }}>{card.label}</p>
            <div style={{ textAlign: "right" }}>
              <p style={{ fontSize: "16px", fontWeight: "500", marginBottom: '2px' }}>Total : {card.value}</p>
              <p style={{ fontSize: "14px", color: "gray", marginBottom: '2px' }}>This Week : {card.weeklyCount}</p>
              <p style={{ fontSize: "14px", fontWeight: "500", color: parseFloat(card.previousWeekToTotalRatio) > 0 ? "green" : parseFloat(card.previousWeekToTotalRatio) < 0 ? "red" : "gray" }}>
                {card.previousWeekToTotalRatio > 0 ? `+${card.previousWeekToTotalRatio}` : `${card.previousWeekToTotalRatio}`}
              </p>
            </div>
          </div>
        ))}
      </div>

      <div className="charts-followups-container">
        {/* Left Side - Charts */}
        <div className="charts-container">
          <div className="chart-box">
            <Typography style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }} variant="h6">Lead Source Distribution
              <button
                onClick={() => setShowChart(!showChart)}
                style={{
                  padding: "5px 10px",
                  cursor: "pointer",
                  backgroundColor: "#3b82f6",
                  color: "#fff",
                  border: "none",
                  borderRadius: "4px",
                  marginBottom: '10px',
                  marginTop: '10px'
                }}
              >
                {showChart ? "Show List" : "Show Chart"}
              </button>
            </Typography>

            {showChart ? (
              <div style={{ width: "250px", height: "250px", margin: "0 auto" }}>
                <Doughnut
                  data={doughnutChartData}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                  }}
                />
              </div>
            ) : (
              <div style={{ margin: "0 auto", width: "100%" }}>
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <thead>
                    <tr>
                      <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>Lead Source Name</th>
                      <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>Record Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {leadSourceLabels.map((label, index) => (
                      <tr key={index}>
                        <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>{label}</td>
                        <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>{leadSourceValues[index]}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>


          <div className="chart-box">
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: '10px' }}>
              <div>
                <Typography variant="h6">Client </Typography>
              </div>
              {/* <div ref={clientConversionDropdownRef} style={{ position: "relative" }}>
                <button
                  onClick={toggleClientConversionDropdown}
                  style={{
                    width: '42%',
                    padding: "5px 10px",
                    cursor: "pointer",
                    backgroundColor: "#3b82f6",
                    color: "#fff",
                    border: "none",
                    borderRadius: "4px",
                    marginLeft: '130px'
                  }}
                >
                  {clientConversionFilter}
                </button>
                {clientConversionDropdownOpen && (
                  <div
                    style={{
                      position: "absolute",
                      top: "100%",
                      right: 0,
                      backgroundColor: "#f5f7fa",
                      borderRadius: "4px",
                      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                      zIndex: 1000,
                      minWidth: "120px",
                      marginLeft: '150px'
                    }}
                  >
                    {["This Week", "This Month", "This Quarter", "This Year"].map((filter, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          setClientConversionFilter(filter);
                          setClientConversionDropdownOpen(false);
                        }}
                        style={{
                          padding: "8px 16px",
                          cursor: "pointer",
                          color: "#000",
                          fontSize: "14px",
                          "&:hover": {
                            backgroundColor: "#dcdee0",
                          },
                        }}
                      >
                        {filter}
                      </div>
                    ))}
                  </div>
                )}
              </div> */}
            </div>
            <Bar data={clientConversionData} />
          </div>

          {/* <div className="chart-box">
            <Typography variant="h6">Monthly Sales</Typography>
            <Line data={lineChartData} />
          </div> */}


          <div className="chart-box">
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: '10px' }}>
              <Typography variant="h6">Lead Conversion</Typography>
              {/* <div ref={leadConversionDropdownRef} style={{ position: "relative" }}>
                <button
                  onClick={toggleLeadConversionDropdown}
                  style={{
                    width: '42%',
                    padding: "5px 10px",
                    cursor: "pointer",
                    backgroundColor: "#3b82f6",
                    color: "#fff",
                    border: "none",
                    borderRadius: "4px",
                    marginLeft: '130px'
                  }}
                >
                  {leadConversionFilter}
                </button>
                {leadConversionDropdownOpen && (
                  <div
                    style={{
                      position: "absolute",
                      top: "100%",
                      right: 0,
                      backgroundColor: "#f5f7fa",
                      borderRadius: "4px 0 4px 4px",
                      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                      zIndex: 1000,
                      minWidth: "120px",
                      marginLeft: '150px'
                    }}
                  >
                    {["This Week", "This Month", "This Quarter", "This Year"].map((filter, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          setLeadConversionFilter(filter);
                          setLeadConversionDropdownOpen(false);
                        }}
                        style={{
                          padding: "8px 16px",
                          cursor: "pointer",
                          color: "#000",
                          fontSize: "14px",
                          "&:hover": {
                            backgroundColor: "#dcdee0",
                          },
                        }}
                      >
                        {filter}
                      </div>
                    ))}
                  </div>
                )}
              </div> */}
            </div>
            <Bar data={leadConversionData} />
          </div>


          <div className="chart-box">
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: '10px' }}>
              <Typography variant="h6">Deal Conversion</Typography>
              {/* <div ref={dealConversionDropdownRef} style={{ position: "relative" }}>
                <button
                  onClick={toggleDealConversionDropdown}
                  style={{
                    width: '42%',
                    padding: "5px 10px",
                    cursor: "pointer",
                    backgroundColor: "#3b82f6",
                    color: "#fff",
                    border: "none",
                    borderRadius: "4px",
                    marginLeft: '130px'
                  }}
                >
                  {dealConversionFilter}
                </button>
                {dealConversionDropdownOpen && (
                  <div
                    style={{
                      position: "absolute",
                      top: "100%",
                      right: 0,
                      backgroundColor: "#f5f7fa",
                      borderRadius: "4px 0 4px 4px",
                      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                      zIndex: 1000,
                      minWidth: "120px",
                      marginLeft: '150px'
                    }}
                  >
                    {["This Week", "This Month", "This Quarter", "This Year"].map((filter, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          setDealConversionFilter(filter);
                          setDealConversionDropdownOpen(false);
                        }}
                        style={{
                          padding: "8px 16px",
                          cursor: "pointer",
                          color: "#000",
                          fontSize: "14px",
                          "&:hover": {
                            backgroundColor: "#dcdee0",
                          },
                        }}
                      >
                        {filter}
                      </div>
                    ))}
                  </div>
                )}
              </div> */}
            </div>
            <Bar data={dealConversionData} />
          </div>

        </div>

        {/* Right Side - Upcoming Follow-ups and Recent Activities */}
        <div className="right-container">
          <div className="followups-container">
            <Typography variant="h6">Upcoming Follow-ups</Typography>
            <div style={{
              maxHeight: '250px',
              overflowY: 'auto',
              scrollbarWidth: 'none',  /* For Firefox */
              msOverflowStyle: 'none', /* For Internet Explorer and Edge */
            }}>
              {/* Hide scrollbar for Chrome, Safari, and Opera */}
              <style>
                {`
                div::-webkit-scrollbar {
                  display: none;
                }
            `}
              </style>
              <table style={{ width: "100%", borderCollapse: "collapse", marginTop: '10px' }}>
                <thead>
                  <tr>
                    <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>Client</th>
                    <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>Next Follow-Up </th>
                  </tr>
                </thead>
                <tbody>
                  {upcomingFollowups.length > 0 ? (
                    upcomingFollowups.map((followup) => {
                      const [datePart, timePart] = followup.next_follow_up_date.split(', ');
                      const [day, month, year] = datePart.split('/');
                      const [hours, minutes, seconds] = timePart.split(':');

                      const nextFollowUpDate = new Date(`${year}-${month}-${day}T${hours}:${minutes}:${seconds}`);
                      const formattedDate = formatDate(nextFollowUpDate); // Format as dd-mm-yyyy
                      const formattedTime = nextFollowUpDate.toLocaleTimeString(['en-GB'], { hour: '2-digit', minute: '2-digit' }); // 24-hour format

                      return (
                        <tr key={followup.id}>
                          <td style={{ border: "1px solid #ddd", padding: "8px" }}>{followup.client_name}</td>
                          <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                            {`${formattedDate} ( ${formattedTime} )`} {/* Combine date and time */}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="2" style={{ textAlign: "center", padding: "8px" }}>No upcoming follow-ups</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className="recent-activities-container">
            <Typography variant="h6">Recent Activities</Typography>
            <ul>
              {recentActivities.length > 0 ? (
                recentActivities.map((activity, index) => (
                  <li key={index}>{activity.description}</li>
                ))
              ) : (
                <Typography>No recent activities</Typography>
              )}
            </ul>
          </div>
        </div>
      </div>

      <div
        style={{
          marginTop: "20px",
          padding: "20px",
          border: "1px solid #ddd",
          borderRadius: "8px",
          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h3>Overview (Bar Chart)</h3>
        <div><Bar data={barChartData} /></div>
      </div>
    </div>
  );
};

export default CRMDashboard;

const styles = `

.charts-followups-container {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

 .card-grid-container {
    display: grid;
    grid-template-columns:repeat(4, minmax(250px, 1fr));
    gap: 10px;
  }

.charts-container {
  flex: 3;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.chart-box {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center; /* Center align chart titles */
}

.chart-box div {
  margin: 0 auto; /* Center the chart container */
}

.right-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.followups-container{
  height:50%;
}

.recent-activities-container{
  height:50%;
}

.followups-container, .recent-activities-container {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  margin-bottom: 10px;
}


  


@media (max-width: 800px) { /* Tablet View */
  .charts-followups-container {
    flex-direction: column;
  }

  .charts-container {
    grid-template-columns: 1fr;
  }

  .right-container {
    flex-direction: row;
    gap: 10px;
  }

  .followups-container,
  .recent-activities-container {
    flex: 1;
    height: auto;
  }
}

@media (max-width: 500px) { /* Mobile View */
  .top-controls {
    flex-direction: column;
    align-items: flex-start;
  }
    
   .top-controls h1 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

     .top-controls > div {
    position: static !important;
    width: 100%;
    justify-content: flex-start;
    gap: 10px;
    flex-wrap: wrap;
  }

  .dropdown-container {
    width: 100%;
    display: flex;
    gap: 10px;
  }

  .dropdown-container > div {
    flex: 1;
  }

  .dropdown-container button {
    width: 100%;
    padding: 8px 12px;
    font-size: 14px;
  }

  .main-container {
    padding: 10px;
  }

  .charts-followups-container {
    flex-direction: column;
  }

  .charts-container {
    grid-template-columns: 1fr;
  }

  .right-container {
    flex-direction: column;
  }

  .followups-container,
  .recent-activities-container {
    width: 100%;
  }

  .top-cards-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .top-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
  }


    /* Grid layout for cards */
  .card-grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .card-grid-container > div {
    padding: 15px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .card-grid-container p {
    font-size: 14px;
  }

  .card-grid-container .MuiSvgIcon-root {
    font-size: 1.5rem;
  }
}

`;

const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);
