import { GridMoreVertIcon } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import Swal from 'sweetalert2';
import noDataImage from '../assets/NoDataFound.png';
import { Vortex } from 'react-loader-spinner';
import { Box, Stack, Pagination } from '@mui/material';

const Vendor = () => {
  const [vendors, setVendors] = useState([]); // State to store fetched vendor data
  const navigate = useNavigate(); // Create navigate function
  const [actionMenuVisible, setActionMenuVisible] = useState(null);
  const [searchQuery, setSearchQuery] = useState(""); // For search functionality
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Items per page

  const fetchVendors = async () => {
    const token = localStorage.getItem('authToken'); // Get token from localStorage

    try {
      const response = await fetch('https://api.myntask.com/vendor/list', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      console.log('Response:', response); // Log the response for debugging

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Response Error Text:', errorText);
        throw new Error('Network response was not ok');
      }

      const data = await response.json(); // Parse JSON response
      console.log('Fetched vendors:', data); // Log the fetched data for debugging

      setVendors(data); // Adjust this based on the actual structure
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching vendors:', error);
      setError('Failed to Fetch Vendors');
      setIsLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to the first page on search
  };

  const filteredVendors = vendors.filter((vendor) =>
    vendor.company_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const totalPages = Math.ceil(filteredVendors.length / itemsPerPage);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const paginatedVendors = filteredVendors.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleSort = (column) => {
    let direction = "asc";
    if (sortColumn === column && sortDirection === "asc") {
      direction = "desc";
    }
    setSortColumn(column);
    setSortDirection(direction);

    const sortedData = [...vendors].sort((a, b) => {
      const aValue = a[column] || ""; // Fallback to an empty string if null/undefined
      const bValue = b[column] || "";

      if (direction === "asc") {
        return aValue.toString().localeCompare(bValue.toString());
      } else {
        return bValue.toString().localeCompare(aValue.toString());
      }
    });

    setVendors(sortedData);
  };

  useEffect(() => {
    fetchVendors();
  }, []);

  const toggleActionMenu = (id) => {
    setActionMenuVisible((prevVisible) => (prevVisible === id ? null : id));
  };

  const handleDeleteClick = async (id) => {
    try {
      const token = localStorage.getItem('authToken');

      if (!token) {
        Swal.fire({
          title: 'Error',
          text: 'Authorization token is missing!',
          icon: 'error',
          timer: 1500,
          showConfirmButton: false,
        });
        return;
      }

      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'Do you want to delete this item?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel',
      });

      if (result.isConfirmed) {
        const url = `https://api.myntask.com/vendor/delete`;

        const response = await fetch(url, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ vendor_id: id }), // Send the vendor ID in the request body
        });

        const data = await response.json();
        console.log('Item deleted successfully:', data);

        Swal.fire({
          title: 'Deleted!',
          text: 'The item has been deleted successfully.',
          icon: 'success',
          timer: 1500,
          showConfirmButton: false,
        });

        fetchVendors();
      }
    } catch (error) {
      console.error('Error deleting item:', error);

      Swal.fire({
        title: 'Error',
        text: 'Failed to delete the item. Please try again.',
        icon: 'error',
        timer: 1500,
        showConfirmButton: false,
      });
    }
  };

  const toTitleCase = (str) => {
    if (!str) return 'N/A';
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const handleEditClick = (vendorId) => {
    navigate(`/vendor-edit/${vendorId}`);
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }

  // if (error) {
  //   return (
  //     <Typography
  //       color="error"
  //       sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
  //     >
  //       {error}
  //     </Typography>
  //   );
  // }

  return (
    <div className="main-container">
      <div className="top-controls">
        <h1 className='title'>Vendors
          <div className="underline"></div>
        </h1>
        <div className="buttons-row">
          <button className="color-button" onClick={() => navigate('/add-vendor')}>
            Add Vendor
          </button>
        </div>
      </div>

      <div className="top-controls">
        <div className="filters-and-search">
          <input
            className="searchbar"
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange} // Trigger search on input change
          />
        </div>
      </div>

      <div>
        {vendors.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th onClick={() => handleSort("company_name")}>
                  Company {sortColumn === "company_name" && (sortDirection === "asc" ? "↑" : "↓")}
                </th>
                <th onClick={() => handleSort("email")}>Email {sortColumn === "email" && (sortDirection === "asc" ? "↑" : "↓")}</th>
                <th onClick={() => handleSort("city")}>City {sortColumn === "city" && (sortDirection === "asc" ? "↑" : "↓")}</th>
                {/* <th onClick={() => handleSort("phone")}>Phone {sortColumn === "phone" && (sortDirection === "asc" ? "↑" : "↓")}</th> */}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {paginatedVendors.length > 0 ? (
                paginatedVendors.map((vendor) => (
                  <tr key={vendor.id}>
                    <td>
                      {toTitleCase(vendor.company_name || 'N/A')}
                    </td>
                    <td>{vendor.email || 'N/A'}</td>
                    <td>{vendor.city || 'N/A'}</td>
                    {/* <td>{vendor.phone || 'N/A'}</td> */}
                    <td>
                      <div className="action-container">
                        <div className="action-dots" onClick={() => toggleActionMenu(vendor.id)}>
                          <GridMoreVertIcon />
                        </div>
                        {actionMenuVisible === vendor.id && (
                          <div className="action-menu">
                            <div className="action-item" onClick={() => handleEditClick(vendor.id)}>Edit</div>
                            <div className="action-item" onClick={() => handleDeleteClick(vendor.id)}>Delete</div>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" style={{ textAlign: 'center' }}>No matching vendors found</td>
                </tr>
              )}
            </tbody>
          </table>
        ) : (
          <div className="no-data-container">
            <img src={noDataImage} alt="No data available" className="no-data-image" />
            <p className="no-data-text">No data found</p>
          </div>
        )}
      </div>

      {/* Pagination Controls */}
      {filteredVendors.length > itemsPerPage && (
        <Stack spacing={2} alignItems="center" sx={{ marginTop: "20px" }}>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
        </Stack>
      )}
    </div>
  );
};

export default Vendor;
