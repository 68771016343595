import { GridMoreVertIcon } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const VendorPaymentPage = () => {
  const [data, setData] = useState([]); // State to store API data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const navigate = useNavigate();
  const [actionMenuVisible, setActionMenuVisible] = useState({});
  const actionMenuRef = React.useRef(null);


  const fetchVendorPayments = async () => {
    const token = localStorage.getItem("authToken");
    try {
      const response = await fetch("https://api.myntask.com/purchaseApproval/listpurchase", {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const result = await response.json();
      setData(result); // Store API data
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
  
    fetchVendorPayments();
  }, []);


const handleDelete = async (purchaseId) => {
  const token = localStorage.getItem("authToken");

  // Show confirmation alert
  const result = await Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#d33",
    cancelButtonColor: "#3085d6",
    confirmButtonText: "Yes, delete it!",
  });

  if (result.isConfirmed) {
    try {
      const response = await fetch(`https://api.myntask.com/purchaseApproval/delete/${purchaseId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to delete");
      }

      // Show success toast
      Swal.fire({
        icon: "success",
        title: "Deleted!",
        text: "The purchase record has been deleted.",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });

      // Refresh data after deletion
      fetchVendorPayments();
    } catch (error) {
      console.error("Error deleting:", error);

      // Show error toast
      Swal.fire({
        icon: "error",
        title: "Oops!",
        text: "Something went wrong. Try again!",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });
    }
  }
};


    // Toggle the action menu visibility for a specific task
    const toggleActionMenu = (purchaseId) => {
      setActionMenuVisible((prevState) => ({
        ...prevState,
        [purchaseId]: !prevState[purchaseId],
      }));
    };
  
  
    // Close action menu when clicking outside
    useEffect(() => {
      const handleClickOutside = (event) => {
        // Check if the click is outside the action menu
        if (actionMenuRef.current && !actionMenuRef.current.contains(event.target)) {
          setActionMenuVisible({});
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [actionMenuRef]);

    const handleEditClick = (purchaseId) => {
      console.log("Editing purchase:", purchaseId); // Debugging
      navigate(`/edit-payment/${purchaseId}`);
    };
  


  return (
    <div className="main-container">
       <div className="top-controls">
        <h1 className='title'>Vendor Payments
          <div className="underline"></div>
        </h1>
        <div className="buttons-row">
        <button className="color-button" onClick={() => navigate('/add-payment')}>
            Add Payment
          </button>
        </div>
      </div>

      {loading && <p>Loading...</p>}
      {error && <p style={{ color: "red" }}>Error: {error}</p>}
      
      {!loading && !error && data.length === 0 && <p>No data available</p>}

      {!loading && !error && data.length > 0 && (
        <table >
          <thead>
            <tr>
              <th>Project Name</th>
              <th>Vendor Name</th>
              {/* <th>Amount</th>
              <th>Tax</th>
              <th>Other Expenses</th> */}
              <th>Total Amount</th>
              <th>Delivery Date</th>
              <th>Priority</th>
              <th>Payment Type</th>
              <th>Vendor Location</th>
              <th>Vendor Type</th>
              <th>Invoice</th>
              <th>Action</th>
            </tr>
          </thead>
         <tbody>
  {data.map((item) => (
    <tr key={item.purchase_id}>
      <td>{item.project_name || "N/A"}</td>
      <td>{item.vendor_name || "N/A"}</td>
      {/* <td>{item.amount}</td>
      <td>{item.tax}</td>
      <td>{item.other_expenses}</td> */}
      <td>{item.total_amount}</td>
      <td>{item.expected_delivery_date}</td>
      <td>{item.priority}</td>
      <td>{item.payment_type}</td>
      <td>{item.vendor_location}</td>
      <td>{item.vendor_type}</td>
      <td>
  {item.invoiceFile ? (
    <a 
      href={item.invoiceFile} 
      target="_blank" 
      rel="noopener noreferrer"
    >
      View Invoice
    </a>
  ) : (
    "No Invoice"
  )}
</td>

      <td>
        <div className="action-container">
          <div className="action-dots" onClick={() => toggleActionMenu(item.purchase_id)}>
            <GridMoreVertIcon />
          </div>
          {actionMenuVisible[item.purchase_id] && (
            <div className="action-menu" ref={actionMenuRef}>
             <div className="action-item" onClick={() => handleEditClick(item.purchase_id)}>
      Edit
    </div>
              <div className="action-item" onClick={() => handleDelete(item.purchase_id)}>
  Delete
</div>

            </div>
          )}
        </div>
      </td>
    </tr>
  ))}
</tbody>

        </table>
      )}
    </div>
  );
};

export default VendorPaymentPage;
