import React from 'react';

function ProfileSettingg() {
  return (
    <div>
      <h3>Profile Settings</h3>
      <p>This is the profile settings tab content.</p>
      {/* Add your profile settings form or other content here */}
    </div>
  );
}

export default ProfileSettingg;
