import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Paper,
  Container,
  Typography,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

// Styled Components
const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledTableContainer = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  position: 'relative',
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
}));

// Function to initialize permissions with default values
const initializePermissions = (data) => {
  const initializedData = {};

  Object.keys(data).forEach((module) => {
    initializedData[module] = {};
    Object.keys(data[module]).forEach((action) => {
      if (typeof data[module][action] === 'object' && Object.keys(data[module][action]).length > 0) {
        initializedData[module][action] = { ...data[module][action] };
      } else {
        initializedData[module][action] = {};
      }
    });
  });

  return initializedData;
};

// Render permissions data with proper handling of nested objects
const renderPermissionsData = (permissions) => {
  const actionTypes = ['add', 'view', 'edit', 'update', 'delete', 'more'];

  return (
    <div>
      {Object.keys(permissions).map((module) => (
        <div key={module} style={{ marginBottom: '16px' }}>
          <h3>{module}</h3>
          {actionTypes.map((action) => (
            <div key={action} style={{ marginBottom: '8px', border: '1px solid #ddd', borderRadius: '4px', padding: '8px' }}>
              <h4>{action.charAt(0).toUpperCase() + action.slice(1)}</h4>
              {permissions[module] && permissions[module][action] ? (
                typeof permissions[module][action] === 'object' ? (
                  Object.keys(permissions[module][action]).length === 0 ? (
                    <p>No data available</p>
                  ) : (
                    Object.keys(permissions[module][action]).map((key) => (
                      <div key={key} style={{ marginBottom: '4px' }}>
                        <strong>{key}:</strong>
                        {typeof permissions[module][action][key] === 'object' ? (
                          JSON.stringify(permissions[module][action][key])
                        ) : (
                          permissions[module][action][key].toString()
                        )}
                        <input
                          type="checkbox"
                          checked={permissions[module][action][key] === 4}
                          readOnly
                          style={{ marginLeft: '8px' }}
                        />
                        <label style={{ marginLeft: '4px' }}>
                          {permissions[module][action][key] === 4 ? 'Enabled' : 'Disabled'}
                        </label>
                      </div>
                    ))
                  )
                ) : (
                  <div style={{ marginBottom: '4px' }}>
                    <input
                      type="checkbox"
                      checked={permissions[module][action] === 4}
                      readOnly
                      style={{ marginLeft: '8px' }}
                    />
                    <label style={{ marginLeft: '4px' }}>
                      {permissions[module][action] === 4 ? 'Enabled' : 'Disabled'}
                    </label>
                  </div>
                )
              ) : (
                <p>No data available</p>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

const RolesPermission = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [permissions, setPermissions] = useState(null);
  const [permissionsLoading, setPermissionsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('authToken'); // Retrieve token from localStorage
      if (!token) {
        setError('No token found');
        setLoading(false);
        return;
      }

      try {
        const response = await fetch('https://api.myntask.com/rolepermission/roles', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          const errorMessage = await response.text();
          throw new Error(errorMessage || 'Network response was not ok');
        }

        const result = await response.json();
        setData(result);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleOpen = async () => {
    setOpen(true);
    await fetchPermissions();
  };

  const handleClose = () => {
    setOpen(false);
    setPermissions(null);
  };

  const fetchPermissions = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('No token found');
      return;
    }

    setPermissionsLoading(true);

    try {
      const response = await fetch('https://api.myntask.com/rolepermission/permissions_list', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(errorMessage || 'Network response was not ok');
      }

      const result = await response.json();
      console.log('API Response:', result); // Console log the API response
      setPermissions(initializePermissions(result));
    } catch (error) {
      setError(error.message);
    } finally {
      setPermissionsLoading(false);
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <div>Error: {error}</div>;

  return (
    <StyledContainer>
      <Title variant="h4">Roles Data</Title>
      <StyledTableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Role ID</TableCell>
              <TableCell>Role Name</TableCell>
              <TableCell>Members</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((role) => (
              <TableRow key={role.role_id}>
                <TableCell>{role.role_id}</TableCell>
                <TableCell>{role.role_name}</TableCell>
                <TableCell>{role.member}</TableCell>
                <TableCell>
                  <Button variant="contained" color="primary" onClick={handleOpen}>
                    Permissions
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <StyledDialogTitle>
          Permissions
          <CloseButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </CloseButton>
        </StyledDialogTitle>
        <DialogContent>
          {permissionsLoading ? (
            <CircularProgress />
          ) : (
            permissions && renderPermissionsData(permissions)
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary">
            Save
          </Button>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </StyledContainer>
  );
};

export default RolesPermission;
