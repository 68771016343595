import { Vortex } from 'react-loader-spinner';
import { Box, Stack, Typography, Pagination } from '@mui/material';
import { GridMoreVertIcon } from '@mui/x-data-grid';
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import noDataImage from '../assets/NoDataFound.png';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../Components/MainFunction';

function PurchaseOrder() {
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null); // For tracking selected row
  const [searchQuery, setSearchQuery] = useState(''); // State for the search query
  const [actionMenuVisible, setActionMenuVisible] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10); 
  const [isDownloading, setIsDownloading] = useState(false);
  const navigate = useNavigate();


  const fetchPurchaseOrders = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('No authentication token found. Please log in.');
      return;
    }
    try {
      const response = await fetch('https://api.myntask.com/purchase_order/list', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch purchase orders');
      }
      const data = await response.json();
      setPurchaseOrders(data);
      setIsLoading(false);
    } catch (error) {
      setError('Error fetching purchase orders. Please try again later.');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPurchaseOrders();
  }, []);

  const handleRowClick = (id) => {
    setSelectedRow(id); // Set selected row by ID
  };

  const handleDownload = async (orderId) => {
    const token = localStorage.getItem('authToken');
    console.log('order id', orderId);
    if (!token) {
      Swal.fire({
        icon: 'error',
        title: 'Authentication Error',
        text: 'No authentication token found. Please log in.',
        toast: true,
        position: 'top-end',
        timer: 3000,
        showConfirmButton: false,
      });
      return;
    }
    setIsDownloading(true); // Set loading state to true
    try {
      const response = await fetch(`https://api.myntask.com/purchase_order/download/${orderId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to download purchase order');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `purchase_order_${orderId}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      Swal.fire({
        icon: 'success',
        title: 'Download Successful',
        text: `Purchase order ${orderId} has been downloaded.`,
        toast: true,
        position: 'top-end',
        timer: 3000,
        showConfirmButton: false,
      });
    } catch (error) {
      console.error('Error downloading purchase order:', error);

      Swal.fire({
        icon: 'error',
        title: 'Download Error',
        text: 'Failed to download purchase order. Please try again later.',
        toast: true,
        position: 'top-end',
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      setIsDownloading(false); // Reset loading state
    }
  };


  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const toggleActionMenu = (id) => {
    setActionMenuVisible((prevVisible) => (prevVisible === id ? null : id));
  };

  const handleDeleteClick = async (orderId) => {
    const token = localStorage.getItem('authToken'); // Retrieve token from local storage




    // Display confirmation dialog
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to delete this order?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    });




    if (result.isConfirmed) {
      try {
        const response = await fetch(`https://api.myntask.com/purchase_order/delete/${orderId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`, // Pass token in the header
          },
        });

        if (!response.ok) {
          throw new Error('Failed to delete the order');
        }

        // Refetch the products list after successful deletion
        await fetchPurchaseOrders();;

        // Show success message
        Swal.fire({
          title: 'Deleted!',
          text: 'Order deleted successfully.',
          icon: 'success',
          timer: 1500,
          showConfirmButton: false,
        });
      } catch (error) {
        console.error('Error deleting Order:', error);
        // Show error message
        Swal.fire({
          title: 'Error!',
          text: 'Error deleting Order. Please try again.',
          icon: 'error',
          timer: 1500,
          showConfirmButton: false,
        });
      }
    }
  };

  // Filter purchase orders based on the search query
  const filteredOrders = purchaseOrders.filter((order) => {
    return (
      order.purchase_order_number.toLowerCase().includes(searchQuery.toLowerCase()) ||
      order.primary_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      order.status.toLowerCase().includes(searchQuery.toLowerCase()) // Include status in the search
    );
  });


  const toTitleCase = (str) => {
    if (!str) return 'N/A';
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };


  const handleStatusUpdate = async (id, status) => {
    const token = localStorage.getItem("authToken"); // Retrieve token from local storage
    if (!token) {
      console.error("No token found in local storage.");
      Swal.fire({
        icon: "error",
        title: "Authentication Error",
        text: "No token found. Please log in again.",
        toast: true,
        position: "top-right",
        timer: 3000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
      return;
    }
    const requestBody = {
      id,
      status,
    };
    try {
      const response = await fetch(
        "https://api.myntask.com/purchase_order/status_update",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        }
      );
      if (!response.ok) {
        const errorData = await response.json();
        console.error("Error updating status:", errorData.message);
        Swal.fire({
          icon: "error",
          title: "Failed to Update Status",
          text: errorData.message || "An error occurred. Please try again.",
          toast: true,
          position: "top-right",
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
        return;
      }
      const result = await response.json();
      console.log(`Status updated successfully to '${status}' .`);
      Swal.fire({
        icon: "success",
        title: "Status Updated",
        text: `Successfully updated status to '${status}' .`,
        toast: true,
        position: "top-right",
        timer: 3000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
      // Refresh the screen or reload the data
      window.location.reload(); // Refreshes the entire page (alternatively, update the state if using React state management)
    } catch (error) {
      console.error("Failed to update status:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to update status. Please try again.",
        toast: true,
        position: "top-right",
        timer: 3000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    }
  };

  const addOrderPage = () => {
    navigate('/addOrder');
  }


  const handleEditClick = (id) => {
    navigate(`/EditPurchaseOrder/${id}`);
  };


  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const paginatedOrders = filteredOrders.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );


  // Add this function to handle viewing the PDF
  const handleView = (orderId) => {
    navigate(`/ViewPurchaseOrder/${orderId}`);
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }
  // if (error) {
  //   return (
  //     <Typography
  //       color="error"
  //       sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
  //     >
  //       {error}
  //     </Typography>
  //   );
  // }

  const getStatusColor = (status) => {
    const colors = {
      pending: "rgba(255, 165, 0, 0.6)", // Brighter orange with 60% opacity
      approved: "rgba(0, 0, 255, 0.6)", // Brighter blue with 60% opacity
      rejected: "rgba(255, 0, 0, 0.6)", // Brighter red with 60% opacity
    };
    return colors[status.toLowerCase()] || "white"; // Default to white if status is undefined
  };



  return (
    <div className='main-container'>
      <div className="top-controls">
        <h1 className='title'>Purchase Orders
          <div className="underline"></div>
        </h1>
        <div className="buttons-row">
          <button className="color-button" onClick={addOrderPage}>
            Add Order
          </button>
        </div>
      </div>
      <div className="top-controls">
        <div className="filters-and-search">
          <input
            className="searchbar"
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange} // Trigger search on input change
          />
        </div>
      </div>
      {paginatedOrders.length > 0 ? (
        <>
          <table>
            <thead>
              <tr>
                <th>PO Number</th>
                <th>Supplier Name</th>
                <th>Purchase Date</th>
                <th>Expected Delivery</th>
                <th>Delivery Status</th>
                <th>Total Amount</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {paginatedOrders.map((order) => (
                <tr
                  key={order.id}
                  onClick={() => handleRowClick(order.id)}
                  className={selectedRow === order.id ? 'active' : ''}
                >
                  <td>{toTitleCase(order.purchase_order_number || 'N/A')}</td>
                  <td style={{ width: '20vw' }}>{toTitleCase(order.primary_name || 'N/A')}</td>
                  <td style={{ textAlign: 'center' }}>{formatDate(order.purchase_date ? new Date(order.purchase_date) : 'N/A')}</td>
                  <td style={{ textAlign: 'center' }}>{formatDate(order.expected_delivery_date ? new Date(order.expected_delivery_date) : 'N/A')}</td>
                  <td>{toTitleCase(order.delivery_status || 'N/A')}</td>
                  <td style={{ textAlign: 'center' }}>₹{order.total_amount || 'N/A'}</td>
                  {/* Inline Dropdown for Status */}
                  {/* <td>
  <select
    value={order.status || "pending"} // Default value if status is undefined
    onChange={(e) => handleStatusUpdate(order.id, e.target.value)}
    className="table-filter"
    style={{
      width: "100%",
      marginLeft: "0px",
      backgroundColor: getStatusColor(order.status || "pending"), // Dynamic background color
      color: "white", // White text for contrast
      fontWeight: "bold",
      padding: "5px",
      borderRadius: "5px",
      border: "none",
    }}
  >
    <option value="pending">Pending</option>
    <option value="approved">Approved</option>
    <option value="rejected">Rejected</option>
  </select>
</td> */}

                  <td>
                    <select
                      value={order.status || "pending"} // Default value if status is undefined
                      onChange={(e) => handleStatusUpdate(order.id, e.target.value)}
                      style={{
                        textAlign: 'center',
                        border: 'none',
                        backgroundColor: getStatusColor(order.status || "select status"), // Background color for dropdown
                        color: "white", // White text for contrast
                        width: '120px',
                        padding: '10px',
                        borderRadius: "4px",
                      }}
                    >
                      <option value="pending" style={{ backgroundColor: "white", color: "black" }}>
                        Pending
                      </option>
                      <option value="approved" style={{ backgroundColor: "white", color: "black" }}>
                        Approved
                      </option>
                      <option value="rejected" style={{ backgroundColor: "white", color: "black" }}>
                        Rejected
                      </option>
                    </select>
                  </td>


                  <td style={{ textAlign: 'center' }}>
                    <div className="action-container">
                      <div className="action-dots" onClick={() => toggleActionMenu(order.id)}>
                        <GridMoreVertIcon />
                      </div>
                      {actionMenuVisible === order.id && (
                        <div className="action-menu">
                          {/* <div
                            className="action-item"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent row click
                              handleDownload(order.id);
                            }}
                          >
                            {isDownloading ? 'Loading...' : 'Download'}
                          </div> */}

                          <div className="action-item" onClick={() => handleEditClick(order.id)}>
                            Edit
                          </div>

                          <div className="action-item" onClick={() => handleView(order.id)}>
                            View
                          </div>

                          <div
                            className="action-item"
                            onClick={() => handleDeleteClick(order.id)}
                          >
                            Delete
                          </div>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Box display="flex" justifyContent="center" marginTop={2}>
            <Pagination
              count={Math.ceil(filteredOrders.length / rowsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
            />
          </Box>
        </>
      ) : (
        <div className="no-data-container">
          <img src={noDataImage} alt="No data available" className="no-data-image" />
          <p className="no-data-text">No data found</p>
        </div>
      )}
    </div>
  );
}

export default PurchaseOrder;
