import React, { useState, useEffect } from "react";
import "./LandingPage.css";
import dashboardImage from "../assets/landing.png";
import partnerLogos from "../assets/sky.png";
import blackWhiteLogo from "../assets/black-white.png";
import Login from "./Login";
import SignUpLogInForm from "../Components/SignUpLogInForm";
import { Modal, Box } from "@mui/material";
import SignUp from "../Components/SignUp";




const LandingPage = () => {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);

  useEffect(() => {
    setIsLoginModalOpen(true); // Opens the login modal automatically on page load
  }, []);



  const handleOpenLoginModal = () => {
    setIsLoginModalOpen(true);
  };




  const handleCloseLoginModal = () => {
    setIsLoginModalOpen(false);
  };




  const handleOpenSignUpModal = () => {
    setIsSignUpModalOpen(true);
  };




  const handleCloseSignUpModal = () => {
    setIsSignUpModalOpen(false);
  };




  return (
    <div className="landing-page">
      {/* Top Navigation */}
      <div className="top-navigation">
        <div className="logo">
          <img src={blackWhiteLogo} alt="MynTask CRM Logo" className="logo-image" />
        </div>
        {/* <button className="sign-in-button" onClick={handleOpenLoginModal}>
          Sign In
        </button> */}
      </div>




      {/* Hero Section */}
      {/* <section className="hero">
        <div className="hero-content">
          <h1>
            Manage Your <span className="highlight">Tasks</span>, Projects, and Teams with Ease
          </h1>
          <p className="hero-subtitle">
            MynTask CRM brings together all your business needs: HR, Project Management, Lead Management, and Purchase Orders — all in one platform.
          </p>
          <div className="cta-buttons"> */}
      {/* <button className="cta-button primary">Get Started Free</button> */}
      {/* <button className="cta-button primary" onClick={handleOpenSignUpModal}>
              Sign Up
            </button>
            <button className="cta-button secondary">View Demo</button>
          </div>
        </div>
        <div className="hero-image">
          <img src={dashboardImage} alt="MynTask CRM Dashboard" />
        </div>
      </section> */}




      {/* Footer Section */}
      {/* <footer className="footer">
        <div className="footer-container">
          <div className="footer-column">
            <h3>Links</h3>
            <ul>
              <li>Home</li>
              <li>Contact</li>
              <li>About Us</li>
              <li>Get Started</li>
            </ul>
          </div>
          <div className="footer-column">
            <h3>Services</h3>
            <ul>
              <li>HR Management</li>
              <li>Project Management</li>
              <li>Lead Management</li>
              <li>Purchase Orders</li>
            </ul>
          </div>
          <div className="footer-column">
            <h3>Contact</h3>
            <ul>
              <li>Phone: +91 9876543210</li>
              <li>Email: support@myntaskcrm.com</li>
              <li>Address: 123 Business St, Jaipur, India</li>
              <li>Office Hours: Mon - Fri, 9:00 AM - 6:00 PM</li>
            </ul>
          </div>
        </div>
        <div className="footer-bottom">
          <p>
            Privacy Policy | Made with ❤️ by MynTask CRM
          </p>
        </div>
      </footer> */}








      {/* Login Modal */}
      <Modal open={isLoginModalOpen}  >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: "10px",
          }}
        >
          {/* <Login /> */}
          <SignUpLogInForm />
        </Box>
      </Modal>


      {/* Sign Up Modal */}
      {/* <Modal open={isSignUpModalOpen} onClose={handleCloseSignUpModal} >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "95%", sm: "80%", md: "60%" }, // Responsive width
            maxHeight: "calc(90vh - 40px)", // Prevent overflow
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: "12px",
            overflowY: "auto", // Enable scroll for content overflow
          }}
        >


          <SignUp />


        </Box>
      </Modal> */}


    </div>
  );
};




export default LandingPage;


















