import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, startOfWeek, endOfWeek, startOfMonth, endOfMonth, startOfYear, endOfYear, subMonths } from "date-fns";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend, Cell } from "recharts";
import { Box, Modal, Typography } from "@mui/material";



const ProjectExpenseReport = () => {
  const [expenses, setExpenses] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem('authToken');
    const [filter, setFilter] = useState("");
    const [customDates, setCustomDates] = useState({ start: null, end: null });
    const [members, setMembers] = useState([]);
    const [selectedMember, setSelectedMember] = useState("");
    const [projects, setProjects] = useState([]);
    const [projectId, setProjectId] = useState("");
    const [open, setOpen] = useState(false);
    const [chartData, setChartData] = useState([]);
  
    const getDateRange = () => {
      const today = new Date();
      let startDate, endDate;
  
      switch (filter) {
        case "thisWeek":
          startDate = startOfWeek(today);
          endDate = endOfWeek(today);
          break;
        case "thisMonth":
          startDate = startOfMonth(today);
          endDate = endOfMonth(today);
          break;
        case "thisQuarter":
          startDate = subMonths(today, 2);
          endDate = today;
          break;
        case "thisYear":
          startDate = startOfYear(today);
          endDate = endOfYear(today);
          break;
        case "custom":
          startDate = customDates.start;
          endDate = customDates.end;
          break;
        default:
          startDate = null;
          endDate = null;
      }
  
      return { startDate, endDate };
    };


          // Fetch project list from the API
          useEffect(() => {
            const fetchProject = async () => {
              try {
                // Retrieve the token from local storage
                const token = localStorage.getItem('authToken');
                // Check if the token is available
                if (!token) {
                  throw new Error('Authorization token is missing');
                }
                const response = await fetch('https://api.myntask.com/task/project_list', {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                  },
                });
                // Handle the response
                if (!response.ok) {
                  throw new Error(`Failed to fetch project list: ${response.statusText}`);
                }
                const data = await response.json();
                setProjects(data);
                console.log('Project List Data**********************************:', data);
               
              } catch (error) {
                console.error('Error fetching project list:', error);
              } 
            };
        
            fetchProject();
          }, []); // Run only once on component mount

    useEffect(() => {
        const fetchData = async (url, setter) => {
          const token = localStorage.getItem("authToken");
          try {
            const response = await fetch(url, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            });
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setter(data || []);
          } catch (error) {
            console.error(`Error fetching data from ${url}:`, error);
            setter([]);
          }
        };
    
        fetchData("https://api.myntask.com/employee/member", setMembers);
      }, []);

  useEffect(() => {

    const fetchExpenses = async () => {
               const { startDate, endDate } = getDateRange();
              const formattedStartDate = startDate ? format(startDate, "yyyy-MM-dd") : "";
              const formattedEndDate = endDate ? format(endDate, "yyyy-MM-dd") : ""; 
      try {
        const response = await fetch(
          `https://api.myntask.com/project/expense/${projectId}?member_id=${selectedMember}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        setExpenses(data.expenses || []); // Ensure it's always an array
          // Format chart data
          const formattedData = formatChartData(data.status_totals);
          console.log("✅ Formatted Chart Data:", formattedData);
    
          setChartData(formattedData); // Set formatted data for the chart
        console.log("Chart Data:", data.status_totals); // Log the chart data
      } catch (error) {
        setExpenses([]); // Set to empty array if error occurs
      } finally {
        setLoading(false);
      }
    };

    fetchExpenses();
  }, [filter, customDates, selectedMember, projectId]);

  // ✅ Function to format chart data
  const formatChartData = (statusTotals) => {
    if (!statusTotals || typeof statusTotals !== "object") {
      console.warn("formatChartData received invalid data:", statusTotals);
      return [];
    }

    return Object.entries(statusTotals).map(([key, value]) => ({
      status: key,
      amount: parseFloat(value.replace(/[^0-9.]/g, "")), // Convert ₹ to number
      color: getStatusColor(key),
    }));
  };

  // ✅ Function to assign colors based on status
  const getStatusColor = (status) => {
    const statusColors = {
      paid: "#4CAF50", // Green
      approved: "#2196F3", // Blue
      pending: "#FFC107", // Yellow
      rejected: "#F44336", // Red
    };
    return statusColors[status] || "#8884d8"; // Default color if not found
  };

  return (
    <div className="main-container">
       <div className="top-controls">
              <h1 className="title">
                Project Expense Report
                <div className="underline"></div>
              </h1>
            </div>
      
      
         {/* Filters */}
         <div className="top-controls">
              <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>

              <div >
                       <label className="lead-form-label">Select Project</label>
                       <select
                         className="select-dropdown"
                         value={projectId}
                         onChange={(e) => setProjectId(e.target.value)}
                       >
                         <option value="">Select Project</option>
                         {projects.map((project) => (
                           <option key={project.id} value={project.id}>
                             {project.project_name}
                           </option>
                         ))}
                       </select>
                     </div>
      
                   {/* Member Selection Dropdown */}
                   <div >
                       <label className="lead-form-label">Select Member</label>
                       <select
                         className="select-dropdown"
                         value={selectedMember}
                         onChange={(e) => setSelectedMember(e.target.value)}
                       >
                         <option value="">Select Member</option>
                         {members.map((member) => (
                           <option key={member.id} value={member.id}>
                             {member.member_name}
                           </option>
                         ))}
                       </select>
                     </div>
            {/* Date Filter */}
            <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
              <div >
                <label className="lead-form-label">Date Filter</label>
                <select className="select-dropdown" value={filter} onChange={(e) => setFilter(e.target.value)}>
                <option value="" disabled selected>Select Date</option>
                  <option value="thisWeek">This Week ({format(startOfWeek(new Date()), "MMM d")} - {format(endOfWeek(new Date()), "MMM d")})</option>
                  <option value="thisMonth">This Month ({format(new Date(), "MMMM")})</option>
                  <option value="thisQuarter">This Quarter (Dec - {format(new Date(), "MMM")})</option>
                  <option value="thisYear">This Year ({new Date().getFullYear()})</option>
                  <option value="custom">Custom Date</option>
                </select>
              </div>
      
              {filter === "custom" && (
                <div className="date-picker-container" style={{ width: "50%",marginTop:'0px' }}>
                  <div>
                    <label className="lead-form-label">Start Date:</label>
                    <DatePicker
                      selected={customDates.start}
                      onChange={(date) => setCustomDates({ ...customDates, start: date })}
                      selectsStart
                      startDate={customDates.start}
                      endDate={customDates.end}
                      placeholderText="Start Date"
                      className="date-picker"
                    />
                  </div>
                  <div>
                    <label className="lead-form-label">End Date:</label>
                    <DatePicker
                      selected={customDates.end}
                      onChange={(date) => setCustomDates({ ...customDates, end: date })}
                      selectsEnd
                      startDate={customDates.start}
                      endDate={customDates.end}
                      placeholderText="End Date"
                      className="date-picker"
                    />
                  </div>
                </div>
              )}
            </div>
            </div>
            <div className="buttons-row"> 
            {projectId && (
    <button className="color-button" onClick={() => setOpen(true)}>
      View
    </button>
  )}
                </div>
            </div>
      {loading ? (
        <p>Loading...</p>
      ) : expenses.length === 0 ? (
        <p>No data available</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Expense</th>
              <th>Amount</th>
              <th>Purchase From</th>
              <th>Purchase On</th>
              <th>Paid By</th>
              <th>Status</th>
              <th>Project Name</th>
              {/* <th>Designation</th> */}
              
            </tr>
          </thead>
          <tbody>
            {expenses.map((expense) => (
              <tr key={expense.expenses_id}>
                <td>{expense.item_name}</td>
                <td>₹{expense.price}</td>
                <td>{expense.purchase_from}</td>
                <td>{expense.purchase_date}</td>
                <td>
  <img
    src={expense.user_avatar}
    alt="User Avatar"
    width="30"
    height="30"
    style={{ borderRadius: "50%", cursor: "pointer" }}
    data-tooltip-id={`tooltip-${expense.expenses_id}`} // Unique ID
  />
  <Tooltip id={`tooltip-${expense.expenses_id}`} place="top" effect="solid">
    {expense.name}
  </Tooltip>
</td>
                <td>{expense.status}</td>
                <td>{expense.project_name}</td>
                {/* <td>{expense.designation_name || "N/A"}</td> */}
               
              </tr>
            ))}
          </tbody>
        </table>
      )}
  <Modal open={open} onClose={() => setOpen(false)}>
  <Box
    sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 500,
      bgcolor: "background.paper",
      boxShadow: 24,
      p: 3,
      borderRadius: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center", // Centering content
      textAlign: "center", // Centering text inside Box
    }}
  >
    <div className="top-controls" style={{ width: "100%" }}>
   <h1 className="title" >
               Expense Overview
                <div className="underline"></div>
              </h1>
              <div className="buttons-row">
                <button className="color-button" onClick={() => setOpen(false)}>
                   Close
                </button>
              </div>
              </div>

    {Array.isArray(chartData) && chartData.length > 0 ? (
      <ResponsiveContainer width="100%" height={240}>
        <BarChart data={chartData}>
          <XAxis dataKey="status" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="amount">
            {chartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    ) : (
      <Typography>No data available</Typography>
    )}

  </Box>
</Modal>

    </div>
  );
};

export default ProjectExpenseReport;
