
import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, startOfWeek, endOfWeek, startOfMonth, endOfMonth, startOfYear, endOfYear, subMonths } from "date-fns";
import "./TaskReport.css";

const DateFilter = () => {
  const [filter, setFilter] = useState("thisWeek");
  const [customDates, setCustomDates] = useState({ start: null, end: null });
  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [taskData, setTaskData] = useState(null);
  const [expandedStatus, setExpandedStatus] = useState(null); // To manage which status box is expanded

  useEffect(() => {
    fetchMembers();
  }, []);

  useEffect(() => {
    if (selectedMember) {
      fetchTaskData(selectedMember, getDateRange());
    }
  }, [selectedMember, filter, customDates]);

  // Fetch members list from the API
  const fetchMembers = async () => {
    const token = localStorage.getItem("authToken");

    try {
      const response = await fetch("https://api.myntask.com/employee/list", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      const memberNames = data.data.map((member) => ({
        user_name: member.user_name,
        user_id: member.user_id,
      }));
      setMembers(memberNames);
    } catch (error) {
      console.error("Error fetching members:", error);
    }
  };

  // Fetch task data based on the selected member and date range
  const fetchTaskData = async (memberId, { startDate, endDate }) => {
    const token = localStorage.getItem("authToken");

    const formattedStartDate = startDate ? format(startDate, "yyyy-MM-dd") : "";
    const formattedEndDate = endDate ? format(endDate, "yyyy-MM-dd") : "";

    try {
      const response = await fetch(
        `https://api.myntask.com/report/task?start_date=${formattedStartDate}&end_date=${formattedEndDate}&member_id=${memberId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      setTaskData(data);
    } catch (error) {
      console.error("Error fetching task data:", error);
    }
  };

  // Function to get the date range based on the selected filter
  const getDateRange = () => {
    const today = new Date();
    let startDate, endDate;

    switch (filter) {
      case "thisWeek":
        startDate = startOfWeek(today);
        endDate = endOfWeek(today);
        break;
      case "thisMonth":
        startDate = startOfMonth(today);
        endDate = endOfMonth(today);
        break;
      case "thisQuarter":
        startDate = subMonths(today, 2);
        endDate = today;
        break;
      case "thisYear":
        startDate = startOfYear(today);
        endDate = endOfYear(today);
        break;
      case "custom":
        startDate = customDates.start;
        endDate = customDates.end;
        break;
      default:
        startDate = null;
        endDate = null;
    }

    return { startDate, endDate };
  };

  // Toggle the visibility of the task table for a specific status
  const toggleStatus = (statusName) => {
    setExpandedStatus(expandedStatus === statusName ? null : statusName);
  };

  return (
    <div className="main-container">
      <div className="top-controls">
        <h1 className="title">
          Task Report
          <div className="underline"></div>
        </h1>
      </div>

      <div style={{ display: 'flex', gap: '15px', marginBottom: '10px', alignItems: 'center' }}>
        {/* Date Filter Dropdown */}
        <div style={{ width: '48%' }}>
          <label className="lead-form-label">Date Filter</label>
          <select
            className="select-dropdown"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          >
            <option value="thisWeek">
              This Week ({format(startOfWeek(new Date()), "MMM d")} -{" "}
              {format(endOfWeek(new Date()), "MMM d")})
            </option>
            <option value="thisMonth">
              This Month ({format(new Date(), "MMMM")})
            </option>
            <option value="thisQuarter">
              This Quarter ({format(subMonths(new Date(), 2), "MMM")} -{" "}
              {format(new Date(), "MMM")})
            </option>
            <option value="thisYear">
              This Year ({new Date().getFullYear()})
            </option>
            <option value="custom">Custom Date</option>
          </select>
        </div>

        {/* Custom Date Picker */}
        {filter === "custom" && (
          <div className="date-picker-container" style={{ width: '50%', marginBottom: '6px' }}>
            <div>
              <label className="lead-form-label">Start Date : </label>
              <DatePicker
                selected={customDates.start}
                onChange={(date) => setCustomDates({ ...customDates, start: date })}
                selectsStart
                startDate={customDates.start}
                endDate={customDates.end}
                placeholderText="Start Date"
                className="date-picker"
              />
            </div>
            <div>
              <label className="lead-form-label">End Date : </label>
              <DatePicker
                selected={customDates.end}
                onChange={(date) => setCustomDates({ ...customDates, end: date })}
                selectsEnd
                startDate={customDates.start}
                endDate={customDates.end}
                placeholderText="End Date"
                className="date-picker"
              />
            </div>
          </div>
        )}
      </div>

      {/* Member Selection Dropdown */}
      <div style={{ width: '48%' }}>
        <label className="lead-form-label">Select Member</label>
        <select
          className="select-dropdown"
          value={selectedMember}
          onChange={(e) => setSelectedMember(e.target.value)}
        >
          <option value="">Select Member</option>
          {members.map((member) => (
            <option key={member.user_id} value={member.user_id}>
              {member.user_name}
            </option>
          ))}
        </select>
      </div>


      {/* Display Task Data */}
      <div className="filtered-data">
        {taskData && Array.isArray(taskData.task_list) && taskData.task_list.length > 0 ? (
          <div className="member-info">
            <div>
              <img
                src={taskData.task_list[0]?.user_image || "default_image_url"}
                alt={taskData.task_list[0]?.user_name || "Unknown User"}
                className="user-image"
              />
              <p className="user-name">{taskData.task_list[0]?.user_name || "N/A"}</p>
            </div>

            {/* Task Status Boxes */}
            <div className="task-status-container">
              {Array.isArray(taskData.task_status) &&
                taskData.task_status
                  .filter((status) => status.status_name.toLowerCase() !== "total") // Filter out "Total"
                  .map((status) => (
                    <div
                      key={status.status_name}
                      className={`task-status-box ${expandedStatus === status.status_name ? "active" : ""}`}
                      onClick={() => toggleStatus(status.status_name)}
                    >
                      <h4>{status.status_name}</h4>
                      <p>Total: {status.total}</p>
                    </div>
                  ))}
            </div>

          </div>
        ) : (
          <p>No task data available</p>
        )}
      </div>

      {/* Task Table at the Bottom */}
      {expandedStatus && taskData && (
        <div style={{ marginTop: '20px' }}>
          <h3>{expandedStatus} Tasks</h3>
          <table className="task-table">
            <thead>
              <tr>
                <th>Task Name</th>
                <th>Start Date</th>
                <th>Due Date</th>
              </tr>
            </thead>
            <tbody>
              {taskData?.task_list?.[0]?.tasks?.[expandedStatus]?.list ? (
                taskData.task_list[0].tasks[expandedStatus].list.map((task) => (
                  <tr key={task.task_id}>
                    <td>{task.task}</td>
                    <td>{task.start_date}</td>
                    <td>{task.due_date}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3">No tasks available for this status.</td>
                </tr>
              )}


            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default DateFilter;