import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './ViewExpense.css'; // Assuming you want to manage the styling in a separate CSS file
import Swal from 'sweetalert2';




const ViewExpense = () => {
  const { expenseId } = useParams(); // Get the expenseId from the URL
  const [expenseDetails, setExpenseDetails] = useState(null);
  const [statusOptions, setStatusOptions] = useState([]);
  const navigate = useNavigate(); // For navigation
  const [error, setError] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');

  const [isPaidModalOpen, setIsPaidModalOpen] = useState(false);
  const [paidAmount, setPaidAmount] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState('cash');
  const [transactionNo, setTransactionNo] = useState('');
  const [receipt, setReceipt] = useState(null);
  const [loading, setLoading] = useState(false);
  const [itemName, setItemName] = useState('');
  const dropdownRef = useRef(null);




  const fetchExpenseDetails = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      return;
    }


    try {
      const response = await fetch(`https://api.myntask.com/expenses/view/${expenseId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });


      if (!response.ok) {
        throw new Error('Failed to fetch expense details');
      }


      const data = await response.json();
      setExpenseDetails(data);
    } catch (err) {
      console.error('Error fetching expense details:', err);
    }
  };




  // Fetch expense details whenever expenseId or selectedStatus changes
  useEffect(() => {

    if (expenseId) {
      fetchExpenseDetails();
    }
  }, [expenseId]); // Runs when expenseId or selectedStatus changes




  // Fetch status options when the component mounts
  useEffect(() => {
    const fetchStatusOptions = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setError('No token found');
        return;
      }


      try {
        const response = await fetch('https://api.myntask.com/expenses/status', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });


        if (!response.ok) {
          throw new Error('Failed to fetch status options');
        }


        const data = await response.json();
        setStatusOptions(data);
      } catch (err) {
        console.error('Error fetching status options:', err);
        setError('Failed to load status options.');
      }
    };


    fetchStatusOptions();
  }, []);




  // Function to handle status change
  const handleStatusChange = (event) => {
    const newStatus = event.target.value;
    setSelectedStatus(newStatus);
    if (newStatus === 'paid') {
      handlePaidClick();
    } else {
      updateExpenseStatus(newStatus);
    }
  };

  const updateExpenseStatus = async (newStatus) => {
    const token = localStorage.getItem('authToken');
    if (!token) return;

    try {
      const payload = {
        expense_id: [Number(expenseId)], // Ensure expense_id is an array of numbers
        status: newStatus,
      };

      console.log('Sending Payload:', payload);

      const response = await fetch("https://api.myntask.com/expenses/status_update", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json", // Send as JSON
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload), // Convert payload to JSON
      });

      if (!response.ok) throw new Error("Failed to update status");

      const responseData = await response.json();
      console.log('Status Update Response:', responseData);

      Swal.fire("Success", "Status updated successfully", "success");
      setSelectedStatus("");  // Reset dropdown
      fetchExpenseDetails();  // Refresh expense details
    } catch (err) {
      console.error('Error updating status:', err);
      Swal.fire("Error", "Failed to update status", "error");
    }
  };




  const handlePaidClick = () => {
    const numericPrice = parseFloat(expenseDetails.price.replace(/[^\d.]/g, '')); // Remove non-numeric characters
    setPaidAmount(numericPrice || 0); // Fallback to 0 if NaN
    setItemName(expenseDetails.item_name);
    setIsPaidModalOpen(true);
  };

  const handleConfirmPaid = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) return;

    setLoading(true);

    try {
      const payload = {
        expense_id: [Number(expenseId)], // Ensure expense_id is an array of numbers
        paid_amount: paidAmount,
        transaction_no: transactionNo,
        payment_method: paymentMethod,
        status: 'paid',
      };

      console.log('Sending Payload:', payload);

      const response = await fetch('https://api.myntask.com/expenses/expense_paid', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Send as JSON
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload), // Convert payload to JSON
      });

      if (!response.ok) throw new Error('Failed to mark as paid');

      const responseData = await response.json();
      console.log('Response Data:', responseData);

      Swal.fire("Success", "Marked as paid successfully", "success");
      setIsPaidModalOpen(false);
      fetchExpenseDetails(); // Refresh expense details
    } catch (err) {
      console.error('Error marking as paid:', err);
      Swal.fire("Error", "Failed to mark as paid", "error");
    } finally {
      setLoading(false);
    }
  };



  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };


    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [])


  const getFilteredStatusOptions = () => {
    if (expenseDetails?.status === 'approved') {
      // Include "paid" in the options
      return statusOptions;
    } else {
      // Exclude "paid" from the options
      return statusOptions.filter((status) => status !== 'paid');
    }
  };



  return (
    <div className="main-container">
      <div className="top-controls">
        <h2 className="title">Expense Details</h2>
        <div className="buttons-row">
          {/* Conditionally render the dropdown */}
          {expenseDetails?.status !== 'paid' && (
            <div className="dropdown-container">
              <select onChange={handleStatusChange} value={selectedStatus} className="color-button">
                <option value="">Update Status</option>
                {getFilteredStatusOptions().map((status, index) => (
                  <option key={index} value={status}>
                    {status.charAt(0).toUpperCase() + status.slice(1)}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
      </div>
      {expenseDetails ? (
        <div className="expense-details-grid">
          <div className="expense-detail">
            <strong>Employee Image:</strong>
            <img
              src={expenseDetails.employee_image}
              alt={expenseDetails.employee_name}
              className="expense-avatar"
            />
          </div>
          <div className="expense-detail"><strong>Item Name:</strong><div>{expenseDetails.item_name}</div></div>
          <div className="expense-detail"><strong>Status</strong><div>{expenseDetails.status || "N/A"}</div></div>
          <div className="expense-detail"><strong>Price:</strong><div>{expenseDetails.price}</div></div>


          <div className="expense-detail"><strong>Purchase From:</strong><div>{expenseDetails.purchase_from}</div></div>
          <div className="expense-detail"><strong>Purchase Date:</strong><div>{expenseDetails.purchase_date}</div></div>
          <div className="expense-detail"><strong>Project:</strong><div>{expenseDetails.project_name}</div></div>


          <div className="expense-detail"><strong>Category:</strong><div>{expenseDetails.category_name}</div></div>
          <div className="expense-detail"><strong>Employee Name:</strong><div>{expenseDetails.employee_name}</div></div>
          <div className="expense-detail"><strong>Employee Designation:</strong><div>{expenseDetails.employee_designation}</div></div>


          <div className="expense-detail"><strong>Approved By:</strong><div>{expenseDetails.approved_user_name}</div></div>
          <div className="expense-detail"><strong>Approved User Designation:</strong><div>{expenseDetails.approved_user_designation}</div></div>



          <div className="expense-detail">
            <strong>Bill:</strong>
            <div>
              {expenseDetails.bill && expenseDetails.bill !== "Not available" ? (
                <a href={expenseDetails.bill} target="_blank" rel="noopener noreferrer">View Bill</a>
              ) : (
                <span>Not Available</span>
              )}
            </div>
          </div>
          <div className="expense-detail"><strong>Transaction No.:</strong><div>{expenseDetails.transaction_no || "N/A"}</div></div>



          {/* New Fields */}
          <div className="expense-detail"><strong>Paid Amount:</strong><div>{expenseDetails.paid_amount}</div></div>
          <div className="expense-detail">
            <strong>Receipt:</strong>
            <div>
              {expenseDetails.receipt && expenseDetails.receipt !== "Not available" ? (
                <a href={expenseDetails.receipt} target="_blank" rel="noopener noreferrer">View Receipt</a>
              ) : (
                <span>Not Available</span>
              )}
            </div>
          </div>
          <div className="expense-detail"><strong>Approved User Image:</strong>
            <img
              src={expenseDetails.approved_user_image}
              alt={expenseDetails.approved_user_name}
              className="expense-avatar"
            />
          </div>

          <div className="expense-detail"><strong>Description:</strong><div>{expenseDetails.description}</div></div>
        </div>
      ) : (
        <p>Loading...</p>
      )}

      {isPaidModalOpen && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0,0,0,0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000
        }}>
          <div style={{
            backgroundColor: '#fff',
            padding: '30px',
            borderRadius: '10px',
            width: '450px',
            textAlign: 'center',
            boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
            position: 'relative'
          }}>
            <h3 style={{ fontSize: '20px', marginBottom: '10px' }}>Confirm Payment</h3>
            <p style={{ color: '#666', marginBottom: '20px' }}>
              Please confirm the payment details below.
            </p>
            <p style={{ fontWeight: '450', marginBottom: '20px', textAlign: 'left' }}>
              Item Name : {itemName}
            </p>
            <div style={{ marginBottom: '15px', textAlign: 'left' }}>
              <label style={{ fontWeight: '600', marginBottom: '5px', display: 'block' }}>Paid Amount: </label>
              <input
                type="number"
                value={paidAmount}
                readOnly
                onChange={(e) => setPaidAmount(e.target.value)}
                className="field"
              />
            </div>

            <div style={{ marginBottom: '15px', textAlign: 'left' }}>
              <label style={{ fontWeight: '600', marginBottom: '5px', display: 'block' }}>
                Payment Method:
              </label>
              <select value={paymentMethod} className="drop" onChange={(e) => setPaymentMethod(e.target.value)}>
                <option value="cash">Cash</option>
                <option value="credit">Credit</option>
                <option value="bank_transfer">Bank Transfer</option>
              </select>
            </div>

            <div style={{ marginBottom: '15px', textAlign: 'left' }}>
              <label>Transaction Ref. No.: </label>
              <input
                type="text"
                value={transactionNo}
                onChange={(e) => setTransactionNo(e.target.value)}
                placeholder="Enter Transaction ID / UTR Number"
                className='field' />
            </div>

            <div style={{ marginBottom: '15px', textAlign: 'left' }}>
              <label>Upload Receipt: </label>
              <input
                type="file"
                className='field'
                onChange={(e) => setReceipt(e.target.files[0])}

              />
            </div>

            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '20px'
            }}>
              <button
                onClick={handleConfirmPaid}
                disabled={loading}
                style={{
                  backgroundColor: '#4caf50',
                  color: '#fff',
                  padding: '10px 15px',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  width: '48%'
                }}
              >
                Mark as Paid
              </button>
              <button
                onClick={() => setIsPaidModalOpen(false)}
                style={{
                  backgroundColor: '#f44336',
                  color: '#fff',
                  padding: '10px 15px',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  width: '48%'
                }}
              >Cancel

              </button>
            </div>

          </div>
        </div>
      )}

      <button onClick={() => navigate(-1)} className="expense-back-button">
        Go Back
      </button>
    </div>
  );
};




export default ViewExpense;

