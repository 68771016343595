import React, { useState, useEffect } from 'react';
import './EditLeave.css';
import Swal from 'sweetalert2'; // Import SweetAlert
import { useNavigate } from 'react-router-dom';


const EditLeave = () => {
  const [formData, setFormData] = useState({
    leave_type_id: '',
    status: '',
    leave_date: '',
    reason: '',
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [leaveTypes, setLeaveTypes] = useState([]);
  const navigate = useNavigate(); // Initialize navigate

  const leaveId = window.location.pathname.split('/').pop(); // Extract leaveId from URL


  useEffect(() => {
    if (!leaveId) return;


    const fetchLeaveData = async () => {
      const token = localStorage.getItem('authToken');
      try {
        const response = await fetch(`https://api.myntask.com/leave/leave_edit/${leaveId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch leave data');
        }
        const data = await response.json();
        if (data.length > 0) {
          const leave = data[0];
          setFormData({
            leave_type_id: leave.leave_type_id,
            status: leave.status,
            leave_date: leave.leave_date.split('T')[0],
            reason: leave.reason,
          });
        }
      } catch (err) {
        setError('Error fetching leave data.');
      } finally {
        setLoading(false);
      }
    };


    const fetchLeaveTypes = async () => {
      const token = localStorage.getItem('authToken');
      try {
        const response = await fetch('https://api.myntask.com/leave/leave_type', {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch leave types');
        }
        const data = await response.json();
        setLeaveTypes(data);
      } catch (err) {
        setError('Error fetching leave types.');
      }
    };


    fetchLeaveData();
    fetchLeaveTypes();
  }, [leaveId]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('authToken');
    const payload = {
      id: leaveId,
      ...formData,
    };

    try {
      const response = await fetch(`https://api.myntask.com/leave/leave_update`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Failed to update leave');
      }

      // Show success message using SweetAlert
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Leave updated successfully!',
        timer: 2000, // Auto-close after 2 seconds
        showConfirmButton: false, // Hide the confirm button
      }).then(() => {
        // Navigate to the leave page after the alert is closed
        navigate('/leave');
      });
    } catch (error) {
      console.error('Error updating leave:', error);
      setError('Failed to update leave.');

      // Show error message using SweetAlert
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to update leave.',
        timer: 2000, // Auto-close after 2 seconds
        showConfirmButton: false, // Hide the confirm button
      });
    }
  };


  return (
    <div className="edit-leave-page">
      <h2>Edit Leave</h2>
      <div className="loading-container">
        {loading && <div className="spinner"></div>}
      </div>
      <form onSubmit={handleSubmit} className="edit-leave-form">
        <div className="form-group">
          <label htmlFor="leave_type_id">Leave Type</label>
          <select
            id="leave_type_id"
            name="leave_type_id"
            value={formData.leave_type_id}
            onChange={handleChange}

          >
            {leaveTypes.length > 0 ? (
              leaveTypes.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.type_name}
                </option>
              ))
            ) : (
              <option value="">Loading leave types...</option>
            )}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="status">Leave Status</label>
          <input
            type="text"
            id="status"
            name="status"
            value={formData.status}
            onChange={handleChange}

          />
        </div>
        <div className="form-group">
          <label htmlFor="leave_date">Leave Date</label>
          <input
            type="date"
            id="leave_date"
            name="leave_date"
            value={formData.leave_date}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="reason">Reason</label>
          <input
            type="text"
            id="reason"
            name="reason"
            value={formData.reason}
            onChange={handleChange}

          />
        </div>
        {error && <p className="error-text">{error}</p>}
        <div className="button-group">
          <button type="submit" className="submit-button" disabled={loading}>
            Save
          </button>
          <button type="button" className="cancel-button" onClick={() => window.history.back()}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};


export default EditLeave;




