import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import eye icons
import bevelImage from '../assets/bevel.png'; // Import the bevel image for promotion
import { requestForToken } from '../firebase'; // Import Firebase token request function
import iconImage from '../assets/Icon.png';
import { Box, Modal, Stack, Typography } from '@mui/material';
import { Vortex } from 'react-loader-spinner';
import './Login.css'; // Import the CSS for styling


const Login = () => {
  const navigate = useNavigate();
  const [isSignUp, setIsSignUp] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [username, setUsername] = useState(''); // New state for username
  const [companyName, setCompanyName] = useState(''); // New state for company name
  const [error, setError] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false); // State for password visibility
  const [tokenFound, setTokenFound] = useState(false);
  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] = useState(false);
  const [forgotEmail, setForgotEmail] = useState('');
  const [forgotPasswordMessage, setForgotPasswordMessage] = useState(''); // State for success or error message
  const [isForgotPasswordError, setIsForgotPasswordError] = useState(false); // State to track if it's an error message
  const [isLoading, setIsLoading] = useState(true);










  // Request the Firebase client token when the component mounts
  useEffect(() => {
    requestForToken(setTokenFound) // Request for the token when the component mounts
      .then(() => setIsLoading(false)) // Stop loading after token request completes
      .catch(() => setIsLoading(false));
  }, []);








  // Prevent navigation away from login page using the back button
  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    const handlePopState = () => {
      window.history.pushState(null, document.title, window.location.href);
    };
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);








  const openForgotPasswordModal = () => {
    setIsForgotPasswordModalOpen(true);
  };








  const closeForgotPasswordModal = () => {
    setIsForgotPasswordModalOpen(false);
    setForgotEmail('');
  };








  const handleForgotPasswordVerify = async () => {
    try {
      const response = await fetch('https://api.myntask.com/auth/forget-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: forgotEmail }),
      });


      if (!response.ok) {
        throw new Error('Invalid email address');
      }

      const data = await response.json();
      console.log('Forgot password response:', data);
      setForgotPasswordMessage(data.message);
      setIsForgotPasswordError(false);
    } catch (error) {
      console.error('Forgot password error:', error);
      setForgotPasswordMessage('Enter Correct email.');
      setIsForgotPasswordError(true);
    }
  };


  // const handleForgotPasswordVerify = async () => {
  //   try {
  //     const response = await fetch('https://api.myntask.com/auth/forget-password', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ email: forgotEmail }),
  //     });

  //     // Parse the JSON response
  //     const data = await response.json();

  //     if (!response.ok) {
  //       // For invalid email, display the exact message from the backend
  //       setForgotPasswordMessage(data.message || 'Invalid email address');
  //       setIsForgotPasswordError(true);
  //       return;
  //     }

  //     // For successful email verification
  //     console.log('Forgot password response:', data);
  //     setForgotPasswordMessage(data.message);
  //     setIsForgotPasswordError(false);
  //   } catch (error) {
  //     console.error('Forgot password error:', error);
  //     // Handle unexpected errors
  //     setForgotPasswordMessage('An unexpected error occurred. Please try again.');
  //     setIsForgotPasswordError(true);
  //   }
  // };







  // Handle the login logic
  const handleLogin = async () => {
    if (!email || !password) {
      setError('Please enter both email and password.');
      return;
    }


    // Fetch the client token from localStorage (this was stored earlier in firebase.js)
    const clientToken = localStorage.getItem('clientToken') || '';


    try {
      const response = await fetch('https://api.myntask.com/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
          user_token: clientToken // Pass the client token as user_token
        }),
      });


      if (!response.ok) {
        throw new Error('Network response was not ok');
      }


      const data = await response.json();
      console.log('Login successful:', data);


      // Store various items in localStorage
      localStorage.setItem('authToken', data.token);
      localStorage.setItem('is_superadmin', data.is_superadmin);
      if (data.profile) {
        localStorage.setItem('userProfile', JSON.stringify(data.profile));
      }
      localStorage.setItem('taskCount', JSON.stringify(data.task_count));
      localStorage.setItem('workFromHome', JSON.stringify(data.work_from_home_today));
      localStorage.setItem('projectCount', JSON.stringify(data.project_count));
      localStorage.setItem('todayJoinings', JSON.stringify(data.today_joinings));
      localStorage.setItem('leaveToday', JSON.stringify(data.leave_today || []));
      localStorage.setItem('birthdays', JSON.stringify(data.birthdays || []));
      localStorage.setItem('probation', JSON.stringify(data.probation_data || []));
      localStorage.setItem('taskList', JSON.stringify(data.task_list || []));
      localStorage.setItem('notice', JSON.stringify(data.notice_list || []));
      localStorage.setItem('expenses', JSON.stringify(data.expenses || []));
      localStorage.setItem('attendanceStatus', JSON.stringify(data.attendance_status));
      localStorage.setItem('tickets', JSON.stringify(data.tickets || []));
      localStorage.setItem('expiryMessage', data.expire_message);
      localStorage.setItem('task_point', JSON.stringify(data.task_point || []));


      if (data.is_superadmin === 1) {
        // Navigate to SuperAdmin page and pass the data via state
        navigate('/SuperAdmin', { state: { data } });
      } else if (data.is_superadmin === 2) {
        // Save data to local storage and navigate to client dashboard
        localStorage.setItem('client_data', JSON.stringify(data));
        navigate('/client-dashboard');
      } else {
        // Default navigation to the dashboard
        navigate('/dashboard');
      }



    } catch (error) {
      console.error('Error logging in:', error);
      setError('Failed to log in. Please check your credentials and try again.');
    }
  };





  const handleSignUp = () => {
    if (!email || !password || !confirmPassword || !username || !companyName) {
      setError('Please fill in all fields.');
      return;
    }
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }
    // Add the sign-up logic here
    setIsSignUp(false);
  };








  const switchToSignUp = () => {
    setEmail('');
    setPassword('');
    setConfirmPassword('');
    setUsername(''); // Clear the username field
    setCompanyName(''); // Clear the company name field
    setError('');
    setIsSignUp(true);
  };








  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible); // Toggle visibility state
  };


  //..........Loading Spinner ..........................................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }


  // if (error) {
  //   return (
  //     <Typography
  //       color="error"
  //       sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
  //     >
  //       {error}
  //     </Typography>
  //   );
  // }
  const handleCancel = () => {
    navigate('-1');
  }




  return (
    <div className="login-page">
      <div className="login-container">
        <div className="right-side">
          <img className="promotion-image-centered" src={bevelImage} alt="Bevel" />
        </div>
        <div className="left-side">
          <h1 className="login-title">{isSignUp ? 'Sign Up' : 'Login'}</h1>
          <p className="login-subtitle">Connect, manage, and grow — all with one CRM.</p>




          {isSignUp && (
            <>
              <input
                type="text"
                placeholder="Username"
                className="textField"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <input
                type="text"
                placeholder="Company Name"
                className="textField"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </>
          )}




          <input
            type="email"
            placeholder="Email"
            className="textField"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="passwordFieldContainer">
            <input
              type={passwordVisible ? 'text' : 'password'}
              placeholder="Enter your Password"
              className="textField"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span onClick={togglePasswordVisibility} className="eyeIcon">
              {passwordVisible ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
          {isSignUp && (
            <input
              type={passwordVisible ? 'text' : 'password'}
              placeholder="Confirm Password"
              className="textField"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          )}

          {error && <p className="error">{error}</p>}

          <a href="#" className="forgotPasswordLink" onClick={openForgotPasswordModal}>
            Forgot Password?
          </a>

          {isForgotPasswordModalOpen && (
            <div className="modal-overlay-deal">
              <div className="modal-content-deal">
                <img
                  src={iconImage} // Replace 'iconImage' with the path or import for Icon.png
                  alt="Close"
                  style={{
                    position: "absolute",
                    top: "16px",
                    right: "16px",
                    border: "none",
                    cursor: "pointer",
                    width: '30px'
                  }}
                  onClick={closeForgotPasswordModal}
                />
                <h2>Forgot Password</h2>
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="textField"
                  value={forgotEmail}
                  onChange={(e) => setForgotEmail(e.target.value)}
                />
                {/* <button className="color-button" onClick={closeForgotPasswordModal}>
                  Close
                </button> */}
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <button className="color-button" onClick={handleForgotPasswordVerify}>
                    Verify
                  </button>
                </div>
                {forgotPasswordMessage && (
                  <p
                    style={{
                      color: forgotPasswordMessage === "Password reset link has been sent to your email"
                        ? 'green'
                        : 'red', // Set green for success and red for error
                      marginTop: '10px',
                    }}
                  >
                    {forgotPasswordMessage}
                  </p>
                )}
              </div>
            </div>
          )}

          <button className="button" onClick={isSignUp ? handleSignUp : handleLogin}>
            {isSignUp ? 'Sign Up' : 'Login'}
          </button>


          <p className="switch-text">
            {isSignUp ? 'Already have an account?' : "Don't have an account?"}{' '}
            <span
              // onClick={switchToSignUp}
              className="switch-link">
              {isSignUp ? 'Login' : 'Sign Up'}
            </span>
          </p>
        </div>


      </div>
    </div>
  );
};


export default Login;



