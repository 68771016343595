import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';


const AssetDetail = () => {
  const { id } = useParams();  // Get the asset ID from the URL
  const [asset, setAsset] = useState(null);


  useEffect(() => {
    const token = localStorage.getItem("authToken");
    fetch(`https://api.myntask.com/assets/view/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data) && data.length > 0) {
          setAsset(data[0]);  // Extract the first object from the array
        } else {
          console.error('Unexpected data format:', data);
          setAsset(null);
        }
      })
      .catch(error => console.error('Error fetching asset details:', error));
  }, [id]);


  const containerStyle = {

    padding: '30px',
    fontSize: '18px',
  };


  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
    gap: '15px',
  };


  const labelStyle = {
    fontWeight: 'bold',
    textAlign: 'left',
  };


  const dataStyle = {
    textAlign: 'left',
  };


  return (
    <div style={containerStyle}>
      <h1 style={{ color: 'blue' }}>Asset Detail</h1>
      {asset ? (
        <div style={gridStyle}>
          <div style={labelStyle}>Asset Name:</div>
          <div style={dataStyle}>{asset.asset_name}</div>


          <div style={labelStyle}>Type:</div>
          <div style={dataStyle}>{asset.type}</div>


          <div style={labelStyle}>Status:</div>
          <div style={dataStyle}>{asset.status}</div>


          <div style={labelStyle}>Serial Number:</div>
          <div style={dataStyle}>{asset.serial_number}</div>


          <div style={labelStyle}>Value:</div>
          <div style={dataStyle}>{asset.value || 'N/A'}</div>


          <div style={labelStyle}>Location:</div>
          <div style={dataStyle}>{asset.location}</div>
        </div>
      ) : (
        <p>Loading asset details...</p>
      )}
    </div>
  );
};


export default AssetDetail;
