import React, { useEffect, useState } from 'react';
import { Box, Typography, Avatar, Grid, Paper, Button, TextField, IconButton, MenuItem, Select, FormControl, InputLabel, Stack } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { ToastContainer, toast } from 'react-toastify';
import { Audio, Vortex } from 'react-loader-spinner';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const ProfileData = () => {
  const [profileData, setProfileData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editedData, setEditedData] = useState({
    name: '',
    email: '',
    mobile: '',
    gender: '',
    date_of_birth: null, // Initialize as null
    marital_status: '',
    language: 'en',
    address: '',
    country: '',
    profile_picture: ''
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);


  const [imageFile, setImageFile] = useState(null);  // State to hold the image file
  const navigate = useNavigate(); // Initialize the navigate function

  // Helper function to safely parse dates
  const parseDate = (dateString) => {
    if (!dateString) return null;
    try {
      const date = new Date(dateString);
      return isNaN(date.getTime()) ? null : date;
    } catch (e) {
      return null;
    }
  };

  // Helper function to format date for input field
  const formatDateForInput = (date) => {
    if (!date) return '';
    try {
      const d = date instanceof Date ? date : new Date(date);
      return isNaN(d.getTime()) ? '' : d.toISOString().split('T')[0];
    } catch (e) {
      return '';
    }
  };


  useEffect(() => {
    const fetchProfileData = async () => {
      const token = localStorage.getItem('authToken');

      try {
        const response = await fetch('https://api.myntask.com/auth/profile_setting', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch profile data');
        }

        const data = await response.json();
        console.log('Profile data:', data);
        setProfileData(data);
        setEditedData({
          ...data,
          date_of_birth: parseDate(data.date_of_birth) // Ensure this is a Date object or null
        });
        setIsLoading(false);
      } catch (err) {
        console.error('Error fetching profile data:', err);
        setError('Failed to load profile data');
        setIsLoading(false);
      }
    };

    fetchProfileData();
  }, []);

  const handleEditToggle = () => {
    setEditMode(!editMode);
    if (editMode) {
      // Reset to original data when canceling edit
      setEditedData({
        ...profileData,
        date_of_birth: parseDate(profileData.date_of_birth)
      });
      setImageFile(null);
    }
  };

  const handleInputChange = (e) => {
    console.log('Field:', e.target.name, 'Value:', e.target.value);
    setEditedData({
      ...editedData,
      [e.target.name]: e.target.value,
    });
  };

  const handleDateChange = (e) => {
    const dateValue = e.target.value ? new Date(e.target.value) : null;
    setEditedData({
      ...editedData,
      date_of_birth: dateValue
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {  // Check if the file size is greater than 5MB
        toast.error('Image size should be less than 5MB.');
        return;
      }

      setImageFile(file);  // Store the image file directly
      const reader = new FileReader();
      reader.onload = () => {
        setEditedData({
          ...editedData,
          profile_picture: reader.result,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleProfileUpdate = async () => {
    const token = localStorage.getItem('authToken');

    const formData = new FormData();
    formData.append('name', editedData.name || profileData.name);
    formData.append('email', editedData.email || profileData.email);
    formData.append('email_notifications', profileData.receive_email_notification);
    formData.append('rtl', profileData.enable_rtl_theme);
    formData.append('locale', editedData.language ?? profileData.language ?? '');
    formData.append('password', ''); // Assuming password is not being updated through this form
    formData.append('mobile', editedData.mobile || profileData.mobile);
    formData.append('gender', editedData.gender || profileData.gender);

    // Safely handle date field - this is the critical fix
    let dateToSend = '';
    if (editedData.date_of_birth) {
      try {
        // Ensure we have a Date object
        const dateObj = editedData.date_of_birth instanceof Date
          ? editedData.date_of_birth
          : new Date(editedData.date_of_birth);

        if (!isNaN(dateObj.getTime())) {
          dateToSend = dateObj.toISOString();
        }
      } catch (e) {
        console.error('Error processing date:', e);
      }
    }
    formData.append('date_of_birth', dateToSend);

    formData.append('slack_username', profileData.slack_username || '');
    formData.append('marital_status', editedData.marital_status || profileData.marital_status);
    formData.append('address', editedData.address || profileData.address);
    formData.append('country', editedData.country || profileData.country);

    if (imageFile) {
      formData.append('image', imageFile);
      console.log('Image file appended:', {
        name: imageFile.name,
        size: imageFile.size,
        type: imageFile.type,
      });
    } else {
      formData.append('image', '');
      console.log('No image file selected, sending empty string for image.');
    }

    try {
      const response = await fetch('https://api.myntask.com/auth/profile_setting_update', {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      console.log('Response status:', response.status);
      if (!response.ok) {
        throw new Error('Failed to save profile data');
      }

      const data = await response.json();
      console.log('Profile data saved successfully:', data);

      // Update the profile data in state
      setProfileData(data);
      setEditedData({
        ...data,
        date_of_birth: parseDate(data.date_of_birth)
      });
      setEditMode(false);
      toast.success('Profile updated successfully!');


      // Update local storage with the new profile data
      localStorage.setItem('userProfile', JSON.stringify(data));

      // Assume other local storage keys that need to be updated are available in the data
      if (data.task_count) {
        localStorage.setItem('taskCount', JSON.stringify(data.task_count));
      }
      if (data.work_from_home_today) {
        localStorage.setItem('workFromHome', JSON.stringify(data.work_from_home_today));
      }
      if (data.project_count) {
        localStorage.setItem('projectCount', JSON.stringify(data.project_count));
      }
      if (data.today_joinings) {
        localStorage.setItem('todayJoinings', JSON.stringify(data.today_joinings));
      }
      if (data.leave_today) {
        localStorage.setItem('leaveToday', JSON.stringify(data.leave_today || []));
      }
      if (data.birthdays) {
        localStorage.setItem('birthdays', JSON.stringify(data.birthdays || []));
      }
      if (data.probation_data) {
        localStorage.setItem('probation', JSON.stringify(data.probation_data || []));
      }
      if (data.task_list) {
        localStorage.setItem('taskList', JSON.stringify(data.task_list || []));
      }

      // Force reloading the image from the new data
      setEditedData(prev => ({
        ...prev,
        profile_picture: data.profile_picture,
      }));

      setEditMode(false);

      // Navigate to the dashboard after successful save
      navigate('/dashboard');
    } catch (err) {
      console.error('Error saving profile data:', err);
      setError('Failed to save profile data');
    }
  };

  const handleCancel = () => {
    setEditedData(profileData);
    setEditMode(false);
  };

  //..........Loading Spinner ..........................................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }

  if (error) {
    return (
      <Typography
        color="error"
        sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
      >
        {error}
      </Typography>
    );
  }



  return (
    <Box sx={{ p: 3 }}>
      <Paper elevation={3} sx={{ p: 3, backgroundColor: 'var(--card-background)', boxShadow: `0 3px 15px var(--box-shadow-color)` }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h5" sx={{ color: 'var(--primary-color)' }}>
            Profile Data
          </Typography>
          <Button variant="contained" onClick={handleEditToggle} sx={{ backgroundColor: 'var(--primary-color)' }}>
            {editMode ? 'Cancel Edit' : 'Edit'}
          </Button>
        </Box>

        <Grid container spacing={3}>
          {/* Left Container */}
          <Grid item xs={12} sm={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <Box sx={{ position: 'relative', width: '100%', height: '100%', maxWidth: '200px', marginBottom: '20px' }}>
              <Avatar
                src={editedData.profile_picture || profileData.profile_picture || '/default-avatar.png'}
                alt="Profile Image"
                sx={{ width: '100%', height: 'auto', backgroundColor: 'var(--icon-background-color)', borderRadius: '50%' }}
              />
              {editMode && (
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                  sx={{ position: 'absolute', bottom: 0, right: 0 }}
                >
                  <input hidden accept="image/*" type="file" onChange={handleImageChange} />
                  <PhotoCamera />
                </IconButton>
              )}
            </Box>
            <TextField
              label="Email"
              name="email"
              value={editedData.email}
              onChange={handleInputChange}
              fullWidth
              disabled={!editMode}
              variant={editMode ? 'outlined' : 'standard'}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Mobile"
              name="mobile"
              value={editedData.mobile}
              onChange={handleInputChange}
              fullWidth
              disabled={!editMode}
              variant={editMode ? 'outlined' : 'standard'}
            />
          </Grid>

          {/* Right Container */}
          <Grid item xs={12} sm={8}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Date of Birth"
                  type="date"
                  name="date_of_birth"
                  value={formatDateForInput(editedData.date_of_birth)}
                  onChange={handleDateChange}
                  fullWidth
                  disabled={!editMode}
                  variant={editMode ? "outlined" : "standard"}
                />

              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined" disabled={!editMode}>
                  <InputLabel id="gender-label">Gender</InputLabel>
                  <Select
                    labelId="gender-label"
                    name="gender"
                    value={editedData.gender}
                    onChange={handleInputChange}
                    label="Gender"
                    variant={editMode ? 'outlined' : 'standard'}
                  >
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="others">Others</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Marital Status"
                  name="marital_status"
                  value={editedData.marital_status}
                  onChange={handleInputChange}
                  fullWidth
                  disabled={!editMode}
                  variant={editMode ? 'outlined' : 'standard'}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined" disabled={!editMode}>
                  <InputLabel id="language-label">Language</InputLabel>
                  <Select
                    labelId="language-label"
                    name="language"
                    value={editedData.language || ''}
                    onChange={handleInputChange}
                    label="Language"
                    variant={editMode ? 'outlined' : 'standard'}
                  >
                    <MenuItem value="en">English</MenuItem>
                    <MenuItem value="fr">Hindi</MenuItem>
                    <MenuItem value="es">Others</MenuItem>
                    {/* Add more languages as required */}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Address"
                  name="address"
                  value={editedData.address}
                  onChange={handleInputChange}
                  fullWidth
                  disabled={!editMode}
                  variant={editMode ? 'outlined' : 'standard'}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Country"
                  name="country"
                  value={editedData.country}
                  onChange={handleInputChange}
                  fullWidth
                  disabled={!editMode}
                  variant={editMode ? 'outlined' : 'standard'}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {editMode && (
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button variant="contained" color="primary" onClick={handleProfileUpdate} sx={{ backgroundColor: 'var(--primary-color)' }}>
              Save
            </Button>
            {/* <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button> */}
          </Box>
        )}
      </Paper>
      <ToastContainer />
    </Box>
  );
};

export default ProfileData;
