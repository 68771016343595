// src/screens/SuperAdminBill.js
import React, { useState, useEffect } from 'react';
import {
  TextField,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  TablePagination
} from '@mui/material';

const SuperAdminBill = () => {
  const [bills, setBills] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    fetchBills();
  }, []);

  const fetchBills = async () => {
    try {
      const token = localStorage.getItem('authToken'); // Get token from local storage
      const response = await fetch('https://api.myntask.com/bill/list', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`, // Pass token in Authorization header
          'Content-Type': 'application/json', // Optional: Define content type if needed
        },
      });

      if (response.ok) {
        const data = await response.json();
        setBills(data); // Assuming `setBills` updates your state
      } else {
        console.error('Failed to fetch bills:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching bills:', error);
    }
  };


  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredBills = bills.filter((bill) =>
    bill.company_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const paginatedBills = filteredBills.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <div>
      <h1>SuperAdminBilling</h1>
      <TextField
        fullWidth
        label="Search by Company Name"
        variant="outlined"
        margin="normal"
        value={searchTerm}
        onChange={handleSearchChange}
        style={{ marginBottom: '20px' }}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Company Name</TableCell>
              <TableCell>Package</TableCell>
              <TableCell>Payment Date</TableCell>
              <TableCell>Next Payment Date</TableCell>
              <TableCell>Gateway Name</TableCell>
              <TableCell>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedBills.map((bill) => (
              <TableRow key={bill.id}>
                <TableCell>{bill.id}</TableCell>
                <TableCell>{bill.company_name}</TableCell>
                <TableCell>{bill.package}</TableCell>
                <TableCell>{new Date(bill.payment_date).toLocaleDateString()}</TableCell>
                <TableCell>{new Date(bill.next_payment_date).toLocaleDateString()}</TableCell>
                <TableCell>{bill.gateway_name}</TableCell>
                <TableCell>{bill.amount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={filteredBills.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
};

export default SuperAdminBill;
