import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Pagination } from '@mui/material';


const Tasks = ({ projectId, projectName }) => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [statusOptions, setStatusOptions] = useState([]);
  const [error, setError] = useState(null);
  const [employees, setEmployees] = useState([]);
  const navigate = useNavigate();
  const [statusFilter, setStatusFilter] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const indexOfLastTask = currentPage * itemsPerPage;
const indexOfFirstTask = indexOfLastTask - itemsPerPage;
const currentTasks = tasks.slice(indexOfFirstTask, indexOfLastTask);

  

  useEffect(() => {
    const fetchTasks = async () => {
      const token = localStorage.getItem("authToken");
      const url = `https://api.myntask.com/project/tasks/${projectId}?status=${statusFilter}&member_id=${employeeId}`;

      try {
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch tasks");
        }

        const data = await response.json();
        setTasks(data);
      } catch (error) {
        console.error("Error fetching tasks:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTasks();
  }, [projectId, statusFilter, employeeId]); // Refetch when filters change


  useEffect(() => {
    const fetchEmployees = async () => {
      const token = localStorage.getItem("authToken");
      const response = await fetch("https://api.myntask.com/employee/member", {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${token}`, // Replace with actual token
          "Content-Type": "application/json"
        }
      });

      const data = await response.json();
      setEmployees(data);
    };

    fetchEmployees();
  }, []);



  const handleStatusChange = async (taskId, newStatus) => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('No token found');
      toast.error('No token found');
      return;
    }

    const statusOption = statusOptions.find(option => option.status === newStatus);
    if (!statusOption) {
      setError('Invalid status selected');
      toast.error('Invalid status selected');
      return;
    }

    try {
      const response = await fetch('https://api.myntask.com/task/task_status_update', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          task_id: taskId,
          status_id: statusOption.id,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update status');
      }

      const updatedTasks = tasks.map(task =>
        task.task_id === taskId ? { ...task, status: newStatus } : task
      );
      setTasks(updatedTasks);

      // Show success toast
      toast.success('Task status updated successfully');
    } catch (error) {
      console.error('Error updating status:', error);
      setError(error.message);

      // Show error toast
      toast.error(`Error: ${error.message}`);
    }
  };

  useEffect(() => {
    const fetchStatusOptions = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setError('No token found');
        return;
      }


      try {
        const response = await fetch('https://api.myntask.com/task/task_status', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });


        if (!response.ok) {
          throw new Error('Failed to fetch status options');
        }


        const data = await response.json();
        setStatusOptions(data);
      } catch (error) {
        console.error('Error fetching status options:', error);
        setError(error.message);
      }
    };


    fetchStatusOptions();
  }, []);


  const truncateTaskName = (taskName) => {
    const words = taskName.split(' ');
    if (words.length > 6) {
      return words.slice(0, 6).join(' ') + '...'; // Truncate after 6 words
    }
    return taskName;
  };


  const handleDownloadPdf = () => {
    const doc = new jsPDF();
    const tableColumn = ['Code', 'Task', 'Start Date', 'Due Date', 'Status', 'Assigned To'];
    const tableRows = [];


    tasks.forEach(task => {
      const assignedUsers = task.assigned_users.map(user => user.name).join(', ');


      const taskData = [
        task.code || 'N/A',
        truncateTaskName(task.task) || 'N/A',
        task.start_date || 'N/A',
        task.due_date || 'N/A',
        task.status || 'N/A',
        assignedUsers || 'N/A',
      ];


      tableRows.push(taskData);
    });


    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
    });


    doc.save('tasks_table.pdf');
  };




  const statusColors = {
    "Incomplete": "red",
    "To Do": "blue",
    "Doing": "orange",
    "Completed": "green"
  };

  // Navigate to the task detail page with the task ID
  const handleTaskNameClick = (taskId) => {
    navigate(`/task-detail/${taskId}`);
  };

  if (loading) {
    return <p>Loading tasks...</p>;
  }


  return (
    <div style={{ padding: '10px 30px' }}>
      <div className="top-controls">
        <h1 className='title'>{projectName} - Tasks
          <div className="underline"></div>
        </h1>
        <div className="buttons-row">
<button
      className="color-button"
      onClick={() => navigate(`/addProjectTask/${projectId}?name=${encodeURIComponent(projectName)}`)}
    >
      Add Task
    </button>

          <button className="color-button" onClick={() => handleDownloadPdf()}>Download Table</button>
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th>Code</th>
            <th>Task</th>
            <th>Start Date</th>
            <th>Due Date</th>
            <th>
  <div>
    <select
      className="header-filter"
      onChange={(e) => setStatusFilter(e.target.value)}
      value={statusFilter}
    >
      <option value="">Status</option>
      {statusOptions.map((status) => (
        <option key={status.id} value={status.status}>
          {status.status}
        </option>
      ))}
    </select>
  </div>
</th>

            <th>
              <div>
                <select
                  className="header-filter"
                  onChange={(e) => setEmployeeId(e.target.value)}
                  value={employeeId}
                >
                  <option value="">Assigned To</option>
                  {employees.map((employee) => (
                    <option key={employee.id} value={employee.id}>
                      {employee.member_name}
                    </option>
                  ))}
                </select>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {currentTasks.length === 0 ? (
            <tr>
              <td colSpan={6} style={{ textAlign: 'center', padding: '8px' }}>No data is available</td>
            </tr>
          ) : (
            currentTasks.map((task) => (
              <tr key={task.task_id}>
                <td>{task.code}</td>
                <td
                  style={{
                    color: 'blue',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleTaskNameClick(task.task_id)}
                >{truncateTaskName(task.task)}</td>
                <td>{task.start_date}</td>
                <td>{task.due_date}</td>
                <td>
                  <div className="custom-select-container" style={{ position: 'relative', width: '100%' }}>
                    <div
                      className="selected-item"
                      style={{
                        padding: '4px',
                        border: '1px solid #ccc',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <span
                        style={{
                          display: 'inline-block',
                          width: '10px',
                          height: '10px',
                          backgroundColor: statusColors[task.status],
                          borderRadius: '50%',
                          marginRight: '8px',
                        }}
                      />
                      {task.status}
                    </div>
                    <select
                      value={task.status}
                      onChange={(e) => handleStatusChange(task.task_id, e.target.value)}
                      style={{
                        width: '100%',
                        padding: '5px',
                        border: 'none',
                        cursor: 'pointer',
                        backgroundColor: 'transparent',
                        appearance: 'none',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        height: '100%',
                        opacity: 0,
                      }}
                    >
                      {statusOptions.map(option => (
                        <option key={option.id} value={option.status}>
                          {option.status}
                        </option>
                      ))}
                    </select>
                  </div>
                </td>
                <td>
                  <div style={{ display: 'flex' }}>
                    {task.assigned_users.map((user, index) => (
                      <div key={index}>
                        <img src={user.image} alt={user.name} className="image" style={{ margin: '0px' }} />
                      </div>
                    ))}
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
      <Pagination
  count={Math.ceil(tasks.length / itemsPerPage)}  // Total pages
  page={currentPage}
  onChange={(event, value) => setCurrentPage(value)}
  color="primary"
  shape="rounded"
/>
</div>

    </div>
  );
};


export default Tasks;

