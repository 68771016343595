import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, startOfWeek, endOfWeek, startOfMonth, endOfMonth, startOfYear, endOfYear, subMonths } from "date-fns";
import { PieChart, Pie, Cell, Tooltip, Legend, XAxis, YAxis, Bar, BarChart, ResponsiveContainer, CartesianGrid} from "recharts";


const BUDGET_COLORS = ["#4CAF50", "#2196F3", "#FF0000"];

const ProjectReport = () => {
  const [projects, setProjects] = useState([]);
  const [filter, setFilter] = useState("thisMonth");
  const [customDates, setCustomDates] = useState({ start: null, end: null });
  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState("");
  const [selectedBudgetStatus, setSelectedBudgetStatus] = useState(""); // New state for budget status
  const [selectedStatus, setSelectedStatus] = useState(""); // New state for project status
  const [selectedProjectOnTime, setSelectedProjectOnTime] = useState(""); // New state for project on time
  const [statuses, setStatuses] = useState([]);
  const [error, setError] = useState(null);

  
  const getDateRange = () => {
    const today = new Date();
    let startDate, endDate;

    switch (filter) {
      case "thisWeek":
        startDate = startOfWeek(today);
        endDate = endOfWeek(today);
        break;
      case "thisMonth":
        startDate = startOfMonth(today);
        endDate = endOfMonth(today);
        break;
      case "thisQuarter":
        startDate = subMonths(today, 2);
        endDate = today;
        break;
      case "thisYear":
        startDate = startOfYear(today);
        endDate = endOfYear(today);
        break;
      case "custom":
        startDate = customDates.start;
        endDate = customDates.end;
        break;
      default:
        startDate = null;
        endDate = null;
    }

    return { startDate, endDate };
  };


     useEffect(() => {
        const fetchData = async (url, setter) => {
          const token = localStorage.getItem("authToken");
          try {
            const response = await fetch(url, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            });
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setter(data || []);
          } catch (error) {
            console.error(`Error fetching data from ${url}:`, error);
            setter([]);
          }
        };
    
        fetchData("https://api.myntask.com/employee/member", setMembers);
      }, []);

      

      useEffect(() => {
        const fetchProjectReportData = async () => {
          const token = localStorage.getItem("authToken");
          const { startDate, endDate } = getDateRange();
          const formattedStartDate = startDate ? format(startDate, "yyyy-MM-dd") : "";
          const formattedEndDate = endDate ? format(endDate, "yyyy-MM-dd") : "";
      
          // Construct query parameters dynamically
          const queryParams = new URLSearchParams({
            start_date: formattedStartDate,
            end_date: formattedEndDate,
            member_id: selectedMember || "",
            budget_status: selectedBudgetStatus || "",  // New filter
            status: selectedStatus || "",  // New filter
            project_on_time: selectedProjectOnTime || "",  // New filter
          });
      
          try {
            const response = await fetch(
              `https://api.myntask.com/report/project?${queryParams.toString()}`,
              {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
      
            const data = await response.json();
            setProjects(data);
          } catch (error) {
            console.error("Error fetching project report data:", error);
          }
        };
      
        fetchProjectReportData();
      }, [filter, customDates, selectedMember, selectedBudgetStatus, selectedStatus, selectedProjectOnTime]); // Include new filters in dependencies
      
      

  // Process Data for Charts
  // const completedOnTime = projects.filter((p) => p.project_completed_on_time === "Yes").length;
  // const completedLate = projects.filter((p) => p.project_completed_on_time === "No").length;
  // const ongoing = projects.filter((p) => p.project_completed_on_time === "Working").length;

  // const completionData = [
  //   { name: "On Time", value: completedOnTime },
  //   { name: "Late", value: completedLate },
  //   { name: "Ongoing", value: ongoing },
  // ];

  const fetchStatuses = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('No authentication token found.');
      return;
    }

    try {
      const response = await fetch('https://api.myntask.com/project/status', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok. Status: ${response.status}`);
      }

      const data = await response.json();
      setStatuses(data);
    } catch (error) {
      setError('Failed to fetch statuses. Please try again later.');
    }
  };

  useEffect(() => { 
    fetchStatuses();
  }, []);

  const underBudget = projects.filter((p) => p.budget_status === "Within Budget").length;
  const overBudget = projects.filter((p) => p.budget_status === "Over Budget").length;

  const budgetData = [
    { name: "Under Budget", value: underBudget },
    { name: "Over Budget", value: overBudget },
  ];

  const STATUS_COLORS = {
    "In Progress": "#8884d8",
    "Not Started": "#82ca9d",
    "On Hold": "#ffc658",
    "Canceled": "#ff4848",
    "Finished": "#4caf50",
  };

// Process Data for Charts
const statusData = [
  { name: "In Progress", value: projects.filter((p) => p.status === "in progress").length },
  { name: "Not Started", value: projects.filter((p) => p.status === "not started").length },
  { name: "On Hold", value: projects.filter((p) => p.status === "on hold").length },
  { name: "Canceled", value: projects.filter((p) => p.status === "canceled").length },
  { name: "Finished", value: projects.filter((p) => p.status === "finished").length },
];

console.log(statusData);
  return (
    <div className="main-container">
      <div className="top-controls">
        <h1 className="title">
          Project Report
          <div className="underline"></div>
        </h1>
      </div>


   {/* Filters */}
   <div className="top-controls">
        <div className="filters-and-search">

             {/* Member Selection Dropdown */}
             <div >
                 <label className="lead-form-label">Select Member</label>
                 <select
                   className="select-dropdown"
                   value={selectedMember}
                   onChange={(e) => setSelectedMember(e.target.value)}
                 >
                   <option value="">Select Member</option>
                   {members.map((member) => (
                     <option key={member.id} value={member.id}>
                       {member.member_name}
                     </option>
                   ))}
                 </select>
               </div>
      {/* Date Filter */}
      <div style={{ display: "flex", gap: "15px", marginBottom: "10px", alignItems: "center" }}>
        <div >
          <label className="lead-form-label">Date Filter</label>
          <select className="select-dropdown" value={filter} onChange={(e) => setFilter(e.target.value)}>
            <option value="thisWeek">This Week ({format(startOfWeek(new Date()), "MMM d")} - {format(endOfWeek(new Date()), "MMM d")})</option>
            <option value="thisMonth">This Month ({format(new Date(), "MMMM")})</option>
            <option value="thisQuarter">This Quarter (Dec - {format(new Date(), "MMM")})</option>
            <option value="thisYear">This Year ({new Date().getFullYear()})</option>
            <option value="custom">Custom Date</option>
          </select>
        </div>

        {filter === "custom" && (
          <div className="date-picker-container" style={{ width: "50%",marginTop: "0px" }}>
            <div>
              <label className="lead-form-label">Start Date:</label>
              <DatePicker
                selected={customDates.start}
                onChange={(date) => setCustomDates({ ...customDates, start: date })}
                selectsStart
                startDate={customDates.start}
                endDate={customDates.end}
                placeholderText="Start Date"
                className="date-picker"
              />
            </div>
            <div>
              <label className="lead-form-label">End Date:</label>
              <DatePicker
                selected={customDates.end}
                onChange={(date) => setCustomDates({ ...customDates, end: date })}
                selectsEnd
                startDate={customDates.start}
                endDate={customDates.end}
                placeholderText="End Date"
                className="date-picker"
              />
            </div>
          </div>
        )}
      </div>
      </div>
      </div>

      {/* Charts Section */}
      <div style={styles.container}>
  {/* Status Chart */}
  <div style={styles.chartWrapper}>
    
    <ResponsiveContainer width={500} height={300}>
      <BarChart data={statusData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />

        {/* Single Bar for Status with Different Colors */}
        <Bar dataKey="value">
          {statusData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={STATUS_COLORS[entry.name]} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
    <div style={styles.chartTitle}>Status Chart</div>
  </div>

  {/* Budget Chart */}
  <div style={styles.chartWrapper}>
   
    <ResponsiveContainer width={300} height={300}>
      <PieChart>
        <Pie
          data={budgetData}
          cx="50%"
          cy="50%"
          innerRadius={60} // Creates a donut shape
          outerRadius={100}
          fill="#8884d8"
          dataKey="value"
          label
        >
          {budgetData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={BUDGET_COLORS[index]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
    <div style={styles.chartTitle}>Budget Chart</div>
  </div>
</div>


    
      <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Client</th>
          <th>Start Date</th>
          <th>Deadline</th>
          <th>
                <select className='header-filter'
                  value={selectedStatus}
                  onChange={(e) => {
                    setSelectedStatus(e.target.value);
                  }}
                >
                  <option value="">All Statuses</option>
                  {statuses.map((status) => (
                    <option key={status.id} value={status.status_name}>
                      {status.status_name}
                    </option>
                  ))}
                </select>
              </th>
              <th>
  <select
    className="header-filter"
    value={selectedProjectOnTime}
    onChange={(e) => {
      setSelectedProjectOnTime(e.target.value);
    }}
  >
    <option value="">Project on time</option>
    <option value="No">No</option>
    <option value="Working">Working</option>
  </select>
</th>

         
          <th>Total Tasks</th>
          <th>Completed </th>
          <th>On Time</th>
          <th>Progress</th>
          <th>Budget</th>
          <th>
          <select
    className="header-filter"
    value={selectedBudgetStatus}
    onChange={(e) => {
      setSelectedBudgetStatus(e.target.value);
    }}
  >
    <option value="">Budget Status</option>
    <option value="Over Budget">Over Budget</option>
    <option value="Within Budget">Within Budget</option>
  </select>
          </th>
          
          <th>Expense</th>
        </tr>
      </thead>
      <tbody>
        {projects.map((project) => (
          <tr key={project.project_id}>
            <td>{project.project_name}</td>
            <td>
              <img
                src={project.client_image}
                
                title={project.client_name || 'N/A'} // Tooltip showing client name
                className="image"
              />
            </td>
            <td>{new Date(project.start_date).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })}</td>
            <td>{new Date(project.deadline).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })}</td>
            <td>{project.status}</td>
            <td>{project.project_completed_on_time}</td>
            <td>{project.total_tasks}</td>
            <td>{project.completed_tasks}</td>
            <td>{project.completed_on_time}</td>
            <td>{project.progress_percentage}</td>
            <td>{project.project_budget !== null ? project.project_budget : 'N/A'}</td>
            <td>{project.budget_status}</td>
            <td>{project.total_expense}</td>
          </tr>
        ))}
      </tbody>
    </table>
     
    </div>
  );
};

export default ProjectReport;


const styles = {
  container: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    marginBottom: "30px",
    gap: "20px",
    marginTop: "30px",
    flexWrap: "wrap",
  },
  chartWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  chartTitle: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "10px",
    textAlign: "center",
  },
};
