import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import noDataImage from '../assets/NoDataFound.png';
import { Vortex } from 'react-loader-spinner';
import { Stack } from '@mui/material';
import { GridMoreVertIcon } from '@mui/x-data-grid';
import Swal from 'sweetalert2';


const PurchaseRequisition = () => {
  const [requisitions, setRequisitions] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [open, setOpen] = useState(false); // Modal open state
  const navigate = useNavigate();
  const token = localStorage.getItem('authToken'); // Retrieve the token from localStorage
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [actionMenuVisible, setActionMenuVisible] = useState(null);


  const fetchRequisitions = async () => {
    const token = localStorage.getItem('authToken'); // Retrieve the token from localStorage
    try {
      const response = await fetch('https://api.myntask.com/purchase_requisition/list', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`, // Add token to Authorization header
          'Content-Type': 'application/json',
        },
      });


      const data = await response.json();


      // Log the response to console
      console.log('API Response:', data);


      if (!response.ok) {
        throw new Error('Failed to fetch requisitions');
      }


      setRequisitions(data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching requisitions:', error);
      setError("Failed to Fetch Requisitions");
      setIsLoading(false);
    }
  };

  useEffect(() => {

    fetchRequisitions();
  }, []);

  const goToNextScreen = () => {
    navigate('/Add-purchase-requisation');
  };


  // Handle product view button click
  const handleProductView = (products) => {
    setSelectedProducts(products);
    setOpen(true); // Open the modal
  };


  const handleDeleteClick = async (prId) => {
    const token = localStorage.getItem('authToken'); // Retrieve token from local storage


    // Display confirmation dialog
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to delete this ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    });


    if (result.isConfirmed) {
      setIsLoading(true);
      try {
        const response = await fetch(`https://api.myntask.com/purchase_requisition/pr_delete/${prId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`, // Pass token in the header
          },
        });


        if (!response.ok) {
          throw new Error('Failed to delete the Requisition');
        }


        // Refetch the products list after successful deletion
        await fetchRequisitions();


        // Show success message
        Swal.fire({
          title: 'Deleted!',
          text: 'Requisition deleted successfully.',
          icon: 'success',
          timer: 1500,
          showConfirmButton: false,
        });
        setIsLoading(false);
      } catch (error) {
        console.error('Error deleting Requisition:', error);
        // Show error message
        Swal.fire({
          title: 'Error!',
          text: 'Error deleting Requisition. Please try again.',
          icon: 'error',
          timer: 1500,
          showConfirmButton: false,
        });
      }
      setIsLoading(false);
    }
  };


  const handleClose = () => setOpen(false); // Close modal function

  const toggleActionMenu = (id) => {
    setActionMenuVisible((prevVisible) => (prevVisible === id ? null : id));
  };


  const toTitleCase = (str) => {
    if (!str) return ''; // Return an empty string if str is null or undefined
    return str
      .toLowerCase() // Convert to lowercase
      .split(' ') // Split the string into words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(' '); // Join the words back into a string
  };


  //..........Loading Spinner ..........................................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }

  // if (error) {
  //   return (
  //     <Typography
  //       color="error"
  //       sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
  //     >
  //       {error}
  //     </Typography>
  //   );
  // }



  return (
    <div className="main-container">


      <div className="top-controls">
        <h1 className='title'>Purchase Requisition List
          <div className="underline"></div>
        </h1>
        <div className="buttons-row">
          <button className="color-button" onClick={goToNextScreen}>
            Add Purchase Requisition
          </button>
        </div>
      </div>


      {requisitions.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>PR ID</th>
              <th>User Info</th>
              <th>Description</th>
              <th>Project Name</th>
              <th>Category</th>
              <th>Sub-category</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {requisitions.map((requisition) => (
              <tr key={requisition.pr_id}>
                <td >{requisition.pr_id}</td>
                <td>
                  <div className="info-container">
                    <img
                      src={requisition.user_image}
                      alt={requisition.user_name}
                      className='image'
                    />
                    <div>
                      {toTitleCase(requisition.user_name)} {/* Apply toTitleCase */}
                    </div>
                  </div>
                </td>
                <td>{requisition.description ? toTitleCase(requisition.description) : 'N/A'}</td>
                <td>{toTitleCase(requisition.project_name)}</td>
                <td>{toTitleCase(requisition.category)}</td>
                <td>{requisition.sub_category ? toTitleCase(requisition.sub_category) : 'N/A'}</td>
                <td >{toTitleCase(requisition.status)}</td>
                <td>
                  <div className="action-container">
                    <div className="action-dots" onClick={() => toggleActionMenu(requisition.pr_id)}>
                      <GridMoreVertIcon />
                    </div>
                    {actionMenuVisible === requisition.pr_id && (
                      <div className="action-menu">
                        <div
                          className="action-item"
                          onClick={() => handleProductView(requisition.products)}
                        >
                          View
                        </div>
                        <div
                          className="action-item"
                          onClick={() => handleDeleteClick(requisition.pr_id)}
                        >
                          Delete
                        </div>
                      </div>
                    )}
                  </div>

                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="no-data-container">
          <img src={noDataImage} alt="No data available" className="no-data-image" />
          <p className="no-data-text">No data found</p>
        </div>
      )}






      {/* Modal for displaying products */}
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
        <Box sx={styles.modalBox}>
          <Typography id="modal-title" variant="h6" component="h2">
            Product Details
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            {selectedProducts.length > 0 ? (
              <table >
                <thead>
                  <tr>
                    <th >Product Name</th>
                    <th >Quantity</th>
                    <th >Description</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedProducts.map((product, index) => (
                    <tr key={index}>
                      <td >{product.name}</td>
                      <td >{product.qty}</td>
                      <td >{product.description}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No products available</p>
            )}
          </Typography>
          <Button onClick={handleClose} style={styles.closeButton}>Close</Button>
        </Box>
      </Modal>
    </div>
  );
};


// Inline styles for the button, header layout, and modal
const styles = {


  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800, // Increased modal width
    maxWidth: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    textAlign: 'center',
  },
  closeButton: {
    marginTop: '20px',
    backgroundColor: '#007bff',
    color: '#fff',
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },




};


export default PurchaseRequisition;






