import React, { useState, useEffect, useRef } from 'react';
// import './components/EstimateForm.css';
import './../Components/EstimateForm.css';
import Swal from 'sweetalert2';
import { Box, Modal, Stack, Typography } from "@mui/material";
import { Vortex } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

const AddOrder = () => {
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState('');
  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState(null);
  const [orderDate, setOrderDate] = useState(null);
  const [shippingAddresses, setShippingAddresses] = useState([]);
  const [selectedShippingAddress, setSelectedShippingAddress] = useState('');
  const [unitTypes, setUnitTypes] = useState([]);
  const [taxes, setTaxes] = useState([]); // State for taxes
  const [manualShippingAddress, setManualShippingAddress] = useState('');

  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedClient, setSelectedClient] = useState("");
  const [rows, setRows] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [productDetails, setProductDetails] = useState(null);
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [note, setNote] = useState('');

  const [errors, setErrors] = useState({}); // State for error messages

  // Create refs for required fields
  const vendorRef = useRef(null);
  const orderDateRef = useRef(null);
  const expectedDeliveryDateRef = useRef(null);
  const categoryRef = useRef(null);
  const subcategoryRef = useRef(null);

  const token = localStorage.getItem('authToken');
  const navigate = useNavigate();


  useEffect(() => {
    const token = localStorage.getItem('authToken');

    const fetchVendors = async () => {
      try {
        const response = await fetch('https://api.myntask.com/purchase_order/vendor_company', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        if (Array.isArray(data)) {
          setVendors(data);
        } else {
          console.error('API response is not an array:', data);
          setVendors([]); // Fallback
        }
      } catch (error) {
        console.error('Error fetching vendors:', error);
        setError('Failed to fetch vendors. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchVendors();
  }, []);

  useEffect(() => {

    fetch('https://api.myntask.com/purchase_order/shipping_address', {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        setShippingAddresses(data);
      })
      .catch(error => console.error('Error fetching shipping addresses:', error));


  }, [token]);



  useEffect(() => {
    const fetchCategories = async () => {
      console.log('Fetching categories...');
      try {
        const response = await fetch('https://api.myntask.com/product/category', {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = await response.json();
        console.log('Categories fetched:', data);
        setCategories(data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
    fetchCategories();
  }, [token]);

  useEffect(() => {
    const fetchSubcategories = async () => {
      console.log('Fetching subcategories for category:', selectedCategory);
      if (selectedCategory) {
        try {
          const response = await fetch(
            `https://api.myntask.com/product/sub_category/${selectedCategory}`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          const data = await response.json();
          console.log('Subcategories fetched:', data);
          setSubcategories(data);
        } catch (error) {
          console.error('Error fetching subcategories:', error);
        }
      }
    };
    fetchSubcategories();
  }, [selectedCategory, token]);

  useEffect(() => {
    const fetchProducts = async () => {
      console.log('Fetching products for category and subcategory:', selectedCategory, selectedSubcategory);
      if (selectedCategory && selectedSubcategory) {
        try {
          const response = await fetch(
            `https://api.myntask.com/product/product_dropdown?category_id=${selectedCategory}&subcategory_id=${selectedSubcategory}`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          const data = await response.json();
          console.log('Products fetched:', data);
          setProducts(data);
        } catch (error) {
          console.error('Error fetching products:', error);
        }
      }
    };
    fetchProducts();
  }, [selectedCategory, selectedSubcategory, token]);



  const fetchProductDetails = async (productId) => {
    console.log('Fetching product details for product ID:', productId);
    setLoadingProduct(true);
    try {
      const response = await fetch(
        `https://api.myntask.com/purchase_order/product_dropdown_info/${productId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const data = await response.json();
      console.log('Product details fetched:', data);

      if (data && data.length > 0) {
        setProductDetails(data[0]);
      } else {
        console.error('No product data available');
      }
    } catch (error) {
      console.error('Error fetching product details:', error);
    } finally {
      setLoadingProduct(false); // Stop loading
    }
  };


  // Fetch unit types
  useEffect(() => {
    const fetchUnitTypes = async () => {
      try {
        const response = await fetch('https://api.myntask.com/product/unit_types', {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = await response.json();
        setUnitTypes(data);
      } catch (error) {
        console.error('Error fetching unit types:', error);
      }
    };
    fetchUnitTypes();
  }, [token]);


  // Fetch tax data
  useEffect(() => {
    const fetchTaxes = async () => {
      try {
        const response = await fetch('https://api.myntask.com/product/taxes', {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = await response.json();
        setTaxes(data);
      } catch (error) {
        console.error('Error fetching taxes:', error);
      }
    };
    fetchTaxes();
  }, [token]);


  const handleProductSelect = (productId) => {
    console.log('Product selected:', productId);
    setSelectedProduct(productId);
    fetchProductDetails(productId);
  };

  const addProductRow = () => {
    console.log('Adding product row with details:', productDetails);
    if (!productDetails) {
      alert('Please select a product.');
      return;
    }

    const unitPrice = parseFloat(productDetails.purchase_price) || 0;
    const qty = 1;
    // const taxPercentage = productDetails.taxes && productDetails.taxes.length > 0
    //   ? parseFloat(productDetails.taxes[0].split('@')[1])
    //   : 0;

    const newRow = {
      id: rows.length + 1,
      product: productDetails.name,
      description: productDetails.description || '',
      qty,
      unitPrice,
      discount: 0,
      tax: [],
      taxAmount: 0,
      totalAmount: unitPrice * qty,
      hsn_code: productDetails.hsn_sac_code, // Add hsn_sac_code here
      unitTypeId: '',
    };
    setRows([...rows, newRow]);
    calculateSummary([...rows, newRow]);
    setSelectedProduct('');
    setProductDetails(null);
  };

  const addEmptyRow = () => {
    console.log('Adding empty row...');
    const newRow = {
      id: rows.length + 1,
      product: '',
      description: '',
      qty: 1,
      unitPrice: 0.0,
      discount: 0,
      tax: [],
      hsn_code: '', // Optional: Add if needed
      taxAmount: 0,
      totalAmount: 0,
    };
    setRows([...rows, newRow]);
  };

  const deleteRow = (id) => {
    console.log('Deleting row with ID:', id);
    const updatedRows = rows.filter((row) => row.id !== id).map((row, index) => ({
      ...row,
      id: index + 1,
    }));
    setRows(updatedRows);
    // Recalculate the summary
    calculateSummary(updatedRows);
  };

  // Handle Input Changes
  const handleRowChange = (id, field, value) => {
    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        // Safely parse the input fields and assign defaults
        const qty = field === "qty" ? parseFloat(value) || 0 : parseFloat(row.qty) || 0;
        const unitPrice = field === "unitPrice" ? parseFloat(value) || 0 : parseFloat(row.unitPrice) || 0;
        const discount = field === "discount" ? parseFloat(value) || 0 : parseFloat(row.discount) || 0;
        // const tax = parseFloat(row.tax) || 0; // Default to 0 if not a number

        // Update tax selection
        const selectedTaxIds = field === "tax" ? value : row.tax; // value should be an array of selected tax IDs
        const selectedTaxes = taxes.filter(tax => selectedTaxIds.includes(tax.id));

        // Calculate the discounted price per item
        const discountedPrice = unitPrice * (1 - discount / 100);

        // Calculate the total tax amount from selected taxes
        const totalTaxAmount = selectedTaxes.reduce((sum, tax) => {
          const taxPercentage = parseFloat(tax.tax_name.split('@')[1]) || 0;
          return sum + (taxPercentage / 100) * discountedPrice * qty;
        }, 0);

        // Calculate the total amount
        const totalAmount = discountedPrice * qty + totalTaxAmount;

        return {
          ...row,
          [field]: value, // Update the specific field
          taxAmount: Number.isFinite(totalTaxAmount) ? totalTaxAmount : 0, // Ensure taxAmount is a number
          totalAmount: Number.isFinite(totalAmount) ? totalAmount : 0, // Ensure totalAmount is a number
        };
      }
      return row; // No changes for other rows
    });

    setRows(updatedRows);
    calculateSummary(updatedRows); // Update summary totals
  };



  const calculateSummary = (rowsData) => {
    console.log('Calculating summary for rows:', rowsData);
    const total = rowsData.reduce((sum, row) => sum + row.totalAmount, 0);
    const tax = rowsData.reduce((sum, row) => sum + row.taxAmount, 0);
    setTotalAmount(total);
    setTotalTax(tax);
  };

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    if (!selectedVendor) {
      newErrors.vendor = "Vendor is required.";
      isValid = false;
    }
    if (!orderDate) {
      newErrors.orderDate = "OrderDate is required.";
      isValid = false;
    }
    if (!expectedDeliveryDate) {
      newErrors.expectedDeliveryDate = "Delivery Date is required.";
      isValid = false;
    }
    if (!selectedCategory) {
      newErrors.category = "Category is required.";
      isValid = false;
    }
    if (!selectedSubcategory) {
      newErrors.subcategory = "Subcategory is required.";
      isValid = false;
    }

    setErrors(newErrors);

    // Focus on the first error field if it exists
    if (!isValid) {
      if (vendorRef.current && newErrors.vendor) vendorRef.current.focus();
      else if (orderDateRef.current && newErrors.orderDate) orderDateRef.current.focus();
      else if (expectedDeliveryDateRef.current && newErrors.date) expectedDeliveryDateRef.current.focus();
      else if (categoryRef.current && newErrors.category) categoryRef.current.focus();
      else if (subcategoryRef.current && newErrors.subcategory) subcategoryRef.current.focus();

    }

    return isValid;
  };

  const handleSave = async () => {

    if (!validateForm()) {
      console.error('Form validation failed!');
      return;
    }

    const addressData = {};
    if (manualShippingAddress) {
      // When manual address is provided
      addressData.address_id = null;
      addressData.shipping_address = manualShippingAddress;
    } else if (selectedShippingAddress) {
      // When dropdown address is selected
      addressData.address_id = selectedShippingAddress;
      addressData.shipping_address = null;
    } else {
      // Show error if neither is provided
      Swal.fire({
        icon: 'error',
        title: 'Shipping address is required!',
        toast: true,
        position: 'top-right',
        showConfirmButton: false,
        timer: 3000
      });
      return;
    }

    const items = rows.map((row) => ({
      item_name: row.product || '',
      quantity: parseFloat(row.qty) || 0,
      product_id: selectedProduct || null,
      hsn_sac_code: row.hsn_code || '',
      unit_price: parseFloat(row.unitPrice) || 0,
      unit_id: row.unitTypeId || null,
      item_summary: row.description || '',
      amount: parseFloat(row.totalAmount) || 0,
      tax_id: row.tax,
    }));

    const dataToSend = {
      vendor_company_id: selectedVendor, // Replace with your dynamic value
      purchase_date: orderDate ? orderDate.toISOString().split('T')[0] : '',
      expected_delivery_date: expectedDeliveryDate ? expectedDeliveryDate.toISOString().split('T')[0] : '',
      ...addressData, // Replace with your dynamic value

      calculate_tax: "after_discount", // Replace with your dynamic value
      delivery_status: "not_started", // Replace with your dynamic value
      note: note, // Replace with your dynamic value
      sub_total: rows.reduce((sum, row) => sum + parseFloat(row.unitPrice) * parseFloat(row.qty), 0),
      total: totalAmount,
      discount: rows.reduce((dis, row) => parseFloat(row.discount), 0), // Replace with your dynamic value
      discount_type: "percent", // Replace with your dynamic value
      items,
    };
    setIsLoading(true);

    // Send the POST request using fetch
    console.log('Purchase Order Data:', dataToSend); // Debug before sending

    fetch('https://api.myntask.com/purchase_order/save', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(dataToSend)
    })
      .then(response => response.json())
      .then(data => {
        // Display SweetAlert toast notification on success
        Swal.fire({
          icon: 'success',
          title: 'Purchase order saved successfully!',
          toast: true,
          position: 'top-right',
          showConfirmButton: false,
          timer: 3000
        }).then(() => {
          // Navigate to purchase-order page after the toast disappears
          navigate('/purchase-order');
          setIsLoading(false);
        });
        console.log('API Response:', data);
      })
      .catch(error => {
        setIsLoading(false);
        // Display SweetAlert toast notification on error
        Swal.fire({
          icon: 'error',
          title: 'Failed to save purchase order!',
          toast: true,
          position: 'top-right',
          showConfirmButton: false,
          timer: 3000
        });
        console.error('Error saving order:', error);
      });
  };


  const resetForm = () => {
    navigate('/purchase-order');
    console.log('Resetting form...');
    setSelectedClient('');
    setSelectedCategory('');
    setSelectedSubcategory('');
    setSelectedProduct('');
    setRows([]);
    setTotalAmount(0);
    setTotalTax(0);
    setProductDetails(null);
  };



  // Custom dropdown for selecting multiple taxes
  const TaxDropdown = ({ row }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };

    const handleCheckboxChange = (taxId) => {
      const newTaxIds = row.tax.includes(taxId)
        ? row.tax.filter(id => id !== taxId)
        : [...row.tax, taxId];

      handleRowChange(row.id, "tax", newTaxIds);
    };

    return (
      <div className="tax-dropdown">
        <div className="dropdown-header" onClick={toggleDropdown} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
          <span>
            {row.tax.length > 0
              ? taxes.filter(tax => row.tax.includes(tax.id)).map(tax => tax.tax_name).join(', ')
              : 'Select Taxes'}
          </span>
          <span style={{ marginLeft: 'auto' }}>▼</span> {/* Arrow icon */}
        </div>
        {isOpen && (
          <div className="dropdown-list">
            {taxes.map(tax => (
              <label key={tax.id} style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="checkbox"
                  checked={row.tax.includes(tax.id)}
                  onChange={() => handleCheckboxChange(tax.id)}
                  style={{ marginRight: '5px' }}
                />
                {tax.tax_name}
              </label>
            ))}
          </div>
        )}
      </div>
    );
  };



  useEffect(() => {
    // Adjust the timer duration here (e.g., 3000ms = 3 seconds)
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500);

    // Cleanup the timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }



  return (
    <div className="main-container">
      <h2 style={{ textAlign: 'center', marginBottom: '20px' }}> Add Purchase Order</h2>
      <div className="add-order-body">
        <form>
          <div className='form-row' style={{ marginTop: '20px' }}>
            {/* Vendor Dropdown */}
            <div className='section-div'>
              <label>Vendor <span className="required">*</span></label>
              <select
                className='expenseInput'
                value={selectedVendor}
                onChange={(e) => setSelectedVendor(e.target.value)}
                style={{ width: '100%' }}
                ref={vendorRef}
              >
                <option value="">Select Vendor</option>
                {vendors.map((vendor) => (
                  <option key={vendor.id} value={vendor.id}>
                    {vendor.company_name}
                  </option>
                ))}
              </select>
              {errors.vendor && <span className="error-message">{errors.vendor}</span>}
            </div>

            {/* <div className='form-row'> */}
            <div className="section-div">
              <label>Order Date <span className="required">*</span></label>
              <input
                type="date"
                id='orderDate'
                value={orderDate ? orderDate.toISOString().split('T')[0] : ""}
                onChange={(e) => setOrderDate(new Date(e.target.value))}
                className='expenseInput'
                ref={orderDateRef}
              // placeholder="Order Date"
              />
              {errors.orderDate && <span className="error-message">{errors.orderDate}</span>}
            </div>
            {/* Expected Delivery Date */}
            <div className="section-div">
              <label>Delivery Date <span className="required">*</span></label>
              <input
                type="date"
                id='expectedDeliveryDate'
                value={expectedDeliveryDate ? expectedDeliveryDate.toISOString().split('T')[0] : ""}
                onChange={(e) => setExpectedDeliveryDate(new Date(e.target.value))}
                className='expenseInput'
                ref={expectedDeliveryDateRef}
              // placeholder="Expected Delivery Date"
              />
              {errors.expectedDeliveryDate && <span className="error-message">{errors.expectedDeliveryDate}</span>}
            </div>
            {/* </div> */}
          </div>

          <div className="formgroup">
            <label>Shipping Address <span className="required">*</span></label>
            <div style={{ display: 'flex', alignItems: 'start', width: '100%', }}>
              <select
                className='expenseInput'
                value={selectedShippingAddress}
                onChange={(e) => {
                  setSelectedShippingAddress(e.target.value);
                  setManualShippingAddress('');
                }}
                style={{ width: '20%', marginRight: '2px' }} // Adjust width as needed
              >
                <option value="">Select Address</option>
                {shippingAddresses.length > 0 ? (
                  shippingAddresses.map((address) => (
                    <option key={address.id} value={address.id}>
                      {address.address}
                    </option>
                  ))
                ) : (
                  <option value="">No addresses available</option>
                )}
              </select>
              <textarea
                type="text"
                className='expenseInput'
                placeholder='Or type shipping address manually'
                value={manualShippingAddress}
                onChange={(e) => {
                  setManualShippingAddress(e.target.value);
                  setSelectedShippingAddress(''); // Clear selection when typing manually
                }}
                style={{ flex: 1 }} // Take the remaining space
              />
            </div>
          </div>






          <div className="form-row" style={{ marginTop: '25px', marginBottom: '30px' }}>
            <div className="section-div">
              <label>Category: <span className="required">*</span></label>
              <select className='expenseInput' onChange={(e) => setSelectedCategory(e.target.value)} value={selectedCategory} ref={categoryRef}>
                <option value="">Select Category</option>
                {categories.map((cat) => (
                  <option key={cat.id} value={cat.id}>
                    {cat.category_name}
                  </option>
                ))}
              </select>
              {errors.category && <span className="error-message">{errors.category}</span>}
            </div>
            <div className="section-div">
              <label>Subcategory: <span className="required">*</span></label>
              <select className='expenseInput' onChange={(e) => setSelectedSubcategory(e.target.value)} value={selectedSubcategory} ref={subcategoryRef}>
                <option value="">Select Subcategory</option>
                {subcategories.map((sub) => (
                  <option key={sub.id} value={sub.id}>
                    {sub.category_name}
                  </option>
                ))}
              </select>
              {errors.subcategory && <span className="error-message">{errors.subcategory}</span>}
            </div>


            <div className="section-div">
              <label>Product: <span className="required">*</span></label>
              <select className='expenseInput' onChange={(e) => handleProductSelect(e.target.value)} value={selectedProduct}>
                <option value="">Select Product</option>
                {products.map((prod) => (
                  <option key={prod.id} value={prod.id}>
                    {prod.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* </div> */}
          <div className="est-actions">
            <button
              className='color-button'
              type="button"
              onClick={addProductRow}
              disabled={!productDetails || loadingProduct} // Disable button during loading
            >
              {loadingProduct ? "Loading..." : "Add Product"}
            </button>
            <button type="button" className='color-button' onClick={addEmptyRow}>
              + Add Empty Row
            </button>
          </div>

        </form>
        {/* </div> */}

        <table className='estimate-table'>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Item Name</th>
              {/* <th>Description</th> */}
              <th>Qty</th>
              <th>Unit Type</th>
              <th>Unit Price</th>
              <th>Discount (%)</th>
              <th>Tax (%)</th>
              {/* <th>Tax Amount</th> */}
              <th>Amount</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className='estimate-body'>
            {rows.map((row) => (
              <tr key={row.id}>
                <td style={{ width: '1vw' }}>{row.id}</td>
                <td style={{ width: '20vw' }}>
                  <input
                    className='estimateInput'
                    type="text"
                    value={row.product}
                    placeholder='Product Name'
                    onChange={(e) => handleRowChange(row.id, "product", e.target.value)}
                    style={{ marginBottom: '10px' }}
                  />

                  <input
                    className='estimateInput'
                    rows="2"
                    placeholder='Description'
                    value={row.description}
                    onChange={(e) => handleRowChange(row.id, "description", e.target.value)}
                  />
                </td>
                <td style={{ width: '5vw' }}>
                  <input
                    className='estimateInput'
                    type="number"
                    value={row.qty}
                    onChange={(e) => handleRowChange(row.id, "qty", parseFloat(e.target.value))}

                  />
                </td>
                <td style={{ width: '7vw' }}>
                  <select
                    className='estimateInput'
                    value={row.unitTypeId}
                    onChange={(e) => handleRowChange(row.id, "unitTypeId", e.target.value)}
                  >
                    <option value="">Select Unit Type</option>
                    {unitTypes.map((unit) => (
                      <option key={unit.id} value={unit.id}>
                        {unit.unit_type}
                      </option>
                    ))}
                  </select>
                </td>
                <td style={{ width: '7vw' }}>
                  <input
                    className='estimateInput'
                    type="number"
                    value={row.unitPrice}
                    onChange={(e) => handleRowChange(row.id, "unitPrice", parseFloat(e.target.value))}

                  />
                </td>
                <td style={{ width: '5vw' }}>
                  <input
                    className='estimateInput'
                    type="number"
                    value={row.discount}
                    onChange={(e) => handleRowChange(row.id, "discount", parseFloat(e.target.value))}

                  />
                </td>
                <td style={{ width: '7vw' }}>
                  <TaxDropdown row={row} />
                </td>
                {/* <td>{row.taxAmount.toFixed(2)}</td> */}
                <td style={{ width: '8vw' }}>{row.totalAmount.toFixed(2)}</td>
                <td style={{ width: '2vw' }}>
                  <button onClick={() => deleteRow(row.id)}>➖</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="estimate-summary">
          <div>
            <label>Total Tax :</label> {totalTax.toFixed(2)}
          </div>
          <div>
            <label>Total Amount :</label> {totalAmount.toFixed(2)}
          </div>
        </div>

        <div className="note-section" style={{ marginTop: '20px' }}>
          <label>Terms and Conditions :</label>
          <textarea
            className='expenseInput'
            rows="4"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            placeholder="Enter any notes here..."
            style={{ width: '100%' }} // Adjust width as needed
          />
        </div>


        <div style={{ display: 'flex', justifyContent: 'end', marginTop: '40px', gap: '20px' }}>
          <button type="button" className='color-button' onClick={resetForm}>
            Cancel
          </button>
          <button type="button" className='color-button' onClick={handleSave} disabled={isLoading}>
            {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Save'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddOrder;
