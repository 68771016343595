
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../Components/MainFunction';
import './../Components/Main.css'; // Import the CSS file
import { Vortex } from 'react-loader-spinner';
import { Box, Stack, Typography } from '@mui/material';
import Swal from 'sweetalert2';
import { GridMoreVertIcon } from '@mui/x-data-grid';
import './Estimate.css';

function Estimate() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [actionMenuVisible, setActionMenuVisible] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [canceledEstimates, setCanceledEstimates] = useState(new Set());
  const [statusFilter, setStatusFilter] = useState('all');
  const actionMenuRef = useRef(null);


  useEffect(() => {
    const handleClickOutside = (event) => {
      // If the action menu is open and the click is outside the menu, close it
      if (actionMenuRef.current && !actionMenuRef.current.contains(event.target)) {
        setActionMenuVisible(null); // Close the dropdown
      }
    };

    // Add event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);



  const fetchEstimates = async () => {
    const token = localStorage.getItem('authToken');

    if (!token) {
      console.error('No token found, please login first.');
      return;
    }

    try {
      const response = await fetch('https://api.myntask.com/estimate/list', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch estimates');
      }

      const result = await response.json();
      console.log('Estimate data:', result);

      // Set the data and check for canceled estimates
      setData(result);

      // If any estimate has status "canceled", mark it as canceled
      const canceledIds = result
        .filter((estimate) => estimate.status === 'canceled')
        .map((estimate) => estimate.id);

      setCanceledEstimates(new Set(canceledIds));
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching estimates:', error);
      setError("Failed to Fetch Estimates");
      setIsLoading(false);
    }
  };


  useEffect(() => {

    fetchEstimates();
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };


  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value); // Update the status filter
  };


  const filteredData = data.filter((estimate) => {
    const matchesSearchTerm =
      estimate.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      estimate.estimate_number.toLowerCase().includes(searchTerm.toLowerCase());

    const matchesStatusFilter =
      statusFilter === 'all' || estimate.status === statusFilter;

    return matchesSearchTerm && matchesStatusFilter;
  });




  const toTitleCase = (str) => {
    if (!str) return ""; // Return an empty string if the input is null or undefined
    return str
      .toLowerCase() // Convert to lowercase
      .split(' ') // Split the string into words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(' '); // Join the words back into a string
  };





  const deleteEstimate = async (id) => {

    setIsLoading(true);
    try {
      const authToken = localStorage.getItem("authToken"); // Get the authToken from local storage

      if (!authToken) {
        console.error("Auth token not found");
        return;
      }

      const url = `https://api.myntask.com/estimate/delete/${id}`;

      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${authToken}`, // Include the authToken in the headers
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Estimate deleted successfully", data);
        fetchEstimates();
        Swal.fire("Deleted!", "The estimate has been deleted.", "success");
        // Add any additional logic, such as updating the UI
      } else {
        console.error("Failed to delete the estimate");
        Swal.fire("Error", "Failed to delete the estimate.", "error");
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error deleting estimate:", error);
      Swal.fire("Error", "Something went wrong!", "error");
    }
    setIsLoading(false);
  };

  const confirmDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteEstimate(id); // Call the delete function if confirmed
      }
    });
  };


  const handleStatusChange = async (estimateId, newStatus) => {
    const token = localStorage.getItem('authToken');

    if (!token) {
      console.error('No token found, please login first.');
      return;
    }

    try {
      // Update the status in the backend
      const response = await fetch('https://api.myntask.com/estimate/status_update', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          status: newStatus,
          estimate_id: estimateId,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update status');
      }

      const result = await response.json();
      console.log('Status updated successfully:', result);

      // Update the local state to reflect the new status
      setData((prevData) =>
        prevData.map((estimate) =>
          estimate.id === estimateId ? { ...estimate, status: newStatus } : estimate
        )
      );

      // If the status is "canceled", add the estimate ID to canceledEstimates
      if (newStatus === 'canceled') {
        setCanceledEstimates((prev) => new Set([...prev, estimateId]));
      } else {
        // If the status is not "canceled", remove the estimate ID from canceledEstimates
        setCanceledEstimates((prev) => {
          const newCanceled = new Set(prev);
          newCanceled.delete(estimateId);
          return newCanceled;
        });
      }

      Swal.fire("Success!", "Status updated successfully.", "success");
    } catch (error) {
      console.error('Error updating status:', error);
      Swal.fire("Error", "Failed to update status.", "error");
    }
  };



  //..........Cancel estimate start..................................................
  useEffect(() => {
    // Load canceled estimates from localStorage
    const storedCanceledEstimates = JSON.parse(localStorage.getItem('canceledEstimates')) || [];
    setCanceledEstimates(new Set(storedCanceledEstimates));

    fetchEstimates();
  }, []);



  const ViewEstimate = (id) => {
    navigate(`/ViewEstimate/${id}`);
  }


  //.......Cancel estimate end..................................................


  const toggleActionMenu = (id) => {
    setActionMenuVisible((prevVisible) => (prevVisible === id ? null : id));
  };

  const handleEdit = (id) => {
    navigate(`/EditEstimate/${id}`);
  }


  const currentStatusColors = {
    waiting: 'rgba(255, 165, 0, 0.6)', // Brighter orange with 60% opacity
    approved: 'rgba(0, 0, 255, 0.6)', // Brighter blue with 60% opacity
    rejected: 'rgba(255, 0, 0, 0.6)', // Brighter red with 60% opacity
    canceled: 'rgba(128, 128, 128, 0.6)', // Grey with 60% opacity
  };


  //..........Loading Spinner ..........................................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }



  return (
    <div className="main-container">
      <div className="top-controls" style={{ gap: '1rem' }}>
        <h1 className='title'>Estimates
          <div className="underline"></div>
        </h1>

        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearch}
          className="searchbar"
        />

        <div className="buttons-row">
          <button className="color-button" onClick={() => navigate('/addestimate')}>
            Create Estimate
          </button>

        </div>
      </div>
      <div className="top-controls">

      </div>

      <table>
        <thead>
          <tr>
            <th>Est No.</th>
            {/* <th>Image</th> */}
            <th>Customer Name</th>
            <th>Company Name</th>
            <th>Created </th>
            <th>Valid Till</th>
            <th>Amount</th>
            <th style={{ width: '10vw' }}>
              <div>
                <select
                  value={statusFilter}
                  onChange={handleStatusFilterChange}
                  className="header-filter"
                >
                  <option value="all">Status</option>
                  <option value="waiting">Pending</option>
                  <option value="approved">Approved</option>
                  <option value="rejected">Rejected</option>
                  <option value="canceled">Canceled</option>
                </select>
              </div>
            </th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((estimate) => (
            <tr key={estimate.id} className={canceledEstimates.has(estimate.id) ? 'canceled' : ''}>
              <td style={{ width: '5vw' }}>{estimate.estimate_number}</td>
              <td>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {estimate.image ? (
                    <img
                      src={estimate.image}
                      alt={estimate.name || "default"}
                      className="image"
                    />
                  ) : (
                    <img
                      src="/path/to/default/image.jpg"
                      alt="Default"
                      className="image"
                    />
                  )}
                  {toTitleCase(estimate.name)}
                </div>
              </td>
              <td style={{ maxWidth: '15vw' }}>{estimate.company_name || "N/A"}</td>


              <td>{formatDate(estimate.created_at)}</td>
              <td>{formatDate(estimate.valid_till)}</td>

              <td>{estimate.total}</td>

              <td>
                <select
                  value={estimate.status}
                  onChange={(e) => handleStatusChange(estimate.id, e.target.value)}
                  disabled={estimate.status === 'canceled'} // Disable dropdown if estimate is canceled
                  style={{
                    width: '120px',
                    padding: '10px',
                    textAlign: 'center',
                    border: 'none',
                    backgroundColor: currentStatusColors[estimate.status], // Apply color here
                    color: 'white', // Text color
                    cursor: 'pointer',
                    borderRadius: '4px', // Optional: Add rounded corners
                  }}
                >
                  <option value="waiting" style={{ backgroundColor: "white", color: "black" }}>Pending</option>
                  <option value="approved" style={{ backgroundColor: "white", color: "black" }}>Approved</option>
                  <option value="rejected" style={{ backgroundColor: "white", color: "black" }}>Rejected</option>
                  <option value="canceled" style={{ backgroundColor: "white", color: "black" }}>Canceled</option>
                </select>
              </td>
              {/* <td>
                <span
                  onClick={() => confirmDelete(estimate.id)}
                  style={{ color: "red", cursor: "pointer" }}
                >
                  Delete
                </span>
              </td> */}
              <td style={{ width: '6vw' }}>
                <div className="action-container">
                  <div className="action-dots" onClick={() => toggleActionMenu(estimate.id)}>
                    <GridMoreVertIcon />
                  </div>
                  {actionMenuVisible === estimate.id && (
                    <div className="action-menu" ref={actionMenuRef}>
                      {estimate.status === 'canceled' ? (
                        <div className="action-item" onClick={() => handleStatusChange(estimate.id, 'waiting')}>
                          Restore Estimate
                        </div>
                      ) : (
                        <>
                          <div className="action-item" onClick={() => handleEdit(estimate.id)}>
                            Edit
                          </div>
                          <div className='action-item' onClick={() => ViewEstimate(estimate.id)}>
                            View
                          </div>
                          {/* <div className='action-item'>
                            Send
                          </div> */}
                          <div className="action-item" onClick={() => confirmDelete(estimate.id)}>
                            Delete
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

    </div>
  );
}

export default Estimate;
