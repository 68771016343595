// src/Contracts.js
import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
} from '@mui/material';

const Contracts = () => {
  const [contracts, setContracts] = useState([]);
  const [newContract, setNewContract] = useState({
    title: '',
    client: '',
    startDate: '',
    endDate: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewContract((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    if (
      newContract.title &&
      newContract.client &&
      newContract.startDate &&
      newContract.endDate
    ) {
      setContracts((prev) => [...prev, newContract]);
      setNewContract({ title: '', client: '', startDate: '', endDate: '' });
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Contracts
      </Typography>

      <Paper sx={{ p: 2, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Create New Contract
        </Typography>
        <TextField
          fullWidth
          label="Contract Title"
          name="title"
          value={newContract.title}
          onChange={handleChange}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="Client Name"
          name="client"
          value={newContract.client}
          onChange={handleChange}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="Start Date"
          name="startDate"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={newContract.startDate}
          onChange={handleChange}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="End Date"
          name="endDate"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={newContract.endDate}
          onChange={handleChange}
          sx={{ mb: 2 }}
        />
        <Button variant="contained" onClick={handleSubmit}>
          Submit
        </Button>
      </Paper>

      <Typography variant="h6" gutterBottom>
        Contracts List
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Client</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contracts.map((contract, index) => (
              <TableRow key={index}>
                <TableCell>{contract.title}</TableCell>
                <TableCell>{contract.client}</TableCell>
                <TableCell>{contract.startDate}</TableCell>
                <TableCell>{contract.endDate}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Contracts;
