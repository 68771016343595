import React, { useState, useEffect, useRef } from 'react';
import './EstimateForm.css';
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

const EditEstimate = () => {
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [clients, setClients] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedSubcategory, setSelectedSubcategory] = useState("");
    const [selectedProduct, setSelectedProduct] = useState("");
    const [selectedClient, setSelectedClient] = useState("");
    const [rows, setRows] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalTax, setTotalTax] = useState(0);
    const [productDetails, setProductDetails] = useState(null);
    const [loadingProduct, setLoadingProduct] = useState(false);
    const [estimateNumber, setEstimateNumber] = useState('');
    const [expectedDeliveryDate, setExpectedDeliveryDate] = useState(null);
    const [unitTypes, setUnitTypes] = useState([]);
    const [taxes, setTaxes] = useState([]);
    const [note, setNote] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [billingAddress, setBillingAddress] = useState('');
    const [shippingAddress, setShippingAddress] = useState('');

    const token = localStorage.getItem('authToken');
    const navigate = useNavigate();
    const { id } = useParams(); // Get the estimate ID from the URL

    // Create refs for required fields
    const clientRef = useRef(null);
    const dateRef = useRef(null);

    // Fetch estimate data to pre-fill the form
    useEffect(() => {
        const fetchEstimateData = async () => {
            try {
                const response = await fetch(`https://api.myntask.com/estimate/edit/${id}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                const data = await response.json();
                console.log('Estimate data fetched:', data);

                // Set estimate data
                const estimate = data.estimate_data[0];
                setEstimateNumber(estimate.estimate_number);
                setSelectedClient(estimate.client_id);
                setExpectedDeliveryDate(new Date(estimate.valid_till));
                setBillingAddress(estimate.billing_address);
                setShippingAddress(estimate.shipping_address);
                setNote(estimate.note);
                setTotalAmount(estimate.total);
                setTotalTax(estimate.total - estimate.sub_total);

                // Set items (rows)
                const updatedRows = data.items.map((item, index) => ({
                    id: index + 1,
                    product: item.item_name,
                    description: item.item_summary,
                    qty: item.quantity,
                    unitPrice: item.item_rate,
                    discount: item.discount,
                    tax: JSON.parse(item.tax_ids), // Parse tax_ids as an array
                    taxAmount: item.item_value - item.item_rate,
                    totalAmount: item.item_value,
                    hsn_code: item.hsn_sac_code,
                    unitTypeId: item.unit_id,
                }));
                setRows(updatedRows);
            } catch (error) {
                console.error('Error fetching estimate data:', error);
            }
        };
        fetchEstimateData();
    }, [id, token]);

    // Fetch categories
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetch('https://api.myntask.com/product/category', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                const data = await response.json();
                setCategories(data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };
        fetchCategories();
    }, [token]);

    // Fetch subcategories
    useEffect(() => {
        const fetchSubcategories = async () => {
            if (selectedCategory) {
                try {
                    const response = await fetch(
                        `https://api.myntask.com/product/sub_category/${selectedCategory}`,
                        { headers: { Authorization: `Bearer ${token}` } }
                    );
                    const data = await response.json();
                    setSubcategories(data);
                } catch (error) {
                    console.error('Error fetching subcategories:', error);
                }
            }
        };
        fetchSubcategories();
    }, [selectedCategory, token]);

    // Fetch products
    useEffect(() => {
        const fetchProducts = async () => {
            if (selectedCategory && selectedSubcategory) {
                try {
                    const response = await fetch(
                        `https://api.myntask.com/product/product_dropdown?category_id=${selectedCategory}&subcategory_id=${selectedSubcategory}`,
                        { headers: { Authorization: `Bearer ${token}` } }
                    );
                    const data = await response.json();
                    setProducts(data);
                } catch (error) {
                    console.error('Error fetching products:', error);
                }
            }
        };
        fetchProducts();
    }, [selectedCategory, selectedSubcategory, token]);

    // Fetch clients
    useEffect(() => {
        const fetchClients = async () => {
            try {
                const response = await fetch('https://api.myntask.com/project/client', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                const data = await response.json();
                setClients(data);
            } catch (error) {
                console.error('Error fetching clients:', error);
            }
        };
        fetchClients();
    }, [token]);

    // Fetch unit types
    useEffect(() => {
        const fetchUnitTypes = async () => {
            try {
                const response = await fetch('https://api.myntask.com/product/unit_types', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                const data = await response.json();
                setUnitTypes(data);
            } catch (error) {
                console.error('Error fetching unit types:', error);
            }
        };
        fetchUnitTypes();
    }, [token]);

    // Fetch taxes
    useEffect(() => {
        const fetchTaxes = async () => {
            try {
                const response = await fetch('https://api.myntask.com/product/taxes', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                const data = await response.json();
                setTaxes(data);
            } catch (error) {
                console.error('Error fetching taxes:', error);
            }
        };
        fetchTaxes();
    }, [token]);

    // Fetch product details
    const fetchProductDetails = async (productId) => {
        setLoadingProduct(true);
        try {
            const response = await fetch(
                `https://api.myntask.com/purchase_order/product_dropdown_info/${productId}`,
                { headers: { Authorization: `Bearer ${token}` } }
            );
            const data = await response.json();
            if (data && data.length > 0) {
                setProductDetails(data[0]);
            } else {
                console.error('No product data available');
            }
        } catch (error) {
            console.error('Error fetching product details:', error);
        } finally {
            setLoadingProduct(false);
        }
    };

    // Handle product selection
    const handleProductSelect = (productId) => {
        setSelectedProduct(productId);
        fetchProductDetails(productId);
    };

    // Add a product row
    const addProductRow = () => {
        if (!productDetails) {
            alert('Please select a product.');
            return;
        }

        const unitPrice = parseFloat(productDetails.purchase_price) || 0;
        const qty = 1;

        const newRow = {
            id: rows.length + 1,
            product: productDetails.name,
            description: productDetails.description || '',
            qty,
            unitPrice,
            discount: 0,
            tax: [],
            taxAmount: 0,
            totalAmount: unitPrice * qty,
            hsn_code: productDetails.hsn_sac_code,
            unitTypeId: '',
        };
        setRows([...rows, newRow]);
        calculateSummary([...rows, newRow]);
        setSelectedProduct('');
        setProductDetails(null);
    };

    // Add an empty row
    const addEmptyRow = () => {
        const newRow = {
            id: rows.length + 1,
            product: '',
            description: '',
            qty: 1,
            unitPrice: 0.0,
            discount: 0,
            tax: [],
            hsn_code: '',
            taxAmount: 0,
            totalAmount: 0,
        };
        setRows([...rows, newRow]);
    };

    // Delete a row
    const deleteRow = (id) => {
        const updatedRows = rows.filter((row) => row.id !== id).map((row, index) => ({
            ...row,
            id: index + 1,
        }));
        setRows(updatedRows);
        calculateSummary(updatedRows);
    };

    // Handle row changes
    const handleRowChange = (id, field, value) => {
        const updatedRows = rows.map((row) => {
            if (row.id === id) {
                const qty = field === "qty" ? parseFloat(value) || 0 : parseFloat(row.qty) || 0;
                const unitPrice = field === "unitPrice" ? parseFloat(value) || 0 : parseFloat(row.unitPrice) || 0;
                const discount = field === "discount" ? parseFloat(value) || 0 : parseFloat(row.discount) || 0;
                const selectedTaxIds = field === "tax" ? value : row.tax;
                const selectedTaxes = taxes.filter(tax => selectedTaxIds.includes(tax.id));

                const discountedPrice = unitPrice * (1 - discount / 100);
                const totalTaxAmount = selectedTaxes.reduce((sum, tax) => {
                    const taxPercentage = parseFloat(tax.tax_name.split('@')[1]) || 0;
                    return sum + (taxPercentage / 100) * discountedPrice * qty;
                }, 0);

                const totalAmount = discountedPrice * qty + totalTaxAmount;

                return {
                    ...row,
                    [field]: value,
                    taxAmount: Number.isFinite(totalTaxAmount) ? totalTaxAmount : 0,
                    totalAmount: Number.isFinite(totalAmount) ? totalAmount : 0,
                };
            }
            return row;
        });

        setRows(updatedRows);
        calculateSummary(updatedRows);
    };

    // Calculate summary
    const calculateSummary = (rowsData) => {
        const total = rowsData.reduce((sum, row) => sum + row.totalAmount, 0);
        const tax = rowsData.reduce((sum, row) => sum + row.taxAmount, 0);
        setTotalAmount(total);
        setTotalTax(tax);
    };


    const validateForm = () => {
        const newErrors = {};
        let isValid = true;

        if (!selectedClient) {
            newErrors.client = "Client name is required.";
            isValid = false;
        }
        if (!expectedDeliveryDate) {
            newErrors.date = "Date is required.";
            isValid = false;
        }

        setErrors(newErrors);

        // Focus on the first error field if it exists
        if (!isValid) {
            if (clientRef.current && newErrors.client) clientRef.current.focus();
            else if (dateRef.current && newErrors.date) dateRef.current.focus();

        }

        return isValid;
    };


    // Handle save
    const handleSave = async () => {
        if (!validateForm()) return;

        const items = rows.map((row) => ({
            item_name: row.product || '',
            quantity: parseFloat(row.qty) || 0,
            product_id: selectedProduct || null,
            hsn_sac_code: row.hsn_code || '',
            unit_price: parseFloat(row.unitPrice) || 0,
            unit_id: row.unitTypeId || null,
            item_summary: row.description || '',
            amount: parseFloat(row.totalAmount) || 0,
            tax_id: row.tax,
        }));

        const estimateData = {
            estimate_number: estimateNumber,
            currency_id: 33,
            client_id: parseInt(selectedClient) || null,
            valid_till: expectedDeliveryDate ? expectedDeliveryDate.toISOString().split('T')[0] : "",
            description: 'Estimate Description',
            calculate_tax: 'after_discount',
            billing_address: billingAddress,
            shipping_address: shippingAddress,
            note: note,
            sub_total: rows.reduce((sum, row) => sum + parseFloat(row.unitPrice) * parseFloat(row.qty), 0),
            total: totalAmount,
            discount: rows.reduce((sum, row) => sum + (parseFloat(row.discount) || 0), 0),
            discount_type: 'percent',
            items,
        };
        setIsLoading(true);

        try {
            const response = await fetch(`https://api.myntask.com/estimate/update/${id}`, {
                method: 'PUT', // Use PUT for updates
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(estimateData),
            });

            if (response.ok) {
                Swal.fire('Success', 'Estimate updated successfully!', 'success');
                navigate('/estimates');
                setIsLoading(false);
            } else {
                Swal.fire('Error', 'Failed to update estimate.', 'error');
            }
        } catch (error) {
            console.error('Error updating estimate:', error);
            Swal.fire('Error', 'An error occurred while updating.', 'error');
        }
    };

    // Reset form
    const resetForm = () => {
        setSelectedClient('');
        setSelectedCategory('');
        setSelectedSubcategory('');
        setSelectedProduct('');
        setRows([]);
        setTotalAmount(0);
        setTotalTax(0);
        setProductDetails(null);
        setBillingAddress('');
        setShippingAddress('');
    };

    // Handle cancel
    const handleCancel = () => {
        navigate(-1);
        resetForm();
    };

    // Tax dropdown component
    const TaxDropdown = ({ row }) => {
        const [isOpen, setIsOpen] = useState(false);

        const toggleDropdown = () => {
            setIsOpen(!isOpen);
        };

        const handleCheckboxChange = (taxId) => {
            const newTaxIds = row.tax.includes(taxId)
                ? row.tax.filter(id => id !== taxId)
                : [...row.tax, taxId];
            handleRowChange(row.id, "tax", newTaxIds);
        };

        return (
            <div className="tax-dropdown" style={{ position: 'relative', width: '100%' }}>
                <div
                    className="dropdown-header"
                    onClick={toggleDropdown}
                    style={{
                        padding: '8px',
                        border: '1px solid #ddd',
                        borderRadius: '4px',
                        backgroundColor: '#fff',
                        cursor: 'pointer',
                        minHeight: '36px',
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <div style={{
                        flex: 1,
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '4px',
                        alignItems: 'center',
                        minHeight: '20px'
                    }}>
                        {row.tax.length > 0 ? (
                            taxes
                                .filter(tax => row.tax.includes(tax.id))
                                .map(tax => (
                                    <span key={tax.id} style={{
                                        backgroundColor: '#f0f0f0',
                                        padding: '2px 6px',
                                        borderRadius: '4px',
                                        fontSize: '12px',
                                        whiteSpace: 'nowrap'
                                    }}>
                                        {tax.tax_name}
                                    </span>
                                ))
                        ) : (
                            <span style={{ color: '#999' }}>Select Taxes</span>
                        )}
                    </div>
                    <span style={{ marginLeft: '8px' }}>▼</span>
                </div>

                {isOpen && (
                    <div style={{
                        position: 'absolute',
                        zIndex: 1000,
                        backgroundColor: '#fff',
                        border: '1px solid #ddd',
                        borderRadius: '4px',
                        marginTop: '5px',
                        padding: '8px',
                        boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
                        maxHeight: '200px',
                        overflowY: 'auto',
                        width: '100%'
                    }}>
                        {taxes.map(tax => (
                            <div key={tax.id} style={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '5px 0'
                            }}>
                                <input
                                    type="checkbox"
                                    checked={row.tax.includes(tax.id)}
                                    onChange={() => handleCheckboxChange(tax.id)}
                                    style={{ marginRight: '8px' }}
                                />
                                <span>{tax.tax_name}</span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="main-container">
            <h2>Edit Estimate</h2>
            <form>
                <div className="form-row" style={{ marginTop: '25px', marginBottom: '30px' }}>
                    <div className="section-div">
                        <label>Client Name: <span className="required">*</span></label>
                        <select
                            onChange={(e) => setSelectedClient(e.target.value)}
                            value={selectedClient}
                            className='expenseInput'
                            ref={clientRef}
                        >
                            <option value="">Select Client</option>
                            {clients.map((client) => (
                                <option key={client.client_id} value={client.client_id}>
                                    {client.client_name}
                                </option>
                            ))}
                        </select>
                        {errors.client && <span className="error-message">{errors.client}</span>}
                    </div>
                    <div className="section-div">
                        <label>Date: <span className="required">*</span></label>
                        <input
                            className='expenseInput'
                            type="date"
                            ref={dateRef}
                            value={expectedDeliveryDate ? expectedDeliveryDate.toISOString().split('T')[0] : ""}
                            onChange={(e) => setExpectedDeliveryDate(new Date(e.target.value))}
                        />
                        {errors.date && <span className="error-message">{errors.date}</span>}
                    </div>
                </div>

                <div className="form-row">
                    <div className="section-div">
                        <label>Billing Address: <span className="required">*</span></label>
                        <textarea
                            rows="2"
                            value={billingAddress}
                            onChange={(e) => setBillingAddress(e.target.value)}
                            placeholder="Enter billing address"
                        />
                        {errors.billingAddress && <span className="error-message">{errors.billingAddress}</span>}
                    </div>
                    <div className="section-div">
                        <label>Shipping Address: <span className="required">*</span></label>
                        <textarea
                            rows="2"
                            value={shippingAddress}
                            onChange={(e) => setShippingAddress(e.target.value)}
                            placeholder="Enter shipping address"
                        />
                        {errors.shippingAddress && <span className="error-message">{errors.shippingAddress}</span>}
                    </div>
                </div>

                <div className="form-row" style={{ marginTop: '25px', marginBottom: '30px' }}>
                    <div className="section-div">
                        <label>Category: <span className="required">*</span></label>
                        <select
                            className='expenseInput'
                            onChange={(e) => setSelectedCategory(e.target.value)}
                            value={selectedCategory}
                        >
                            <option value="">Select Category</option>
                            {categories.map((cat) => (
                                <option key={cat.id} value={cat.id}>
                                    {cat.category_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="section-div">
                        <label>Subcategory: <span className="required">*</span></label>
                        <select
                            className='expenseInput'
                            onChange={(e) => setSelectedSubcategory(e.target.value)}
                            value={selectedSubcategory}
                        >
                            <option value="">Select Subcategory</option>
                            {subcategories.map((sub) => (
                                <option key={sub.id} value={sub.id}>
                                    {sub.category_name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="section-div">
                        <label>Product: <span className="required">*</span></label>
                        <select
                            className='expenseInput'
                            onChange={(e) => handleProductSelect(e.target.value)}
                            value={selectedProduct}
                        >
                            <option value="">Select Product</option>
                            {products.map((prod) => (
                                <option key={prod.id} value={prod.id}>
                                    {prod.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="est-actions">
                    <button
                        className='color-button'
                        type="button"
                        onClick={addProductRow}
                        disabled={!productDetails || loadingProduct}
                    >
                        {loadingProduct ? "Loading..." : "Add Product"}
                    </button>
                    <button type="button" className='color-button' onClick={addEmptyRow}>
                        + Add Empty Row
                    </button>
                </div>
            </form>


            <table className='estimate-table'>
                <thead>
                    <tr>
                        <th>S.No.</th>
                        <th>Item Name</th>
                        <th>Qty</th>
                        <th>Unit Type</th>
                        <th>Unit Price</th>
                        <th>Discount (%)</th>
                        <th>Tax (%)</th>
                        <th>Tax Amount</th>
                        <th>Amount</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody className='estimate-body'>
                    {rows.map((row) => (
                        <tr key={row.id}>
                            <td style={{ width: '2vw' }}>{row.id}</td>
                            <td style={{ width: '20vw' }}>
                                <input
                                    className='estimateInput'
                                    type="text"
                                    value={row.product}
                                    placeholder='Product Name'
                                    onChange={(e) => handleRowChange(row.id, "product", e.target.value)}
                                    style={{ marginBottom: '10px' }}
                                />

                                <input
                                    className='estimateInput'
                                    rows="2"
                                    placeholder='Description'
                                    value={row.description}
                                    onChange={(e) => handleRowChange(row.id, "description", e.target.value)}
                                />
                            </td>
                            <td style={{ width: '5vw' }}>
                                <input
                                    className='estimateInput'
                                    type="number"
                                    value={row.qty}
                                    onChange={(e) => handleRowChange(row.id, "qty", parseFloat(e.target.value))}
                                />
                            </td>
                            <td style={{ width: '7vw' }}>
                                <select
                                    className='estimateInput'
                                    value={row.unitTypeId}
                                    onChange={(e) => handleRowChange(row.id, "unitTypeId", e.target.value)}
                                >
                                    <option value="">Select Unit Type</option>
                                    {unitTypes.map((unit) => (
                                        <option key={unit.id} value={unit.id}>
                                            {unit.unit_type}
                                        </option>
                                    ))}
                                </select>
                            </td>
                            <td style={{ width: '8vw' }}>
                                <input
                                    className='estimateInput'
                                    type="number"
                                    value={row.unitPrice}
                                    onChange={(e) => handleRowChange(row.id, "unitPrice", parseFloat(e.target.value))}
                                />
                            </td>
                            <td style={{ width: '5vw' }}>
                                <input
                                    className='estimateInput'
                                    type="number"
                                    value={row.discount}
                                    onChange={(e) => handleRowChange(row.id, "discount", parseFloat(e.target.value))}
                                />
                            </td>
                            <td style={{ width: '9vw' }}>
                                <TaxDropdown row={row} />
                            </td>
                            <td style={{ width: '8vw' }}>{row.taxAmount.toFixed(2)}</td>
                            <td style={{ width: '8vw' }}>{row.totalAmount.toFixed(2)}</td>
                            <td style={{ width: '4vw' }}>
                                <button onClick={() => deleteRow(row.id)}>➖</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="estimate-summary">
                <div>
                    <label>Total Tax :</label> {totalTax.toFixed(2)}
                </div>
                <div>
                    <label>Total Amount :</label> {totalAmount.toFixed(2)}
                </div>
            </div>


            <div className="note-section" style={{ marginTop: '20px' }}>
                <label>Terms and Conditions :</label>
                <textarea
                    className='expenseInput'
                    rows="4"
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    placeholder="Type here..."
                    style={{ width: '100%' }} // Adjust width as needed
                />
            </div>


            <div style={{ display: 'flex', justifyContent: 'end', marginTop: '40px', gap: '20px' }}>
                <button type="button" className='color-button' onClick={handleCancel}>
                    Cancel
                </button>
                <button type="button" className='color-button' onClick={handleSave} disabled={isLoading}>
                    {isLoading ? <CircularProgress size={20} color="inherit" /> : 'Save'}
                </button>
            </div>
        </div>
    );
}

export default EditEstimate;