import React from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Legend, LabelList } from "recharts";

const LeadReport = ({ reportData }) => {
    const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#FF5733", "#FFC300"];

    // Lead Source Data (e.g., Email, Google, Referral, TV, Direct, WhatsApp, etc.)

    // const leadSourceData = reportData.lead_source_counts || [];
    const leadSourceData = reportData.leads || [];

    // Lead Status Data
    const leadStatusData = [
        { name: "Total Leads", value: reportData.total_leads || 0 },
        { name: "New", value: reportData.new_leads || 0 },
        { name: "Qualified", value: reportData.qualified_leads || 0 },
        { name: "Closed", value: reportData.closed_leads || 0 },
        { name: "Lost", value: reportData.lost_leads || 0 },
    ];

    // Lead Conversion Data
    const leadConversionData = [
        { name: "Total Leads", value: reportData.total_leads || 0 },
        { name: "Converted Leads", value: reportData.converted_leads || 0 },
        { name: "Lost Leads", value: reportData.lost_leads || 0 },
        { name: "Pending Leads", value: reportData.pending_leads || 0 },
    ];

    return (
        <Grid container spacing={3} p={3}>
            {/* Lead Source Report (Pie Chart) */}
            {/* <Grid item xs={12} md={6}>
                <Card sx={{ boxShadow: 3 }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Lead Source Report
                        </Typography>
                        <ResponsiveContainer width="100%" height={300}>
                            <PieChart>
                                <Pie
                                    data={leadSourceData}
                                    dataKey="total" // Assuming the API returns `total` for each source
                                    nameKey="source_name" // Assuming the API returns `source_name` for each source
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={100}
                                    label
                                >
                                    {leadSourceData.map((_, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                            </PieChart>
                        </ResponsiveContainer>
                    </CardContent>
                </Card>
            </Grid> */}

            <Grid item xs={12} md={6}>
                <Card sx={{ boxShadow: 3 }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Lead Source Report
                        </Typography>
                        <ResponsiveContainer width="100%" height={300}>
                            <PieChart>
                                <Pie
                                    data={leadSourceData}
                                    dataKey="value"
                                    nameKey="name"
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={100}
                                    label
                                >
                                    {leadSourceData.map((_, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                            </PieChart>
                        </ResponsiveContainer>
                    </CardContent>
                </Card>
            </Grid>


            {/* Lead Status Report (Bar Chart) */}
            <Grid item xs={12} md={6}>
                <Card sx={{ boxShadow: 3 }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Lead Status Report
                        </Typography>
                        <ResponsiveContainer width="100%" height={300}>
                            <BarChart data={leadStatusData}>
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="value" fill="#8884d8">
                                    <LabelList dataKey="value" position="top" />
                                </Bar>
                            </BarChart>
                        </ResponsiveContainer>
                    </CardContent>
                </Card>
            </Grid>

            {/* Lead Conversion Report (Bar Chart) */}
            <Grid item xs={12} md={6}>
                <Card sx={{ boxShadow: 3 }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Lead Conversion Report
                        </Typography>
                        <ResponsiveContainer width="100%" height={300}>
                            <BarChart data={leadConversionData}>
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="value" fill="#82ca9d">
                                    <LabelList dataKey="value" position="top" />
                                </Bar>
                            </BarChart>
                        </ResponsiveContainer>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default LeadReport;