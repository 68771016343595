import { useEffect, useState } from 'react';
import { Chrono } from 'react-chrono'; // Only this import is necessary
import Swal from 'sweetalert2';
import "./FollowUpModal.css";


const FollowUpModal = ({ isOpen, onClose, dealId }) => {
  const [followUpDetails, setFollowUpDetails] = useState([]);
  const [remark, setRemark] = useState('');
  const [loading, setLoading] = useState(false);
  const [nextFollowUpDate, setNextFollowUpDate] = useState('');
  const [nextFollowUpTime, setNextFollowUpTime] = useState('');
  const [sendReminder, setSendReminder] = useState(false);
  const [remindTime, setRemindTime] = useState('');
  const [remindType, setRemindType] = useState('');
  const [errors, setErrors] = useState({
    nextFollowUpDate: '',
    nextFollowUpTime: '',
  });

  const [isVisible, setIsVisible] = useState(false);

  // Add this useEffect for smooth transitions
  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [isOpen]);



  // Add or remove a class to the body element to control the scrollbar
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    // Cleanup function to remove the class when the component unmounts
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isOpen]);


  const resetFields = () => {
    setRemark('');
    setNextFollowUpDate('');
    setNextFollowUpTime('');
    setSendReminder(false);
    setRemindTime('');
    setRemindType('');
  };

  const validateForm = () => {
    const newErrors = {
      nextFollowUpDate: !nextFollowUpDate ? 'Next Follow-Up Date is required' : '',
      nextFollowUpTime: !nextFollowUpTime ? 'Time is required' : '',
    };
    setErrors(newErrors);

    // Return true if no errors
    return !newErrors.nextFollowUpDate && !newErrors.nextFollowUpTime;
  };



  const fetchFollowUpDetails = async () => {
    if (!dealId) return;
    setLoading(true); // Show loading state
    setFollowUpDetails([]);
    try {
      const token = localStorage.getItem('authToken');
      const response = await fetch(`https://api.myntask.com/leads/follow_up_list/${dealId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });


      if (!response.ok) {
        throw new Error('Failed to fetch follow-up details');
      }


      const data = await response.json();
      setFollowUpDetails(data);
    } catch (error) {
      console.error('Error fetching follow-up details:', error);
    } finally {
      setLoading(false); // Remove loading state
    }
  };


  useEffect(() => {
    if (isOpen && dealId) {
      fetchFollowUpDetails(); // Initial fetch when the modal opens
    }
  }, [isOpen, dealId]);


  if (!isOpen) {
    return null;
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("deal Id :", dealId);

    if (!validateForm()) {
      return; // Stop if validation fails
    }

    // Combine date and time into a single string
    const nextFollowUpDateTime = `${nextFollowUpDate} ${nextFollowUpTime}`;

    const payload = {
      deal_id: dealId,
      remark,
      next_follow_up_date: nextFollowUpDateTime,
      send_reminder: sendReminder ? 1 : null,
      remind_time: sendReminder ? parseInt(remindTime) : null,
      remind_type: sendReminder ? remindType : null,
    };
    console.log("Data :", payload);

    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        Swal.fire({
          icon: 'error',
          title: 'Authentication Error',
          text: 'Please log in again.',
          timer: 1500,
          showConfirmButton: false,
        });
        return;
      }

      const response = await fetch('https://api.myntask.com/leads/add_follow_up', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      // First, check if the response status is OK
      if (!response.ok) {
        throw new Error(`HTTP Error: ${response.status}`);
      }

      const responseData = await response.json(); // Parse the response JSON
      console.log("API Response:", responseData); // Log response

      // Check for specific SQL errors in response
      if (responseData.code === 'WARN_DATA_TRUNCATED') {
        throw new Error('Reminder Type is Required');
      }

      // If no errors, show success message
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Follow-up added successfully!',
        timer: 1500,
        showConfirmButton: false,
      });

      await fetchFollowUpDetails();
      resetFields();

    } catch (error) {
      // Show error alert when something goes wrong
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message || 'Failed to add follow-up. Please try again.',
        timer: 2500,
        showConfirmButton: true,
      });
      console.error('Error adding follow-up:', error);
    }
  };



  const timelineItems = followUpDetails.map((followUp) => {
    const createdAt = new Date(followUp.created_at);

    // Extracting the next follow-up date and time
    const nextFollowUpDateTime = new Date(followUp.next_follow_up_date);
    const nextFollowUpDate = nextFollowUpDateTime.toLocaleDateString('en-GB'); // Format date
    const nextFollowUpTime = nextFollowUpDateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }); // Format time

    return {
      title: createdAt.toLocaleDateString(), // Date
      cardTitle: (
        <div>
          <div style={{ fontWeight: 'bold' }}>{nextFollowUpDate}</div> {/* Date in bold */}
          <div style={{ color: 'gray', fontSize: '0.8rem' }}>{nextFollowUpTime}</div> {/* Time in gray and smaller font */}
        </div>
      ),
      cardDetailedText: followUp.remark, // Remark
    };
  }).reverse(); // Reverse the array to show the latest follow-up at the top


  return (
    <div className={`follow-modal ${isVisible ? 'active' : ''}`}>
      <div className="follow-modal-content">
        <div className="top-controls" style={{
          position: 'sticky',
          top: '-25px',
          zIndex: '2',
          backgroundColor: 'white',
          padding: '20px',
          borderBottom: '1px solid #c4c4c4',

        }}>
          <h1 className="title">
            Follow-Up
            {/* <div className="underline"></div> */}
          </h1>
          <div className="buttons-row">
            <button className="color-button" onClick={onClose}>
              Close
            </button>
          </div>
        </div>
        <div>
          {timelineItems.length > 0 ? (
            <div style={{ height: '400px', overflowY: 'auto' }}>
              <Chrono
                items={timelineItems}
                mode="VERTICAL"  // Change mode to vertical
                theme={{
                  primary: '#4cd964',
                  secondary: '#b1dff0',
                  cardBgColor: '#dec8c8',
                  titleColor: '#333',
                }}
                cardWidth={300}
                cardHeight={40}  // Adjust the height if needed
                scrollable
                hideControls
              />
            </div>
          ) : (
            <p className="no-data-message">No follow-up details available.</p>
          )}
        </div>
        {/* <div className="underline"></div> */}
        <hr className="divider" />
        <form >
          <div style={{ marginBottom: '10px' }}>
            <label style={labelStyle}>Remark</label>
            <textarea
              value={remark}
              onChange={(e) => setRemark(e.target.value)}
              required
              placeholder="Enter your remark"
              style={inputStyle}
              className='input-field'
            ></textarea>
          </div>

          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '5%'
          }}>
            <div style={{ marginBottom: '10px', width: '50%' }}>
              <label style={labelStyle}>Next Follow-Up Date <span className="required">*</span></label>
              <input
                type="date"
                value={nextFollowUpDate}
                onChange={(e) => {
                  setNextFollowUpDate(e.target.value);
                  setErrors(prev => ({ ...prev, nextFollowUpDate: '' })); // Clear error when typing
                }}
                required
                style={{
                  width: '100%',
                  padding: '10px',
                  borderRadius: '5px',
                  border: errors.nextFollowUpDate ? '1px solid red' : '1px solid #ccc',
                  boxSizing: 'border-box'
                }}
              />
              {errors.nextFollowUpDate && (
                <p style={{ color: 'red', fontSize: '0.8rem', marginTop: '5px' }}>
                  {errors.nextFollowUpDate}
                </p>
              )}
            </div>

            {/* Time Input */}
            <div style={{ marginBottom: '10px', width: '50%' }}>
              <label style={labelStyle}>Start Time <span className="required">*</span></label>
              <input
                type="time"
                value={nextFollowUpTime}
                onChange={(e) => {
                  setNextFollowUpTime(e.target.value);
                  setErrors(prev => ({ ...prev, nextFollowUpTime: '' })); // Clear error when typing
                }}
                required
                style={{
                  width: '100%',
                  padding: '10px',
                  borderRadius: '5px',
                  border: errors.nextFollowUpTime ? '1px solid red' : '1px solid #ccc',
                  boxSizing: 'border-box'
                }}
              />
              {errors.nextFollowUpTime && (
                <p style={{ color: 'red', fontSize: '0.8rem', marginTop: '5px' }}>
                  {errors.nextFollowUpTime}
                </p>
              )}
            </div>
          </div>

          <div className='send-reminder'>
            <label className='labelName'>Send Reminder</label>
            <input
              type="checkbox"
              id='sendReminder'
              checked={sendReminder}
              onChange={() => setSendReminder((prev) => !prev)}
            />
          </div>
          {sendReminder && (
            <>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '5%'
              }}>
                <div style={{
                  width: '50%'
                }}>
                  <label style={labelStyle}>Remind Before</label>
                  <input
                    type="number"
                    value={remindTime}
                    onChange={(e) => setRemindTime(e.target.value)}

                    placeholder="e.g., 2"
                    style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc', boxSizing: 'border-box' }}
                  />
                </div>
                <div style={{
                  width: '50%',
                }}>
                  <label style={labelStyle}>Reminder Type<span className="required">*</span></label>
                  <select
                    value={remindType}
                    onChange={(e) => setRemindType(e.target.value)}
                    required
                    style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc', boxSizing: 'border-box' }}
                  >
                    <option value="">Select Type</option>
                    <option value="hour">Hour</option>
                    <option value="day">Day</option>
                  </select>
                </div>
              </div>
            </>
          )}
          <div className="modal-actions">
            <button className='color-button' onClick={handleSubmit}>Save</button>
          </div>
        </form>


      </div>
    </div>
  );
};


export default FollowUpModal;




const labelStyle = {
  fontweight: 'bold',
  marginTop: '10px',
  marginBottom: '10px',
  color: '#555',
}
const inputStyle = {
  width: '100%',
  padding: '10px',
  border: '1px solid #ccc',
  borderRadius: '4px',
  boxSizing: 'border-box',
};






