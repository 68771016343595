import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; // For fetching the ticket ID from the URL
import "./TicketView.css"; // For styling

const TicketView = () => {
    const { ticketId } = useParams(); // Get the ticket ID from the URL
    const [ticket, setTicket] = useState(null); // State to store ticket data
    const [loading, setLoading] = useState(true); // State to handle loading
    const [error, setError] = useState(null); // State to handle errors
    const [isChatModalOpen, setIsChatModalOpen] = useState(false); // State to manage chat modal
    const [messages, setMessages] = useState([]); // State to store chat messages
    const [newMessage, setNewMessage] = useState(""); // State to store the new message input

    const currentUser = localStorage.getItem("currentUser");

    // Fetch ticket data from the API
    useEffect(() => {
        const fetchTicket = async () => {
            try {
                const token = localStorage.getItem("authToken"); // Assuming you use token-based authentication
                const response = await fetch(
                    `https://api.myntask.com/ticket/view_ticket/${ticketId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                    }
                );

                if (!response.ok) {
                    throw new Error("Failed to fetch ticket details");
                }

                const data = await response.json();
                setTicket(data[0]); // Set the fetched data to state (API returns an array with one object)
            } catch (error) {
                setError(error.message); // Set error message if something goes wrong
            } finally {
                setLoading(false); // Stop loading
            }
        };

        fetchTicket();
    }, [ticketId]);

    // Fetch chat messages when the modal is opened
    const fetchChatMessages = async () => {
        try {
            const token = localStorage.getItem("authToken");
            const response = await fetch(
                `https://api.myntask.com/ticket/message_list/${ticketId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            if (!response.ok) {
                throw new Error("Failed to fetch chat messages");
            }

            const data = await response.json();
            setMessages(data); // Set the fetched messages to state
        } catch (error) {
            console.error("Error fetching chat messages:", error);
        }
    };

    // Send a new message
    const sendMessage = async () => {
        if (!newMessage.trim()) return; // Don't send empty messages

        try {
            const token = localStorage.getItem("authToken");
            const response = await fetch("https://api.myntask.com/ticket/message_send", {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    ticket_id: ticketId,
                    message: newMessage,
                }),
            });

            if (!response.ok) {
                throw new Error("Failed to send message");
            }

            // Clear the input field
            setNewMessage("");

            // Fetch updated messages
            await fetchChatMessages();
        } catch (error) {
            console.error("Error sending message:", error);
        }
    };

    // Open chat modal and fetch messages
    const openChatModal = async () => {
        setIsChatModalOpen(true);
        await fetchChatMessages();
    };

    // Close chat modal
    const closeChatModal = () => {
        setIsChatModalOpen(false);
    };

    // Display loading state
    if (loading) {
        return <div className="loading">Loading ticket details...</div>;
    }

    // Display error state
    if (error) {
        return <div className="error">Error: {error}</div>;
    }

    // Display ticket data
    return (
        <div className="main-container">
            <div className="top-controls">
                <h1>Ticket Details</h1>
                {/* Chat Button */}
                <div className="buttons-row">
                    <button className="color-button" onClick={openChatModal}>
                        Chat
                    </button>
                </div>
            </div>
            {ticket ? (
                <div className="ticket-details">
                    <div className="detail-row">
                        <span className="detail-label">Ticket ID:</span>
                        <span className="detail-value">{ticket.ticket_id}</span>
                    </div>
                    <div className="detail-row">
                        <span className="detail-label">Subject:</span>
                        <span className="detail-value">{ticket.subject}</span>
                    </div>
                    <div className="detail-row">
                        <span className="detail-label">Description:</span>
                        <span className="detail-value">{ticket.description}</span>
                    </div>
                    <div className="detail-row">
                        <span className="detail-label">Status:</span>
                        <span className="detail-value">{ticket.status}</span>
                    </div>
                    <div className="detail-row">
                        <span className="detail-label">Date & Time:</span>
                        <span className="detail-value">
                            {new Date(ticket.datetime).toLocaleString()}
                        </span>
                    </div>
                    <div className="detail-row">
                        <span className="detail-label">Raised By:</span>
                        <span className="detail-value">{ticket.raised_by}</span>
                    </div>
                </div>
            ) : (
                <p>No ticket found.</p>
            )}



            {/* Chat Modal */}
            {isChatModalOpen && (
                <div className="chat-modal-overlay">
                    <div className="chat-modal">
                        <div className="chat-modal-header">
                            <h2>Chat</h2>
                            <button className="close-button" onClick={closeChatModal}>
                                &times;
                            </button>
                        </div>
                        <div className="chat-messages">
                            {messages.map((message) => (
                                <div
                                    key={message.id}
                                    className={`message ${message.raised_by === currentUser ? "current-user" : "other-user"
                                        }`}
                                >
                                    {message.raised_by !== currentUser && (
                                        <img
                                            src={message.user_image}
                                            alt={message.raised_by}
                                            className="user-image"
                                        />
                                    )}
                                    <div className="message-content">
                                        <div className="message-text">{message.message}</div>
                                        <div className="message-time">{message.time_ago}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="chat-input">
                            <input
                                type="text"
                                placeholder="Type a message..."
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                                onKeyPress={(e) => e.key === "Enter" && sendMessage()}
                            />
                            <button className="send-button" onClick={sendMessage}>
                                Send
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TicketView;