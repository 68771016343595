import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, LineChart, Line } from 'recharts';
import { useNavigate } from 'react-router-dom';
import { FaBell, FaCalendarAlt, FaMailBulk, FaUserPlus, FaClipboardList, FaTicketAlt, FaCalendar, FaFileContract, FaChartLine, FaFolderOpen } from 'react-icons/fa';
import './Dashboard.css';
import BannerRender from '../Components/BannerRender';
import ClockIn from '../Components/ClockIn';
import Button from '@mui/material/Button';
import DailyUpdate from '../Components/DailyUpdate';
import Nodata from '../assets/undraw.png';
import Norecord from '../assets/noRecord.png';
import { Vortex } from 'react-loader-spinner';
import { Box, Stack } from '@mui/material';




const Dashboard = () => {
  const navigate = useNavigate();
  // Declare state variables
  const [userProfile, setUserProfile] = useState({});
  const [taskCount, setTaskCount] = useState({ overdue: 0, pending: 0 });
  const [projectCount, setProjectCount] = useState({ in_progress: 0, overdue: 0 });
  const [leaveToday, setLeaveToday] = useState([]);
  const [todayJoinings, setTodayJoinings] = useState('');
  const [notice, setNotice] = useState([]);
  const [taskList, setTaskList] = useState([]);
  const [expenseData, setExpenseData] = useState([]);
  const [probationData, setProbationData] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [openDailyUpdate, setOpenDailyUpdate] = useState(false);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [taskPoints, setTaskPoints] = useState(null); // State to store task points
  const [darkMode, setDarkMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [projectOverview, setProjectOverview] = useState({
    open: 0,
    completed: 0,
    hold: 0,
    overdue: 0,
    progression: 0,
  });



  useEffect(() => {
    const fetchProjectOverview = async () => {
      const token = localStorage.getItem("authToken");
      try {
        const response = await fetch("https://api.myntask.com/project/overview", {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();
        console.log("Fetched Project Overview Data:", data);

        // Update state with the fetched data
        setProjectOverview({
          open: data.open || 0,
          completed: data.completed || 0,
          hold: data.hold || 0,
          overdue: data.overdue || 0,
          progression: data.progression || 0,
        });
      } catch (error) {
        console.error("Error fetching project overview:", error);
      }
    };

    fetchProjectOverview();
  }, []);



  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };




  // Define colors for charts
  const COLORS = [
    '#4CAF50', // Green for 'paid'
    '#F44336', // Red for 'rejected'
    '#2196F3', // Blue for 'approved'
    '#FFC107', // Yellow for 'pending'
    '#8884d8', // Default color for other statuses
    '#FF9F40',  // Optional additional color (you can replace this if needed)
    '#f74572'
  ];




  useEffect(() => {
    const modalShown = localStorage.getItem('modalShown');
    if (!modalShown) {
      setShowWelcomeModal(true);
      localStorage.setItem('modalShown', 'true'); // Mark modal as shown
    }
  }, []);
  // Load data from localStorage or API on component mount
  useEffect(() => {
    const storedProfile = JSON.parse(localStorage.getItem('userProfile')) || {};
    const storedTaskCount = JSON.parse(localStorage.getItem('taskCount')) || { overdue: 0, pending: 0 };
    const storedProjectCount = JSON.parse(localStorage.getItem('projectCount')) || { in_progress: 0, overdue: 0 };
    const storedLeaveToday = JSON.parse(localStorage.getItem('leaveToday')) || [];
    const storedTodayJoinings = localStorage.getItem('todayJoinings') || '';
    const storedNotices = JSON.parse(localStorage.getItem('notice')) || [];
    const storedTasks = JSON.parse(localStorage.getItem('taskList')) || [];
    const storedExpenseData = JSON.parse(localStorage.getItem('expenses')) || [];
    const storedProbationData = JSON.parse(localStorage.getItem('probation')) || [];
    const storedTickets = JSON.parse(localStorage.getItem('tickets')) || [];
    // Set state with the fetched data
    setUserProfile(storedProfile);
    setTaskCount(storedTaskCount);
    setProjectCount(storedProjectCount);
    setLeaveToday(storedLeaveToday);
    setTodayJoinings(storedTodayJoinings);
    setNotice(storedNotices);
    setTaskList(storedTasks);
    setExpenseData(storedExpenseData);
    setProbationData(storedProbationData);
    setTickets(storedTickets);
    setIsLoading(false);


    console.log('tickets are', tickets);
  }, []);


  const fetchTaskPoints = async () => {
    const token = localStorage.getItem("authToken");

    try {
      const response = await fetch("https://api.myntask.com/home/task_point", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();
      console.log("Fetched Task Points Data:", data);

      if (Array.isArray(data) && data.length > 0) {
        setTaskPoints(data[0]); // Extract the first object from the array
      } else {
        setTaskPoints(null);
      }
    } catch (error) {
      console.error("Error fetching task points:", error);
      setTaskPoints(null);
    }
  };


  useEffect(() => {
    fetchTaskPoints();
  }, []);


  useEffect(() => {
    // Prevent going back to login page after logging in
    const handlePopState = (event) => {
      navigate('/dashboard'); // Or redirect to the dashboard or a suitable page
    };
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);




  // Prepare data for PieChart
  const pieData = [
    { name: 'Overdue', value: taskCount.overdue, color: '#f52257' },
    { name: 'Pending', value: taskCount.pending, color: COLORS[3] },
    { name: 'Completed ', value: taskCount.completed, color: COLORS[4] },
    { name: 'In Progress', value: taskCount.in_progress, color: COLORS[0] },
    { name: 'Hold', value: taskCount.hold, color: COLORS[5] },
    // { name: 'Projects Overdue', value: projectCount.overdue, color: COLORS[1] },
    // { name: 'Projects In Progress', value: projectCount.in_progress, color: COLORS[2] },
  ];
  // Check if all pieData values are 0
  const allZero = pieData.every(item => item.value === 0);
  const handleCloseDailyUpdate = () => {
    setOpenDailyUpdate(false);
  };
  const handleCloseModal = () => {
    setShowWelcomeModal(false); // Set the modal visibility to false
  };




  const handleClickOpenDailyUpdate = () => {
    setOpenDailyUpdate(true);
  };
  // Handlers for navigation
  const handleProjectClick = () => navigate('/project');
  const handleMyDataClick = () => navigate('/profileData');




  const statusColors = {
    Incomplete: 'red',
    'To Do': 'orange',
    Doing: 'blue',
    Completed: 'green',
  };
  const handleTaskClick = () => {
    navigate('/task');
  };
  const toTitleCase = (str) => {
    return str
      .toLowerCase() // Convert to lowercase
      .split(' ') // Split the string into words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(' '); // Join the words back into a string
  };
  const totalProjects = projectCount.in_progress + projectCount.overdue;
  const progressPercentage = (projectCount.in_progress / totalProjects) * 100 || 0;
  const totalTasks = taskCount.overdue + taskCount.pending;


  const formatNumber = (num) => {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + 'M'; // Format as millions
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + 'K'; // Format as thousands
    }
    return num; // Return the number as is if less than 1000
  };

  const formatYAxisLabel = (value) => {
    if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + 'M'; // Format as millions
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + 'K'; // Format as thousands
    }
    return value; // Return the number as is if less than 1000
  };



  //..........Loading Spinner ..........................................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }


  // if (error) {
  //   return (
  //     <Typography
  //       color="error"
  //       sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
  //     >
  //       {error}
  //     </Typography>
  //   );
  // }



  return (
    <div
      className={`newdashboard-container ${darkMode ? 'dark-mode' : ''}`}
      style={{
        backgroundColor: darkMode ? '#121212' : '#f5f5f5',
        color: darkMode ? '#e0e0e0' : '#000',
      }}
    >
      {/* Daily Update Modal Component */}
      <div className="flex-row-container">
        <BannerRender />
        <div style={{ display: 'flex' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickOpenDailyUpdate}
            sx={{
              backgroundColor: 'white',
              color: 'black',
              padding: '4px 10px',
              fontSize: { xs: '10px', sm: '12px', md: '14px' },
              minWidth: '120px', // Set a minimum width for the button
              whiteSpace: 'nowrap', // Prevent text from wrapping
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              border: '1px solid #ccc',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: '#f0f0f0',
                boxShadow: 'none',
              },
              marginRight: '10px'
            }}
          >
            + Daily Update
          </Button>
          <ClockIn />
        </div>
        {/* <Button
          variant="contained"
          onClick={toggleDarkMode}
          sx={{
            backgroundColor: darkMode ? '#333' : '#f5f5f5',
            color: darkMode ? '#fff' : '#000',
            padding: '4px 10px',
            fontSize: '12px',
            border: '1px solid #ccc',
            boxShadow: 'none',
            width: 120,
            height: 50,
            '&:hover': {
              backgroundColor: darkMode ? '#444' : '#e0e0e0',
              boxShadow: 'none',
            },
          }}
        >
          {darkMode ? 'Light Mode' : 'Dark Mode'}
        </Button> */}
      </div>




      <DailyUpdate
        open={openDailyUpdate}
        handleClose={handleCloseDailyUpdate}
        userProfile={userProfile}
      />



      {/* First row with 4 containers */}
      {/* First row with 4 containers */}
      <div className="newdashboard-row">
        <div className="newdashboard-card newdashboard-card-wide newdashboard-first-row-card" onClick={handleMyDataClick}>
          <div>
            <img src={userProfile.image} alt="User" className="newdashboard-user-avatar" />
            <h3 className='newdashboard-first-row-card-heading'>{userProfile.user_name}</h3>
          </div>
          <div className='newdashboard-card-detail'>
            <div style={{ textAlign: 'right' }}>
              <p>My Projects : </p>
              <p>My Tasks : </p>
            </div>
            <div>
              <p> <span>{userProfile.projects}</span></p>
              <p> <span>{userProfile.open_task}</span></p>
            </div>
          </div>
        </div>



        <div className="newdashboard-card newdashboard-card-wide newdashboard-first-row-card" onClick={handleProjectClick}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FaMailBulk className="newdashboard-icon-background" style={{ fontSize: '30px' }} />
            <h3 className='newdashboard-first-row-card-heading' >Projects</h3>
          </div>
          {/* Flexbox Container for Progress Bar and Details */}
          <div className='newdashboard-card-detail'>
            <div style={{ textAlign: 'right' }}>
              <p>On Point : </p>
              <p>Overdue : </p>
            </div>
            <div>
              <p><span>{projectCount.in_progress - projectCount.overdue}</span></p>
              <p><span className="newdashboard-overdue">{projectCount.overdue}</span></p>
            </div>
          </div>
        </div>



        <div className="newdashboard-card newdashboard-card-wide newdashboard-first-row-card" onClick={handleTaskClick}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FaCalendar className="newdashboard-icon-background" style={{ fontSize: '30px' }} />
            <h3 className='newdashboard-first-row-card-heading'>Tasks</h3>
          </div>
          <div className='newdashboard-card-detail'>
            {/* Project details */}
            <div style={{ textAlign: 'right' }}>
              <p>On Point:</p>
              <p>Overdue :</p>
            </div>
            <div>
            <p>  <span style={{ color: 'orange' }}>{taskCount.pending - taskCount.overdue}</span></p>
              <p> <span style={{ color: 'red' }}>{taskCount.overdue}</span></p>
            </div>

          </div>
        </div>


        <div className="newdashboard-card newdashboard-card-wide newdashboard-first-row-card">
          <div style={{ display: "flex", alignItems: "center" }}>
            <FaUserPlus className="newdashboard-icon-background" style={{ fontSize: "30px" }} />
            <h3 className="newdashboard-first-row-card-heading">Task Points</h3>
          </div>
          <div className="newdashboard-card-detail">
            {taskPoints ? (
              <>
                <div style={{ textAlign: "right" }}>
                  <p>
                    Total Points :
                  </p>
                  <p>
                    Earned Points :
                  </p>
                </div>
                <div>
                  <p> <span style={{ color: "#3b82f6" }}>{taskPoints.earned_task_points || 0}</span></p>
                  <p> <span>{taskPoints.total_task_points || 0}</span></p>
                </div>
              </>
            ) : (
              <p>No task points record available.</p>
            )}
          </div>
        </div>
      </div>




      <div className="newdashboard-row">
        {/* Expense Details */}
        <div className="newdashboard-card">
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '10px' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <FaChartLine className="newdashboard-icon newdashboard-icon-background" />
              <h3 className='newdashboard-card-heading'>Expense Details</h3>
            </div>
            <button
              onClick={() => navigate('/expense')}
              className="newdashboard-view-details-btn"
            >
              View Details
            </button>
          </div>
          {expenseData && expenseData.length > 0 ? (
            <ResponsiveContainer width="100%" height={280}>
              <BarChart
                data={expenseData}
                margin={{ top: 20, right: 20, left: 10, bottom: 10 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="status" tickFormatter={(value) => value.charAt(0).toUpperCase() + value.slice(1)} />
                <YAxis tickFormatter={formatYAxisLabel} />
                <Tooltip formatter={(value) => [formatNumber(value), 'Total Price']} />
                <Legend />
                <Bar dataKey="total_price" name="Total Price" fill="#36A2EB">
                  {expenseData.map((entry, index) => {
                    let fillColor;
                    switch (entry.status) {
                      case 'paid':
                        fillColor = '#4CAF50';
                        break;
                      case 'rejected':
                        fillColor = '#F44336';
                        break;
                      case 'approved':
                        fillColor = '#2196F3';
                        break;
                      case 'pending':
                        fillColor = '#FFC107';
                        break;
                      default:
                        fillColor = '#8884d8';
                    }
                    return <Cell key={`cell-${index}`} fill={fillColor} />;
                  })}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '280px' }}>
              <img src={Nodata} alt="No Data Available" style={{ maxWidth: '100%', maxHeight: '100%' }} />
            </div>
          )}

        </div>
        {/* Line Chart */}
        <div className="newdashboard-card">
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <FaChartLine className="newdashboard-icon newdashboard-icon-background" />
            <h3 className="newdashboard-card-heading">Trend Analysis</h3>
          </div>
          {expenseData && expenseData.length > 0 ? (
            <ResponsiveContainer width="100%" height={280}>
              <LineChart
                data={expenseData}
                margin={{ top: 20, right: 20, left: 10, bottom: 10 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="status" tickFormatter={(value) => value.charAt(0).toUpperCase() + value.slice(1)} />
                <YAxis tickFormatter={formatYAxisLabel} />
                <Tooltip formatter={(value, name) => [value, toTitleCase(name)]}
                  labelFormatter={(label) => toTitleCase(label)}
                />
                <Legend />
                <Line type="monotone" dataKey="total_price" name="Total Price" stroke="#FF6347" strokeWidth={2} />
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '280px' }}>
              <img src={Nodata} alt="No Data Available" style={{ maxWidth: '100%', maxHeight: '100%' }} />
            </div>
          )}
        </div>
        {/* Overview */}
        <div className="newdashboard-card">
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <FaFolderOpen className="newdashboard-icon newdashboard-icon-background" />
            <h3 className="newdashboard-card-heading">Task Overview</h3>
          </div>
          {!allZero ? (
            <ResponsiveContainer width="100%" height={280}>
              <PieChart>
                <Pie data={pieData}
                  dataKey="value"
                  outerRadius={75}
                  fill="#8884d8"
                  label={({ name, value }) => (value > 0 ? `${name}: ${value}` : null)}// Add labels to the pie chart
                  labelLine={false} // Hide the label lines
                >
                  {pieData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
                <Tooltip
                  wrapperStyle={{ zIndex: 1000 }}
                  contentStyle={{ backgroundColor: '#fff', border: '1px solid #ccc', fontSize: '12px', height: '40px' }}
                />
                <Legend layout="horizontal" align="center" verticalAlign="bottom" />
              </PieChart>
            </ResponsiveContainer>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '280px' }}>
              <img src={Nodata} alt="No Data Available" style={{ maxWidth: '100%', maxHeight: '100%' }} />
            </div>
          )}
        </div>

      </div>



      {/* Third row with Notices and Task List */}
      <div className="newdashboard-row">
        <div className="newdashboard-card">
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '10px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FaBell className="newdashboard-icon newdashboard-icon-background" />
              <h3 className='newdashboard-card-heading'>Notice</h3>
            </div>
            <button onClick={() => navigate('/notice-board')} className="newdashboard-view-details-btn">
              View Details
            </button>
          </div>
          <ul className="newdashboard-notice-list">
            {notice.length > 0 ? (
              notice.map((item, index) => (
                <li key={index}>
                  <span>{item.heading}</span>
                  <div className="newdashboard-notice-date">
                    <FaCalendarAlt className="newdashboard-calendar-icon" />
                    <span>{item.date}</span>
                  </div>
                </li>
              ))
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '150px' }}>
                <img src={Norecord} alt="No Record Available" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                <p style={{ fontSize: '16px', color: '#666' }}>-- No data available --</p>
              </div>
            )}
          </ul>
        </div>
        <div className="newdashboard-card newdashboard-task-list-container">
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '10px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FaFileContract className="newdashboard-icon newdashboard-icon-background" />
              <h3 className='newdashboard-card-heading'>My Open Task List</h3>
            </div>
            <button onClick={() => navigate('/task')} className="newdashboard-view-details-btn">
              View Details
            </button>
          </div>
          <ul className="newdashboard-task-list">
            {taskList.length > 0 ? (
              taskList.map((task, index) => (
                <li key={index}>
                  <span className='taskdata'>{toTitleCase(task.heading)}</span>
                  <div className='taskcontent'>
                    <span className='status' style={{ color: statusColors[task.status], width: '9vw', textAlign: 'left' }}>
                      {toTitleCase(task.status)}
                    </span>
                    <div className="newdashboard-notice-date">
                      <FaCalendarAlt className="newdashboard-calendar-icon" />
                      <span>{new Date(task.due_date).toLocaleDateString('en-GB')}</span>
                    </div>
                  </div>
                </li>
              ))
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '150px' }}>
                <img src={Norecord} alt="No Record Available" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                <p style={{ fontSize: '16px', color: '#666' }}>-- No data available --</p>
              </div>
            )}
          </ul>
        </div>
      </div>
      <div className="newdashboard-row">
        {/* Probation Data Container */}
        <div className="newdashboard-card">
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <FaClipboardList className="newdashboard-icon newdashboard-icon-background" />
            <h3 className='newdashboard-card-heading'>Probation Data</h3>
          </div>


          <ul className="newdashboard-notice-list">
            {probationData.length > 0 ? (
              probationData.map((item, index) => (
                <li key={index}>
                  <span>{item.name} ({item.department})</span>
                  <div className="newdashboard-notice-date">
                    <FaCalendarAlt className="newdashboard-calendar-icon" />
                    <span>{new Date(item.probation_end_date).toLocaleDateString('en-GB')}</span>
                  </div>
                </li>
              ))
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '150px' }}>
                <img src={Norecord} alt="No Record Available" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                <p style={{ fontSize: '16px', color: '#666' }}>-- No data available --</p>
              </div>
            )}
          </ul>
        </div>
        {/* Tickets Container */}
        <div className="newdashboard-card">
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '10px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FaTicketAlt className="newdashboard-icon newdashboard-icon-background" />
              <h3 className='newdashboard-card-heading'>Tickets</h3>
            </div>
            <button onClick={() => navigate('/tickets')} className="newdashboard-view-details-btn">
              View Details
            </button>
          </div>


          <ul className="newdashboard-notice-list" style={{ maxHeight: '150px', overflowY: tickets.length > 4 ? 'auto' : 'hidden', }}>
            {tickets.length > 0 ? (
              tickets.map((item, index) => ( // Limit to 4 tickets
                <li key={index}>
                  <span>{item.subject} ({item.status})</span>
                  <div className="newdashboard-notice-date">
                    <FaCalendarAlt className="newdashboard-calendar-icon" />
                    <span>{new Date(item.date).toLocaleDateString('en-GB')}</span>
                  </div>
                </li>
              ))
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '150px' }}>
                <img src={Norecord} alt="No Record Available" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                <p style={{ fontSize: '16px', color: '#666' }}>-- No data available --</p>
              </div>
            )}
          </ul>


        </div>
      </div>

      <div className="newdashboard-row">
        {/* Project Overview */}
        <div className="newdashboard-card">
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <FaFolderOpen className="newdashboard-icon newdashboard-icon-background" />
            <h3 className='newdashboard-card-heading'>Project Overview</h3>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-evenly', marginBottom: '10px' }}>
            {/* <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}> */}
            <div>
              <p style={{ fontSize: '18px', fontWeight: 'bold', color: '#01b393', textAlign: 'center' }}>{projectOverview.open}</p>
              <p style={{ fontSize: '14px', color: '#666', marginBottom: '5px' }}>Open</p>
            </div>
            <div>
              <p style={{ fontSize: '18px', fontWeight: 'bold', color: '#2196f3', textAlign: 'center' }}>{projectOverview.completed}</p>
              <p style={{ fontSize: '14px', color: '#666', marginBottom: '5px' }}>Completed</p>
            </div>
            <div>
              <p style={{ fontSize: '18px', fontWeight: 'bold', color: '#ff9800', textAlign: 'center' }}>{projectOverview.hold}</p>
              <p style={{ fontSize: '14px', color: '#666', marginBottom: '5px' }}>Hold</p>
            </div>
            <div>
              <p style={{ fontSize: '18px', fontWeight: 'bold', color: '#FD397A', textAlign: 'center' }}>{projectOverview.overdue}</p>
              <p style={{ fontSize: '14px', color: '#666', marginBottom: '5px' }}>Overdue</p>
            </div>
            {/* </div> */}
          </div>
          <div style={{ marginTop: '20px', marginBottom: '20px', textAlign: 'center', }}>
            <div style={{ width: '80%', backgroundColor: '#e0e0e0', borderRadius: '10px', height: '30px', overflow: 'hidden', marginLeft: '10%' }}>
              <p style={{ fontSize: '14px', color: '#666', marginBottom: '5px', marginTop: '5px' }}>Progression {projectOverview.progression}%</p>
              <div
                style={{
                  width: `${projectOverview.progression}%`,
                  backgroundColor: '#4CAF50',
                  borderRadius: '10px',
                  height: '100%',
                }}
              ></div>
            </div>
          </div>
        </div>


        <div className="newdashboard-card">

        </div>


      </div>

    </div>
  );
};




export default Dashboard;
const modalStyles = {
  container: {
    backgroundColor: '#fff',
    padding: '30px 20px',
    borderRadius: '12px',
    width: '90%',
    maxWidth: '600px', // Maximum width for responsiveness
    textAlign: 'left',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    overflowY: 'auto',
    maxHeight: '80vh', // Makes the modal scrollable if content overflows
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '18px',
    cursor: 'pointer',
  },
  imageRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    marginBottom: '20px',
  },
  image: {
    width: '35%', // Reduced image width
    height: 'auto',
    borderRadius: '8px',
  },
  title: {
    fontSize: '20px', // Smaller title font size
    fontWeight: 'bold',
    color: '#1976d2',
    marginBottom: '16px',
    textAlign: 'center',
  },
  instructionsList: {
    margin: '0',
    padding: '0 10px',
    fontSize: '12.5px', // Smaller font size for descriptions
    lineHeight: '1.3',
    listStyleType: 'disc',
  },
  subscribeButton: {
    backgroundColor: '#1976d2',
    color: '#fff',
    padding: '10px 20px',
    borderRadius: '8px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
    marginTop: '20px',
    width: '100%',
  },




};

