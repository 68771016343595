import React from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, LineChart, Line, CartesianGrid, Legend } from "recharts";

const DealReport = ({ reportData }) => {
    // Deal Finance Data
    const dealFinanceData = [
        { name: "Total Deal Value", value: reportData.total_deal_value || 0 },
        { name: "Total Win Amount", value: reportData.total_win_deal_amount || 0 },
        { name: "Total Lost Amount", value: reportData.total_lost_deal_amount || 0 },
    ];

    // Revenue Data (Month-wise)
    const revenueData = reportData.monthly_revenue || [];

    return (
        <Grid container spacing={3} p={3}>
            <Grid item xs={12} md={6}>
                <Card sx={{ boxShadow: 3 }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Deal Report
                        </Typography>
                        <ResponsiveContainer width="100%" height={300}>
                            <BarChart data={reportData.deals}>
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Bar dataKey="value" fill="#8884d8" />
                            </BarChart>
                        </ResponsiveContainer>
                    </CardContent>
                </Card>
            </Grid>

            {/* Deal Finance Report (Bar Chart) */}
            <Grid item xs={12} md={6}>
                <Card sx={{ boxShadow: 3 }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Deal Finance Report
                        </Typography>
                        <ResponsiveContainer width="100%" height={300}>
                            <BarChart data={dealFinanceData}>
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Bar dataKey="value" fill="#8884d8" />
                            </BarChart>
                        </ResponsiveContainer>
                    </CardContent>
                </Card>
            </Grid>

            {/* Revenue Graph (Month-wise) */}
            <Grid item xs={12}>
                <Card sx={{ boxShadow: 3 }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Month Wise Revenue Report
                        </Typography>
                        <ResponsiveContainer width="100%" height={300}>
                            <LineChart data={revenueData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="month" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="revenue" stroke="#82ca9d" activeDot={{ r: 8 }} />
                            </LineChart>
                        </ResponsiveContainer>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default DealReport;
