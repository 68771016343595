import React from 'react';

const CreditNote = () => {
  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh', // Full viewport height
    backgroundColor: '#f0f0f0', // Light grey background
    fontFamily: 'Arial, sans-serif',
  };

  const textStyle = {
    fontSize: '3rem',
    color: '#333', // Dark grey text
    textTransform: 'uppercase',
    letterSpacing: '2px',
  };

  return (
    <div style={containerStyle}>
      <h1 style={textStyle}>Coming Soon</h1>
    </div>
  );
};

export default CreditNote;
