import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import '../Components/ViewEstimate.css';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { formatDate } from "../Components/MainFunction";
import { useNavigate } from 'react-router-dom';

const ViewPurchaseOrder = () => {
    const { order_id } = useParams();
    const [PurchaseOrder, setPurchaseOrder] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isDownloading, setIsDownloading] = useState(false);
    const pdfRef = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPurchaseOrder = async () => {
            const token = localStorage.getItem("authToken");
            console.log('order Id:', order_id);
            try {
                const response = await fetch(`https://api.myntask.com/purchase_order/download/${order_id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data = await response.json();
                console.log('API Data:', data); // Debugging: Log the API data
                setPurchaseOrder(data);
                console.log('API Data:', PurchaseOrder);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching Proposal data:', error);
                setLoading(false);
            }
        };

        fetchPurchaseOrder();
    }, [order_id]);

    // Function to download PDF
    const downloadPDF = () => {
        setIsDownloading(true);
        const input = pdfRef.current;

        // Options for html2canvas to handle CORS
        const options = {
            scale: 2,
            useCORS: true, // This is important for external images
            allowTaint: true, // Allows cross-origin images to taint the canvas
            logging: true, // Helps debug if needed
        };

        html2canvas(input, options).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgWidth = 190;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
            pdf.save(`Purchase_${order_id}.pdf`);
        }).catch(error => {
            console.error('Error generating PDF:', error);
        }).finally(() => {
            setIsDownloading(false); // Stop loading whether successful or failed
        });
    };

    const handleBack = () => {
        navigate(-1);
    }


    if (loading) {
        return <div className="loading">Loading...</div>;
    }

    if (!PurchaseOrder) {
        return <div className="error">No data found</div>;
    }

    // Calculate tax amount and total amount
    const totalTaxAmount = PurchaseOrder.items.reduce((acc, item) => {
        return acc + (item.item_value - item.quantity * item.item_rate);
    }, 0);

    return (
        <div className="po-container">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button
                    onClick={downloadPDF}
                    className="color-button"
                    style={{ marginBottom: '10px' }}
                    disabled={isDownloading} // Disable button while loading
                >
                    {isDownloading ? (
                        <>
                            <span className="button-loader"></span> Downloading...
                        </>
                    ) : (
                        'Download'
                    )}
                </button>
                <button onClick={handleBack} className='color-button' style={{ marginBottom: '10px' }}>Back</button>
            </div>

            <div className="po-content" ref={pdfRef}>
                {/* Header Section */}
                <div className="po-header" >
                    <div className="header-content">
                        <div className="logo-container">
                            {PurchaseOrder.company_data.logo ? (
                                <img
                                    src={PurchaseOrder.company_data.logo}
                                    alt="Company Logo"
                                    className="company1-logo"
                                />
                            ) : (
                                <div className="no-logo">No Image</div>
                            )}
                        </div>
                        <h1 style={{ textAlign: 'right' }}>PURCHASE ORDER</h1>
                    </div>
                </div>


                {/* Supplier Information */}
                {/* Supplier Information - Updated Version */}
                <div className="header-section">
                    {/* Company Info and Proposal Details */}
                    <table className="header-table">
                        <tbody>
                            <tr>
                                {/* Company Info Column */}
                                <td className="company-info">
                                    <div className="company_name">{PurchaseOrder.company_data.company_name || 'N/A'}</div>
                                    <div className="company_address">{PurchaseOrder.company_data.address || 'N/A'}</div>
                                    <div className="company-details">
                                        <span>GSTIN : {PurchaseOrder.company_data.gst_number || 'N/A'}</span>
                                        <span>Phone no : {PurchaseOrder.company_data.company_phone || 'N/A'}</span>
                                        <span>Email : {PurchaseOrder.company_data.company_email || 'N/A'}</span>
                                    </div>
                                </td>

                                {/* Proposal Details Column */}
                                <td className="proposal-details">
                                    <div className="proposal-header-row">
                                        <div className="proposal-number">
                                            <span>Purchase order NO.</span>
                                            <strong>{PurchaseOrder.purchase_data.purchase_order_number || 'N/A'}</strong>
                                        </div>
                                        <div className="proposal-date">
                                            <span>Status:</span>
                                            <strong style={{ color: 'blue' }}>{PurchaseOrder.purchase_data.purchase_status || 'N/A'}</strong>
                                            {/* <span>Valid Till</span>
                                            <strong>{formatDate(PurchaseOrder.purchase_data.expected_delivery_date || 'N/A')}</strong> */}
                                        </div>
                                    </div>
                                    <div className="proposal-header-row">
                                        <div className="proposal-number">
                                            <span>Order Date</span>
                                            <strong>{formatDate(PurchaseOrder.purchase_data.purchase_date || 'N/A')}</strong>
                                        </div>
                                        <div className="proposal-date">
                                            <span>Expected Delivery Date</span>
                                            <strong>{formatDate(PurchaseOrder.purchase_data.expected_delivery_date || 'N/A')}</strong>
                                        </div>

                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {/* Bill To and Ship To */}
                <div className="bill-ship-section">
                    <div className="bill-to">
                        <h3>From : </h3>
                        <p>{PurchaseOrder.purchase_data.company_name}</p>
                        <p>{PurchaseOrder.purchase_data.address}</p>
                        <br></br>
                        <p>GSTIN : {PurchaseOrder.purchase_data.company_gst}</p>
                        <p>Contact : {PurchaseOrder.purchase_data.phone}</p>
                        <p>Email : {PurchaseOrder.purchase_data.email}</p>
                    </div>

                    <div className="ship-to">
                        <h3>Ship To : </h3>
                        <p>{PurchaseOrder.purchase_data.shipping_address}</p>
                        <br></br>
                        <p>GSTIN : {PurchaseOrder.purchase_data.company_gst}</p>
                        <p>Contact : {PurchaseOrder.purchase_data.phone_number}</p>
                        <p>Email : {PurchaseOrder.purchase_data.email}</p>
                    </div>
                </div>



                {/* Items Table */}
                <table className="po-items-table">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Product Name</th>
                            <th>HSN code</th>
                            <th>Unit</th>
                            <th>Quantity</th>
                            <th>Rate</th>
                            <th>Tax</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {PurchaseOrder.items.map((item, index) => {
                            const taxAmount = item.item_value - item.quantity * item.item_rate;
                            const itemAmount = item.quantity * item.item_rate;

                            return (
                                <tr key={item.id}>
                                    <td>{index + 1}</td>
                                    <td className="product-cell">
                                        <div className="product-name">{item.item_name}</div>
                                        {item.item_summary && (
                                            <div className="product-description">{item.item_summary}</div>
                                        )}
                                    </td>
                                    <td>{item.hsn_sac_code}</td>
                                    <td>{item.unit_type}</td>
                                    <td>{item.quantity}</td>
                                    <td>{item.item_rate.toFixed(2)}</td>
                                    <td>{item.total_tax_percent}%</td>
                                    <td>{(itemAmount).toFixed(2)}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

                <div style={{ display: 'flex' }}>
                    <div style={{ marginTop: '20px', textAlign: 'left', width: '50%' }}>
                        <p style={{ textTransform: 'capitalize' }}>
                            Amount Chargeable (in words) <br />
                            <b>{PurchaseOrder.purchase_data.total_amount_in_words}</b>
                        </p>
                    </div>

                    {/* Totals Section */}
                    <div className="totals-section">
                        <div className="total-row">
                            <span>Total</span>
                            <span>₹{PurchaseOrder.purchase_data.sub_total}</span>
                        </div>
                        <div className='total-row'>
                            <span>Total Tax</span>
                            <span>₹{totalTaxAmount.toFixed(2)}</span>
                        </div>
                        <div className="total-row">
                            <span>Discounts</span>
                            <span>{PurchaseOrder.purchase_data.discount}</span>
                        </div>
                        <div className="total-row grand-total">
                            <span>Grand total</span>
                            <span>₹{PurchaseOrder.purchase_data.total_amount.toFixed(2)}</span>
                        </div>
                    </div>
                </div>

                {/* Terms and Conditions */}
                <div className="terms-section">
                    <h3>Terms and conditions:</h3>
                    <p>
                        {PurchaseOrder.purchase_data.note || 'No terms and conditions provided.'}
                    </p>
                    {/* <ol>
                           <li>We observe the right to cancel the proposal anytime before product shipment.</li>
                           <li>Invoice raised to us should contain the details of proposal with date mentioned.</li>
                           <li>Adherence to agreed product specifications is a must. Any deviation during delivery will start in cancellation of PO.</li>
                           <li>Packing and shipping charges are to be borne by supplier.</li>
                           <li>Delivery should be strictly done within 5 days from the date of proposal.</li>
                       </ol> */}
                </div>

                {/* Footer */}
                <div className="po-footer">

                    <div className="signature">
                        <p style={{ fontWeight: '600' }}>{PurchaseOrder.company_data.company_name}</p>
                        <p>Authorized Signatory</p>
                    </div>
                </div>

                <p style={{ textAlign: 'center', marginTop: '30px' }}>Generated By Myntask</p>
            </div>
        </div>
    );
};

export default ViewPurchaseOrder;