import React, { useState, useEffect } from "react";
import "./AddProduct.css";
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import iconImage from '../assets/Icon.png';
import './css/Designation.css';
import { Box, Modal, Stack, Typography } from "@mui/material";
import { Vortex } from 'react-loader-spinner';




const AddProduct = () => {
  const [formData, setFormData] = useState({
    type: "Goods", // Default to Goods
    name: "",
    sku: "",
    unitType: "",
    unitTypeId: null,
    productCategory: "",
    productCategoryId: null,
    productSubCategory: "",
    productSubCategoryId: null,
    sellingPrice: "",
    costPrice: "",
    tax: [],
    hsnSac: "",
    clientCanPurchase: false,
    description: "",
    image: null, // Track a single image
  });


  const navigate = useNavigate(); // Initialize navigate function
  const [taxOptions, setTaxOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [unitTypeOptions, setUnitTypeOptions] = useState([]);
  const [taxDropdownVisible, setTaxDropdownVisible] = useState(false);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [isSubCategoryModalOpen, setIsSubCategoryModalOpen] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [subCategoryName, setSubCategoryName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);




  // Fetch Tax Options
  useEffect(() => {
    const fetchTaxes = async () => {
      const token = localStorage.getItem("authToken");
      if (!token) return;

      try {
        const response = await fetch("https://api.myntask.com/product/taxes", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });


        if (!response.ok) throw new Error("Failed to fetch taxes");

        const data = await response.json();
        setTaxOptions(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching taxes:", error);
        setError("Failed to fetch taxes");
      }
      finally {
        setIsLoading(false);
      }
    };

    fetchTaxes();
  }, []);




  const fetchCategories = async () => {
    const token = localStorage.getItem("authToken");
    if (!token) return;

    try {
      const response = await fetch("https://api.myntask.com/product/category", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });


      if (!response.ok) throw new Error("Failed to fetch product categories");

      const data = await response.json();
      setCategoryOptions(data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching product categories:", error);
      setError("Failed to fetch product categories");
    }
  };


  // Fetch Category Options
  useEffect(() => {


    fetchCategories();
  }, []);








  // Fetch Unit Type Options
  useEffect(() => {
    const fetchUnitTypes = async () => {
      const token = localStorage.getItem("authToken");
      if (!token) return;


      try {
        const response = await fetch("https://api.myntask.com/product/unit_types", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) throw new Error("Failed to fetch unit types");

        const data = await response.json();
        setUnitTypeOptions(data);
      } catch (error) {
        console.error("Error fetching unit types:", error);
      }
    };


    fetchUnitTypes();
  }, []);

  const fetchSubCategories = async (categoryId) => {
    const token = localStorage.getItem("authToken");
    if (!token || !categoryId) return;

    try {
      const response = await fetch(
        `https://api.myntask.com/product/sub_category/${categoryId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) throw new Error("Failed to fetch sub-categories");

      const data = await response.json();
      setSubCategoryOptions(data); // Update state only with the latest response
    } catch (error) {
      console.error("Error fetching sub-categories:", error);
    }
  };

  // Handle category change
  const handleCategoryChange = (selectedCategoryId) => {
    setFormData((prev) => ({
      ...prev,
      productCategoryId: selectedCategoryId,
      productSubCategoryId: "", // Reset sub-category
    }));
    fetchSubCategories(selectedCategoryId); // Fetch subcategories for the selected category
  };

  // Trigger fetchSubCategories on category change
  useEffect(() => {
    if (!formData.productCategoryId) {
      setSubCategoryOptions([]); // Clear subcategories if no category is selected
      return;
    }
    fetchSubCategories(formData.productCategoryId);
  }, [formData.productCategoryId]);


  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : type === "file" ? files[0] : value,
    });
  };


  const handleTaxSelect = (id) => {
    if (formData.tax.includes(id)) {
      setFormData({
        ...formData,
        tax: formData.tax.filter((taxId) => taxId !== id),
      });
    } else {
      setFormData({
        ...formData,
        tax: [...formData.tax, id],
      });
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();

    // Append the other form fields to FormData
    formDataToSend.append("category_id", formData.productCategoryId);
    formDataToSend.append("sub_category_id", formData.productSubCategoryId);
    formDataToSend.append("name", formData.name);
    formDataToSend.append("sku", formData.sku);
    formDataToSend.append("type", formData.type.toLowerCase());
    formDataToSend.append("unit_id", formData.unitTypeId);
    formDataToSend.append("cost_price", parseFloat(formData.costPrice));
    formDataToSend.append("selling_price", parseFloat(formData.sellingPrice));
    formDataToSend.append("taxes", JSON.stringify(formData.tax)); // Convert to JSON string if needed
    formDataToSend.append("han_code", formData.hsnSac);
    formDataToSend.append("client_can_purchase", formData.clientCanPurchase ? 1 : 0);
    formDataToSend.append("brand", formData.brand); // Ensure 'brand' exists in your state
    formDataToSend.append("description", formData.description);


    // Append image to FormData if selected
    if (formData.image) {
      formDataToSend.append("image", formData.image); // Append the single image
    }


    console.log("Payload to send:", formDataToSend);
    setIsLoading(true);

    try {
      const token = localStorage.getItem("authToken");
      if (!token) {
        console.error("No token found. Please log in.");
        Swal.fire({
          icon: 'error',
          title: 'Authentication Error',
          text: 'Please log in to continue.',
          timer: 1500,
          showConfirmButton: false,
        });
        return;
      }


      const response = await fetch("https://api.myntask.com/product/save", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formDataToSend, // Send FormData with the image
      });


      if (!response.ok) throw new Error("Failed to save product");


      const data = await response.json();
      console.log("Product saved successfully:", data);

      // Show success notification
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Product saved successfully!',
        timer: 1500,
        showConfirmButton: false,
      });
      navigate('/product'); // Navigate to the product list page
      setIsLoading(false);
    } catch (error) {
      console.error("Error saving product:", error);

      // Show error notification
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message || 'Failed to save the product.',
        timer: 1500,
        showConfirmButton: false,
      });
    }
    setIsLoading(false);
  };





  // Open and close modals
  const openCategoryModal = () => setIsCategoryModalOpen(true);
  const closeCategoryModal = () => {
    setIsCategoryModalOpen(false);
    setCategoryName("");
  };




  const openSubCategoryModal = () => {
    if (!formData.productCategoryId) {
      alert("Please select a category before adding a subcategory.");
      return;
    }
    setIsSubCategoryModalOpen(true);
  };




  const closeSubCategoryModal = () => {
    setIsSubCategoryModalOpen(false);
    setSubCategoryName("");
  };






  // Save Category
  const handleSaveCategory = async () => {
    if (!categoryName.trim()) {
      Swal.fire({
        icon: "warning",
        title: "Validation Error",
        text: "Category name cannot be empty.",
        position: "top-end",
        toast: true,
        timer: 3000,
        showConfirmButton: false,
      });
      return;
    }


    const token = localStorage.getItem("authToken");
    if (!token) {
      Swal.fire({
        icon: "error",
        title: "Authentication Error",
        text: "User is not authenticated.",
        position: "top-end",
        toast: true,
        timer: 3000,
        showConfirmButton: false,
      });
      return;
    }


    const requestData = { name: categoryName };
    setIsSubmitting(true);


    try {
      const response = await fetch("https://api.myntask.com/product/category/save", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestData),
      });


      if (!response.ok) throw new Error("Failed to save category.");


      fetchCategories();
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Category added successfully!",
        position: "top-end",
        toast: true,
        timer: 3000,
        showConfirmButton: false,
      });
      closeCategoryModal();
    } catch (error) {
      console.error("Error saving category:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while saving the category.",
        position: "top-end",
        toast: true,
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      setIsSubmitting(false);
    }
  };


  // Save SubCategory
  const handleSaveSubCategory = async () => {
    if (!subCategoryName.trim()) {
      Swal.fire({
        icon: "warning",
        title: "Validation Error",
        text: "Subcategory name cannot be empty.",
        position: "top-end",
        toast: true,
        timer: 3000,
        showConfirmButton: false,
      });
      return;
    }


    const token = localStorage.getItem("authToken");
    if (!token) {
      Swal.fire({
        icon: "error",
        title: "Authentication Error",
        text: "User is not authenticated.",
        position: "top-end",
        toast: true,
        timer: 3000,
        showConfirmButton: false,
      });
      return;
    }


    const requestData = {
      category_id: formData.productCategoryId,
      name: subCategoryName,
    };


    setIsSubmitting(true);


    try {
      const response = await fetch("https://api.myntask.com/product/sub_category/save", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestData),
      });


      if (!response.ok) throw new Error("Failed to save subcategory.");


      // Fetch updated subcategories for the selected category
      await fetchSubCategories(formData.productCategoryId);

      // await fetchSubCategories();
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Subcategory added successfully!",
        position: "top-end",
        toast: true,
        timer: 3000,
        showConfirmButton: false,
      });
      closeSubCategoryModal();
    } catch (error) {
      console.error("Error saving subcategory:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while saving the subcategory.",
        position: "top-end",
        toast: true,
        timer: 3000,
        showConfirmButton: false,
      });
    } finally {
      setIsSubmitting(false);
    }
  };





  const handleCancel = () => {
    navigate('/product'); // Navigate to the product list page
  };




  //..........Loading Spinner ..........................................

  useEffect(() => {
    // Adjust the timer duration here (e.g., 3000ms = 3 seconds)
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 300);

    // Cleanup the timer on component unmount
    return () => clearTimeout(timer);
  }, []);


  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box
            component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }


  // if (error) {
  //   return (
  //     <Typography
  //       color="error"
  //       sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
  //     >
  //       {error}
  //     </Typography>
  //   );
  // }


  return (
    <div className="main-container">
      <div className="top-controls">
        <h1 className='title'>Add Product
          <div className="underline"></div>
        </h1>
      </div>




      {/* Radio Buttons */}
      <div className="radio-group">
        <label>
          <input


            type="radio"
            name="type"
            value="Goods"
            checked={formData.type === "Goods"}
            onChange={handleChange}
          />
          Goods
        </label>
        <label>
          <input


            type="radio"
            name="type"
            value="Service"
            checked={formData.type === "Service"}
            onChange={handleChange}
          />
          Service
        </label>
      </div>




      <div className="product-container">
        {/* Description Section */}
        <div className="section">
          <h3>Description</h3>
          {/* Name */}
          <div>
            <label htmlFor="name">Name</label>
            <input
              className="product-input"
              type="text"
              id="name"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>




          {/* Brand */}
          <div>
            <label htmlFor="brand">Brand</label>
            <input
              className="product-input"
              type="text"
              id="brand"
              name="brand"
              placeholder="Brand"
              value={formData.brand}
              onChange={handleChange}
              required
            />
          </div>




          <div>
            <label htmlFor="description">Description</label>
            <textarea
              className="product-input"
              id="description"
              name="description"
              placeholder="Enter product description"
              value={formData.description}
              onChange={handleChange}
            ></textarea>
          </div>




          {/* <button className="color-button">Upload .txt file</button> */}
        </div>




        {/* Inventory Section */}
        <div className="section">
          <h3>Inventory</h3>




          {/* <label htmlFor="quantity">Quantity</label>
        <input
          className="product-input"
          type="number"
          id="quantity"
          name="quantity"
          placeholder="Enter quantity"
          value={formData.quantity}
          onChange={handleChange}
        /> */}










          {/* SKU */}
          <div>
            <label htmlFor="sku">SKU</label>
            <input
              type="text"
              className="product-input"
              id="sku"
              name="sku"
              placeholder="SKU"
              value={formData.sku}
              onChange={handleChange}
            />
          </div>




          {/* HSN/SAC */}
          <div>
            <label htmlFor="hsn-sac">HSN/SAC</label>
            <input
              type="text"
              className="product-input"
              id="hsn-sac"
              name="hsnSac"
              placeholder="HSN/SAC"
              value={formData.hsnSac}
              onChange={handleChange}
            />
          </div>




          {/* Unit Type */}
          <div>
            <label htmlFor="unit-type">Unit Type</label>
            <select
              id="unit-type"
              className="product-input"
              value={formData.unitTypeId || ""}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  unitTypeId: e.target.value, // Save selected unit type ID
                })
              }
            >
              <option value="" disabled>
                Select a unit type
              </option>
              {unitTypeOptions.map((unit) => (
                <option key={unit.id} value={unit.id}>
                  {unit.unit_type}
                </option>
              ))}
            </select>
          </div>




          {/* Client Can Purchase */}
          <div className="checkbox-group">
            <label>
              <input
                type="checkbox"
                name="clientCanPurchase"
                checked={formData.clientCanPurchase}
                onChange={handleChange}
              />
              Client Can Purchase
            </label>
          </div>








        </div>








        {/* Category Section */}
        <div className="section">
          <h3>Category</h3>
          <div>
            {/* Product Category */}
            <label htmlFor="product-category">Product Category</label>
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <select
                id="product-category"
                className="product-input"
                value={formData.productCategoryId || ""}
                onChange={(e) => handleCategoryChange(e.target.value)}
              >
                <option value="" disabled>
                  Select a category
                </option>
                {categoryOptions.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.category_name}
                  </option>
                ))}
              </select>
              <button className="color-button" style={{ marginBottom: '15px' }} onClick={openCategoryModal}>
                Add
              </button>
            </div>
          </div>





          {/* Sub-Category */}
          <div>
            <label htmlFor="product-sub-category">Product Sub Category</label>
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <select
                id="product-sub-category"
                className="product-input"
                value={formData.productSubCategoryId || ""}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    productSubCategoryId: e.target.value,
                  }))
                }
                disabled={!formData.productCategoryId} // Disable until a category is selected
              >
                <option value="" disabled>
                  Select a sub-category
                </option>
                {subCategoryOptions.map((subCategory) => (
                  <option key={subCategory.id} value={subCategory.id}>
                    {subCategory.category_name}
                  </option>
                ))}
              </select>
              <button className="color-button" style={{ marginBottom: '15px' }} onClick={openSubCategoryModal}>
                Add
              </button>
            </div>
          </div>




        </div>










        {/* Pricing Section */}
        <div className="section">
          <h3>Pricing</h3>
          {/* Cost Price */}
          <div>
            <label htmlFor="cost-price">Cost Price</label>
            <input
              type="number"
              className="product-input"
              id="cost-price"
              name="costPrice"
              placeholder="Cost Price"
              value={formData.costPrice}
              onChange={handleChange}
            />
          </div>








          {/* Selling Price */}
          <div>
            <label htmlFor="selling-price">Selling Price</label>
            <input
              type="number"
              className="product-input"
              id="selling-price"
              name="sellingPrice"
              placeholder="Selling Price"
              value={formData.sellingPrice}
              onChange={handleChange}
            />
          </div>




        </div>




        {/* Tax Section */}
        <div className="section">
          <h3>Tax Information</h3>
          {/* Tax Dropdown */}
          <div className="multi-select">
            <label>Select Tax</label>
            <div
              className="multi-select-field"
              onClick={() => setTaxDropdownVisible(!taxDropdownVisible)}
            >
              {formData.tax.length === 0
                ? "Click to select taxes"
                : formData.tax.map(
                  (id) =>
                    taxOptions.find((tax) => tax.id === id)?.tax_name || id
                ).join(", ")}
            </div>
            {taxDropdownVisible && (
              <div className="multi-select-dropdown">
                {taxOptions.map((tax) => (
                  <div
                    key={tax.id}
                    className={`dropdown-item ${formData.tax.includes(tax.id) ? "selected" : ""
                      }`}
                    onClick={() => handleTaxSelect(tax.id)}
                  >
                    <input
                      type="checkbox"
                      checked={formData.tax.includes(tax.id)}
                      readOnly
                    />
                    {tax.tax_name}
                  </div>
                ))}
              </div>
            )}
          </div>




        </div>








        {/* Product Images Section */}
        <div className="section">
          <h3>Product Image</h3>
          <label>Upload Image</label>
          <input
            type="file"
            onChange={(e) => {
              setFormData({
                ...formData,
                image: e.target.files[0], // Capture the selected single file
              });
            }}
          />
          <p>Click to upload or drag and drop</p>
        </div>








      </div>




      <div className="buttons-row" style={{ marginTop: '15px', justifyContent: 'flex-end' }}>
        <button className="color-button" onClick={handleCancel}>Cancel</button>

        <button className="color-button" onClick={handleSubmit}>Save</button>


      </div>




      {/* Category Modal */}
      <Modal
        open={isCategoryModalOpen}
        onClose={closeCategoryModal}
        aria-labelledby="category-modal"
        aria-describedby="modal-for-adding-category"
      >
        <Box className="designation-modal">
          {/* Header with title and close button */}
          <div className="designation-header">
            <h1 className="designation-title">Add Category</h1>
            <img
              src={iconImage} // Replace 'iconImage' with the path or import for Icon.png
              alt="Close"
              className="designation-close"
              onClick={closeCategoryModal}
            />
          </div>




          {/* Form Section */}
          <div className="designation-form">
            <label className="designation-label" >
              Category Name
            </label>
            <input
              type="text"
              id="category-name"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
              placeholder="Enter category name"
              className="designation-input"
            />
          </div>




          <button
            className="designation-save"
            onClick={handleSaveCategory}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Saving..." : "Save"}
          </button>




        </Box>
      </Modal>








      {/* SubCategory Modal */}
      <Modal
        open={isSubCategoryModalOpen}
        onClose={closeSubCategoryModal}
        aria-labelledby="subcategory-modal"
        aria-describedby="modal-for-adding-subcategory"
      >
        <Box className="designation-modal">
          {/* Header with title and close button */}
          <div className="designation-header">
            <h1 className="designation-title">Add Subcategory</h1>
            <img
              src={iconImage} // Replace 'iconImage' with the path or import for Icon.png
              alt="Close"
              className="designation-close"
              onClick={closeSubCategoryModal}
            />
          </div>




          {/* Form Section */}
          <div className="designation-form">
            <label className="designation-label" htmlFor="subcategory-name">
              Subcategory Name
            </label>
            <input
              type="text"
              id="subcategory-name"
              value={subCategoryName}
              onChange={(e) => setSubCategoryName(e.target.value)}
              placeholder="Enter subcategory name"
              className="designation-input"
            />
          </div>




          <button
            className="designation-save"
            onClick={handleSaveSubCategory}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Saving..." : "Save"}
          </button>
        </Box>
      </Modal>




    </div>
  );


};








export default AddProduct;


















