import React, { useState, useEffect, useRef } from 'react';
import './EstimateForm.css';
import Swal from 'sweetalert2';
import { Box, Modal, Stack, Typography } from "@mui/material";
import { Vortex } from 'react-loader-spinner';
import { useNavigate, useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

const AddDealProposal = () => {
    const { dealId } = useParams();
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [deals, setDeals] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedSubcategory, setSelectedSubcategory] = useState("");
    const [selectedProduct, setSelectedProduct] = useState("");
    const [selectedClient, setSelectedClient] = useState("");
    const [rows, setRows] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalTax, setTotalTax] = useState(0);
    const [productDetails, setProductDetails] = useState(null);
    const [loadingProduct, setLoadingProduct] = useState(false);
    const [expectedDeliveryDate, setExpectedDeliveryDate] = useState(null);
    const [unitTypes, setUnitTypes] = useState([]);
    const [taxes, setTaxes] = useState([]); // State for taxes
    const [isLoading, setIsLoading] = useState(false);
    const [vorLoading, setVorLoading] = useState(true);
    const [defaultCompanyName, setDefaultCompanyName] = useState('');
    const [billingAddress, setBillingAddress] = useState('');
    const [shippingAddress, setShippingAddress] = useState('');

    const [errors, setErrors] = useState({}); // State for error messages

    // Create refs for required fields
    const companyRef = useRef(null);
    const dateRef = useRef(null);
    const categoryRef = useRef(null);
    const subcategoryRef = useRef(null);

    const token = localStorage.getItem('authToken');
    const navigate = useNavigate();
    useEffect(() => {
        const fetchCategories = async () => {
            console.log('Fetching categories...');
            try {
                const response = await fetch('https://api.myntask.com/product/category', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                const data = await response.json();
                console.log('Categories fetched:', data);
                setCategories(data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };
        fetchCategories();
    }, [token]);

    useEffect(() => {
        const fetchSubcategories = async () => {
            console.log('Fetching subcategories for category:', selectedCategory);
            if (selectedCategory) {
                try {
                    const response = await fetch(
                        `https://api.myntask.com/product/sub_category/${selectedCategory}`,
                        { headers: { Authorization: `Bearer ${token}` } }
                    );
                    const data = await response.json();
                    console.log('Subcategories fetched:', data);
                    setSubcategories(data);
                } catch (error) {
                    console.error('Error fetching subcategories:', error);
                }
            }
        };
        fetchSubcategories();
    }, [selectedCategory, token]);

    useEffect(() => {
        const fetchProducts = async () => {
            console.log('Fetching products for category and subcategory:', selectedCategory, selectedSubcategory);
            if (selectedCategory && selectedSubcategory) {
                try {
                    const response = await fetch(
                        `https://api.myntask.com/product/product_dropdown?category_id=${selectedCategory}&subcategory_id=${selectedSubcategory}`,
                        { headers: { Authorization: `Bearer ${token}` } }
                    );
                    const data = await response.json();
                    console.log('Products fetched:', data);
                    setProducts(data);
                } catch (error) {
                    console.error('Error fetching products:', error);
                }
            }
        };
        fetchProducts();
    }, [selectedCategory, selectedSubcategory, token]);

    const fetchDealsList = async () => {
        const token = localStorage.getItem('authToken');
        if (!token) return;

        setVorLoading(true);
        try {
            const response = await fetch('https://api.myntask.com/leads/deals_list', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch deals');
            }
            const data = await response.json();
            setDeals(data);

            // If dealId exists in URL, find and set the corresponding company name
            if (dealId) {
                const selectedDeal = data.find(deal => deal.id.toString() === dealId);
                if (selectedDeal) {
                    setDefaultCompanyName(selectedDeal.company_name);
                    setSelectedClient(dealId); // Set the selected client ID
                }
            }

            setVorLoading(false);
        } catch (err) {
            console.error('Error fetching deals:', err);
            setVorLoading(false);
        }
    };



    useEffect(() => {
        fetchDealsList();
    }, []);


    // Fetch unit types
    useEffect(() => {
        const fetchUnitTypes = async () => {
            try {
                const response = await fetch('https://api.myntask.com/product/unit_types', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                const data = await response.json();
                setUnitTypes(data);
            } catch (error) {
                console.error('Error fetching unit types:', error);
            }
        };
        fetchUnitTypes();
    }, [token]);

    const fetchProductDetails = async (productId) => {
        console.log('Fetching product details for product ID:', productId);
        setLoadingProduct(true);
        try {
            const response = await fetch(
                `https://api.myntask.com/purchase_order/product_dropdown_info/${productId}`,
                { headers: { Authorization: `Bearer ${token}` } }
            );
            const data = await response.json();
            console.log('Product details fetched:', data);

            if (data && data.length > 0) {
                setProductDetails(data[0]);
            } else {
                console.error('No product data available');
            }
        } catch (error) {
            console.error('Error fetching product details:', error);
        } finally {
            setLoadingProduct(false); // Stop loading
        }
    };

    // Fetch tax data
    useEffect(() => {
        const fetchTaxes = async () => {
            try {
                const response = await fetch('https://api.myntask.com/product/taxes', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                const data = await response.json();
                setTaxes(data);
            } catch (error) {
                console.error('Error fetching taxes:', error);
            }
        };
        fetchTaxes();
    }, [token]);


    const handleProductSelect = (productId) => {
        console.log('Product selected:', productId);
        setSelectedProduct(productId);
        fetchProductDetails(productId);
    };

    const addProductRow = () => {
        console.log('Adding product row with details:', productDetails);
        if (!productDetails) {
            alert('Please select a product.');
            return;
        }

        const unitPrice = parseFloat(productDetails.purchase_price) || 0;
        const qty = 1;
        // const taxPercentage = productDetails.taxes && productDetails.taxes.length > 0
        //     ? parseFloat(productDetails.taxes[0].split('@')[1])
        //     : 0;

        const newRow = {
            id: rows.length + 1,
            product: productDetails.name,
            description: productDetails.description || '',
            qty,
            unitPrice,
            discount: 0,
            tax: [],
            taxAmount: 0,
            totalAmount: unitPrice * qty,
            hsn_code: productDetails.hsn_sac_code, // Add hsn_sac_code here
            unitTypeId: ''
        };
        setRows([...rows, newRow]);
        calculateSummary([...rows, newRow]);
        setSelectedProduct('');
        setProductDetails(null);
    };

    const addEmptyRow = () => {
        console.log('Adding empty row...');
        const newRow = {
            id: rows.length + 1,
            product: '',
            description: '',
            qty: 1,
            unitPrice: 0.0,
            discount: 0,
            tax: [],
            hsn_code: '', // Optional: Add if needed
            taxAmount: 0,
            totalAmount: 0,
        };
        setRows([...rows, newRow]);
    };

    const deleteRow = (id) => {
        console.log('Deleting row with ID:', id);
        const updatedRows = rows.filter((row) => row.id !== id).map((row, index) => ({
            ...row,
            id: index + 1,
        }));
        setRows(updatedRows);
        // Recalculate the summary
        calculateSummary(updatedRows);
    };

    // Handle Input Changes
    const handleRowChange = (id, field, value) => {
        const updatedRows = rows.map((row) => {
            if (row.id === id) {
                // Safely parse the input fields and assign defaults
                const qty = field === "qty" ? parseFloat(value) || 0 : parseFloat(row.qty) || 0;
                const unitPrice = field === "unitPrice" ? parseFloat(value) || 0 : parseFloat(row.unitPrice) || 0;
                const discount = field === "discount" ? parseFloat(value) || 0 : parseFloat(row.discount) || 0;
                // Update tax selection
                const selectedTaxIds = field === "tax" ? value : row.tax; // value should be an array of selected tax IDs
                const selectedTaxes = taxes.filter(tax => selectedTaxIds.includes(tax.id));

                // Calculate the discounted price per item
                const discountedPrice = unitPrice * (1 - discount / 100);

                // Calculate the total tax amount from selected taxes
                const totalTaxAmount = selectedTaxes.reduce((sum, tax) => {
                    const taxPercentage = parseFloat(tax.tax_name.split('@')[1]) || 0;
                    return sum + (taxPercentage / 100) * discountedPrice * qty;
                }, 0);

                // Calculate the total amount
                const totalAmount = discountedPrice * qty + totalTaxAmount;

                return {
                    ...row,
                    [field]: value, // Update the specific field
                    taxAmount: Number.isFinite(totalTaxAmount) ? totalTaxAmount : 0, // Ensure taxAmount is a number
                    totalAmount: Number.isFinite(totalAmount) ? totalAmount : 0, // Ensure totalAmount is a number
                };
            }
            return row; // No changes for other rows
        });

        setRows(updatedRows);
        calculateSummary(updatedRows); // Update summary totals
    };



    const calculateSummary = (rowsData) => {
        console.log('Calculating summary for rows:', rowsData);
        const total = rowsData.reduce((sum, row) => sum + row.totalAmount, 0);
        const tax = rowsData.reduce((sum, row) => sum + row.taxAmount, 0);
        setTotalAmount(total);
        setTotalTax(tax);
    };


    const validateForm = () => {
        const newErrors = {};
        let isValid = true;

        if (!selectedClient) {
            newErrors.client = "Company name is required.";
            isValid = false;
        }
        if (!expectedDeliveryDate) {
            newErrors.date = "Date is required.";
            isValid = false;
        }
        if (!selectedCategory) {
            newErrors.category = "Category is required.";
            isValid = false;
        }
        if (!selectedSubcategory) {
            newErrors.subcategory = "Subcategory is required.";
            isValid = false;
        }
        if (!billingAddress.trim()) {
            newErrors.billingAddress = "Billing address is required.";
            isValid = false;
        }
        if (!shippingAddress.trim()) {
            newErrors.shippingAddress = "Shipping address is required.";
            isValid = false;
        }

        setErrors(newErrors);

        // Focus on the first error field if it exists
        if (!isValid) {
            if (companyRef.current && newErrors.client) companyRef.current.focus();
            else if (dateRef.current && newErrors.date) dateRef.current.focus();
            else if (categoryRef.current && newErrors.category) categoryRef.current.focus();
            else if (subcategoryRef.current && newErrors.subcategory) subcategoryRef.current.focus();

        }

        return isValid;
    };

    const handleSave = async () => {

        if (!validateForm()) return;

        console.log('Saving Proposal...');
        const items = rows.map((row) => ({
            item_name: row.product || '',
            quantity: parseFloat(row.qty) || 0,
            product_id: selectedProduct || null,
            hsn_code: row.hsn_code || '',
            unit_price: parseFloat(row.unitPrice) || 0,
            unit_id: row.unitTypeId || null,
            item_summary: row.description || '',
            amount: parseFloat(row.totalAmount) || 0,
            tax_id: row.tax,
        }));

        const proposalData = {
            currency_id: 33,
            deal_id: parseInt(selectedClient) || null,
            valid_till: expectedDeliveryDate ? expectedDeliveryDate.toISOString().split('T')[0] : "",
            note: 'Additional notes',
            shipping_address: shippingAddress,
            billing_address: billingAddress,
            sub_total: rows.reduce((sum, row) => sum + parseFloat(row.unitPrice) * parseFloat(row.qty), 0),
            total: totalAmount,
            discount: rows.reduce((sum, row) => sum + (parseFloat(row.discount) || 0), 0), // Total discount
            discount_type: 'percent',
            items,
        };

        // Log each field (for debugging purposes)
        console.log('Currency ID:', proposalData.currency_id);
        console.log('Deal ID:', proposalData.deal_id);
        console.log('Valid Till:', proposalData.valid_till)
        console.log('Note:', proposalData.note);
        console.log('Sub Total:', proposalData.sub_total);
        console.log('Total:', proposalData.total);
        console.log('Discount:', proposalData.discount);
        console.log('Discount Type:', proposalData.discount_type);
        console.log('Items:', proposalData.items);

        setIsLoading(true);
        try {
            const response = await fetch('https://api.myntask.com/proposal/save', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(proposalData),
            });

            if (response.ok) {
                console.log('Proposal saved successfully.');
                Swal.fire('Success', 'Proposal saved successfully!', 'success');
                navigate(-1);
                resetForm();

            } else {
                console.error('Failed to save estimate.');
                Swal.fire('Error', 'Failed to save proposal.', 'error');
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error saving proposal:', error);
            Swal.fire('Error', 'An error occurred while saving.', 'error');
        }
    };

    const resetForm = () => {
        console.log('Resetting form...');
        setSelectedClient('');
        setSelectedCategory('');
        setSelectedSubcategory('');
        setSelectedProduct('');
        setRows([]);
        setTotalAmount(0);
        setTotalTax(0);
        setProductDetails(null);
        setShippingAddress('');
        setBillingAddress('');
    };

    const handleCancel = () => {
        console.log('Canceling proposal...');
        navigate(-1);
        resetForm();
    }


    const TaxDropdown = ({ row }) => {
        const [isOpen, setIsOpen] = useState(false);

        const toggleDropdown = () => {
            setIsOpen(!isOpen);
        };

        const handleCheckboxChange = (taxId) => {
            const newTaxIds = row.tax.includes(taxId)
                ? row.tax.filter(id => id !== taxId)
                : [...row.tax, taxId];

            handleRowChange(row.id, "tax", newTaxIds);
        };

        return (
            <div className="tax-dropdown">
                <div className="dropdown-header" onClick={toggleDropdown} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                    <span>
                        {row.tax.length > 0
                            ? taxes.filter(tax => row.tax.includes(tax.id)).map(tax => tax.tax_name).join(', ')
                            : 'Select Taxes'}
                    </span>
                    <span style={{ marginLeft: 'auto' }}>▼</span> {/* Arrow icon */}
                </div>
                {isOpen && (
                    <div className="dropdown-list">
                        {taxes.map(tax => (
                            <label key={tax.id} style={{ display: 'flex', alignItems: 'center' }}>
                                <input
                                    type="checkbox"
                                    checked={row.tax.includes(tax.id)}
                                    onChange={() => handleCheckboxChange(tax.id)}
                                    style={{ marginRight: '5px' }}
                                />
                                {tax.tax_name}
                            </label>
                        ))}
                    </div>
                )}
            </div>
        );
    };


    useEffect(() => {
        // Adjust the timer duration here (e.g., 3000ms = 3 seconds)
        const timer = setTimeout(() => {
            setVorLoading(false);
        }, 300);

        // Cleanup the timer on component unmount
        return () => clearTimeout(timer);
    }, []);
    // Loading Spinner
    if (vorLoading) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    backgroundColor: "#f5f5f5",
                }}
            >
                <Stack alignItems="center">
                    <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
                    <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
                        Loading....
                    </Box>
                </Stack>
            </Box>
        );
    }




    return (
        <div className="main-container">
            <h2>Proposal Page</h2>
            <form>
                {/* <div className="form-container"> */}
                <div className="form-row" style={{ marginTop: '25px', marginBottom: '30px' }}>
                    <div className="section-div">
                        <label>Company Name: <span className="required">*</span></label>
                        {dealId ? (
                            // Show read-only input when coming from a specific deal
                            <input
                                className='expenseInput'
                                type="text"
                                value={defaultCompanyName}
                                readOnly
                                ref={companyRef}
                            />
                        ) : (
                            // Show normal dropdown when creating a new proposal
                            <select
                                onChange={(e) => setSelectedClient(e.target.value)}
                                value={selectedClient}
                                className='expenseInput'
                                ref={companyRef}
                            >
                                <option value="">Select Company</option>
                                {deals.map((deal) => (
                                    <option key={deal.id} value={deal.id}>
                                        {deal.company_name}
                                    </option>
                                ))}
                            </select>
                        )}
                    </div>
                    <div className="section-div">
                        <label>Date: <span className="required">*</span></label>
                        <input className='expenseInput'
                            type="date"
                            value={expectedDeliveryDate ? expectedDeliveryDate.toISOString().split('T')[0] : ""}
                            onChange={(e) => setExpectedDeliveryDate(new Date(e.target.value))}
                            ref={dateRef}
                        />
                        {errors.date && <span className="error-message">{errors.date}</span>}
                    </div>
                </div>

                <div className="form-row">
                    <div className="section-div">
                        <label>Billing Address: <span className="required">*</span></label>
                        <textarea
                            rows="2"
                            value={billingAddress}
                            onChange={(e) => setBillingAddress(e.target.value)}
                            placeholder="Enter billing address"
                        />
                        {errors.billingAddress && <span className="error-message">{errors.billingAddress}</span>}
                    </div>
                    <div className="section-div">
                        <label>Shipping Address: <span className="required">*</span></label>
                        <textarea
                            rows="2"
                            value={shippingAddress}
                            onChange={(e) => setShippingAddress(e.target.value)}
                            placeholder="Enter shipping address"
                        />
                        {errors.shippingAddress && <span className="error-message">{errors.shippingAddress}</span>}
                    </div>
                </div>

                <div className="form-row" style={{ marginTop: '25px', marginBottom: '30px' }}>
                    <div className="section-div">
                        <label>Category: <span className="required">*</span></label>
                        <select className='expenseInput' onChange={(e) => setSelectedCategory(e.target.value)} value={selectedCategory} ref={categoryRef}>
                            <option value="">Select Category </option>
                            {categories.map((cat) => (
                                <option key={cat.id} value={cat.id}>
                                    {cat.category_name}
                                </option>
                            ))}
                        </select>
                        {errors.category && <span className="error-message">{errors.category}</span>}
                    </div>
                    <div className="section-div">
                        <label>Subcategory: <span className="required">*</span></label>
                        <select className='expenseInput' onChange={(e) => setSelectedSubcategory(e.target.value)} value={selectedSubcategory} ref={subcategoryRef}>
                            <option value="">Select Subcategory</option>
                            {subcategories.map((sub) => (
                                <option key={sub.id} value={sub.id}>
                                    {sub.category_name}
                                </option>
                            ))}
                        </select>
                        {errors.subcategory && <span className="error-message">{errors.subcategory}</span>}
                    </div>

                    <div className="section-div">
                        <label>Product: <span className="required">*</span></label>
                        <select className='expenseInput' onChange={(e) => handleProductSelect(e.target.value)} value={selectedProduct}>
                            <option value="">Select Product </option>
                            {products.map((prod) => (
                                <option key={prod.id} value={prod.id}>
                                    {prod.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                {/* </div> */}
                <div className="est-actions">
                    <button
                        className='color-button'
                        type="button"
                        onClick={addProductRow}
                        disabled={!productDetails || loadingProduct} // Disable button during loading
                    >
                        {loadingProduct ? "Loading..." : "Add Product"}
                    </button>
                    <button type="button" className='color-button' onClick={addEmptyRow}>
                        + Add Empty Row
                    </button>
                </div>
            </form>

            <table className='estimate-table'>
                <thead>
                    <tr>
                        <th>S.No.</th>
                        <th>Item Name</th>
                        <th>Qty</th>
                        <th>Unit Type</th>
                        <th>Unit Price</th>
                        <th>Discount (%)</th>
                        <th>Tax (%)</th>
                        <th>Tax Amount</th>
                        <th>Amount</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody className='estimate-body'>
                    {rows.map((row) => (
                        <tr key={row.id}>
                            <td style={{ width: '2vw' }}>{row.id}</td>
                            <td style={{ width: '20vw' }}>
                                <input
                                    className='estimateInput'
                                    type="text"
                                    value={row.product}
                                    placeholder='Product Name'
                                    onChange={(e) => handleRowChange(row.id, "product", e.target.value)}
                                    style={{ marginBottom: '10px' }}
                                />

                                <input
                                    className='estimateInput'
                                    rows="2"
                                    placeholder='Description'
                                    value={row.description}
                                    onChange={(e) => handleRowChange(row.id, "description", e.target.value)}
                                />
                            </td>
                            <td style={{ width: '5vw' }}>
                                <input
                                    className='estimateInput'
                                    type="number"
                                    value={row.qty}
                                    onChange={(e) => handleRowChange(row.id, "qty", parseFloat(e.target.value))}
                                />
                            </td>
                            <td style={{ width: '7vw' }}>
                                <select
                                    className='estimateInput'
                                    value={row.unitTypeId}
                                    onChange={(e) => handleRowChange(row.id, "unitTypeId", e.target.value)}
                                >
                                    <option value="">Select Unit Type</option>
                                    {unitTypes.map((unit) => (
                                        <option key={unit.id} value={unit.id}>
                                            {unit.unit_type}
                                        </option>
                                    ))}
                                </select>
                            </td>
                            <td style={{ width: '8vw' }}>
                                <input
                                    className='estimateInput'
                                    type="number"
                                    value={row.unitPrice}
                                    onChange={(e) => handleRowChange(row.id, "unitPrice", parseFloat(e.target.value))}
                                />
                            </td>
                            <td style={{ width: '5vw' }}>
                                <input
                                    className='estimateInput'
                                    type="number"
                                    value={row.discount}
                                    onChange={(e) => handleRowChange(row.id, "discount", parseFloat(e.target.value))}
                                />
                            </td>
                            <td style={{ width: '9vw' }}>
                                <TaxDropdown row={row} />
                            </td>
                            <td style={{ width: '8vw' }}>{row.taxAmount.toFixed(2)}</td>
                            <td style={{ width: '8vw' }}>{row.totalAmount.toFixed(2)}</td>
                            <td style={{ width: '4vw' }}>
                                <button onClick={() => deleteRow(row.id)}>➖</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="estimate-summary">
                <div>
                    <label>Total Tax :</label> {totalTax.toFixed(2)}
                </div>
                <div>
                    <label>Total Amount :</label> {totalAmount.toFixed(2)}
                </div>
            </div>


            <div style={{ display: 'flex', justifyContent: 'end', marginTop: '40px', gap: '20px' }}>
                <button type="button" className='color-button' onClick={handleCancel}>
                    Cancel
                </button>
                <button type="button" className='color-button' onClick={handleSave} disabled={isLoading}>
                    {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Save'}
                </button>
            </div>
        </div>
    );
};

export default AddDealProposal;
