import { Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import iconImage from '../assets/Icon.png';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert2';



const Milestones = ({ projectId, projectName }) => {

  const [milestones, setMilestones] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [formData, setFormData] = useState({
    milestone_title: '',
    cost: '',
    status: '',
    add_to_budget: 'yes',
    summary: '',
    start_date: '',
    end_date: '',
  });
  const [isLoading, setIsLoading] = useState(false);




  // Function to fetch milestones
  const fetchMilestones = async () => {
    const token = localStorage.getItem('authToken');
  
    try {
      const response = await fetch(`https://api.myntask.com/project/milestone_list/${projectId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
  
      const data = await response.json();
      console.log("Fetched milestones:", data); // Log the API response
  
      setMilestones(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching milestones:", error);
      setLoading(false);
    }
  };
  


  // Fetch milestones on component mount and when projectId changes
  useEffect(() => {
    fetchMilestones();
  }, [projectId]);




  // Function to handle modal open
  const handleCreateMilestoneClick = () => {
    setIsModalOpen(true); // Open modal
  };




  // Function to handle modal close
  const handleCloseModal = () => {
    setIsModalOpen(false); // Close modal
  };




  // Function to handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };


  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('authToken'); // Get token from localStorage

    // Log the form data and project ID being sent
    console.log('Form data being sent:', {
        ...formData,
        project_id: parseInt(projectId, 10), // Send project ID from params
    });

    setIsLoading(true);
    try {
        const response = await fetch('https://api.myntask.com/project/milestone_save', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                ...formData,
                project_id: parseInt(projectId, 10), // Send project ID from params
            }),
        });

        // Log the response status and response data
        console.log('Response status:', response.status);

        if (!response.ok) {
            const errorText = await response.text();
            console.error('Network response was not ok:', errorText);
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        console.log('Milestone created successfully:', data);

        // Show success toast
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Milestone created successfully!',
            showConfirmButton: false,
            timer: 1500,
            toast: true,
        });

        // Close modal and clear form data
        setIsModalOpen(false);
        setFormData({
            milestone_title: '',
            cost: '',
            status: '',
            add_to_budget: 'yes',
            summary: '',
            start_date: '',
            end_date: '',
        });

        // Refetch milestones to update the list
        fetchMilestones();
    } catch (error) {
        console.error('Error creating milestone:', error);

        // Show error toast
        Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Error creating milestone!',
            text: error.message,
            showConfirmButton: false,
            timer: 1500,
            toast: true,
        });
    } finally {
        setIsLoading(false);
    }
};


const handleDelete = async (id) => {
  const token = localStorage.getItem("authToken");

  // Confirm deletion
  const result = await Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#d33",
    cancelButtonColor: "#3085d6",
    confirmButtonText: "Yes, delete it!",
  });

  if (result.isConfirmed) {
    try {
      const response = await fetch(`https://api.myntask.com/project/milestone_delete/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to delete: ${response.status}`);
      }

      Swal.fire({
        icon: "success",
        title: "Milestone deleted successfully!",
        timer: 1500,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
      });

      // Refresh the milestone list after deletion (if you have a fetch function)
      fetchMilestones(); // Call your function to reload data
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error deleting milestone!",
        text: error.message,
        timer: 2000,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
      });
    }
  }
};



  if (loading) {
    return <Typography>Loading Milestones...</Typography>;
  }




  return (
    <div style={{ padding: '10px 30px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <h1 className="title">
          {projectName} - Milestones
          <div className="underline"></div>
        </h1>
        <button className='color-button' onClick={handleCreateMilestoneClick}>
          + Create Milestone
        </button>
      </div>




      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Milestone Title</th>
            <th>Milestone Cost</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {milestones.map((milestone, index) => (
            <tr key={milestone.id}>
              <td>{index + 1}</td>
              <td>{milestone.milestone_title}</td>
              <td>
  ₹{milestone.cost && !isNaN(milestone.cost) ? Number(milestone.cost).toFixed(2) : "0"}
</td>

              <td>
                <span style={{ color: 'red', fontWeight: 'bold' }}>●</span> {milestone.status.charAt(0).toUpperCase() + milestone.status.slice(1)}
              </td>
              <td>
  <span onClick={() => handleDelete(milestone.id)} style={{ color: "red" }}>
    Delete
  </span>
</td>

            </tr>
          ))}
        </tbody>
      </table>




      {isModalOpen && (
        <>
          {/* Overlay */}
          <div className="modal-overlay-deal"  >
            <div className="modal-content-deal ">
              <ToastContainer />


              <img
                src={iconImage} // Replace 'iconImage' with the path or import for Icon.png
                alt="Close"
                style={{
                  position: "absolute",
                  top: "16px",
                  right: "16px",
                  border: "none",
                  cursor: "pointer",
                }}
                onClick={handleCloseModal}
              />




              <h3 className="modal-title-deal">Create Milestone</h3>


              <form onSubmit={handleSubmit} >
                <div className="form-grid-deal" >
                  <div className="form-field-group-deal" style={{ marginBottom: '15px' }}>
                    <label className="form-label-deal" style={{ display: 'flex', flexDirection: 'column' }} >
                      Milestone Title:
                      <input
                        className="form-input-deal"
                        type="text"
                        name="milestone_title"
                        value={formData.milestone_title}
                        onChange={handleChange}
                        required
                      />
                    </label>
                  </div>


                  <div className="form-field-group-deal" style={{ marginBottom: '15px' }}>
                    <label className="form-label-deal" style={{ display: 'flex', flexDirection: 'column' }}>
                      Cost:
                      <input
                        className="form-input-deal"
                        type="number"
                        name="cost"
                        value={formData.cost}
                        onChange={handleChange}
                      />
                    </label>
                  </div>


                  <div className="form-field-group-deal" style={{ marginBottom: '15px' }}>
                    <label className="form-label-deal" style={{ display: 'flex', flexDirection: 'column' }}>
                      Status:
                      <select
                        className="form-input-deal"
                        name="status"
                        value={formData.status}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Select an option
                        </option>
                        <option value="complete">Complete</option>
                        <option value="incomplete">Incomplete</option>
                      </select>
                    </label>
                  </div>

                  <div className="form-field-group-deal" style={{ marginBottom: '15px' }}>
                    <label className="form-label-deal" style={{ display: 'flex', flexDirection: 'column' }}>
                      Add to Budget:
                      <select
                        className="form-input-deal"
                        name="add_to_budget"
                        value={formData.add_to_budget}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Select an option
                        </option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </label>
                  </div>


                  <div className="form-field-group-deal" style={{ marginBottom: '15px' }}>
                    <label className="form-label-deal" style={{ display: 'flex', flexDirection: 'column' }}>
                      Start Date:
                      <input
                        className="form-input-deal"
                        type="date"
                        name="start_date"
                        value={formData.start_date}
                        onChange={handleChange}
                      />
                    </label>
                  </div>
                  <div className="form-field-group-deal" style={{ marginBottom: '15px' }}>
                    <label className="form-label-deal" style={{ display: 'flex', flexDirection: 'column' }}>
                      End Date:
                      <input
                        className="form-input-deal"
                        type="date"
                        name="end_date"
                        value={formData.end_date}
                        onChange={handleChange}
                      />
                    </label>
                  </div>
                </div>
                <div className="form-field-group-deal" style={{ marginBottom: '15px', width: '100%', gridColumn: 'span 3' }}>
                  <label className="form-label-deal" style={{ display: 'flex', flexDirection: 'column' }}>
                    Summary:
                    <textarea
                      name="summary"
                      className="form-input-deal"
                      value={formData.summary}
                      onChange={handleChange}
                    // style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ddd', minHeight: '100px' }}
                    />
                  </label>
                </div>


                <div className="form-button-group-deal">
                  <button type="submit" className="submit-button-deal" disabled={isLoading}>
                    {isLoading ? <CircularProgress size={20} color="inherit" /> : 'Save Milestone'}
                  </button>
                </div>
              </form>


            </div>
          </div>
        </>
      )}




    </div>
  );
};




export default Milestones;










