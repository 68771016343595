import React, { useState, useEffect } from "react";
import { Modal, Box } from "@mui/material";
import designationImage from '../assets/designation.png';
import iconImage from '../assets/Icon.png';
import './../Screens/css/Designation.css';
import Swal from "sweetalert2";

const Label = ({ projectId, projectName }) => {
  const [labels, setLabels] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [labelName, setLabelName] = useState("");
  const [description, setDescription] = useState("");


  const fetchLabels = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await fetch(
        `https://api.myntask.com/project/label_list/${projectId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setLabels(data);
        console.log('labels are', data);
      }
    } catch (err) {
      console.error("Error fetching labels:", err);
    }
  };


  useEffect(() => {

    if (projectId) {
      fetchLabels();
    }
  }, [projectId]);



const handleDeleteLabel = async (labelId) => {
  const token = localStorage.getItem("authToken");

  // Confirm deletion
  const result = await Swal.fire({
    title: "Are you sure?",
    text: "This label will be permanently deleted!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#d33",
    cancelButtonColor: "#3085d6",
    confirmButtonText: "Yes, delete it!",
  });

  if (result.isConfirmed) {
    try {
      const response = await fetch(`https://api.myntask.com/project/label_delete/${labelId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to delete label: ${response.status}`);
      }

      Swal.fire({
        icon: "success",
        title: "Label deleted successfully!",
        timer: 1500,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
      });

      // Refresh the label list after deletion (if you have a fetch function)
      fetchLabels(); // Call your function to reload data
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error deleting label!",
        text: error.message,
        timer: 2000,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
      });
    }
  }
};



  const handleSaveLabel = async () => {
      try {
          const token = localStorage.getItem("authToken");
          const response = await fetch("https://api.myntask.com/project/label_save", {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({
                  project_id: projectId,
                  label_name: labelName,
                  description: description,
              }),
          });
  
          if (!response.ok) {
              throw new Error("Failed to save label");
          }
  
          const newLabel = await response.json();
          setLabels((prevLabels) => [...prevLabels, newLabel]);
          setLabelName("");
          setDescription("");
          fetchLabels();
          setModalOpen(false); // Close modal on success
  
          // SweetAlert for success
          Swal.fire({
              icon: "success",
              title: "Label Added",
              text: "The label was successfully added!",
              position: 'top-end', // Position at top-right corner
              showConfirmButton: false,
              timer: 1500, // Auto-close after 1.5 seconds
              toast: true, // Enable toast
          });
      } catch (error) {
          console.error("Error saving label:", error);
  
          // SweetAlert for error
          Swal.fire({
              icon: "error",
              title: "Error",
              text: "Failed to save the label. Please try again.",
              position: 'top-end', // Position at top-right corner
              showConfirmButton: false,
              timer: 1500, // Auto-close after 1.5 seconds
              toast: true, // Enable toast
          });
      }
  };

  return (
    <div style={{ padding: '10px 30px' }}>
      <div className="top-controls">
        <h1 className="title">
          {projectName} - Labels
          <div className="underline"></div>
        </h1>
        <div className="buttons-row">
          <button
            className="color-button"
            onClick={() => setModalOpen(true)}
          >
            Add Label
          </button>
        </div>
      </div>
      {labels.length === 0 ? (
        <p>No labels available for this project.</p>
      ) : (
        <table >
          <thead>
            <tr>
              <th>ID</th>
              <th>Label Name</th>
              <th>Description</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {labels.map((label) => (
              <tr key={label.id}>
                <td>{label.id}</td>
                <td>{label.label_name}</td>
                <td>{label.description}</td>
                <td onClick={() => handleDeleteLabel(label.id)} style={{ color: "red", cursor: "pointer" }}>
  <span>Delete</span>
</td>

              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Add Label Modal */}
      <Modal open={isModalOpen} onClose={() => setModalOpen(false)}>
        <Box className="designation-modal">
          <div className="designation-header">
            <h1 className="designation-title">Add Label</h1>
            <img
              src={iconImage}
              alt="Close"
              className="designation-close"
              onClick={() => setModalOpen(false)}
            />
          </div>

          <img
            src={designationImage}
            alt="Designation"
            className="designation-image"
          />

          <div className="designation-form">
            <label className="designation-label">Label Name</label>
            <input
              type="text"
              value={labelName}
              onChange={(e) => setLabelName(e.target.value)}
              className="designation-input"
              placeholder="Enter label name"
            />

            <label className="designation-label">Description</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="designation-input"
              placeholder="Enter label description"
            />

            <button
              className="designation-save"
              onClick={handleSaveLabel}
            >
              Save
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Label;
