import React, { useState, useEffect } from 'react';
import { Button, Dialog, Typography } from '@mui/material';
import Swal from 'sweetalert2';
import dailyImage from '../assets/daily.jpg';
import CircularProgress from '@mui/material/CircularProgress';






const DailyUpdate = ({ open, handleClose, userProfile }) => {
  const [projects, setProjects] = useState([{ projectName: '' }]);
  const [projectList, setProjectList] = useState([]);
  // const [summary, setSummary] = useState('');
  const [challenges, setChallenges] = useState('');
  const [mood, setMood] = useState('Happy');
  const [images, setImages] = useState([]);
  const [alreadySubmitted, setAlreadySubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // New loading state






  // Fetch project list and check if the user has already submitted data today
  useEffect(() => {
    if (open) {
      const token = localStorage.getItem('authToken');
      const today = new Date().toLocaleDateString();
      const lastSubmitDate = localStorage.getItem('lastDailyUpdate');




      if (lastSubmitDate === today) {
        setAlreadySubmitted(true);
      } else {
        setAlreadySubmitted(false);
      }




      if (token) {
        fetch('https://api.myntask.com/task/project_list', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
          .then(response => response.json())
          .then(data => setProjectList(data || []))
          .catch(error => console.error('Error fetching project list:', error));
      } else {
        console.error('No auth token found.');
      }
    }
  }, [open]);








  const handleProjectChange = (index, value) => {
    const updatedProjects = [...projects];
    updatedProjects[index].projectName = value;
    setProjects(updatedProjects);
  };




  const addAnotherProject = () => {
    setProjects([...projects, { projectName: '', summary: '', tomorrowWork: '' }]);
  };








  const handleImageChange = (e) => {
    setImages([...e.target.files]);
  };




  const handleSubmit = async () => {
    if (alreadySubmitted) {
      Swal.fire({
        icon: 'error',
        title: 'Duplicate Submission',
        text: 'Your daily update data is already updated.',
        timer: 1500,
        showConfirmButton: false,
        toast: true,
        position: 'top-end',
      });
      return;
    }


    if (projects.length === 0 || projects.some(project => !project.projectName)) {
      Swal.fire({
        icon: 'error',
        title: 'Incomplete Data',
        text: 'Please fill in all required fields.',
        timer: 3000,
        showConfirmButton: false,
        toast: true,
        position: 'top-end',
      });
      return;
    }


    const token = localStorage.getItem('authToken');
    if (!token) {
      Swal.fire({
        icon: 'error',
        title: 'Authentication Error',
        text: 'Authentication token is missing.',
        timer: 1500,
        showConfirmButton: false,
        toast: true,
        position: 'top-end',
      });
      return;
    }


    const formData = new FormData();
    const projectIds = [];
    const summaries = [];
    const tomorrowWorks = [];


    projects.forEach(project => {
      if (project.projectName) {
        projectIds.push(project.projectName);
        summaries.push(project.summary);
        tomorrowWorks.push(project.tomorrowWork);
      }
    });


    projectIds.forEach(projectId => formData.append('project[]', projectId));
    summaries.forEach(summary => formData.append('summary[]', summary));
    tomorrowWorks.forEach(task => formData.append('tomorrow_work[]', task));
    formData.append('mood', mood.toLowerCase());
    formData.append('challenges', challenges);


    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          formData.append('latitude', latitude);
          formData.append('longitude', longitude);


          images.forEach(image => {
            formData.append('reports', image);
          });


          try {
            setIsLoading(true); // Show loading spinner
            const response = await fetch('https://api.myntask.com/home/daily_report', {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ${token}`,
              },
              body: formData,
            });


            setIsLoading(false); // Hide loading spinner
            handleClose();

            if (!response.ok) {
              throw new Error(`Network response was not ok. Status code: ${response.status}`);
            }


            Swal.fire({
              icon: 'success',
              title: 'Submission Successful',
              text: 'Your daily update has been submitted successfully.',
              timer: 1500,
              showConfirmButton: false,
              toast: true,
              position: 'top-end',
            });


            localStorage.setItem('lastDailyUpdate', new Date().toLocaleDateString());
          } catch (error) {
            setIsLoading(false); // Hide loading spinner
            console.error('Error submitting daily update:', error);
            Swal.fire({
              icon: 'error',
              title: 'Submission Failed',
              text: 'Failed to update the daily report. Please try again.',
              timer: 1500,
              showConfirmButton: false,
              toast: true,
              position: 'top-end',
            });
          }
        },
        (error) => {
          setIsLoading(false); // Hide loading spinner
          console.error('Error getting user location:', error);
          Swal.fire({
            icon: 'error',
            title: 'Location Error',
            text: 'Error getting user location.',
            timer: 1500,
            showConfirmButton: false,
            toast: true,
            position: 'top-end',
          });
        }
      );
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Geolocation Not Supported',
        text: 'Geolocation is not supported by this browser.',
        timer: 1500,
        showConfirmButton: false,
        toast: true,
        position: 'top-end',
      });
    }
  };


  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <div style={styles.dialogHeader}>
        Submit Daily Report
      </div>




      <div style={styles.container}>
        <div style={styles.formContainer}>
          {projects.map((project, index) => (
            <div key={index} style={styles.projectSection}>

              <div style={{ marginBottom: '10px' }}>
                <label className="lead-form-label" style={{ marginBottom: '5px' }}>
                  Project
                </label>
                <select
                  value={project.projectName}
                  onChange={(e) => handleProjectChange(index, e.target.value)}
                  style={styles.dailyinput}
                >
                  <option value="" disabled>Select a project</option>
                  {projectList.map(projectItem => (
                    <option key={projectItem.id} value={projectItem.id}>
                      {projectItem.project_name}
                    </option>
                  ))}
                </select>
              </div>

              <div style={{ marginBottom: '10px' }}>
                <label className="lead-form-label" style={{ marginBottom: '5px' }}>
                  Summary
                </label>
                <textarea
                  value={project.summary}
                  onChange={(e) => {
                    const updatedProjects = [...projects];
                    updatedProjects[index].summary = e.target.value;
                    setProjects(updatedProjects);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault(); // Prevent the default newline behavior
                      const updatedProjects = [...projects];
                      updatedProjects[index].summary += '\n• '; // Add a new bullet point
                      setProjects(updatedProjects);
                    }
                  }}
                  placeholder="Describe your main activities..."
                  rows="1"
                  style={styles.dailyinput}
                />
              </div>

              <div style={{ marginBottom: '10px' }}>
                <label className="lead-form-label" style={{ marginBottom: '5px' }}>
                  Tomorrow's Work
                </label>
                <textarea
                  value={project.tomorrowWork}
                  onChange={(e) => {
                    const updatedProjects = [...projects];
                    updatedProjects[index].tomorrowWork = e.target.value;
                    setProjects(updatedProjects);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      const updatedProjects = [...projects];
                      updatedProjects[index].tomorrowWork += '\n• ';
                      setProjects(updatedProjects);
                    }
                  }}
                  placeholder="What you plan to work on tomorrow..."
                  rows="1"
                  style={styles.dailyinput}
                />
              </div>

            </div>
          ))}
          <Button variant="outlined" onClick={addAnotherProject} style={styles.addButton}>
            ADD ANOTHER PROJECT
          </Button>

          <div style={{ marginBottom: '10px' }}>
            <label className="lead-form-label" style={{ marginBottom: '5px' }}>
              Challenges
            </label>
            <textarea
              value={challenges}
              onChange={(e) => setChallenges(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault(); // Prevent default newline behavior
                  setChallenges((prevChallenges) => `${prevChallenges}\n• `); // Add a new bullet point
                }
              }}
              placeholder="Blockers or challenges faced today..."
              rows="1"
              style={styles.dailyinput}
            />
          </div>

          <div style={{ marginBottom: '10px' }}>
            <label className='lead-form-label' style={{ marginBottom: '5px' }}>
              Mood
            </label>
            <select
              value={mood}
              onChange={(e) => setMood(e.target.value)}
              style={styles.dailyinput}
            >
              <option value="happy">😊 Happy</option>
              <option value="neutral">😐 Neutral</option>
              <option value="unhappy">😞 Unhappy</option>
            </select>
          </div>
          <Button variant="contained" component="label" style={styles.uploadButton}>
            UPLOAD IMAGES
            <input type="file" hidden multiple accept="image/*" onChange={handleImageChange} />
          </Button>
          <Typography variant="caption" style={{ marginTop: '4px', fontSize: '0.75rem' }}>
            {images.length > 0 ? `${images.length} image(s) selected` : "No images selected"}
          </Typography>
        </div>




        <div style={styles.helpSection}>
          <Typography variant="h6" style={styles.helpHeader}>
            Need Help?
          </Typography>
          <ul style={styles.helpList}>
            <li><strong>Project Name:</strong> Select the project you're working on.</li>
            <li><strong>Today's Work Update:</strong> Provide bullet-point updates for tasks completed today.</li>
            <li><strong>Plan for Tomorrow:</strong> List tasks planned for tomorrow in bullet points.</li>
            <li><strong>File Upload:</strong> Attach relevant files or images for each project.</li>
            <li><strong>Mood:</strong> Select your current mood from the dropdown.</li>
          </ul>
          <img
            src={dailyImage}
            alt="Daily Report Help"
            style={styles.helpImage}
          />
        </div>
      </div>




      <div style={styles.actionContainer}>
        <Button
          variant="contained"
          onClick={handleSubmit}
          style={styles.saveButton}
          disabled={isLoading} // Disable button while loading
        >
          {isLoading ? <CircularProgress size={24} color="inherit" /> : 'SAVE'}
        </Button>
        <Button
          variant="outlined"
          onClick={handleClose}
          style={styles.cancelButton}
        >
          CANCEL
        </Button>
      </div>
    </Dialog>
  );
};








export default DailyUpdate;




const styles = {
  dialogHeader: {
    textAlign: 'center',
    padding: '8px',
    fontWeight: 'bold',
    fontSize: '1.1rem',
    borderRadius: '10px 10px 0 0',
    backgroundColor: '#ffffff',
  },
  container: {
    display: 'flex',
    padding: '20px 40px',
    flexWrap: 'wrap',
    backgroundColor: '#ffffff',
    flexDirection: 'row',
    overflowY: 'auto', // Make the container scrollable
    maxHeight: '70vh', // Limit height on smaller screens
    '@media (max-width: 600px)': {
      padding: '10px',
      flexDirection: 'column',
    },
  },
  formContainer: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    '@media (max-width: 600px)': {
      width: '100%',
    },
  },
  projectSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  dailyinput: {
    width: '100%',
    padding: '6px',
    borderRadius: '6px',
    fontSize: '0.8rem',
  },
  addButton: {
    alignSelf: 'center',
    padding: '4px 8px',
    fontWeight: 'bold',
    fontSize: '0.8rem',
    '@media (max-width: 600px)': {
      width: '100%',
    },
  },
  uploadButton: {
    marginTop: '8px',
    fontWeight: '600',
    fontSize: '0.8rem',
    '@media (max-width: 600px)': {
      width: '100%',
    },
  },
  helpSection: {
    flex: 1,
    padding: '8px',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    marginLeft: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 600px)': {
      marginLeft: '0',
      marginTop: '16px',
      width: '100%',
    },
  },
  helpHeader: {
    fontWeight: 'bold',
    color: '#007BFF',
    fontSize: '1rem',
  },
  helpList: {
    paddingLeft: '16px',
    fontSize: '0.9rem',
    marginBottom: '16px',
    lineHeight: '1.5',
  },
  helpImage: {
    marginTop: '8px',
    width: '80%',
    height: 'auto',
    borderRadius: '6px',
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '16px 0',
    gap: '16px',
    backgroundColor: '#ffffff',
    position: 'sticky', // Keeps buttons visible at the bottom
    bottom: '0',
    '@media (max-width: 600px)': {
      flexDirection: 'column',
      gap: '8px',
      padding: '8px',
    },
  },
  saveButton: {
    fontWeight: 'bold',
    minWidth: '120px',
    fontSize: '0.9rem',
    padding: '8px 16px',
    borderRadius: '6px',
    backgroundColor: '#007BFF',
    color: '#fff',
    '@media (max-width: 600px)': {
      width: '100%',
    },
  },
  cancelButton: {
    fontWeight: 'bold',
    minWidth: '120px',
    fontSize: '0.9rem',
    padding: '8px 16px',
    borderRadius: '6px',
    backgroundColor: 'transparent',
    border: '1px solid #007BFF',
    color: '#007BFF',
    '@media (max-width: 600px)': {
      width: '100%',
    },
  },
};






