import React, { useState, useEffect } from "react";

const LeadActivity = ({ id }) => {
    const [activities, setActivities] = useState([]);

    const getActivities = async () => {
        try {
            const token = localStorage.getItem("authToken");
            const response = await fetch(`https://api.myntask.com/leads/getlist/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            setActivities(data);
        } catch (error) {
            console.error("Error fetching activity data:", error);
        }
    };

    useEffect(() => {
        getActivities();
    }, [id]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const month = date.toLocaleString("en-US", { month: "short" });
        const day = date.getDate();
        const time = date.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
        });
        return { month, day, time };
    };

    return (
        <div style={{ padding: '10px 30px' }}>
            <div className="top-controls">
                <h1 className='title'>Lead Activity
                    <div className="underline"></div>
                </h1>
            </div>
            {activities.map((activity) => {
                const { month, day, time } = formatDate(activity.created_at);
                return (
                    <div key={activity.id} style={{ display: "flex", alignItems: "center", borderBottom: "1px solid #ddd", padding: "12px 0" }}>
                        {/* Date Box */}
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "#f3f4f6",
                            color: "#333",
                            padding: "10px",
                            borderRadius: "8px",
                            marginRight: "16px",
                            minWidth: "50px"
                        }}>
                            <span style={{ fontSize: "12px", fontWeight: "600" }}>{month}</span>
                            <span style={{ fontSize: "18px", fontWeight: "bold" }}>{day}</span>
                        </div>
                        {/* Activity Message */}
                        <div>
                            <p style={{ color: "#111", fontWeight: "500", margin: "0" }}>{activity.activity}</p>
                            <span style={{ color: "#777", fontSize: "14px" }}>{time}</span>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default LeadActivity;