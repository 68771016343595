import React, { useEffect, useState, useRef } from 'react';
import AddDealModal from '../Components/AddDealModal';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FollowUpModal from '../Components/FollowUpModal';
import { Vortex } from 'react-loader-spinner';
import { Box, Stack, Typography } from '@mui/material';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { formatDate } from "../Components/MainFunction";

const Deals = () => {
  const [deals, setDeals] = useState([]);
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isFollowUpModalOpen, setIsFollowUpModalOpen] = useState(false);
  const [followUpDetails, setFollowUpDetails] = useState(null);  // State to hold follow-up details
  const [actionMenuVisible, setActionMenuVisible] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();


  const fetchDealsList = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('No token found');
      return;
    }


    try {
      const response = await fetch('https://api.myntask.com/leads/deals_list', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch deals');
      }
      const data = await response.json();
      setDeals(data);
      setIsLoading(false);
    } catch (err) {
      console.error('Error fetching deals:', err);
      setError('Failed to load deals.');
      setIsLoading(false);
    }
  };



  useEffect(() => {
    fetchDealsList();
  }, []);




  const handleCloseModal = () => {
    setIsModalOpen(false);
  };






  const handleSaveDeal = (newDeal) => {
    setDeals((prevDeals) => [...prevDeals, newDeal]);
  };




  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };


  const toggleActionMenu = (dealId) => {
    setActionMenuVisible((prev) => (prev === dealId ? null : dealId));
  };



  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setActionMenuVisible(null);
    }
  };


  useEffect(() => {
    // Add event listener for outside clicks
    document.addEventListener('mousedown', handleClickOutside);
    return () => {


      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);




  const handleViewFollowUp = (dealId) => {
    console.log('deal id is', dealId);
    setFollowUpDetails(dealId); // Set only the deal ID
    setIsFollowUpModalOpen(true); // Open the follow-up modal
  };




  const handleCloseFollowUpModal = () => {
    setIsFollowUpModalOpen(false);
    setFollowUpDetails(null);
    // window.location.reload(); // Reloads the entire page
  };



  const filteredDeals = deals.filter((deal) => {
    return Object.keys(deal).some((key) =>
      String(deal[key]).toLowerCase().includes(searchQuery.toLowerCase())
    );
  });


  const toTitleCase = (str) => {
    if (!str) return 'N/A'; // Handle empty or undefined strings
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const handleDeleteClick = async (dealId) => {
    try {
      // Ask for confirmation using SweetAlert
      const confirmResult = await Swal.fire({
        title: 'Are you sure?',
        text: 'Do you want to delete this lead? This action cannot be undone.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'Cancel',
        reverseButtons: true,
      });

      // If user cancels, exit the function
      if (!confirmResult.isConfirmed) {
        return;
      }

      // Retrieve token from localStorage
      const token = localStorage.getItem('authToken');
      if (!token) {
        Swal.fire({
          title: 'Error',
          text: 'Authorization token is missing!',
          icon: 'error',
          timer: 1500,
          showConfirmButton: false,
        });
        return;
      }

      // Make the DELETE API call
      const response = await fetch(`https://api.myntask.com/leads/lead_delete/${dealId}/`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      // Handle API response
      if (response.ok) {
        Swal.fire({
          title: 'Deleted!',
          text: 'The lead has been deleted successfully.',
          icon: 'success',
          timer: 1500,
          showConfirmButton: false,
        });

        await fetchDealsList(); // Fetch the updated list of leads
      } else {
        const errorData = await response.json();
        Swal.fire({
          title: 'Error',
          text: `Failed to delete lead: ${errorData.message || 'Unknown error'}`,
          icon: 'error',
          timer: 1500,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'An error occurred while deleting the lead.',
        icon: 'error',
        timer: 1500,
        showConfirmButton: false,
      });
      console.error('Error:', error);
    }
  };



  const handleConvertToClient = async (dealId) => {
    try {
      // Ask for confirmation using SweetAlert
      const confirmResult = await Swal.fire({
        title: 'Are you sure?',
        text: 'Do you want to convert this deal to a client?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, convert it!',
        cancelButtonText: 'Cancel',
        reverseButtons: true,
      });

      // If user cancels, exit the function
      if (!confirmResult.isConfirmed) {
        return;
      }

      // Retrieve token from localStorage
      const token = localStorage.getItem('authToken');
      if (!token) {
        Swal.fire({
          title: 'Error',
          text: 'Authorization token is missing!',
          icon: 'error',
          timer: 1500,
          showConfirmButton: false,
        });
        return;
      }

      // Make the POST API call to convert deal to client
      const response = await fetch(`https://api.myntask.com/leads/convert-to-client/${dealId}/`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      // Handle API response
      if (response.ok) {
        Swal.fire({
          title: 'Converted!',
          text: 'The deal has been converted to client successfully.',
          icon: 'success',
          timer: 1500,
          showConfirmButton: false,
        });

        await fetchDealsList(); // Refresh the deals list
      } else {
        const errorData = await response.json();
        Swal.fire({
          title: 'Error',
          text: `Failed to convert deal: ${errorData.message || 'Unknown error'}`,
          icon: 'error',
          timer: 1500,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'An error occurred while converting the deal.',
        icon: 'error',
        timer: 1500,
        showConfirmButton: false,
      });
      console.error('Error:', error);
    }
  };



  // Loading animation.......................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }



  return (
    <div className={`main-container ${isFollowUpModalOpen ? 'wrapped' : ''}`}>


      <div className="top-controls">
        <h1 className='title'>Deals
          <div className="underline"></div>
        </h1>


        <div className="buttons-row">


          {/* <button className="color-button" onClick={handleAddDealClick}>
            Add Deal
          </button> */}


        </div>
      </div>
      <div style={{ marginBottom: '20px' }}>
        <input
          type="text"
          placeholder="Search by name or email..."
          value={searchQuery}
          onChange={handleSearch}
          style={{
            width: '100%',
            padding: '8px 10px',
            fontSize: '14px',
            border: '1px solid var(--border-color)',
            borderRadius: '4px',
            backgroundColor: 'var(--secondary-color)',
            color: 'var(--text-color)',
          }}
        />
      </div>
      {error && <p style={{ color: 'var(--accent-color-3)' }}>{error}</p>}
      {filteredDeals.length > 0 ? (
        <table>
          <thead>
            <tr>

              <th style={{ width: '15vw' }}>Client Name</th>
              <th style={{ width: '15vw' }}>Company Name</th>
              <th style={{ width: '5vw' }}>Client Email</th>
              <th style={{ width: '10vw' }}>Assign to</th>
              <th style={{ textAlign: 'center', width: '10vw' }}>Next Follow-Up</th>
              <th style={{ width: '10vw' }}>Added By</th>
              <th style={{ width: '10vw' }}>Deal Value</th>
              <th style={{ width: '10vw' }}>Created at</th>
              <th style={{ width: '5vw' }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredDeals.map((deal) => (
              <tr key={deal.id}>
                <td>{toTitleCase(deal.client_name)}</td>
                <td style={{ cursor: 'pointer' }} onClick={() => navigate(`/deal-profile/${deal.id}`)}>{toTitleCase(deal.company_name)}</td>
                <td>{deal.client_email || 'N/A'}</td>
                <td>{toTitleCase(deal.assigned_user)}</td>
                {/* <td>{toTitleCase(deal.status)}</td> */}
                <td style={{ textAlign: 'center', color: 'red' }}>{formatDate(deal.next_follow_up || "N/A")}</td>
                <td>{toTitleCase(deal.name)}</td>
                <td>{deal.value || "N/A"}</td>
                <td>{formatDate(deal.created_at)}</td>
                <td>
                  <div className="action-container">
                    <div
                      className="action-dots"
                      onClick={() => toggleActionMenu(deal.id)}
                      style={{ cursor: 'pointer', position: 'relative', padding: '2px' }}
                    >
                      <MoreVertIcon />
                    </div>
                    {actionMenuVisible === deal.id && (
                      <div className="action-menu" ref={dropdownRef}>
                        <div className="action-item" onClick={() => handleViewFollowUp(deal.id)}>
                          Follow Up
                        </div>

                        <div className='action-item' onClick={() => navigate(`/deal-profile/${deal.id}`)}>
                          View
                        </div>

                        {deal.conversion_status === "converted" ? (
                          <div className="action-item" style={{ color: 'green', cursor: 'default' }}>
                            Converted
                          </div>
                        ) : (
                          <div className="action-item" onClick={() => handleConvertToClient(deal.id)}>
                            Convert
                          </div>
                        )}

                        <div
                          className="action-item"
                          onClick={() => handleDeleteClick(deal.id)}
                        >
                          Delete
                        </div>

                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No deals found for this lead.</p>
      )}


      {/* Follow Up Modal */}
      <FollowUpModal
        isOpen={isFollowUpModalOpen}
        onClose={handleCloseFollowUpModal}
        dealId={followUpDetails} // Pass the deal ID
      />


      {/* Use the AddDealModal component */}
      <AddDealModal isOpen={isModalOpen} onClose={handleCloseModal} onSave={handleSaveDeal} />


      {/* <AddFollowUpModal isOpen={isAddFollowUpModalOpen} onClose={handleCloseAddFollowUpModal} onFollowUpAdded={handleFollowUpAdded}
        dealId={selectedDealId} /> */}
    </div>
  );
};




export default Deals;








