
import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { useNavigate } from 'react-router-dom';

const GanttChart = ({ projectId, projectName }) => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();


  // Function to handle task name click
  const handleTaskNameClick = (taskId) => {
    navigate(`/task-detail/${taskId}`); // Navigate to the task detail page
  };

  useEffect(() => {
    const fetchGanttData = async () => {
      const token = localStorage.getItem("authToken");

      if (!projectId) {
        console.error("Project ID is not available");
        return;
      }

      try {
        const response = await fetch(`https://api.myntask.com/project/gantt_chart/${projectId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();
        console.log("Fetched Gantt Chart Data:", data);
        // Check if the fetched data contains tasks
        if (data && Array.isArray(data) && data.length > 0) {
          setTasks(data); // Set tasks if available
        } else {
          setTasks([]); // No tasks available, so set to an empty array
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching Gantt chart data:", error);
        setLoading(false);
      }
    };

    fetchGanttData();
  }, [projectId]);


  useEffect(() => {
    const fetchTasks = async () => {
      const token = localStorage.getItem('authToken');
      const url = `https://api.myntask.com/project/tasks/${projectId}`;
      try {
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });


        if (!response.ok) {
          throw new Error('Failed to fetch tasks');
        }


        const data = await response.json();
        setTasks(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching tasks:', error);
        setLoading(false);
      }
    };


    fetchTasks();
  }, [projectId]);


  const chartData = [
    [
      { type: "string", id: "Task ID" },
      { type: "string", id: "Task Name" },
      { type: "date", id: "Start Date" },
      { type: "date", id: "End Date" },
      { type: "number", id: "Duration" },
      { type: "number", id: "Percent Complete" },
      { type: "string", id: "Dependencies" },
    ],
    ...tasks.map((task) => {
      // Check if start_date and due_date exist, otherwise default to current date
      const startDate = task.start_date ? new Date(task.start_date) : new Date();
      const endDate = task.due_date ? new Date(task.due_date) : new Date(startDate.getTime() + 86400000); // +1 day

      return [
        `Task_${task.task_id}`,
        task.task,
        startDate,
        endDate,
        null,
        task.status === "Completed" ? 100 : 0,
        null,
      ];
    }),
  ];

  const options = {
    height: tasks.length * 50 + 100,
    // gantt: {
    //   trackHeight: 40,
    //   criticalPathEnabled: false,
    //   palette: [
    //     {
    //       color: "#f4c542", // Yellow for incomplete tasks
    //       dark: "#c29107",
    //       light: "#f6db94",
    //     },
    //     {
    //       color: "#e44d42", // Red for completed tasks
    //       dark: "#a32b1f",
    //       light: "#f7a59e",
    //     },
    //   ],
    // },
  };


  // Chart event handler
  const chartEvents = [
    {
      eventName: "select",
      callback: ({ chartWrapper }) => {
        const chart = chartWrapper.getChart();
        const selection = chart.getSelection();
        if (selection.length > 0) {
          const selectedRow = selection[0].row;
          const taskId = tasks[selectedRow].task_id; // Get the task ID from the selected row
          handleTaskNameClick(taskId); // Navigate to the task detail page
        }
      },
    },
  ];

  if (loading) {
    return <p>Loading Gantt chart...</p>;
  }

  // If no tasks are available, show a message
  if (tasks.length === 0) {
    return (
      <div style={{ textAlign: "center" }}>
        <p>No tasks available for this project.</p>
      </div>
    );
  }

  return (
    <div style={{ padding: '10px 30px' }}>
    <div className="top-controls">
      <h1 className='title' style={{ marginBottom: '40px' }}>{projectName} - Gantt Chart
        <div className="underline"></div>
      </h1>
      </div>
      <Chart
        chartType="Gantt"
        width="100%"
        height={`${options.height}px`}
        data={chartData}
        options={options}
        chartEvents={chartEvents}
      />
    </div>
  );
};

export default GanttChart;
