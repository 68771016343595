

import React, { useState, useEffect } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { toast, ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { Box, Modal, Stack } from "@mui/material";
import { Vortex } from 'react-loader-spinner';


const AddPurchaseRequistion = () => {
    const [requisitionDetails, setRequisitionDetails] = useState({
        requisitionDate: '',
        project: '',
        productCategory: '',
        subCategory: '',
        description: '',
        items: [{ productName: '', quantity: 0, unitPrice: 0, itemDescription: '' }],
    });
    const [projects, setProjects] = useState([]);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [token, setToken] = useState('');
    const [products, setProducts] = useState([]); // State to store the product data
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();


    // Fetch project list and product categories when component loads
    useEffect(() => {
        const authToken = localStorage.getItem('authToken');
        setToken(authToken);
        // Fetch project list
        const fetchProjects = async () => {
            try {
                const response = await fetch('https://api.myntask.com/task/project_list', {
                    headers: {
                        'Authorization': `Bearer ${authToken}`,
                    },
                });
                const projectData = await response.json();
                console.log('Project List:', projectData);
                setProjects(projectData);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching project list:', error);
                setError('Failed to fetch project list');
                setIsLoading(false);
            }
        };



        // Fetch product categories
        const fetchCategories = async () => {
            try {
                const response = await fetch('https://api.myntask.com/product/category', {
                    headers: {
                        'Authorization': `Bearer ${authToken}`,
                    },
                });
                const categoryData = await response.json();
                console.log('Product Categories:', categoryData);
                setCategories(categoryData);
            } catch (error) {
                console.error('Error fetching product categories:', error);
                setError('Failed to fetch product categories . Please refresh the page and try again');
            }
        };
        fetchProjects();
        fetchCategories();
    }, []);


    const fetchSubCategories = async (categoryId) => {
        const token = localStorage.getItem('authToken');
        try {
            const response = await fetch(`https://api.myntask.com/product/sub_category/${categoryId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            if (response.ok) {
                const subCategoryData = await response.json();
                console.log('Sub-categories:', subCategoryData);
                setSubCategories(subCategoryData); // Update state with fetched subcategories
            } else {
                console.error('Failed to fetch sub-categories:', response.status);
            }
        } catch (error) {
            console.error('Error fetching sub-categories:', error);
            setError('Failed to fetch sub-categories');
        }
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;


        setRequisitionDetails((prevState) => {
            const updatedDetails = { ...prevState, [name]: value };


            console.log("Input changed:", { field: name, value });
            console.log("Updated requisitionDetails:", updatedDetails);


            return updatedDetails;
        });


        // If product category is changed, find the corresponding ID and fetch subcategories
        if (name === 'productCategory') {
            const selectedCategory = categories.find(
                (category) => category.category_name === value
            );
            if (selectedCategory) {
                fetchSubCategories(selectedCategory.id); // Send the category ID to fetch subcategories
            }
        }


        // If value is cleared, ensure products list is cleared
        if (name === "productCategory" || name === "subCategory") {
            if (!value) {
                setProducts([]); // Clear products if no category or subcategory is selected
            }
        }
    };






    const handleItemChange = (index, event) => {
        const { name, value } = event.target;
        const updatedItems = [...requisitionDetails.items];
        updatedItems[index] = {
            ...updatedItems[index],
            [name]: value, // Dynamically update the field (productId, quantity, unitPrice, itemDescription, etc.)
        };
        setRequisitionDetails({
            ...requisitionDetails,
            items: updatedItems,
        });
    };








    useEffect(() => {
        const fetchProductDropdown = async () => {
            const token = localStorage.getItem('authToken');


            if (!token) {
                console.error("Authorization token is missing!");
                return;
            }


            console.log('Fetching products...');


            const selectedCategory = categories.find(
                (category) => category.category_name === requisitionDetails.productCategory
            );
            const selectedSubCategory = subCategories.find(
                (subCategory) => subCategory.category_name === requisitionDetails.subCategory
            );


            const categoryId = selectedCategory ? selectedCategory.id : '';
            const subCategoryId = selectedSubCategory ? selectedSubCategory.id : '';


            console.log('Selected categoryId:', categoryId);
            console.log('Selected subCategoryId:', subCategoryId);


            // Check if the selected category and subcategory are valid
            if (!categoryId || !subCategoryId) {
                console.warn('Category or sub-category is not selected or invalid.');
                return; // Prevent API call if IDs are not found
            }


            try {
                const apiUrl = `https://api.myntask.com/product/product_dropdown?category_id=${categoryId}&subcategory_id=${subCategoryId}`;
                console.log('API URL:', apiUrl);


                const response = await fetch(apiUrl, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });


                if (response.ok) {
                    const data = await response.json();
                    console.log('API Response:', data);


                    if (Array.isArray(data)) {
                        setProducts(data);
                        console.log('Products set:', data);
                    } else {
                        console.error('Unexpected API response format:', data);
                        setProducts([]);
                    }
                } else {
                    console.error('Failed to fetch product dropdown:', response.status);
                    setProducts([]);
                }
            } catch (error) {
                console.error('Error fetching product dropdown:', error);
                setProducts([]);
            }
        };


        fetchProductDropdown();
    }, [requisitionDetails.productCategory, requisitionDetails.subCategory, categories, subCategories]);






    const addItem = () => {
        setRequisitionDetails({
            ...requisitionDetails,
            items: [...requisitionDetails.items, { productName: '', quantity: 0, unitPrice: 0, itemDescription: '' }],
        });
    };




    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Requisition Submitted:', requisitionDetails);
    };




    const downloadPDF = () => {
        const input = document.getElementById('purchase-requisition');
        html2canvas(input, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgWidth = 210; // A4 width in mm
            const pageHeight = 295; // A4 height in mm
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;


            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;


            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }


            pdf.save('purchase-requisition.pdf');
        });
    };








    // Function to send form data to the backend
    const saveRequisition = async () => {
        console.log('Categories:', categories);
        console.log('Subcategories:', subCategories);
        console.log('Projects:', projects);
        console.log('Requisition Details:', requisitionDetails);




        const selectedCategory = categories.find(cat => cat.category_name === requisitionDetails.productCategory);
        const selectedSubCategory = subCategories.find(sub => sub.category_name === requisitionDetails.subCategory);
        const selectedProject = projects.find(proj => proj.project_name === requisitionDetails.project);




        console.log('Selected Category:', selectedCategory);
        console.log('Selected Subcategory:', selectedSubCategory);
        console.log('Selected Project:', selectedProject);




        const postData = {
            category_id: selectedCategory ? selectedCategory.id : null,
            sub_category_id: selectedSubCategory ? selectedSubCategory.id : null,
            project_id: selectedProject ? selectedProject.id : null,
            description: requisitionDetails.description,
            products: requisitionDetails.items.map((item) => ({
                product_id: item.productId || null, // Use the correct product ID
                qty: item.quantity,
                description: item.itemDescription,
            })),
        };


        console.log('Post Data:', postData); // Log the post data for debugging
        setIsLoading(true);


        try {
            const response = await fetch('https://api.myntask.com/purchase_requisition/save', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(postData),
            });




            if (!response.ok) {
                throw new Error('Failed to save requisition');
            }


            const responseData = await response.json();
            console.log('Requisition saved successfully:', responseData);
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Requisition saved successfully',
                showConfirmButton: false,
                timer: 3000
            });
            navigate('/purchase-requisitions');
            setIsLoading(false);
        } catch (error) {
            console.error('Error saving requisition:', error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Failed to save requisition',
            });
        }
        setIsLoading(false);
    };




    const handleCancel = () => {
        navigate(-1);
    }






    //..........Loading Spinner ..........................................
    useEffect(() => {
        // Adjust the timer duration here (e.g., 3000ms = 3 seconds)
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 300);

        // Cleanup the timer on component unmount
        return () => clearTimeout(timer);
    }, []);


    if (isLoading) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    backgroundColor: "#f5f5f5",
                }}
            >
                <Stack alignItems="center">
                    <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
                    <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
                        Loading....
                    </Box>
                </Stack>
            </Box>
        );
    }


    // if (error) {
    //     return (
    //         <Typography
    //             color="error"
    //             sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
    //         >
    //             {error}
    //         </Typography>
    //     );
    // }










    return (
        <div>
            {/* Download Button */}




            <div id="purchase-requisition" style={styles.container}>
                <h2 style={styles.heading}>Purchase Requisition</h2>
                <form onSubmit={handleSubmit} style={styles.form}>
                    {/* Requisition Date */}
                    <div style={styles.formRow}>
                        <div style={styles.field}>
                            <label style={styles.label}>Requisition Date:</label>
                            <input
                                type="date"
                                name="requisitionDate"
                                value={requisitionDetails.requisitionDate}
                                onChange={handleInputChange}
                                required
                                style={styles.input}
                            />
                        </div>
                    </div>








                    {/* Project */}
                    <div style={styles.formRow}>
                        <div style={styles.field}>
                            <label style={styles.label}>Project:</label>
                            <select
                                name="project"
                                value={requisitionDetails.project}
                                onChange={handleInputChange}
                                required
                                style={styles.input}
                            >
                                <option value="">Select Project</option>
                                {projects.map((project) => (
                                    <option key={project.id} value={project.project_name}>
                                        {project.project_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>








                    {/* Product Category */}
                    <div style={styles.formRow}>
                        <div style={styles.field}>
                            <label style={styles.label}>Product Category:</label>
                            <select
                                name="productCategory"
                                value={requisitionDetails.productCategory}
                                onChange={handleInputChange}


                                style={styles.input}
                            >
                                <option value="">Select Product Category</option>
                                {categories.map((category) => (
                                    <option key={category.id} value={category.category_name}>
                                        {category.category_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>








                    {/* Sub-category */}
                    <div style={styles.formRow}>
                        <div style={styles.field}>
                            <label style={styles.label}>Sub-category:</label>
                            <select
                                name="subCategory"
                                value={requisitionDetails.subCategory}
                                onChange={handleInputChange}


                                style={styles.input}
                            >
                                <option value="">Select Sub-category</option>
                                {subCategories.map((subCategory) => (
                                    <option key={subCategory.id} value={subCategory.category_name}>
                                        {subCategory.category_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>








                    {/* Description */}
                    <div style={styles.formRow}>
                        <div style={styles.field}>
                            <label style={styles.label}>Description:</label>
                            <textarea
                                name="description"
                                value={requisitionDetails.description}
                                onChange={handleInputChange}
                                required
                                style={{ ...styles.input, height: '100px' }}
                            />
                        </div>
                    </div>








                    {/* Item Details */}


                    <div>
                        <h3 style={styles.subHeading}>Item Details</h3>
                        <table style={styles.table}>
                            <thead>
                                <tr>
                                    <th style={styles.tableThTd}>Product Name</th>
                                    <th style={styles.tableThTd}>Quantity</th>
                                    <th style={styles.tableThTd}>Unit Price</th>
                                    <th style={styles.tableThTd}>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                {requisitionDetails.items.map((item, index) => (
                                    <tr key={index}>
                                        <td style={styles.tableThTd}>
                                            <select
                                                name="productId"
                                                value={item.productId || ''}
                                                onChange={(e) => handleItemChange(index, e)}
                                                style={styles.input}
                                                required
                                            >
                                                <option value="">Select a product</option>
                                                {products && products.length > 0 ? (
                                                    products.map((product) => (
                                                        <option key={product.id} value={product.id}>
                                                            {product.name}
                                                        </option>
                                                    ))
                                                ) : (
                                                    <option value="">No products available</option>
                                                )}
                                            </select>
                                        </td>
                                        <td style={styles.tableThTd}>
                                            <input
                                                type="number"
                                                name="quantity"
                                                value={item.quantity || ''}
                                                onChange={(e) => handleItemChange(index, e)}
                                                style={styles.input}
                                                required
                                            />
                                        </td>
                                        <td style={styles.tableThTd}>
                                            <input
                                                type="number"
                                                name="unitPrice"
                                                value={item.unitPrice || ''}
                                                onChange={(e) => handleItemChange(index, e)}
                                                style={styles.input}
                                                required
                                            />
                                        </td>
                                        <td style={styles.tableThTd}>
                                            <input
                                                type="text"
                                                name="itemDescription"
                                                value={item.itemDescription || ''}
                                                onChange={(e) => handleItemChange(index, e)}
                                                style={styles.input}
                                                required
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <button type="button" style={styles.addButton} onClick={addItem}>
                            + Add Item
                        </button>
                    </div>






                    <div style={{ display: 'flex', justifyContent: 'end', marginTop: '20px', gap: '20px' }}>
                        <button className='color-button' onClick={handleCancel}>Cancel</button>
                        <button type="button" className='color-button' onClick={saveRequisition}>
                            Save Requisition
                        </button>
                    </div>
                </form>
            </div>








            {/* Toast Notification */}
            <ToastContainer />
        </div>
    );
};








const styles = {
    container: {
        padding: '30px',
        maxWidth: '900px',
        margin: '0 auto',
        backgroundColor: '#f8f9fa',
        borderRadius: '8px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
        border: '1px solid #e9ecef',
    },
    heading: {
        fontSize: '28px',
        color: '#0046ad',
        marginBottom: '20px',
        textAlign: 'center',
        borderBottom: '2px solid #007bff',
        paddingBottom: '10px',
    },
    subHeading: {
        fontSize: '20px',
        color: '#333',
        marginBottom: '15px',
        fontWeight: '600',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    },
    formRow: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '20px',
    },
    field: {
        flex: 1,
        marginRight: '20px',
    },
    label: {
        display: 'block',
        fontWeight: 'bold',
        marginBottom: '8px',
        color: '#495057',
    },
    input: {
        width: '100%',
        padding: '10px',
        borderRadius: '5px',
        border: '1px solid #ced4da',
        fontSize: '16px',
        boxSizing: 'border-box',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        marginBottom: '20px',
    },
    tableThTd: {
        border: '1px solid #dee2e6',
        padding: '12px',
        textAlign: 'left',
        fontSize: '16px',
    },
    addButton: {
        padding: '10px',
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        marginTop: '10px',
    },
    submitButton: {
        padding: '12px 30px',
        backgroundColor: '#28a745',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: 'bold',
        alignSelf: 'flex-start',
    },
    downloadButton: {
        position: 'fixed',
        top: '10px',
        right: '10px',
        padding: '10px 15px',
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
};








export default AddPurchaseRequistion;






















































































