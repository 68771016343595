import { Box, Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import iconImage from '../assets/Icon.png';
import './../Screens/css/Designation.css';

const Documents = ({ userId }) => {
  const [documents, setDocuments] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const [newCategoryName, setNewCategoryName] = useState('');
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [isAddCategoryModalOpen, setIsAddCategoryModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    category_id: "", // Default value; change if needed
    user_id: userId,
    name: '',
    file: null,
  });

  const navigate = useNavigate();

  const fetchCategories = async () => {
    const token = localStorage.getItem('authToken');

    if (!token) {
      setError('No token found. Please log in.');
      return;
    }

    try {
      const response = await fetch('https://api.myntask.com/employee/document_category_dropdown', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch categories');
      }

      const result = await response.json();
      console.log('Categories:', result);
      setCategories(result);

    } catch (err) {
      console.error('Error fetching categories:', err);
      setError('Failed to fetch categories');
    }
  };


  useEffect(() => {
    fetchCategories();
  }, []);

  const handleSelectCategory = (e) => {
    const selectedCategoryId = e.target.value;
    setSelectedCategory(selectedCategoryId);
  };

  const fetchDocuments = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      console.error("Auth token is missing or invalid");
      return;
    }
    try {
      // Include selected category in the API URL
      const response = await fetch(
        `https://api.myntask.com/employee/document_list/${userId}?category_id=${selectedCategory || ''}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );


      if (response.ok) {
        const data = await response.json();
        setDocuments(data);
      } else {
        console.error('Failed to fetch documents');
      }
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  };

  useEffect(() => {

    fetchDocuments(); // Fetch when userId or selectedCategory changesss
  }, [userId, selectedCategory]); // Include selectedCategory as a dependency


  const handleAddCategory = () => {
    setIsAddCategoryModalOpen(true);
  };


  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle file input change
  const handleFileChange = (e) => {
    setFormData((prev) => ({ ...prev, file: e.target.files[0] }));
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    // Log form data before sending to API
    console.log('Form Data Before Submit:', formData);

    if (!formData.file) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Please upload a file before submitting.',
        timer: 2000,
        showConfirmButton: false,
      });
      return;
    }

    const token = localStorage.getItem('authToken');
    if (!token) {
      Swal.fire({
        icon: 'error',
        title: 'Authentication Error',
        text: 'Auth token is missing or invalid',
        timer: 3000,
        showConfirmButton: false,
      });
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append('category_id', formData.category_id);
    formDataToSend.append('user_id', formData.user_id);
    formDataToSend.append('name', formData.name);
    formDataToSend.append('file', formData.file);

    // Log FormData content
    for (let [key, value] of formDataToSend.entries()) {
      console.log(`${key}:`, value);
    }

    try {
      const response = await fetch('https://api.myntask.com/employee/document_save', {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${token}` },
        body: formDataToSend,
      });

      if (response.ok) {
        const newDocument = await response.json();
        // Update documents state if necessary
        console.log('Document Saved:', newDocument);
        // Trigger a re-fetch of documents
        await fetchDocuments();

        // // Add the newly uploaded document to the documents list
        // setDocuments((prevDocs) => [newDocument, ...prevDocs]);

        Swal.fire({
          icon: 'success',
          title: 'Document Saved',
          text: 'The document has been successfully saved.',
          timer: 1500,
          showConfirmButton: false,
        });
        // await fetchDocuments();
        setIsModalOpen(false);

      } else {
        setIsModalOpen(false);
        Swal.fire({
          icon: 'error',
          title: 'Error Saving Document',
          text: 'Failed to save document. Please try again later.',
          timer: 1500,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error('Error saving document:', error);
      setIsModalOpen(false);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while saving the document. Please try again later.',
        timer: 1500,
        showConfirmButton: false,
      });
    }
  };

  const handleDelete = async (documentId) => {
    // Show confirmation dialog using SweetAlert2
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this document?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    });

    // If user confirmed deletion, proceed with the API call
    if (result.isConfirmed) {
      const token = localStorage.getItem('authToken');
      if (!token) {
        Swal.fire({
          icon: 'error',
          title: 'Authentication Error',
          text: 'Auth token is missing or invalid',
          timer: 3000,
          showConfirmButton: false,
        });
        return;
      }

      try {
        // Make API call to delete the document
        const response = await fetch(`https://api.myntask.com/employee/document_delete/${documentId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          // If the delete is successful, remove the document from the state
          setDocuments((prevDocs) => prevDocs.filter((doc) => doc.id !== documentId));

          Swal.fire({
            icon: 'success',
            title: 'Document Deleted',
            text: 'The document has been successfully deleted.',
            timer: 1500,
            showConfirmButton: false,
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error Deleting Document',
            text: 'Failed to delete document. Please try again later.',
            timer: 1500,
            showConfirmButton: false,
          });
        }
      } catch (error) {
        console.error('Error deleting document:', error);

        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An error occurred while deleting the document. Please try again later.',
          timer: 1500,
          showConfirmButton: false,
        });
      }
    }
  };




  const handleCategorySubmit = async (e) => {
    e.preventDefault(); // Prevent page refresh on form submission

    const token = localStorage.getItem('authToken'); // Get the token from localStorage
    if (!token) {
      Swal.fire({
        icon: 'error',
        title: 'Authentication Error',
        text: 'Auth token is missing or invalid',
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }

    const categoryData = { name: newCategoryName };

    try {
      const response = await fetch('https://api.myntask.com/employee/document_category_save', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(categoryData), // Send the category data as JSON
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Category added:', data);

        Swal.fire({
          icon: 'success',
          title: 'Category Added',
          text: `The category "${newCategoryName}" has been successfully added.`,
          timer: 1500,
          showConfirmButton: false,
        });

        setIsAddCategoryModalOpen(false); // Close the modal after category is added

      } else {
        setIsAddCategoryModalOpen(false); // Close the modal after category is added
        Swal.fire({
          icon: 'error',
          title: 'Error Adding Category',
          text: 'Failed to add the category. Please try again later.',
          timer: 1500,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error('Error adding category:', error);
      setIsAddCategoryModalOpen(false); // Close the modal after category is added
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while adding the category. Please try again later.',
        timer: 1500,
        showConfirmButton: false,
      });
    }
  };

  const handleCategoryChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      category_id: e.target.value, // Update category_id in formData
    }));
  };





  return (
    <div className="main-container">
      <div className="top-controls">
        <h1 className='title'>Documents
          <div className="underline"></div>
        </h1>
        <div className="buttons-row">
          <button className="color-button" onClick={() => setIsModalOpen(true)}>
            Add Document
          </button>
        </div>
      </div>
      <table >
        <thead>
          <tr>

            <th>Name</th>
            <th>File</th>
            <th> <select
              value={selectedCategory}
              onChange={handleSelectCategory}
              className="header-filter"
            >
              <option value="">All Categories</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select></th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {documents.map((document) => (
            <tr key={document.id}>

              <td>{document.name}</td>
              <td >
                <a href={document.file} target="_blank" rel="noopener noreferrer">
                  View File
                </a>
              </td>
              <td>{document.category_name}</td>
              <td>
                <span
                  onClick={() => handleDelete(document.id)}
                  style={{ color: 'red', backgroundColor: 'transparent' }}
                >
                  Delete
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="add-document-modal"
        aria-describedby="modal-for-adding-document"
      >
        <Box
          className='designation-modal'
        >
          <div className='designation-header'>
            <h1 className="designation-title" >
              Add Document
            </h1>
            <img
              src={iconImage}
              alt="Close"
              className='designation-close'
              onClick={() => setIsModalOpen(false)}
            />
          </div>

          <div className='designation-form'>
            <label className='designation-label'>Document Name:<span className="required">*</span> </label>
            <input
              type="text"
              id="name"
              name="name"
              className='designation-input'
              value={formData.name}
              onChange={handleInputChange}
              required
            />

            <label className='designation-label'>Category:<span className="required">*</span> </label>
            <div style={{ display: 'flex', gap: '10px' }}>
              <select
                id="categoryId"
                value={formData.category_id} // Set default value to '' if categoryId is null
                onChange={handleCategoryChange} // Update categoryId when a category is selected
                className='designation-input'
                onFocus={fetchCategories} // Fetch categories when the dropdown is focused
              >
                <option value="" disabled>
                  Select a Category
                </option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>

              <button className="color-button" onClick={handleAddCategory}
                style={{ marginBottom: '15px' }}>Add </button>

            </div>

            <label className='designation-label'>Upload File:<span className="required">*</span></label>
            <input
              type="file"
              id="file"
              name="file"
              className='designation-input'
              onChange={handleFileChange}
              required
            />
          </div>
          <button onClick={handleSubmit} className='designation-save'>Save</button>
        </Box>
      </Modal>

      <Modal
        open={isAddCategoryModalOpen}
        onClose={() => setIsAddCategoryModalOpen(false)}
      >
        <Box
          sx={{
            p: 3,
            backgroundColor: "white",
            margin: "auto",
            mt: "15%",
            maxWidth: "350px",
            borderRadius: "16px",
          }}
        >
          {/* Modal Header */}
          <div className="designation-header">
            <h1 className="designation-title">Add New Category</h1>
            <img
              src={iconImage} // Replace 'iconImage' with the correct image path or import
              alt="Close"
              className="designation-close"
              onClick={() => setIsAddCategoryModalOpen(false)}
            />
          </div>

          {/* Input Field */}
          <div className="designation-form">
            <label className="designation-label">Category Name:</label>
            <input
              type="text"
              id="categoryName"
              value={newCategoryName}
              onChange={(e) => setNewCategoryName(e.target.value)}
              placeholder="Enter category name"
              className="designation-input"
            />
          </div>


          <button className="designation-save" onClick={handleCategorySubmit}>
            Save
          </button>

        </Box>
      </Modal>

    </div>
  );
};

export default Documents;
