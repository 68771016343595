import React from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";

const ProposalReport = ({ reportData }) => {
    return (
        <Grid item xs={12}>
            <Card sx={{ boxShadow: 3 }}>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        Proposal Report
                    </Typography>
                    <table style={{ width: "100%", borderCollapse: "collapse" }}>
                        <thead>
                            <tr style={{ backgroundColor: "#f3f3f3" }}>
                                <th style={{ border: "1px solid #ddd", padding: "8px" }}>Category</th>
                                <th style={{ border: "1px solid #ddd", padding: "8px" }}>Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reportData.proposals.map((item, index) => (
                                <tr key={index}>
                                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>{item.name}</td>
                                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>{item.value}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default ProposalReport;