// src/AddSuperAdminBill.js
import React, { useState } from 'react';

const AddSuperAdminBill = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    userRole: '',
    image: null,
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
  });

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    setFormData({
      ...formData,
      image: e.target.files[0],
    });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { name: '', email: '' };

    if (formData.name.trim() === '') {
      newErrors.name = 'Name is required';
      valid = false;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
      newErrors.email = 'Email is invalid';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formDataToSend = new FormData();
      formDataToSend.append('name', formData.name);
      formDataToSend.append('email', formData.email);
      if (formData.image) {
        formDataToSend.append('image', formData.image);
      }

      console.log('Form Data:', formDataToSend);

      fetch('http://api.myntask.com/superadmin/save', {
        method: 'POST',
        body: formDataToSend,
      })
        .then(response => {
          console.log('Response Status:', response.status);
          return response.text(); // Change to .text() for non-JSON response
        })
        .then(data => {
          console.log('Response Data:', data);
          if (data.includes('save successfully')) { // Check for success text
            setShowSuccessModal(true);
            setFormData({
              name: '',
              email: '',
              userRole: '',
              image: null,
            });
            setErrors({
              name: '',
              email: '',
            });
          } else {
            console.error('Submission failed:', data);
          }
        })
        .catch(error => {
          console.error('There was an error submitting the form!', error);
        });
    }
  };

  const handleCancel = () => {
    setFormData({
      name: '',
      email: '',
      userRole: '',
      image: null,
    });
    setErrors({
      name: '',
      email: '',
    });
  };

  const handleCloseModal = () => {
    setShowSuccessModal(false);
  };

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <h2 style={styles.header}>Add Super Admin</h2>
        <form onSubmit={handleSubmit}>
          <div style={styles.formGroup}>
            <label style={styles.label} htmlFor="name">Name</label>
            <input
              style={styles.input}
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
            {errors.name && <p style={styles.error}>{errors.name}</p>}
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label} htmlFor="email">Email</label>
            <input
              style={styles.input}
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <p style={styles.error}>{errors.email}</p>}
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label} htmlFor="userRole">User Role</label>
            <input
              style={styles.input}
              type="text"
              id="userRole"
              name="userRole"
              value={formData.userRole}
              onChange={handleChange}
            />
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label} htmlFor="image">Upload Image</label>
            <input
              style={styles.input}
              type="file"
              id="image"
              name="image"
              onChange={handleImageChange}
            />
          </div>
          <div style={styles.buttonGroup}>
            <button type="submit" style={styles.saveButton}>Save</button>
            <button type="button" style={styles.cancelButton} onClick={handleCancel}>Cancel</button>
          </div>
        </form>
      </div>
      {showSuccessModal && (
        <div style={styles.modal}>
          <div style={styles.modalContent}>
            <h3>Success</h3>
            <p>Data successfully submitted!</p>
            <button style={styles.closeModalButton} onClick={handleCloseModal}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f0f0f0',
  },
  card: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    width: '400px',
  },
  header: {
    marginBottom: '20px',
    textAlign: 'center',
  },
  formGroup: {
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
  },
  input: {
    width: '100%',
    padding: '8px',
    boxSizing: 'border-box',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  saveButton: {
    padding: '10px 20px',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#4CAF50',
    color: 'white',
    cursor: 'pointer',
  },
  cancelButton: {
    padding: '10px 20px',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#f44336',
    color: 'white',
    cursor: 'pointer',
  },
  error: {
    color: '#f44336',
    marginTop: '5px',
  },
  modal: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: '1000',
  },
  modalContent: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    textAlign: 'center',
    width: '300px',
  },
  closeModalButton: {
    marginTop: '10px',
    padding: '10px 20px',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#4CAF50',
    color: 'white',
    cursor: 'pointer',
  },
};

export default AddSuperAdminBill;
