import React, { useEffect, useState } from 'react';
import { Box, Modal } from '@mui/material';
import Swal from 'sweetalert2';
import './../Screens/css/Designation.css';
import designationImage from '../assets/designation.png';
import iconImage from '../assets/Icon.png';


const DealNotes = ({ id }) => {
    const [notes, setNotes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');




    const fetchNotes = async () => {
        const token = localStorage.getItem('authToken');
        const url = `https://api.myntask.com/leads/list_notes/${id}`;


        try {
            const response = await fetch(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });


            if (!response.ok) {
                throw new Error('Failed to fetch notes');
            }


            const data = await response.json();
            setNotes(data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching notes:', error);
            Swal.fire('Error', 'Unable to fetch notes. Please try again later.', 'error');
            setLoading(false);
        }
    };



    // Fetch notes from API
    useEffect(() => {

        fetchNotes();
    }, [id]);


    // Handle Modal Toggle
    const toggleModal = () => setShowModal(!showModal);


    // Handle Form Submit (POST Request)
    const handleSubmit = async () => {
        const token = localStorage.getItem('authToken');
        if (!token) {
            Swal.fire({
                title: 'Error',
                text: 'Authorization token is missing!',
                icon: 'error',
                timer: 1500,
                showConfirmButton: false,
            });
            return;
        }


        const noteData = {
            deal_id: id,
            title: title,
            description: description,
        };


        try {
            const response = await fetch('https://api.myntask.com/leads/add_note', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(noteData),
            });


            if (!response.ok) {
                throw new Error('Failed to add note');
            }


            Swal.fire({
                title: 'Success',
                text: 'Note added successfully!',
                icon: 'success',
                timer: 1500,
                showConfirmButton: false,
            });


            setShowModal(false);
            setTitle('');
            setDescription('');
            // Fetch updated notes
            fetchNotes();
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: `Failed to add note: ${error.message}`,
                icon: 'error',
                timer: 1500,
                showConfirmButton: false,
            });
        }
    };




    const handleDeleteNote = async (notesId) => {
        const token = localStorage.getItem('authToken');


        try {
            const willDelete = await Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            });


            if (!willDelete.isConfirmed) {
                return; // User canceled the deletion
            }


            const response = await fetch(`https://api.myntask.com/leads/delete_note/${notesId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });


            if (!response.ok) {
                throw new Error('Failed to delete note');
            }


            fetchNotes(); // Refresh the notes list


            Swal.fire({
                title: 'Deleted!',
                text: 'Note deleted successfully!',
                icon: 'success',
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 1500,
            });
        } catch (error) {
            console.error('Error deleting note:', error);


            Swal.fire({
                title: 'Error',
                text: 'Error deleting note. Please try again.',
                icon: 'error',
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 1500,
            });
        }
    };




    return (
        // <Box padding={4}>
        <div>
            <div className="top-controls">
                <h1 className="title">Notes</h1>
                <div className="buttons-row">
                    <button className="color-button" onClick={toggleModal}>
                        Add Notes
                    </button>
                </div>
            </div>


            {loading ? (
                <p>Loading...</p>
            ) : notes.length === 0 ? (
                <p>No notes available.</p>
            ) : (
                <table>
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th>Details</th>
                            <th>Created At</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {notes.map((note) => (
                            <tr key={note.id}>
                                <td style={{ width: '20vw' }}>{note.title}</td>
                                <td style={{ width: '40vw' }}>{note.details}</td>
                                <td style={{ width: '20vw' }}>{new Date(note.created_at).toLocaleDateString()}</td>
                                <td style={{ width: '10vw' }}> <span onClick={() => handleDeleteNote(note.id)} style={{ cursor: 'pointer', color: 'red' }}>delete</span></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}


            {/* Modal for adding a new note */}
            <Modal open={showModal} onClose={toggleModal}>
                <Box className="designation-modal">
                    <div className="designation-header">
                        <h1 className="designation-title">Add Note</h1>
                        <img
                            src={iconImage} // Replace 'iconImage' with the path or import for Icon.png
                            alt="Close"
                            className='designation-close'
                            onClick={toggleModal}
                        />
                    </div>




                    <img
                        src={designationImage}
                        alt="Designation"
                        className='designation-image'
                    />


                    <div className="designation-form">
                        <label className="designation-label">Title:</label>
                        <input
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder="Enter title"
                            className="designation-input"
                        />


                        <label className="designation-label">Description:</label>
                        <textarea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Enter description"
                            className="designation-input"
                        />
                    </div>


                    <button className="designation-save" onClick={handleSubmit}>
                        Add Note
                    </button>
                </Box>
            </Modal>
        </div>
        // </Box>
    );
};


export default DealNotes;






