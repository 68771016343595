import React, { useState, useEffect } from 'react';
import ".//SignUpLogInForm.css"; // Import your CSS file
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import eye icons
import iconImage from '../assets/Icon.png';
import { requestForToken } from '../firebase';
import bevel from '../assets/bevel.png';
// import 'https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css'; // You can include this in your index.html or use a CDN

const SignUpLogInForm = () => {
    const [isLoginActive, setIsLoginActive] = useState(true);
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [error, setError] = useState('');
    const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] = useState(false);
    const [forgotPasswordMessage, setForgotPasswordMessage] = useState('');
    const [isForgotPasswordError, setIsForgotPasswordError] = useState(false);
    const [forgotEmail, setForgotEmail] = useState('');
    const [tokenFound, setTokenFound] = useState(false);
    const [isLoading, setIsLoading] = useState(true);


    const handleToggle = () => {
        setIsLoginActive(!isLoginActive);
    };

    // Request the Firebase client token when the component mounts
    useEffect(() => {
        requestForToken(setTokenFound) // Request for the token when the component mounts
            .then(() => setIsLoading(false)) // Stop loading after token request completes
            .catch(() => setIsLoading(false));
    }, []);


    // Prevent navigation away from login page using the back button
    useEffect(() => {
        window.history.pushState(null, document.title, window.location.href);
        const handlePopState = () => {
            window.history.pushState(null, document.title, window.location.href);
        };
        window.addEventListener('popstate', handlePopState);
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [navigate]);



    // Handle the login logic
    const handleLogin = async (e) => {
        e.preventDefault();

        if (!email || !password) {
            setError('Please enter both email and password.');
            return;
        }


        // Fetch the client token from localStorage (this was stored earlier in firebase.js)
        const clientToken = localStorage.getItem('clientToken') || '';


        try {
            const response = await fetch('https://api.myntask.com/auth/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    password,
                    user_token: clientToken // Pass the client token as user_token
                }),
            });


            if (!response.ok) {
                throw new Error('Network response was not ok');
            }


            const data = await response.json();
            console.log('Login successful:', data);


            // Store various items in localStorage
            localStorage.setItem('authToken', data.token);
            localStorage.setItem('is_superadmin', data.is_superadmin);
            if (data.profile) {
                localStorage.setItem('userProfile', JSON.stringify(data.profile));
            }
            localStorage.setItem('taskCount', JSON.stringify(data.task_count));
            localStorage.setItem('workFromHome', JSON.stringify(data.work_from_home_today));
            localStorage.setItem('projectCount', JSON.stringify(data.project_count));
            localStorage.setItem('todayJoinings', JSON.stringify(data.today_joinings));
            localStorage.setItem('leaveToday', JSON.stringify(data.leave_today || []));
            localStorage.setItem('birthdays', JSON.stringify(data.birthdays || []));
            localStorage.setItem('probation', JSON.stringify(data.probation_data || []));
            localStorage.setItem('taskList', JSON.stringify(data.task_list || []));
            localStorage.setItem('notice', JSON.stringify(data.notice_list || []));
            localStorage.setItem('expenses', JSON.stringify(data.expenses || []));
            localStorage.setItem('attendanceStatus', JSON.stringify(data.attendance_status));
            localStorage.setItem('tickets', JSON.stringify(data.tickets || []));
            localStorage.setItem('expiryMessage', data.expire_message);


            if (data.is_superadmin === 1) {
                // Navigate to SuperAdmin page and pass the data via state
                navigate('/SuperAdmin', { state: { data } });
            } else if (data.is_superadmin === 2) {
                // Save data to local storage and navigate to client dashboard
                localStorage.setItem('client_data', JSON.stringify(data));
                navigate('/client-dashboard');
            } else {
                // Default navigation to the dashboard
                navigate('/dashboard');
            }



        } catch (error) {
            console.error('Error logging in:', error);
            setError('Failed to log in. Please check your credentials and try again.');
        }
    };


    const handleForgotPasswordVerify = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://api.myntask.com/auth/forget-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: forgotEmail }),
            });


            if (!response.ok) {
                throw new Error('Invalid email address');
            }

            const data = await response.json();
            console.log('Forgot password response:', data);
            setForgotPasswordMessage(data.message);
            setIsForgotPasswordError(false);
        } catch (error) {
            console.error('Forgot password error:', error);
            setForgotPasswordMessage('Enter Correct email.');
            setIsForgotPasswordError(true);
        }
    };



    const openForgotPasswordModal = () => {
        setIsForgotPasswordModalOpen(true);
    };

    const closeForgotPasswordModal = () => {
        setIsForgotPasswordModalOpen(false);
        setForgotEmail('');
    };


    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible); // Toggle visibility state
    };


    return (
        <div className={`container ${isLoginActive ? '' : 'active'}`}>
            <div className="form-box login">
                <form className='signUpForm'>
                    <h1>Login</h1>
                    <p className="login-subtitle">Connect, manage, and grow — all with one CRM.</p>
                    <div className='passwordFieldContainer'>
                        <input
                            type="email"
                            placeholder="Email"
                            className="textField"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <i className='bx bxs-envelope' style={{ position: 'absolute', right: '15px', alignContent: 'center' }}></i>
                    </div>

                    <div className="passwordFieldContainer">
                        <input
                            type={passwordVisible ? 'text' : 'password'}
                            placeholder="Enter your Password"
                            className="textField"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <span onClick={togglePasswordVisibility} className="eyeIcon">
                            {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                        </span>
                    </div>

                    {error && <p className="error">{error}</p>}

                    <a href="#" className="forgotPasswordLink" onClick={openForgotPasswordModal}>
                        Forgot Password?
                    </a>

                    {isForgotPasswordModalOpen && (
                        <div className="modal-overlay-deal1">
                            <div className="forgot-password-modal">
                                <img
                                    src={iconImage} // Replace 'iconImage' with the path or import for Icon.png
                                    alt="Close"
                                    style={{
                                        position: "absolute",
                                        top: "16px",
                                        right: "16px",
                                        border: "none",
                                        cursor: "pointer",
                                        width: '30px'
                                    }}
                                    onClick={closeForgotPasswordModal}
                                />
                                <h2 style={{ marginBottom: '20px' }}>Forgot Password</h2>
                                <input
                                    type="email"
                                    placeholder="Enter your email"
                                    className="textField"
                                    value={forgotEmail}
                                    onChange={(e) => setForgotEmail(e.target.value)}
                                />

                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                                    <button className="color-button" onClick={handleForgotPasswordVerify}>
                                        Verify
                                    </button>
                                </div>
                                {forgotPasswordMessage && (
                                    <p
                                        style={{
                                            color: forgotPasswordMessage === "Password reset link has been sent to your email"
                                                ? 'green'
                                                : 'red', // Set green for success and red for error
                                            marginTop: '10px',
                                        }}
                                    >
                                        {forgotPasswordMessage}
                                    </p>
                                )}
                            </div>
                        </div>
                    )}


                    <button type="submit" className="btn1" onClick={handleLogin}>Login</button>
                    {/* <p>or login with social platforms</p>
                    <div className="social-icons">
                        <a href="#"><i className='bx bxl-google'></i></a>
                        <a href="#"><i className='bx bxl-facebook'></i></a>
                        <a href="#"><i className='bx bxl-github'></i></a>
                        <a href="#"><i className='bx bxl-linkedin'></i></a>
                    </div> */}
                </form>
            </div>

            <div className="form-box register">
                <form className='signUpForm'>
                    <h1>Registration</h1>
                    <div className="input-box">
                        <input type="text" placeholder="Username" required />
                        <i className='bx bxs-user'></i>
                    </div>
                    <div className="input-box">
                        <input type="email" placeholder="Email" required />
                        <i className='bx bxs-envelope'></i>
                    </div>
                    <div className="input-box">
                        <input type="password" placeholder="Password" required />
                        <i className='bx bxs-lock-alt'></i>
                    </div>
                    <button type="submit" className="btn1">Register</button>
                    {/* <p>or register with social platforms</p>
                    <div className="social-icons">
                        <a href="#"><i className='bx bxl-google'></i></a>
                        <a href="#"><i className='bx bxl-facebook'></i></a>
                        <a href="#"><i className='bx bxl-github'></i></a>
                        <a href="#"><i className='bx bxl-linkedin'></i></a>
                    </div> */}
                </form>
            </div>

            <div className="toggle-box">

                <div className="toggle-panel toggle-right">
                    {/* <img className="promotion-image-centered" style={{ width: '30%', marginTop: '100px', height: '40%' }} src={bevel} alt="Bevel" /> */}
                    <h1>Welcome Back!</h1>
                    <p>Already have an account ?</p>
                    <button className="btn login-btn" onClick={handleToggle}>Switch To Login</button>
                </div>

                <div className="toggle-panel toggle-left">
                    {/* <img className="promotion-image-centered" style={{ width: '30%', marginTop: '100px', height: '40%' }} src={bevel} alt="Bevel" /> */}
                    <h1 style={{ marginTop: '1px' }}>Hello, Welcome To Myntask!</h1>
                    <p>Don't have an account?</p>
                    <button className="btn register-btn" onClick={handleToggle}>Register Here</button>
                </div>


            </div>
        </div>
    );
};

export default SignUpLogInForm;