import React from 'react';
import './SkeletonDashboard.css'; // Import the CSS file for styling

const SkeletonAdvanceDashboard = () => {
    return (
        <div className="advanced-dashboard-skeleton">
            {/* Skeleton for the tabs */}
            {/* <div className="skeleton-tabs">
                {[...Array(5)].map((_, index) => (
                    <div key={index} className="skeleton-tab"></div>
                ))}
            </div> */}

            {/* Skeleton for the cards */}
            <div className="skeleton-row">
                {[...Array(8)].map((_, index) => (
                    <div key={index} className="skeleton-card"></div>
                ))}
            </div>

            {/* Skeleton for the charts */}
            <div className="skeleton-row-large">
                <div className="skeleton-chart"></div>
            </div>

            {/* Skeleton for the task cards */}
            <div className="skeleton-row-large">
                <div className="skeleton-task-container">
                    <div className="skeleton-task-card"></div>
                    <div className="skeleton-task-card"></div>
                    <div className="skeleton-task-card"></div>
                </div>
                <div className="skeleton-task-container">
                    <div className="skeleton-task-card"></div>
                    <div className="skeleton-task-card"></div>
                    <div className="skeleton-task-card"></div>
                </div>
            </div>
        </div>
    );
};

export default SkeletonAdvanceDashboard;