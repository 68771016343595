import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ResponsiveContainer,
    PieChart,
    Pie,
    Cell,
} from "recharts";

const CRMReports = () => {
    const [reportData, setReportData] = useState({
        leads: [],
        deals: [],
        proposals: [],
        revenue: [],
        conversion: [],
    });

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    const getYearlyDateRange = () => {
        const currentYear = new Date().getFullYear();
        const startDate = `${currentYear}-01-01`;
        const endDate = `${currentYear}-12-31`;
        return { startDate, endDate };
    };

    useEffect(() => {
        const fetchReportData = async () => {
            try {
                const token = localStorage.getItem("authToken");
                const { startDate, endDate } = getYearlyDateRange();

                const response = await fetch(
                    `https://api.myntask.com/dashboard/crm_dashboard?start_date=${startDate}&end_date=${endDate}`,
                    {
                        method: "GET",
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (!response.ok) {
                    throw new Error("Failed to fetch data");
                }

                const data = await response.json();

                // Transform API data to match your chart structures
                setReportData({
                    leads: [
                        { name: "Total Leads", value: data.total_leads.total_all },
                        { name: "New Leads", value: data.lead_conversions.total_new },
                        { name: "Converted Leads", value: data.lead_conversions.total_converted },
                        { name: "Lost Leads", value: data.lead_conversions.total_lost },
                    ],
                    deals: [
                        { name: "Total Deals", value: data.total_deals.total_all },
                        // { name: "New Deals", value: data.deal_conversions.total_new },
                        { name: "Won Deals", value: data.deal_conversions.total_converted },
                        { name: "Lost Deals", value: data.deal_conversions.total_lost },
                    ],
                    proposals: [
                        { name: "Total Proposals Sent", value: data.proposal.total_all || 0 },
                        { name: "Accepted Proposals", value: data.proposal.total_accepted || 0 },
                        { name: "Rejected Proposals", value: data.proposal.total_rejected || 0 },
                    ],
                    // Using client data for revenue since actual revenue data isn't in API
                    revenue: [
                        { name: "Jan", revenue: data.total_client.total_monthly || 0 },
                        { name: "Feb", revenue: data.total_client.total_monthly || 0 },
                        { name: "Mar", revenue: data.total_client.total_monthly || 0 },
                        { name: "Apr", revenue: data.total_client.total_monthly || 0 },
                        { name: "May", revenue: data.total_client.total_monthly || 0 },
                        { name: "Jun", revenue: data.total_client.total_monthly || 0 },
                        { name: "Jul", revenue: data.total_client.total_monthly || 0 },
                        { name: "Aug", revenue: data.total_client.total_monthly || 0 },
                        { name: "Sep", revenue: data.total_client.total_monthly || 0 },
                        { name: "Oct", revenue: data.total_client.total_monthly || 0 },
                        { name: "Nov", revenue: data.total_client.total_monthly || 0 },
                        { name: "Dec", revenue: data.total_client.total_monthly || 0 },
                    ],
                    conversion: [
                        { name: "Leads to Deals", value: data.lead_conversions.total_converted },
                        { name: "Deals To Client", value: data.deal_conversions.total_converted },
                    ],
                });
                setLoading(false);
            } catch (error) {
                console.error("Error fetching report data:", error);
                setError(error.message);
                setLoading(false);
            }
        };

        fetchReportData();
    }, []);


    const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];


    if (loading) {
        return <div>Loading reports...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <Grid container spacing={3} p={3}>
            {/* Lead Report (Pie Chart) */}
            <Grid item xs={12} md={6}>
                <Card sx={{ boxShadow: 3 }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Lead Report
                        </Typography>
                        <ResponsiveContainer width="100%" height={300}>
                            <PieChart>
                                <Pie
                                    data={reportData.leads}
                                    dataKey="value"
                                    nameKey="name"
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={100}
                                    label
                                >
                                    {reportData.leads.map((_, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                            </PieChart>
                        </ResponsiveContainer>
                    </CardContent>
                </Card>
            </Grid>

            {/* Deal Report (Bar Chart) */}
            <Grid item xs={12} md={6}>
                <Card sx={{ boxShadow: 3 }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Deal Report
                        </Typography>
                        <ResponsiveContainer width="100%" height={300}>
                            <BarChart data={reportData.deals}>
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="value" fill="#8884d8" />
                            </BarChart>
                        </ResponsiveContainer>
                    </CardContent>
                </Card>
            </Grid>



            {/* Revenue Report (Bar Chart) */}
            <Grid item xs={12} md={6}>
                <Card sx={{ boxShadow: 3 }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Revenue Report
                        </Typography>
                        <ResponsiveContainer width="100%" height={300}>
                            <BarChart data={reportData.revenue}>
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="revenue" fill="#4CAF50" />
                            </BarChart>
                        </ResponsiveContainer>
                    </CardContent>
                </Card>
            </Grid>

            {/* Lead & Deal Conversion Report (Pie Chart) */}
            <Grid item xs={12} md={6}>
                <Card sx={{ boxShadow: 3 }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Lead & Deal Conversion
                        </Typography>
                        <ResponsiveContainer width="100%" height={300}>
                            <PieChart>
                                <Pie
                                    data={reportData.conversion}
                                    dataKey="value"
                                    nameKey="name"
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={100}
                                    label
                                >
                                    {reportData.conversion.map((_, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                            </PieChart>
                        </ResponsiveContainer>
                    </CardContent>
                </Card>
            </Grid>

            {/* Proposal Report (List View) */}
            <Grid item xs={12} md={6}>
                <Card sx={{ boxShadow: 3 }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Proposal Report
                        </Typography>
                        <table style={{ width: "100%", borderCollapse: "collapse" }}>
                            <thead>
                                <tr style={{ backgroundColor: "#f3f3f3" }}>
                                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>Category</th>
                                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                {reportData.proposals.map((item, index) => (
                                    <tr key={index}>
                                        <td style={{ border: "1px solid #ddd", padding: "8px" }}>{item.name}</td>
                                        <td style={{ border: "1px solid #ddd", padding: "8px" }}>{item.value}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default CRMReports;
