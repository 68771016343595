import React, { useEffect, useState } from 'react';
import './../Components/Form.css';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Modal, Box, Button, Stack, Typography, } from '@mui/material';
import iconImage from '../assets/Icon.png';
import './../Screens/css/Designation.css';
import { MdDelete } from 'react-icons/md';
import './../Components/AddLeadPage.css';
import { Vortex } from 'react-loader-spinner';



const AddClient = () => {
  const [formData, setFormData] = useState({
    salutation: "",
    client_name: "",
    email: "",
    country_id: "99",
    mobile: "",
    gender: "",
    language: "",
    client_category: "",
    client_sub_category: "",
    login_allowed: "enable",
    receive_email_notification: 1,
    company_name: "",
    official_website: "",
    tax_name: "",
    gst_number: "",
    office_phone_number: "",
    city: "",
    state: "",
    postal_code: "",
    company_address: "",
    shipping_address: "",
    note: "",
    electronic_address: "",
    electronic_address_scheme: "",
    industry: "",
    phonecode: "", // Added phonecode field
    image: null, // Added image field
  });




  const [isSubCategoryDropdownOpen, setIsSubCategoryDropdownOpen] = useState(false);
  const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const [newCategory, setNewCategory] = useState('');
  const [countries, setCountries] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [errors, setErrors] = useState({});
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [openSubCategoryModal, setOpenSubCategoryModal] = useState(false);
  const [subCategoryName, setSubCategoryName] = useState('');
  const navigate = useNavigate();


  const [isToggled, setIsToggled] = useState(false);












  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };








  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };








  const validateForm = () => {
    const newErrors = {};
    let firstErrorField = null;




    const requiredFields = ["client_name", "email", "mobile"];




    requiredFields.forEach((field) => {
      if (!formData[field] || formData[field].toString().trim() === "") {
        newErrors[field] = `${field.replace(/_/g, " ")} is required.`;
        if (!firstErrorField) firstErrorField = field;
      }
    });








    if (!formData.client_name.trim()) {
      newErrors.client_name = "Client name is required.";
      if (!firstErrorField) firstErrorField = "client_name";
    }
    if (!formData.email.trim()) {
      newErrors.email = "Email is required.";
      if (!firstErrorField) firstErrorField = "email";
    }
    else if (!validateEmail(formData.email)) {
      newErrors.email = "Enter a valid email address.";
      if (!firstErrorField) firstErrorField = "email";
    }
    if (!formData.mobile.trim()) {
      newErrors.mobile = "Mobile number is required.";
      if (!firstErrorField) firstErrorField = "mobile";
    }




    if (!formData.email || !formData.mobile || !formData.client_category || !formData.client_sub_category) {
      Swal.fire({
        icon: 'error',
        title: 'Missing required fields',
        text: 'Please fill in all the required fields including client category and sub category',
        timer: 2000,
        showConfirmButton: false,
      });
      return false;
    }


    setErrors(newErrors);




    if (firstErrorField) {
      // Focus the first field with an error
      document.getElementsByName(firstErrorField)[0]?.focus();
    }
    return Object.keys(newErrors).length === 0;
  };








  //.....................................................................................................................












  // Fetch country list from the API
  const fetchCountries = async () => {
    try {
      const response = await fetch("https://api.myntask.com/home/country_list");
      if (!response.ok) {
        throw new Error("Failed to fetch country list");
      }
      const data = await response.json();
      setCountries(data); // Assuming the API returns an array of countries


      // Assuming each country object has a `phonecode` property
      const codes = data.map(country => ({ id: country.id, code: country.phonecode, name: country.name }));
      setCountryCodes(codes);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };








  // Fetch client categories from the API
  const fetchCategories = async () => {
    try {
      const token = localStorage.getItem("authToken"); // Get the token from local storage
      const response = await fetch("https://api.myntask.com/client/category", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch client categories");
      }
      const data = await response.json();
      setCategories(data); // Assuming the API returns an array of categories
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };








  useEffect(() => {


    fetchCountries();
    fetchCategories();
  }, []);








  const fetchSubCategories = async (categoryId) => {
    try {
      const token = localStorage.getItem("authToken"); // Get the token from local storage
      const response = await fetch(`https://api.myntask.com/client/sub_category/${categoryId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch client sub-categories");
      }
      const data = await response.json();
      setSubCategories(data); // Assuming the API returns an array of sub-categories
    } catch (error) {
      console.error("Error fetching sub-categories:", error);
    }
  };




  // UseEffect to call fetchSubCategories when selectedCategoryId changes
  useEffect(() => {
    if (selectedCategoryId) {
      fetchSubCategories(selectedCategoryId);
    }
  }, [selectedCategoryId]); // Dependency array




  const handleCountryChange = (e) => {
    const countryId = e.target.value;
    setFormData((prevState) => ({ ...prevState, country_id: countryId }));


    // Find the corresponding phone code and set it
    const selectedCountry = countryCodes.find(country => country.id === parseInt(countryId, 10));
    if (selectedCountry) {
      setFormData((prevState) => ({
        ...prevState,
        phonecode: selectedCountry.code,
      }));
    }
  };








  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: parseInt(value)
    }));
  };








  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };








  const handleCategoryChange = (categoryId) => {
    setFormData((prevState) => ({ ...prevState, client_category: categoryId }));
    setSelectedCategoryId(categoryId);
    fetchSubCategories(categoryId); // Fetch sub-categories based on selected category
    setIsCategoryDropdownOpen(false);
  };




  const handleSubCategoryChange = (subCategoryId) => {


    setFormData((prevState) => ({ ...prevState, client_sub_category: subCategoryId }));
    setIsSubCategoryDropdownOpen(false);
  };








  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      image: file, // Save the selected file in formData
    }));
  };






  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      console.log("Validation failed, API will not be called.");
      return;
    }
    console.log("Validation passed, preparing submission...");


    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      // Display SweetAlert error message
      Swal.fire({
        icon: 'error',
        title: 'Invalid Email',
        text: 'Please enter a valid email address',
        timer: 1500, // Automatically closes after 1.5 seconds
        showConfirmButton: false, // Hide the confirmation button
      });
      return; // Exit the function if email is invalid
    }


    setIsLoading(true);

    try {
      const token = localStorage.getItem("authToken"); // Get the token from local storage
      console.log("Auth Token:", token);
      console.log("Form Data:", formData);


      // Create a FormData object to send the data
      const formDataToSend = new FormData();


      // Append regular form fields
      Object.keys(formData).forEach(key => {
        if (key !== 'image') {
          formDataToSend.append(key, formData[key]);
        }
      });




      // Append the image file (if it exists)
      if (formData.image) {
        formDataToSend.append('image', formData.image);
      }




      // Send the form data as multipart/form-data
      const response = await fetch("https://api.myntask.com/client/save", {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${token}`, // Set the Authorization header
        },
        body: formDataToSend, // Send the FormData object
      });




      if (!response.ok) {
        const errorData = await response.json();
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errorData.message || "Failed to save client",
          timer: 1500,
          showConfirmButton: false,
        });


        throw new Error(errorData.message || "Failed to save client");
      }




      const result = await response.json();
      console.log("Client saved successfully", result);




      // Display SweetAlert success message with automatic close
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'Client saved successfully',
        timer: 1500, // Automatically closes after 3 seconds
        showConfirmButton: false, // Hide the confirmation button
      }).then(() => {
        setIsLoading(false);
        navigate(-1); // Go back to the previous page
      });


    } catch (error) {
      console.error("Error:", error);


      // Display SweetAlert error message for general errors with automatic close
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: error.message || "An unexpected error occurred",
        timer: 1500, // Automatically closes after 3 seconds
        showConfirmButton: false, // Hide the confirmation button
      });
    }
    setIsLoading(false);
  };










  const handleAddCategory = () => {
    setOpenCategoryModal(true);
  };






  const handleSaveCategory = async () => {
    if (!newCategory.trim()) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Category name is required!',
        timer: 1500, // Automatically closes after 3 seconds
        showConfirmButton: false, // Hide the confirmation button
      });
      return;
    }




    const token = localStorage.getItem('authToken');
    if (!token) {
      Swal.fire({
        icon: 'error',
        title: 'Authentication Error',
        text: 'Token not found in local storage!',
        timer: 1500, // Automatically closes after 3 seconds
        showConfirmButton: false, // Hide the confirmation button
      });
      return;
    }




    const payload = {
      category_name: newCategory.trim(),
    };




    try {
      const response = await fetch('https://api.myntask.com/client/category_save', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });




      // Check if the response is JSON
      const contentType = response.headers.get('Content-Type');
      if (contentType && contentType.includes('application/json')) {
        const data = await response.json();
        if (response.ok) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Category saved successfully!',
            timer: 1500, // Automatically closes after 3 seconds
            showConfirmButton: false, // Hide the confirmation button
          });
          fetchCategories();
          setNewCategory('');
          setOpenCategoryModal(false);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: data.message || 'Failed to save category!',
          });
        }
      } else {
        // Handle plain text response
        const text = await response.text();
        if (response.ok) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: text,
            timer: 1500, // Automatically closes after 3 seconds
            showConfirmButton: false, // Hide the confirmation button
          });
          fetchCategories();
          setNewCategory('');
          setOpenCategoryModal(false);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: text,
          });
        }
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Something went wrong: ${error.message}`,
        timer: 1500, // Automatically closes after 3 seconds
        showConfirmButton: false, // Hide the confirmation button
      });
    }
  };








  const handleAddSubCategory = () => {
    setOpenSubCategoryModal(true); // Open the modal
  };








  const handleSaveSubCategory = async () => {
    if (!subCategoryName.trim()) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Sub-category name is required!',
      });
      return;
    }




    const token = localStorage.getItem('authToken');
    if (!token) {
      Swal.fire({
        icon: 'error',
        title: 'Authentication Error',
        text: 'Token not found in local storage!',
      });
      return;
    }




    const payload = {
      category_name: subCategoryName.trim(),
      category_id: selectedCategoryId,  // Send the selected category ID
    };




    setLoading(true);




    try {
      const response = await fetch('https://api.myntask.com/client/sub_category_save', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });




      // Check if the response is JSON
      const contentType = response.headers.get('Content-Type');
      if (contentType && contentType.includes('application/json')) {
        const data = await response.json();
        if (response.ok) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Sub-category saved successfully!',
          });
          setSubCategoryName('');
          fetchSubCategories();
          setOpenSubCategoryModal(false);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: data.message || 'Failed to save sub-category!',
          });
        }
      } else {
        // Handle plain text response
        const text = await response.text();
        if (response.ok) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: text,
          });
          setSubCategoryName('');
          fetchSubCategories();
          setOpenSubCategoryModal(false);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: text,
          });
        }
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Something went wrong: ${error.message}`,
      });
    } finally {
      setLoading(false);
    }
  };








  const handleDeleteCategory = async (categoryId) => {
    const token = localStorage.getItem("authToken"); // Retrieve token from local storage




    if (!token) {
      Swal.fire({
        icon: "error",
        title: "Authorization Error",
        text: "Authorization token not found!",
      });
      return;
    }




    // Confirm deletion using SweetAlert
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "This action will delete the category and cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });




    if (!result.isConfirmed) {
      return; // If the user cancels, exit the function
    }




    try {
      const response = await fetch(`https://api.myntask.com/client/category_delete/${categoryId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });




      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Deleted!",
          text: "Category deleted successfully.",
          timer: 2000,
          showConfirmButton: false,
        });




        // Optionally update the categories list after deletion
        setCategories((prev) => prev.filter((category) => category.category_id !== categoryId));
      } else {
        const errorData = await response.json();
        Swal.fire({
          icon: "error",
          title: "Failed to Delete",
          text: errorData.message || "Unknown error occurred.",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message,
      });
    }
  };








  const handleDeleteSubCategory = async (subCategoryId) => {
    const token = localStorage.getItem("authToken"); // Retrieve token from local storage




    if (!token) {
      Swal.fire({
        icon: "error",
        title: "Authorization Error",
        text: "Authorization token not found!",
      });
      return;
    }




    // Confirm deletion using SweetAlert
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "This action will delete the sub-category and cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });




    if (!result.isConfirmed) {
      return; // If the user cancels, exit the function
    }




    try {
      const response = await fetch(
        `https://api.myntask.com/client/sub_category_delete/${subCategoryId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );




      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Deleted!",
          text: "Sub-category deleted successfully.",
          timer: 2000,
          showConfirmButton: false,
        });




        // Optionally update the sub-categories list after deletion
        setSubCategories((prev) =>
          prev.filter((subCat) => subCat.sub_category_id !== subCategoryId)
        );
      } else {
        const errorData = await response.json();
        Swal.fire({
          icon: "error",
          title: "Failed to Delete",
          text: errorData.message || "Unknown error occurred.",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message,
      });
    }
  };


  const handleCancel = () => {
    navigate(-1); // Go back to the previous page
  };


  //..........Loading Spinner ..........................................
  useEffect(() => {
    // Adjust the timer duration here (e.g., 3000ms = 3 seconds)
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 300);

    // Cleanup the timer on component unmount
    return () => clearTimeout(timer);
  }, []);


  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }


  // if (error) {
  //   return (
  //     <Typography
  //       color="error"
  //       sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
  //     >
  //       {error}
  //     </Typography>
  //   );
  // }








  return (
    <div className="main-container-body">
      <div className="add-lead-form">




        <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Add Client</h1>
        <form>




          <section className="lead-section">
            <div className="lead-section-heading">CLient Details</div>
            {/* <h3 className="sectionTitle">Company Details</h3> */}


            <div className="lead-form-row">
              {/* <div className="formColumn"> */}
              <div className="lead-form-group">
                <label className="lead-form-label">Company Name: <span className="required">*</span></label>
                <input
                  type="text"
                  name="company_name"
                  value={formData.company_name}
                  onChange={handleChange}
                  className="lead-form-input"
                  placeholder="Enter company name"
                />
              </div>
              <div className="lead-form-group">
                <label className="lead-form-label">Official Website:</label>
                <input
                  type="text"
                  name="official_website"
                  value={formData.official_website}
                  onChange={handleChange}
                  className="lead-form-input"
                  placeholder="Enter official website"
                />
              </div>
            </div>








            <div className="lead-form-row">
              <div className="lead-form-group">
                <label className="lead-form-label">Office Phone Number:</label>
                <input
                  type="text"
                  name="office_phone_number"
                  value={formData.office_phone_number}
                  onChange={handleChange}
                  className="lead-form-input"
                  placeholder="Enter office phone number"
                />
              </div>




              <div className="lead-form-group">
                <label className="lead-form-label">City:</label>
                <input
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  className="lead-form-input"
                  placeholder="Enter city"
                />
              </div>
            </div>




            <div className="lead-form-row">
              <div className="lead-form-group">
                <label className="lead-form-label">State:</label>
                <input
                  type="text"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  className="lead-form-input"
                  placeholder="Enter state"
                />
              </div>






              {/* <div className="formColumn"> */}
              <div className="lead-form-group">
                <label className="lead-form-label">Postal Code:</label>
                <input
                  type="text"
                  name="postal_code"
                  value={formData.postal_code}
                  onChange={handleChange}
                  className="lead-form-input"
                  placeholder="Enter postal code"
                />
              </div>
            </div>








            <div className="lead-form-row">
              <div className="lead-form-group">
                <label className="lead-form-label">Client Category:<span className="required">*</span></label>
                <div className="categoryDropdownContainer" style={{ border: "none" }}>
                  <div
                    className="custom-dropdown"
                    style={{ marginRight: "10px" }}
                    onClick={() => setIsCategoryDropdownOpen(!isCategoryDropdownOpen)}
                  >
                    <div className="selected-category">
                      {/* <div> */}
                      {selectedCategoryId
                        ? categories.find((cat) => cat.category_id === selectedCategoryId)?.category_name || "Select Category"
                        : "Select Category"}
                      {/* </div> */}
                    </div>






                    {isCategoryDropdownOpen && (
                      <div className="dropdown-options">
                        {categories.map((category) => (
                          <div key={category.category_id} className="dropdown-item">
                            <span
                              className="dropdown-text"
                              onClick={() => {
                                handleCategoryChange(category.category_id);
                                setIsCategoryDropdownOpen(false); // Close dropdown after selection
                              }}
                            >
                              {category.category_name}




                            </span>
                            <Button
                              className="delete-button"
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent closing dropdown when delete button is clicked
                                handleDeleteCategory(category.category_id);
                              }}
                            >
                              <MdDelete />
                            </Button>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <Button onClick={handleAddCategory}>
                    Add
                  </Button>
                </div>
              </div>




              <div className="lead-form-group">
                <label className="lead-form-label">Client Sub-Category:<span className="required">*</span></label>
                <div className="categoryDropdownContainer" style={{ border: "none" }}>
                  <div
                    className='custom-dropdown '
                    style={{ marginRight: "10px" }}
                    onClick={() => setIsSubCategoryDropdownOpen(!isSubCategoryDropdownOpen)}
                  >
                    <div className="selected-category">
                      {formData.client_sub_category
                        ? subCategories.find(
                          (subCat) => subCat.sub_category_id === formData.client_sub_category
                        )?.category_name || "Select Sub-Category"
                        : "Select Sub-Category"}
                    </div>






                    {isSubCategoryDropdownOpen && (
                      <div className="dropdown-options">
                        {subCategories.map((subCategory) => (
                          <div key={subCategory.sub_category_id} className="dropdown-item">
                            <div
                              className='dropdown-text'
                              onClick={() =>
                                handleSubCategoryChange(subCategory.sub_category_id)
                              }
                            >




                              {subCategory.category_name}
                            </div>
                            <Button
                              className="delete-button" style={{ width: '20px' }}
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent dropdown closing on delete
                                handleDeleteSubCategory(subCategory.sub_category_id);
                              }}
                            >
                              <MdDelete />
                            </Button>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <Button onClick={handleAddSubCategory}>
                    Add
                  </Button>
                </div>




              </div>
            </div>








            <div className="lead-form-row">
              <div className="lead-form-group">
                <label className="lead-form-label">Login Allowed:</label>
                <div
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '60%',
                  }}
                >
                  <Button
                    onClick={() => {
                      const newValue = formData.login_allowed === 'Enable' ? 'Disable' : 'Enable';
                      setFormData({ ...formData, login_allowed: newValue });
                    }}
                    className={`toggle-button ${formData.login_allowed === 'Enable' ? 'on' : 'off'}`}
                  >
                    {formData.login_allowed === 'Enable' ? 'ON' : 'OFF'}
                  </Button>
                </div>
              </div>






              <div className="lead-form-group">
                <label className="lead-form-label">Receive Email Notification:</label>
                <div
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '60%',
                  }}
                >
                  <Button
                    onClick={() => {
                      const newValue = formData.receive_email_notification === 0 ? 1 : 0;
                      setFormData({ ...formData, receive_email_notification: newValue });
                    }}
                    className={`toggle-button ${formData.receive_email_notification === 0 ? 'on' : 'off'}`}
                  >
                    {formData.receive_email_notification === 0 ? 'ON' : 'OFF'}
                  </Button>
                </div>
              </div>
            </div>






            <div className="lead-form-row">
              <div className="lead-form-group">
                <label className="lead-form-label">Company Address:</label>
                <textarea
                  type="text"
                  name="company_address"
                  value={formData.company_address}
                  onChange={handleChange}
                  className="lead-form-input"
                  placeholder="Enter company address"
                />
              </div>
            </div>


          </section>




          {/* Section 2......................................................................................................... */}
          <section className="lead-section">
            <div className="lead-section-heading">Contact Person Details</div>


            <div className="lead-form-row">




              <div className="lead-form-group">
                <label className="lead-form-label">
                  Name: <span className="required">*</span>
                </label>
                <input
                  className={`lead-form-input ${errors.client_name ? "input-error" : ""}`}
                  // className='lead-form-input'
                  type="text"
                  name="client_name"
                  required
                  value={formData.client_name}
                  onChange={handleChange}
                  placeholder="Enter client name" // Added placeholder
                />
                {errors.client_name && <p className="error-message">{errors.client_name}</p>}
              </div>








              <div className="lead-form-group">
                <label className="lead-form-label">
                  Email: <span className="required">*</span>
                </label>
                <input
                  className={`lead-form-input ${errors.email ? "input-error" : ""}`}
                  type="email"
                  name="email"
                  value={formData.email}
                  required
                  onChange={handleChange}
                  placeholder="Enter email address" // Added placeholder
                />
                {errors.email && <p className="error-message">{errors.email}</p>}
              </div>
            </div>




            <div className="lead-form-row">
              <div className="lead-form-group">
                <label className="lead-form-label">Language:</label>
                <input
                  className="lead-form-input"
                  type="text"
                  name="language"
                  value={formData.language}
                  onChange={handleChange}
                  placeholder="Enter preferred language" // Added placeholder
                />
              </div>




              <div className="lead-form-group">
                <label className="lead-form-label">Country:</label>
                <select
                  className="lead-form-input" // Add 'select-box' class to control dropdown width
                  name="country_id"
                  value={formData.country_id}
                  onChange={handleCountryChange}
                >
                  <option value="">Select Country</option> {/* Placeholder for select */}
                  {countries.map((country) => (
                    <option key={country.id} value={country.id}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>






            <div className="lead-form-row">
              <div className="lead-form-group">
                <label className="lead-form-label"> Gender: <span className="required">*</span></label>
                <select
                  className="lead-form-input"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Gender</option> {/* Placeholder for select */}
                  <option value="female">Female</option>
                  <option value="male">Male</option>
                  <option value="others">Others</option>
                </select>
              </div>








              <div className="lead-form-group">
                <label className="lead-form-label">Industry:</label>
                <input
                  className="lead-form-input"
                  type="text"
                  name="industry"
                  value={formData.industry}
                  onChange={handleChange}
                  placeholder="N/A" // Added placeholder (since it's read-only)
                // readOnly
                />
              </div>


            </div>


            <div className="lead-form-row">
              <div className="lead-form-group">
                <label className="lead-form-label">Image:</label>
                <input
                  className="lead-form-input"
                  type="file"
                  name="image"
                  onChange={(e) => handleImageChange(e)} // Call handleImageChange to update formData
                  accept="image/*" // Accepts image files only
                />
              </div>






              <div className="lead-form-group">
                <label className="lead-form-label"> Mobile: <span className="required">*</span></label>
                <input
                  className={`lead-form-input ${errors.mobile ? "input-error" : ""}`}
                  type="number"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  placeholder="Enter mobile number" // Added placeholder
                  required
                />
                {errors.mobile && <p className="error-message">{errors.mobile}</p>}
              </div>
            </div>




          </section>




          {/* //...Section 3 ................................................................................................... */}
          <section className="lead-section">
            <div className="lead-section-heading">Tax and Statutory Details</div>
            {/* <div className="lead-form-row"> */}
            <div className="lead-form-group">
              <label className="lead-form-label">Tax Name:</label>
              <input
                type="text"
                name="tax_name"
                value={formData.tax_name}
                onChange={handleChange}
                className="lead-form-input"
                placeholder="Enter tax name"
              />
            </div>


            <div className="lead-form-group">
              <label className="lead-form-label">GST Number:</label>
              <input
                type="text"
                name="gst_number"
                value={formData.gst_number}
                onChange={handleChange}
                className="lead-form-input"
                placeholder="Enter GST number"
              />
            </div>
            {/* </div> */}
          </section>


          <div style={{ display: 'flex', justifyContent: 'end', marginTop: '20px', gap: '20px' }}>
            <button className='color-button' onClick={handleCancel}>
              Cancel
            </button>
            <button className="color-button" type='submit' onClick={handleSubmit}>
              Save Client
            </button>
          </div>
        </form>
      </div>




      <Modal
        open={openCategoryModal}
        onClose={() => setOpenCategoryModal(false)}
      >
        <Box className="designation-modal">
          {/* Header with title and close button */}
          <div className="designation-header">
            <h1 className="designation-title">Add Category</h1>
            <img
              src={iconImage}
              alt="Close"
              className="designation-close"
              onClick={() => setOpenCategoryModal(false)}
            />
          </div>




          {/* Form Section */}
          <div className="designation-form">
            <div className="form-group">
              <input
                type="text"
                name="newCategory"
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
                placeholder="Category Name"
                className="designation-input"
                required
              />
            </div>


            <button
              className="designation-save"
              onClick={handleSaveCategory}
              disabled={loading}
            >
              {loading ? 'Saving...' : 'Save'}
            </button>
          </div>
        </Box>
      </Modal>





      <Modal open={openSubCategoryModal} onClose={() => setOpenSubCategoryModal(false)}>
        <Box className="designation-modal">
          {/* Modal Header */}
          <div className="designation-header">
            <h1 className="designation-title">Add Sub-Category</h1>
            <img
              src={iconImage} // Replace 'iconImage' with your close icon image
              alt="Close"
              className="designation-close"
              onClick={() => setOpenSubCategoryModal(false)}
            />
          </div>



          {/* Modal Form */}
          <div className="designation-form">
            <div className="form-group">
              <label>Category</label>
              <input
                type="text"
                className="designation-input"
                value={
                  selectedCategoryId
                    ? categories.find(cat => cat.category_id === selectedCategoryId)?.category_name
                    : "No category selected"
                }
                readOnly
              />
            </div>

            <div className="form-group">
              <input
                type="text"
                name="subCategoryName"
                value={subCategoryName}
                onChange={(e) => setSubCategoryName(e.target.value)}
                placeholder="Sub-Category Name"
                className="designation-input"
                required
              />
            </div>


            <button
              className="designation-save"
              onClick={handleSaveSubCategory}
              disabled={loading}
            >
              {loading ? 'Saving...' : 'Save'}
            </button>
          </div>
        </Box>
      </Modal>




    </div>
  );
};



export default AddClient;


