import React from 'react';
import './SkeletonLoaderExpense.css';

const SkeletonProject = () => {
    return (
        <table class="tg">
            {/* <tr>
                <th class="tg-cly1">
                    <div class="line"></div>
                </th>
                <th class="tg-cly1">
                    <div class="line"></div>
                </th>
                <th class="tg-cly1">
                    <div class="line"></div>
                </th>
                <th class="tg-cly1">
                    <div class="line"></div>
                </th>
                <th class="tg-cly1">
                    <div class="line"></div>
                </th>
                <th class="tg-cly1">
                    <div class="line"></div>
                </th>
                <th class="tg-cly1">
                    <div class="line"></div>
                </th>
                <th class="tg-cly1">
                    <div class="line"></div>
                </th>
            </tr> */}
            <tr>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
            </tr>
            <tr>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
            </tr>
            <tr>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
            </tr>
            <tr>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
            </tr>
            <tr>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
                <td class="tg-cly1">
                    <div class="line"></div>
                </td>
            </tr>
        </table>
    );
};

export default SkeletonProject;