import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import Dashboard from './Screens/Dashboard';
import Attendance from './Screens/Attendance';
import Appreciation from './Screens/Appreciation';
import Layout from './Components/Layout';
import Login from './Screens/Login';
import AdvancedDashboard from './Screens/AdvancedDashboard';
import Clients from './Screens/Clients';
import Contracts from './Screens/Contracts';
import BankAccount from './Screens/BankAccount';
import Asset from './Screens/Asset';
import Bill from './Screens/Bill';
import CandidateDB from './Screens/CandidateDB';
import AttendanceReport from './Screens/AttendanceReport';
import ProfileDetail from './Screens/ProfileDetail';
import SuperAdmin from './Components/SuperAdmin';
import SuperAdminCompanies from './Screens/SuperAdminScreens/SuperAdminCompanies';
import CompanyDetail from './Screens/SuperAdminScreens/CompanyDetail';
import SuperAdminPackages from './Screens/SuperAdminScreens/SuperAdminPackages';
import AddCompanies from './Screens/SuperAdminScreens/AddCompanies';
import SuperAdminBill from './Screens/SuperAdminScreens/SuperAdminBill';
import SuperAdminList from './Screens/SuperAdminScreens/SuperAdminList';
import AddSuperAdminBill from './Screens/SuperAdminScreens/AddSuperAdminBill';
import AdminFAQ from './Screens/SuperAdminScreens/AdminFAQ';
import SuperSetting from './Screens/SuperAdminScreens/SuperSetting';
import Employee from './Screens/Employee';
import Leave from './Screens/Leave';
import Department from './Screens/Department';
import Profile from './Components/Profile';
import Projects from './Screens/Projects';
import Task from './Screens/Task';
import Expense from './Screens/Expense';
import Vendor from './Screens/Vendor';
import Product from './Screens/Product';
import PurchaseOrder from './Screens/PurchaseOrder';
import AddOrder from './Screens/AddOrder';
import AddEmployee from './Screens/AddEmployee';
import Estimate from './Screens/Estimate';
import AddEstimate from './Components/AddEstimates';
import ProfileData from './Screens/ProfileData';
import EditProject from './Screens/EditProject';
import Holiday from './Screens/Holiday';
import EditEmployee from './Screens/EditEmployee';
import Designation from './Screens/Designation';
import LeadContact from './Screens/LeadContact';
import LeadProfile from './Screens/LeadProfile';
import Deals from './Screens/Deals';
import NoticeBoard from './Screens/NoticeBoard';
import EmployeeSalary from './Screens/EmployeeSalary';
import TaskReport from './Screens/TaskReport';
import LeaveReport from './Screens/LeaveReport';
import KnowledgeBase from './Screens/KnowledgeBase';
import ShiftRoaster from './Screens/ShiftRoaster';
import Timesheet from './Screens/Timesheet';
import ProjectRoadmap from './Screens/ProjectRoadmap';
import AssetDetail from './Screens/AssetDetail';
import AddTaskModal from './Components/AddTaskModal';
import EditTaskModal from './Components/EditTaskModal';
import AddExpenseModal from './Components/AddExpenseModal';
import EditExpense from './Components/EditExpense';
import ViewExpense from './Components/ViewExpense';
import ExpenseReport from './Screens/ExpenseReport';
import AddClient from './Screens/AddClient';
import ClientEdit from './Screens/ClientEdit';
import ViewEstimate from './Screens/ViewEstimate';
import Proposal from './Screens/Proposal';
import Payment from './Screens/Payment';
import CreditNote from './Screens/CreditNote';
import Evoice from './Screens/Evoice';
import AddLeadPage from './Components/AddLeadPage';
import AddProjectPage from './Components/AddProjectPage';
import RecruitDashboard from './Screens/RecruitDashboard';
import Event from './Screens/Event';
import Settings from './Screens/Setting';
import EmployeeComponent from './Components/EmployeeComponent';
import MapScreen from './Screens/MapScreen';
import Message from './Screens/Message';
import Projectdashboard from './Screens/Projectdashboard';
import EditLeave from './Components/EditLeave';
import TaskDetail from './Components/TaskDetail';
import VendorForm from './Components/VendorForm';
import VendorDetail from './Screens/VendorDetail';
import AddProduct from './Screens/AddProduct';
import Addbom from './Screens/AddBom';
import Bom from './Components/Bom';
import PurchaseRequisation from './Screens/PurchaseRequisition';
import AddPurchaseRequistion from './Screens/AddPurchaseRequistion';
import DailyUpdateList from './Screens/DailyUpdateList';
import NotificationScreen from './Screens/NotificationScreen';
import Payroll from './Screens/Payroll';
import ClientVisit from './Screens/ClientVisit';
import HRDashboard from './Screens/HRDashboard';
import EditLead from './Screens/EditLead';
import LandingPage from './Screens/LandingPage';
import VendorEdit from './Screens/VendorEdit';
import CRMDashboard from './Screens/CRMDashboard';
import EditProduct from './Screens/EditProduct';
import { requestForToken, onMessageListener } from './firebase';
import ForgotPassword from './Screens/ForgotPassword';
import ClientDetail from './Screens/ClientDetail';
import Tickets from './Screens/Tickets';
import AdminTicket from './Screens/AdminTicket';
import DealProfile from './Screens/DealProfile';
import UserPerformance from './Screens/UserPerformance';
import ClientDashboard from './Screens/ClientDashboard';
import ClientTask from './Screens/ClientTask';
import AddProposal from './Components/AddProposal';
import EditPurchaseOrder from './Components/EditPurchaseOrder';
import EditProposal from './Components/EditProposal';
import EditEstimate from './Components/EditEstimate';
// import ThunderToText from './Components/ThunderToText';
import SignUpLogInForm from './Components/SignUpLogInForm';
import TicketView from './Components/TicketView';
import CRM_Reports from './Screens/CRM_Reports';
import ProjectReport from './Screens/ProjectReport';
import PurchaseForm from './Components/PurchaseForm';
import ViewProposal from './Components/ViewProposal';
import ProjectExpenseReport from './Screens/ProjectExpenseReport';
import AddProjectTask from './Components/AddProjectTask';
import Tasks from './Components/Tasks';
import VendorPaymentPage from './Screens/VendorPaymentPage';
import EditPayment from './Screens/EditPayment';
import DealProposal from './Components/DealProposal';
import AddDealProposal from './Components/AddDealProposal';
import ViewPurchaseOrder from './Components/ViewPurchaseOrder';

function MainApp() {
  const userType = localStorage.getItem('is_superadmin');
  const [latestNotice, setLatestNotice] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isTokenFound, setTokenFound] = useState(false);
  const [notification, setNotification] = useState(null);


  const navigate = useNavigate();


  useEffect(() => {
    // Requesting permission for notifications and getting token
    requestForToken(setTokenFound);
  }, []);




  useEffect(() => {
    // Listen for foreground messages
    onMessageListener()
      .then((payload) => {
        console.log('Foreground Message received: ', payload);
        setNotification({
          title: payload.notification.title,
          body: payload.notification.body,
        });
      })
      .catch((err) => console.log('Error while receiving foreground message: ', err));
  }, []);





  useEffect(() => {
    const fetchLatestNotice = async () => {
      const token = localStorage.getItem('authToken');
      const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format


      try {
        const response = await fetch('https://api.myntask.com/notice/list', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });


        if (!response.ok) {
          throw new Error('Failed to fetch notices');
        }


        const data = await response.json();
        const todaysNotice = data.find((notice) => {
          const noticeDate = notice.date.split('-').reverse().join('-'); // Convert notice date to YYYY-MM-DD
          return noticeDate === today;
        });


        if (todaysNotice && (!latestNotice || latestNotice.id !== todaysNotice.id)) {
          setLatestNotice(todaysNotice);
          setIsPopupVisible(true);
          console.log('Today\'s notice detected:', todaysNotice);
        }
      } catch (error) {
        console.error('Error fetching notices:', error);
      }
    };


    const intervalId = setInterval(fetchLatestNotice, 10000); // Poll every 10 seconds


    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [latestNotice]);


  const handleViewClick = () => {
    setIsPopupVisible(false);
    navigate('/notice-board'); // Navigate to the NoticeBoard screen
  };


  const closePopup = () => {
    setIsPopupVisible(false);
  };


  return (
    <>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route
          path="/*"
          element={
            <Layout>
              <div className="main-content-wrapper">
                <Routes>
                  <Route path="Login" element={<Login />} />
                  <Route path="dashboard" element={<Dashboard />} />
                  <Route path="client-dashboard" element={<ClientDashboard />} />
                  <Route path='client-tasks' element={<ClientTask />} />
                  <Route path="advanced-dashboard" element={<AdvancedDashboard />} />
                  <Route path="attendance" element={<Attendance />} />
                  <Route path="appreciation" element={<Appreciation />} />
                  <Route path="contracts" element={<Contracts />} />
                  <Route path="bank-account" element={<BankAccount />} />
                  <Route path="clients" element={<Clients />} />
                  <Route path="AttendanceReport" element={<AttendanceReport />} />
                  <Route path="candidate-database" element={<CandidateDB />} />
                  <Route path="asset" element={<Asset />} />
                  <Route path="bill" element={<Bill />} />
                  <Route path="packages" element={<SuperAdminPackages />} />
                  <Route path="profile-detail" element={<ProfileDetail />} />
                  <Route path="SuperAdmin" element={<SuperAdmin />} />
                  <Route path="/companyDetail/:id" element={<CompanyDetail />} />
                  <Route path="companies" element={<SuperAdminCompanies />} />
                  <Route path="AddCompanies" element={<AddCompanies />} />
                  <Route path="Billing" element={<SuperAdminBill />} />
                  <Route path="Super-Admin" element={<SuperAdminList />} />
                  <Route path="AddSuperAdminBill" element={<AddSuperAdminBill />} />
                  <Route path="admin-FAQ" element={<AdminFAQ />} />
                  <Route path="SuperSetting" element={<SuperSetting />} />
                  <Route path="employee" element={<Employee />} />
                  <Route path="leave" element={<Leave />} />
                  <Route path="department" element={<Department />} />
                  <Route path="project" element={<Projects />} />
                  <Route path="/profile/:userId" element={<Profile />} />
                  <Route path="task" element={<Task />} />
                  <Route path="expense" element={<Expense />} />
                  <Route path="vendor" element={<Vendor />} />
                  <Route path="product" element={<Product />} />
                  <Route path="AddOrder" element={<AddOrder />} />
                  <Route path="purchase-order" element={<PurchaseOrder />} />
                  <Route path="addEmployee" element={<AddEmployee />} />
                  <Route path="estimates" element={<Estimate />} />
                  <Route path="/addestimate" element={<AddEstimate />} />
                  <Route path="ProfileData" element={<ProfileData />} />
                  <Route path="/editproject/:id" element={<EditProject />} />
                  <Route path="holiday" element={<Holiday />} />
                  <Route path="/editEmployee/:id" element={<EditEmployee />} />
                  <Route path="designation" element={<Designation />} />
                  <Route path="lead-contact" element={<LeadContact />} />
                  <Route path="/leadProfile/:id" element={<LeadProfile />} />
                  <Route path="notice-board" element={<NoticeBoard />} />
                  <Route path="addClient" element={<AddClient />} />
                  <Route path="task-report" element={<TaskReport />} />
                  <Route path="leave-report" element={<LeaveReport />} />
                  <Route path="employee-salary" element={<EmployeeSalary />} />
                  <Route path="knowledge-base" element={<KnowledgeBase />} />
                  <Route path="shift-roaster" element={<ShiftRoaster />} />
                  <Route path="timesheet" element={<Timesheet />} />
                  <Route path="project-roadmap" element={<ProjectRoadmap />} />
                  <Route path="/assetDetail/:id" element={<AssetDetail />} />
                  <Route path="attendance-report" element={<AttendanceReport />} />
                  <Route path="deals" element={<Deals />} />
                  <Route path="/new-task" element={<AddTaskModal />} />
                  <Route path="/edit-task/:taskId" element={<EditTaskModal />} />
                  <Route path="/add-expense" element={<AddExpenseModal />} />
                  <Route path="/edit-expense/:expenseId" element={<EditExpense />} />
                  <Route path="/view-expense/:expenseId" element={<ViewExpense />} />
                  <Route path="expense-report" element={<ExpenseReport />} />
                  <Route path="credit-note" element={<CreditNote />} />
                  <Route path="payments" element={<Payment />} />
                  <Route path="/ViewEstimate/:id" element={<ViewEstimate />} />
                  <Route path="proposal" element={<Proposal />} />
                  <Route path="e-invoice" element={<Evoice />} />
                  <Route path="/addLead" element={<AddLeadPage />} />
                  <Route path="/addProject" element={<AddProjectPage />} />
                  <Route path="recruit-dashboard" element={<RecruitDashboard />} />
                  <Route path="event" element={<Event />} />
                  <Route path="settings" element={<Settings />} />
                  {/* <Route path="employeecomponent" element={<EmployeeComponent/>} /> */}
                  <Route path="/employeecomponent/:roleId" element={<EmployeeComponent />} />
                  <Route path="map" element={<MapScreen />} />
                  <Route path="message" element={<Message />} />
                  <Route path="/ClientEdit/:clientId" element={<ClientEdit />} />
                  <Route path="/Projectdashboard/:projectId" element={<Projectdashboard />} />
                  <Route path="/task-detail/:taskId" element={<TaskDetail />} /> {/* Task detail page */}
                  <Route path="/add-vendor" element={<VendorForm />} />
                  <Route path="/vendor-detail/:id" element={<VendorDetail />} />
                  <Route
                    path="/"
                    element={
                      <Navigate
                        to={
                          userType === '1'
                            ? '/SuperAdmin'
                            : userType === '2'
                              ? '/clien-dashboard'
                              : '/dashboard'
                        }
                        replace
                      />
                    }
                  />
                  <Route path="/add-product" element={<AddProduct />} />
                  <Route path="/add-bom" element={<Addbom />} />
                  <Route path="/" element={<Bom />} />
                  <Route path="/purchase-requisitions" element={<PurchaseRequisation />} />
                  <Route path="/Add-purchase-requisation" element={<AddPurchaseRequistion />} />
                  <Route path="/HR-Dashboard" element={<HRDashboard />} />

                  <Route path="/edit-leave/:id" element={<EditLeave />} />
                  <Route path="/daily-update" element={<DailyUpdateList />} />
                  <Route path="/NotificationScreen" element={<NotificationScreen />} />
                  <Route path="/EditLead/:id" element={<EditLead />} />
                  <Route path="payroll" element={<Payroll />} />
                  <Route path="Client-Visit" element={<ClientVisit />} />
                  <Route path="LandingPage" element={<LandingPage />} />
                  <Route path="/vendor-edit/:id" element={<VendorEdit />} />
                  <Route path="CRM-Dashboard" element={<CRMDashboard />} />
                  <Route path="/edit-product/:id" element={<EditProduct />} />
                  <Route path="/newpassword" element={<ForgotPassword />} />
                  <Route path="/ClientDetail/:id" element={<ClientDetail />} />
                  <Route path="/Tickets" element={<Tickets />} />
                  <Route path="/Support-Ticket" element={<AdminTicket />} />
                  <Route path="/deal-profile/:id" element={<DealProfile />} />
                  <Route path="/user-performance" element={<UserPerformance />} />
                  <Route path="/AddProposal" element={<AddProposal />} />
                  <Route path='/EditPurchaseOrder/:id' element={<EditPurchaseOrder />} />
                  <Route path='/EditProposal/:id' element={<EditProposal />} />
                  <Route path='/EditEstimate/:id' element={<EditEstimate />} />
                  {/* <Route path='/ThunderToText' element={<ThunderToText />} /> */}
                  <Route path='/SignUpLogInForm' element={<SignUpLogInForm />} />
                  <Route path='/crm-report' element={<CRM_Reports />} />
                  <Route path='/ticket-view/:ticketId' element={<TicketView />} />
                  <Route path='/project-report' element={<ProjectReport />} />
                  <Route path='/add-payment' element={<PurchaseForm />} />
                  <Route path='/viewProposal/:id' element={<ViewProposal />} />
                  <Route path='/projectExpenseReport' element={<ProjectExpenseReport />} />
                  <Route path="/addProjectTask/:projectId" element={<AddProjectTask />} />
                  <Route path='/project-task' element={<Tasks />} />
                  <Route path='/vendor-payments' element={< VendorPaymentPage />} />
                  <Route path="/edit-payment/:purchase_id" element={<EditPayment />} />
                  <Route path="/deal-proposal/:id" element={<DealProposal />} />
                  <Route path="/add-deal-proposal/:dealId" element={<AddDealProposal />} />
                  <Route path="/ViewPurchaseOrder/:order_id" element={<ViewPurchaseOrder />} />

                </Routes>
              </div>
            </Layout>
          }
        />
      </Routes>
      .
      {/* Popup */}
      {isPopupVisible && latestNotice && (
        <div style={popupOverlayStyle}>
          <div style={popupContentStyle}>
            <h2>Notice Board</h2>
            <p>{latestNotice.heading}</p>
            <button style={viewButtonStyle} onClick={handleViewClick}>
              View
            </button>
            <button style={closeButtonStyle} onClick={closePopup}>
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
}


function App() {
  return (
    <Router>
      <MainApp />
    </Router>
  );
}


export default App;


const popupOverlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
};


const popupContentStyle = {
  backgroundColor: '#fff',
  padding: '20px',
  borderRadius: '8px',
  width: '300px',
  maxWidth: '90%',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  textAlign: 'center',
};


const viewButtonStyle = {
  padding: '10px 20px',
  backgroundColor: '#007bff',
  color: '#fff',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  marginTop: '10px',
};


const closeButtonStyle = {
  padding: '10px 20px',
  backgroundColor: '#ff0000',
  color: '#fff',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  marginTop: '10px',
  marginLeft: '10px',
};

