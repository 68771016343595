import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import defaultimage from '../assets/asset.png';
import { Vortex } from 'react-loader-spinner';
import { Box, Button, Stack, Typography } from '@mui/material';
import Swal from 'sweetalert2';
import { Modal } from "@mui/material";
import iconImage from '../assets/Icon.png';
import { MdDelete } from 'react-icons/md';


const Asset = () => {
  const [assets, setAssets] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const [newCategory, setNewCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const [assetTypes, setAssetTypes] = useState([]);
  const [assetData, setAssetData] = useState({
    name: "",
    asset_type_id: "",
    serial_number: "",
    value: "",
    location: "null",
    status: "Available",
    description: "",
    image: "",
  });

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const [isAssetDropdownOpen, setIsAssetDropdownOpen] = useState(false);
  const [selectedAssetTypeId, setSelectedAssetTypeId] = useState("");

  const handleAssetTypeChange = (id) => {
    setSelectedAssetTypeId(id);
    setAssetData((prev) => ({ ...prev, asset_type_id: id }));
    setIsAssetDropdownOpen(false); // Close dropdown after selection
  };


    // Fetch asset types from API
    const fetchAssetTypes = async () => {
      try {
        const response = await fetch("https://api.myntask.com/assets/types-list", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        });
  
        const result = await response.json();
        if (result.success) {
          setAssetTypes(result.data); // Store asset types in state
          console.log("Fetched Asset Types:", result.data);
        } else {
          throw new Error("Failed to fetch asset types");
        }
      } catch (error) {
        console.error("Error fetching asset types:", error);
      }
    };
  
    // Effect to fetch data on mount
    useEffect(() => {
      fetchAssetTypes();
    }, []);
  

    const handleDeleteAssetType = async (id) => {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      });
    
      if (result.isConfirmed) {
        try {
          const response = await fetch(`https://api.myntask.com/assets/asset-types/${id}`, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          });
    
          if (!response.ok) {
            throw new Error("Failed to delete asset type");
          }
    
          // Remove deleted asset type from state
          setAssetTypes((prev) => prev.filter((type) => type.id !== id));
    
          // Show success message in toast style
          Swal.fire({
            icon: "success",
            title: "Deleted!",
            text: "Asset type deleted successfully!",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        } catch (error) {
          console.error("Error deleting asset type:", error);
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "Failed to delete asset type!",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    };
    


  const handleChange = (e) => {
    const { name, value } = e.target;
    setAssetData((prevData) => ({ ...prevData, [name]: value }));
  };


  const handleFileChange = (e) => {
    setAssetData({ ...assetData, image: e.target.files[0] }); // Store file object
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("name", assetData.name);
      formData.append("asset_type_id", assetData.asset_type_id);
      formData.append("serial_number", assetData.serial_number);
      formData.append("value", assetData.value);
      formData.append("location", assetData.location);
      formData.append("status", assetData.status);
      formData.append("description", assetData.description);

      if (assetData.image) {
        formData.append("image", assetData.image); // Assuming assetData.image is a File object
      }

      const response = await fetch("https://api.myntask.com/assets/add", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
        body: formData, // Send FormData instead of JSON
      });

      if (!response.ok) {
        throw new Error("Failed to add asset");
      }

      Swal.fire({
        icon: "success",
        title: "Asset added successfully!",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
      });

      fetchAssets(); // Refetch assets after adding new asset
      setAssetData({
        name: "",
        asset_type_id: "",
        serial_number: "",
        value: "",
        location: "null",
        status: "Available",
        description: "",
        image: "",
      });
      
      handleCloseModal();
      setIsAssetDropdownOpen(false); // Close dropdown on saving
    } catch (error) {
      console.error("Error adding asset:", error);

      Swal.fire({
        icon: "error",
        title: "Error adding asset!",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };




  const fetchAssets = async () => {
    const token = localStorage.getItem("authToken");

    try {
      const response = await fetch('https://api.myntask.com/assets/list', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();

      if (Array.isArray(data)) {
        setAssets(data);
      } else {
        console.error('Expected array but received:', data);
        setAssets([]); // Set as empty array to avoid issues
      }
    } catch (error) {
      console.error('Error fetching assets:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAssets();
  }, []);


  const handleAssetClick = (id) => {
    navigate(`/assetDetail/${id}`);
  };


  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };


  const filteredAssets = assets.filter(asset => {
    const query = searchQuery.toLowerCase();
    return (
      asset.asset_name.toLowerCase().includes(query) ||
      asset.assets_status.toLowerCase().includes(query) ||
      (asset.lent_to && asset.lent_to.user_name.toLowerCase().includes(query)) ||
      (asset.date && asset.date.toLowerCase().includes(query))
    );
  });

  const handleDeleteAsset = async (assetId) => {
    const token = localStorage.getItem('authToken');

    try {
      // Ask for confirmation using SweetAlert
      const willDelete = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (!willDelete) {
        return; // User canceled the deletion
      }

      const response = await fetch(`https://api.myntask.com/assets/delete/${assetId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to delete category');
      }

      fetchAssets();
      // Show success alert
      Swal.fire({
        title: 'Deleted!',
        text: 'Asset deleted successfully!',
        icon: 'success',
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
      });

    } catch (error) {
      console.error('Error deleting asset:', error);

      // Show error alert
      Swal.fire({
        title: 'Error',
        text: 'Error deleting asset. Please try again.',
        icon: 'error',
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const handleSaveCategory = async () => {
    if (!newCategory.trim()) {
      Swal.fire({
        icon: "warning",
        title: "Category name is required!",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    }

    setLoading(true);

    try {
      const response = await fetch("https://api.myntask.com/assets/types-save", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
        body: JSON.stringify({ name: newCategory }),
      });

      if (!response.ok) {
        throw new Error("Failed to save category");
      }

      Swal.fire({
        icon: "success",
        title: "Category added successfully!",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
      });

      setNewCategory(""); // Clear input after saving
      fetchAssetTypes(); // Refetch categories
      setOpenCategoryModal(false); // Close modal
    } catch (error) {
      console.error("Error saving category:", error);

      Swal.fire({
        icon: "error",
        title: "Error adding category!",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
      });
    } finally {
      setLoading(false);
    }
  };


  //..........Loading Spinner ..........................................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading......
          </Box>
        </Stack>
      </Box>
    );
  }

  // if (error) {
  //   return (
  //     <Typography
  //       color="error"
  //       sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
  //     >
  //       {error}
  //     </Typography>
  //   );
  // }



  return (
    <div className="main-container">
      <div className="top-controls">
        <h1 className="title">
          Assets
          <div className="underline"></div>
        </h1>
        <div className="buttons-row">
          <button className="color-button" onClick={handleOpenModal}>
            Add Asset
          </button>
        </div>
      </div>
      <div className="top-controls">
        <div className="filters-and-search">
          <input
            type="text"
            placeholder="Search assets..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="searchbar"
          />
        </div>
      </div>


      <table>
        <thead>
          <tr>
            <th>Asset</th>
            <th>Status</th>
            <th>Lent To</th>
            <th>Date</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {filteredAssets.length > 0 ? (
            filteredAssets.map((asset) => (
              <tr key={asset.assets_id}>
                <td>
                  <div className="info-container">
                    <img
                      src={asset.image || defaultimage}
                      alt={asset.asset_name}
                      className="image"
                    />
                    <div
                      className="info-text"
                      onClick={() => handleAssetClick(asset.assets_id)}
                      style={{
                        cursor: 'pointer',
                        color: 'inherit',
                        textDecoration: 'none',
                      }}
                    >
                      {asset.asset_name}
                    </div>
                  </div>
                </td>
                <td >{asset.assets_status}</td>
                <td>
                  {asset.assets_status === 'lent' ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={asset.lent_to.user_image || defaultimage}
                        alt={asset.lent_to.user_name}
                        className="image"
                      />
                      <div>
                        <p className="user-name">{asset.lent_to.user_name}</p>
                        <p className="user-designation">{asset.lent_to.designation}</p>
                      </div>
                    </div>
                  ) : <p >None</p>}
                </td>
                <td s>{asset.date || 'N/A'}</td>
                <td>
                  <span onClick={() => handleDeleteAsset(asset.assets_id)} style={{ cursor: "pointer", color: "red" }}>Delete</span>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No assets available</td>
            </tr>
          )}
        </tbody>
      </table>


      <Modal style={styles.modalOverlay} open={showModal} onClose={handleCloseModal}>
        <div style={styles.modalContent}>

          <h1 className="modal-title-deal">Add Asset</h1>
          <img
            src={iconImage} // Replace 'iconImage' with the path or import for Icon.png
            alt="Close"
            style={{
              position: "absolute",
              top: "16px",
              right: "16px",
              border: "none",
              cursor: "pointer",
            }}
            onClick={handleCloseModal}
          />

          <div className="form-grid-deal">
            <div className="form-field-group-deal">
              <label className="form-label-deal">Asset Name</label>
              <input type="text" name="name" value={assetData.name} onChange={handleChange} className="form-input-deal" />
            </div>

            <div className="form-field-group-deal">
              <label className="form-label-deal">Serial Number</label>
              <input type="text" name="serial_number" value={assetData.serial_number} onChange={handleChange} className="form-input-deal" />
            </div>

           
<div style={styles.formFieldGroup}>
  <label style={styles.formLabel}>Asset Type</label>

  <div style={styles.categoryRow}>
  <div style={styles.categoryDropdownContainer}>
  <div
    style={styles.customDropdown}
    onClick={(e) => {
      e.stopPropagation();
      setIsAssetDropdownOpen((prev) => !prev);
    }}
  >
    <div style={styles.selectedCategory}>
      {selectedAssetTypeId
        ? assetTypes.find((type) => type.id === selectedAssetTypeId)?.name || "Select Type"
        : "Select Type"}
    </div>
  </div>

  {isAssetDropdownOpen && (
    <div style={styles.dropdownOptions}>
      {assetTypes.map((type) => (
        <div key={type.id} style={styles.dropdownItem}>
          <span style={styles.dropdownText} onClick={() => handleAssetTypeChange(type.id)}>
            {type.name}
          </span>
          <Button
            style={styles.deleteButton}
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteAssetType(type.id);
            }}
          >
            <MdDelete />
          </Button>
        </div>
      ))}
    </div>
  )}
</div>

    <button className="color-button" onClick={() => setOpenCategoryModal(true)}>
    Add
  </button>
  </div>
</div>

            
            <div className="form-field-group-deal">
              <label className="form-label-deal">Value</label>
              <input type="number" name="value" value={assetData.value} onChange={handleChange} className="form-input-deal" />
            </div>

           

            <div className="form-field-group-deal">
              <label className="form-label-deal">Status</label>
              <select name="status" value={assetData.status} onChange={handleChange} className="form-input-deal">
                <option value="Available">Available</option>
                <option value="In Use">In Use</option>
                <option value="Damaged">Damaged</option>
              </select>
            </div>

            <div className="form-field-group-deal">
              <label className="form-label-deal">Asset Image</label>
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange} 
                className="form-input-deal"
              />
            </div>


          </div>

          <div className="form-field-group-deal">
            <label className="form-label-deal">Description</label>
            <textarea name="description" value={assetData.description} onChange={handleChange} className="form-input-deal" />
          </div>

          <div className="form-button-group-deal">
            <button onClick={handleSubmit} className="submit-button-deal">Save</button>
          </div>

        </div>
      </Modal>

{/* <Modal open={showModal} onClose={handleCloseModal}>
      <div style={styles.modalOverlay}>
        <div style={styles.modalContent}>
          <h1 style={styles.modalTitle}>Add Asset</h1>

          <img
            src="icon.png" // Replace with your image path
            alt="Close"
            style={styles.closeIcon}
            onClick={handleCloseModal}
          />

          <div style={styles.formGrid}>
            <div style={styles.formFieldGroup}>
              <label style={styles.formLabel}>Asset Name</label>
              <input
                type="text"
                name="name"
                value={assetData.name}
                onChange={(e) => setAssetData({ ...assetData, name: e.target.value })}
                className="form-input-deal"
              />
            </div>

            <div style={styles.formFieldGroup}>
              <label style={styles.formLabel}>Serial Number</label>
              <input
                type="text"
                name="serial_number"
                value={assetData.serial_number}
                onChange={(e) => setAssetData({ ...assetData, serial_number: e.target.value })}
                className="form-input-deal"
              />
            </div>

            
            <div style={styles.categoryDropdownContainer}>
              <div
                style={styles.customDropdown}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsAssetDropdownOpen((prev) => !prev);
                }}
              >
                <div style={styles.selectedCategory}>
                  {selectedAssetTypeId === 1
                    ? "Equipment"
                    : selectedAssetTypeId === 2
                    ? "Furniture"
                    : "Select Type"}
                </div>
              </div>

              {isAssetDropdownOpen && (
                <div style={styles.dropdownOptions}>
                  <div style={styles.dropdownItem}>
                    <span style={styles.dropdownText} onClick={() => handleAssetTypeChange(1)}>
                      Equipment
                    </span>
                    <Button
                      style={styles.deleteButton}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteAssetType(1);
                      }}
                    >
                      <MdDelete />
                    </Button>
                  </div>

                  <div style={styles.dropdownItem}>
                    <span style={styles.dropdownText} onClick={() => handleAssetTypeChange(2)}>
                      Furniture
                    </span>
                    <Button
                      style={styles.deleteButton}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteAssetType(2);
                      }}
                    >
                      <MdDelete />
                    </Button>
                  </div>
                </div>
              )}
              
              <button style={styles.addButton} onClick={() => console.log("Add New Asset Type")}>
                Add
              </button>
            </div>

            <div style={styles.formFieldGroup}>
              <label style={styles.formLabel}>Value</label>
              <input
                type="number"
                name="value"
                value={assetData.value}
                onChange={(e) => setAssetData({ ...assetData, value: e.target.value })}
                className="form-input-deal"
              />
            </div>

            <div style={styles.formFieldGroup}>
              <label style={styles.formLabel}>Status</label>
              <select
                name="status"
                value={assetData.status}
                onChange={(e) => setAssetData({ ...assetData, status: e.target.value })}
                className="form-input-deal"
              >
                <option value="Available">Available</option>
                <option value="In Use">In Use</option>
                <option value="Damaged">Damaged</option>
              </select>
            </div>

            <div style={styles.formFieldGroup}>
              <label style={styles.formLabel}>Asset Image</label>
              <input type="file" accept="image/*" className="form-input-deal" />
            </div>
          </div>

          <div style={styles.formFieldGroup}>
            <label style={styles.formLabel}>Description</label>
            <textarea
              name="description"
              value={assetData.description}
              onChange={(e) => setAssetData({ ...assetData, description: e.target.value })}
              className="form-input-deal"
            />
          </div>

          <div style={styles.formButtonGroup}>
            <button style={styles.submitButton} onClick={() => console.log("Submit Asset Data")}>
              Save
            </button>
          </div>
        </div>
      </div>
    </Modal> */}

      {/* Category Modal */}
      <Modal open={openCategoryModal} onClose={() => setOpenCategoryModal(false)}>
        <Box className="designation-modal">
          {/* Header */}
          <div className="designation-header">
            <h1 className="designation-title">Add Category</h1>
            <img
              src={iconImage}
              alt="Close"
              className="designation-close"
              onClick={() => setOpenCategoryModal(false)}
            />
          </div>

          {/* Form Section */}
          <div className="designation-form">
            <div className="form-group">
              <input
                type="text"
                name="newCategory"
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
                placeholder="Category Name"
                className="designation-input"
                required
              />
            </div>

            <button
              className="designation-save"
              onClick={handleSaveCategory}
              disabled={loading}
            >
              {loading ? "Saving..." : "Save"}
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};


export default Asset;




const styles = {
  modalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1300,
  },
  modalContent: {
    background: "#ffffff",
    borderRadius: "16px",
    padding: "32px",
    width: "40%",
    maxWidth: "100%",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
    position: "absolute",
    gap: "16px",
    zIndex: 1300,
  },
  closeIcon: {
    position: "absolute",
    top: "16px",
    right: "16px",
    cursor: "pointer",
  },
  formGrid: {
    display: "grid",
    gap: "16px",
  },
  formFieldGroup: {
    display: "flex",
    flexDirection: "column",
  },
  formLabel: {
    marginBottom: "5px",
  },
  categoryRow: {
    display: "flex",
    alignItems: "center",
    gap: "10px", // Space between select and button
    width: "100%",
  },
  categoryDropdownContainer: {
    position: "relative",
    zIndex: 1400,
    width: "100%",
  },
  customDropdown: {
    position: "relative",
    width: "100%",
  },
  selectedCategory: {
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    backgroundColor: "#f9f9f9",
    cursor: "pointer",
  },
  dropdownOptions: {
    position: "absolute",
    top: "100%",
    left: 0,
    width: "100%",
    backgroundColor: "white",
    border: "1px solid #ccc",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
    zIndex: 1400,
    maxHeight: "200px",  // Set max height
    overflowY: "auto",   // Enable vertical scroll
    borderRadius: "5px", // Optional: Add rounded corners
},
  dropdownItem: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
    cursor: "pointer",
  },

};

