import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, startOfWeek, endOfWeek, startOfMonth, endOfMonth, startOfYear, endOfYear, subMonths } from "date-fns";




const DailyUpdateList = () => {
  const [reports, setReports] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState("today");
  const [customDates, setCustomDates] = useState({ start: null, end: null });
  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState({});




  // const fetchReports = async () => {
  //   setLoading(true);
  //   setError(null);

  //   const token = localStorage.getItem("authToken");
  //   if (!token) {
  //     setError("Authorization token not found");
  //     setLoading(false);
  //     return;
  //   }

  //   let url = "https://api.myntask.com/home/daily_report_list";
  //   const queryParams = new URLSearchParams();


  //   if (selectedMember) {
  //     queryParams.append("member_id", selectedMember);
  //   }

  //   // Get the date range from function
  //   const { startDate, endDate } = getDateRange();
  //   if (startDate && endDate) {
  //     queryParams.append("start_date", format(startDate, "yyyy-MM-dd"));
  //     queryParams.append("end_date", format(endDate, "yyyy-MM-dd"));
  //   }

  //   // Append query parameters to URL
  //   if (queryParams.toString()) {
  //     url += `?${queryParams.toString()}`;
  //   }

  //   try {
  //     const response = await fetch(url, {
  //       method: "GET",
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });

  //     if (!response.ok) {
  //       throw new Error("Failed to fetch reports");
  //     }

  //     const data = await response.json();
  //     console.log("Fetched Reports:", data); // ✅ Check API Response
  //     setReports(data);
  //     setFilteredReports(data); // Initially, filtered reports = all reports
  //   } catch (error) {
  //     setError(error.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };


  // useEffect(() => {
  //   fetchReports();
  // }, []);


  // useEffect(() => {
  //   if (reports.length === 0) return;

  //   const { startDate, endDate } = getDateRange();

  //   const filtered = reports.filter((report) => {
  //     const reportDate = new Date(report.date);
  //     return (
  //       (!selectedMember || report.member_id === selectedMember) &&
  //       (!startDate || reportDate >= startDate) &&
  //       (!endDate || reportDate <= endDate)
  //     );
  //   });

  //   setFilteredReports(filtered);
  // }, [selectedMember, filter, customDates, reports]);



  const fetchReports = async () => {
    setLoading(true);
    setError(null);

    const token = localStorage.getItem("authToken");

    if (!token) {
      setError("Authorization token not found");
      setLoading(false);
      return;
    }

    let url = "https://api.myntask.com/home/daily_report_list";
    const queryParams = new URLSearchParams();

    // Add selected member if available
    if (selectedMember) {
      queryParams.append("member_id", selectedMember);
    }

    // Get the date range from your function
    const { startDate, endDate } = getDateRange();

    if (startDate && endDate) {
      queryParams.append("start_date", format(startDate, "yyyy-MM-dd"));
      queryParams.append("end_date", format(endDate, "yyyy-MM-dd"));
    }

    // Append query parameters to URL
    if (queryParams.toString()) {
      url += `?${queryParams.toString()}`;
    }

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch reports");
      }

      const data = await response.json();
      setReports(data);
      setFilteredReports(data); // Initially, filtered reports = all reports
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Call fetchReports when filters change
  useEffect(() => {
    fetchReports();
  }, [selectedMember, filter, customDates]); // Runs when any filter changes



  useEffect(() => {
    const fetchData = async (url, setter) => {
      const token = localStorage.getItem("authToken");
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setter(data || []);
      } catch (error) {
        console.error(`Error fetching data from ${url}:`, error);
        setter([]);
      }
    };

    fetchData("https://api.myntask.com/employee/member", setMembers);
  }, []);


  // Function to get the date range based on the selected filter
  const getDateRange = () => {
    const today = new Date();
    let startDate, endDate;

    switch (filter) {
      case "today":
        startDate = today;
        endDate = today;
        break;
      case "thisWeek":
        startDate = startOfWeek(today);
        endDate = endOfWeek(today);
        break;
      case "thisMonth":
        startDate = startOfMonth(today);
        endDate = endOfMonth(today);
        break;
      case "thisQuarter":
        startDate = subMonths(today, 2);
        endDate = today;
        break;
      case "thisYear":
        startDate = startOfYear(today);
        endDate = endOfYear(today);
        break;
      case "custom":
        startDate = customDates.start;
        endDate = customDates.end;
        break;
      default:
        startDate = null;
        endDate = null;
    }

    return { startDate, endDate };
  };





  const handleDeleteReport = async (reportId) => {
    const token = localStorage.getItem('authToken');
    try {
      // Ask for confirmation using SweetAlert
      const willDelete = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (!willDelete) {
        return; // User canceled the deletion
      }
      const response = await fetch(`https://api.myntask.com/home/daily_report_delete/${reportId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to delete category');
      }


      fetchReports();
      // Show success alert
      Swal.fire({
        title: 'Deleted!',
        text: 'report deleted successfully!',
        icon: 'success',
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      console.error('Error deleting report:', error);
      // Show error alert
      Swal.fire({
        title: 'Error',
        text: 'Error deleting report. Please try again.',
        icon: 'error',
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };




  const handleOpenModal = (report) => {
    setModalContent(report);
    setOpenModal(true);
  };




  const handleCloseModal = () => {
    setOpenModal(false);
    setModalContent({});
  };








  const handleExport = () => {
    const worksheetData = filteredReports.map((report) => ({
      'Team Member': report.user_name,
      Project: report.project_ids.join(', '), // Combine project IDs into a single string
      Summary: report.summary.join('\n'), // Combine summary items into a single cell with newlines
      ToDo: report.tomorrow_work.join('\n'), // Combine tomorrow's work items into a single cell with newlines
      Mood: report.mood,
      Date: new Date(report.created_at).toLocaleString(), // Convert date to a readable format
      Location: report.location, // Include location (if required)
    }));
    // Convert worksheet data into an Excel sheet
    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Daily Reports');
    XLSX.writeFile(workbook, 'Daily_Updates_List.xlsx');
  };








  //..........Loading Spinner ..........................................
  // if (loading) {
  //   return (
  //     <Box
  //       sx={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         height: "100vh",
  //         backgroundColor: "#f5f5f5",
  //       }}
  //     >
  //       <Stack alignItems="center">
  //         <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
  //         <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
  //           Loading....
  //         </Box>
  //       </Stack>
  //     </Box>
  //   );
  // }















  // if (error) {
  //   return (
  //     <Typography
  //       color="error"
  //       sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
  //     >
  //       {error}
  //     </Typography>
  //   );
  // }












  return (
    <div className="main-container">
      <div className="top-controls">
        <h1 className="title">
          Daily Update List
          <div className="underline"></div>
        </h1>
        <div className="buttons-row">
          <button className="color-button" onClick={handleExport}>
            Export to Excel
          </button>


        </div>
      </div>


      {/* Filters */}
      <div className="top-controls">
        <div className="filters-and-search">


          {/* Member Selection Dropdown */}
          <div >
            <label className="lead-form-label">Select Member</label>
            <select
              className="select-dropdown"
              value={selectedMember}
              onChange={(e) => setSelectedMember(e.target.value)}
            >
              <option value="">Select Member</option>
              {members.map((member) => (
                <option key={member.id} value={member.id}>
                  {member.member_name}
                </option>
              ))}
            </select>
          </div>

          <div style={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
            {/* Date Filter Dropdown */}
            <div>
              <label className="lead-form-label">Date Filter</label>
              <select
                className="select-dropdown"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              >
                <option value="today">
                  Today ({format(new Date(), "MMM d, yyyy")})
                </option>
                <option value="thisWeek">
                  This Week ({format(startOfWeek(new Date()), "MMM d")} -{" "}
                  {format(endOfWeek(new Date()), "MMM d")})
                </option>
                <option value="thisMonth">
                  This Month ({format(new Date(), "MMMM")})
                </option>
                <option value="thisQuarter">
                  This Quarter (Dec - {format(new Date(), "MMM")})
                </option>
                <option value="thisYear">
                  This Year ({new Date().getFullYear()})
                </option>
                <option value="custom">Custom Date</option>
              </select>
            </div>


            {/* Custom Date Picker */}
            {filter === "custom" && (
              <div style={{ display: 'flex', gap: "0.5rem", alignItems: 'center' }}>
                <div>
                  <label className="lead-form-label">Start Date : </label>
                  <DatePicker
                    selected={customDates.start}
                    onChange={(date) => setCustomDates({ ...customDates, start: date })}
                    selectsStart
                    startDate={customDates.start}
                    endDate={customDates.end}
                    placeholderText="Start Date"
                    className="date-picker"
                  />
                </div>
                <div>
                  <label className="lead-form-label">End Date : </label>
                  <DatePicker
                    selected={customDates.end}
                    onChange={(date) => setCustomDates({ ...customDates, end: date })}
                    selectsEnd
                    startDate={customDates.start}
                    endDate={customDates.end}
                    placeholderText="End Date"
                    className="date-picker"
                  />
                </div>
              </div>
            )}
          </div>



        </div>
      </div>






      <table>
        <thead style={{ zIndex: 0 }}>
          <tr>
            <th>Team Member</th>
            <th>Project</th>
            <th>Summary</th>
            <th>Tomorrow work</th>
            {/* <th>Challenges</th> */}
            <th>Mood</th>
            <th>Date</th>
            <th>Location</th>
            <th>Report</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {filteredReports.length > 0 ? (
            filteredReports.map((report, index) => (
              <tr key={index}>
                <td>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src={report.image}
                      alt={report.user_name}
                      className="image"
                    />
                    {report.user_name}
                  </div>
                </td>
                <td>{report.project_name}</td>
                <td
                  style={{ cursor: 'pointer', color: '#1976d2' }}
                  onClick={() => handleOpenModal(report)}
                >
                  {Array.isArray(report.summary)
                    ? report.summary.join(',').split(',').map((item, idx) => (
                      <div key={idx}>
                        <span style={{ marginRight: '8px', color: '#000' }}>•</span>
                        {item.trim()}
                      </div>
                    ))
                    : report.summary.split(',').map((item, idx) => (
                      <div key={idx}>
                        <span style={{ marginRight: '8px', color: '#000' }}>•</span>
                        {item.trim()}
                      </div>
                    ))}
                </td>
                <td
                  style={{ cursor: 'pointer', color: '#1976d2' }}
                  onClick={() => handleOpenModal(report)}
                >
                  {Array.isArray(report.tomorrow_work)
                    ? report.tomorrow_work.join(',').split(',').map((item, idx) => (
                      <div key={idx}>
                        <span style={{ marginRight: '8px', color: '#000' }}>•</span>
                        {item.trim()}
                      </div>
                    ))
                    : report.tomorrow_work.split(',').map((item, idx) => (
                      <div key={idx}>
                        <span style={{ marginRight: '8px', color: '#000' }}>•</span>
                        {item.trim()}
                      </div>
                    ))}
                </td>






                {/* <td
                 style={{ cursor: 'pointer', color: '#1976d2' }}
                 onClick={() => handleOpenModal(report)}
               >
                 {report.challenges}
               </td> */}
                <td
                  style={{
                    color:
                      report.mood === 'happy' ? '#4caf50' : report.mood === 'neutral' ? '#ff9800' : '#f44336',
                    fontWeight: 'bold',
                  }}
                >
                  {report.mood}
                </td>
                <td>{new Date(report.created_at).toLocaleDateString()}</td>


                <td>
                  <a
                    href={report.location}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'underline', color: '#1976d2' }}
                  >
                    View Location
                  </a>
                </td>
                <td>
                  {report.report_files && report.report_files.length > 0 ? (
                    <a
                      href={report.report_files[0]}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'underline', color: '#1976d2' }}
                    >
                      View Files
                    </a>
                  ) : (
                    <span>No Data</span>
                  )}
                </td>
                <td>
                  <span onClick={() => handleDeleteReport(report.id)} style={{ cursor: "pointer", color: "red" }}>Delete</span>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={8} style={{ textAlign: 'center' }}>
                No data available.
              </td>
            </tr>
          )}
        </tbody>
      </table>










      {/* Updated Modal Design */}
      {openModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              backgroundColor: "#ffffff",
              padding: "32px",
              borderRadius: "12px",
              boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)",
              width: "90%",
              maxWidth: "600px",
              textAlign: "left",
              position: "relative",
              animation: "fadeIn 0.3s ease-in-out",
            }}
          >
            <h2
              style={{
                color: "#555",
                marginBottom: "16px",
                fontSize: "24px",
                fontWeight: "bold",
              }}
            >
              Daily Update Detail View
            </h2>






            <div style={{ display: 'flex', gap: '20px', marginBottom: "10px" }}>
              <div style={{
                fontSize: "16px",
                color: "#333",
                marginBottom: "8px",
                fontWeight: 'bold'
              }}>
                <p>Employee Name :</p>
                <p>Date of Update :</p>
                <p>Mood :</p>
              </div>
              <div style={{
                fontSize: "16px",
                color: "#333",
                marginBottom: "8px",
              }}>
                <p>{modalContent.user_name}</p>
                <p>{new Date(modalContent.created_at).toLocaleDateString()}</p>
                <p>{modalContent.mood}</p>
              </div>
            </div>
            <div className='underline'></div>




            <div style={{ marginTop: "16px" }}>
              <h3
                style={{
                  fontSize: "18px",
                  color: "#1976d2",
                  marginBottom: "12px",
                }}
              >
                ☞ Project Updates
              </h3>
              <p
                style={{
                  fontSize: "16px",
                  color: "#333",
                  marginBottom: "8px",
                }}
              >
                <strong>Project:</strong> {modalContent.project_name}
              </p>


              <div style={{
                marginBottom: "16px",
              }}>
                <p
                  style={{
                    fontSize: "16px",
                    color: "#333",
                    marginBottom: "2px",
                  }}
                >
                  <strong>Today's Work Update:</strong>
                </p>
                <p>{modalContent.summary}</p>
              </div>


              <div style={{
                marginBottom: "16px",
              }}>
                <p
                  style={{
                    fontSize: "16px",
                    color: "#333",
                    marginBottom: "2px",
                  }}
                >
                  <strong>Plan for Tomorrow:</strong>
                </p>
                <p>{modalContent.tomorrow_work || 'No plan for tomorrow'}</p>
              </div>


            </div>


            <div>
              <h3
                style={{
                  fontSize: "18px",
                  color: "#1976d2",
                  marginBottom: "7px",
                }}
              >
                ☞ Comments / Challenges
              </h3>
              <p
                style={{
                  fontSize: "16px",
                  marginBottom: "16px",
                }}
              >
                {modalContent.challenges || 'No significant issues today. Everything is on track.'}
              </p>
            </div>




            <h3
              style={{
                fontSize: "18px",
                color: "#1976d2",
                marginBottom: "5px",
              }}
            >
              ☞ Attached Files
            </h3>
            {modalContent.report_files && modalContent.report_files.length > 0 ? (
              modalContent.report_files.map((file, index) => (
                <div key={index} style={{ marginBottom: "8px" }}>
                  <a
                    href={file}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: "underline",
                      color: "#1976d2",
                      fontSize: "14px",
                    }}
                  >
                    {`File ${index + 1}`}
                  </a>
                </div>
              ))
            ) : (
              <p
                style={{
                  fontSize: "16px",
                  marginBottom: "16px",
                }}
              >
                No files attached.
              </p>
            )}
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <button
                onClick={handleCloseModal}
                className="color-button"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};








export default DailyUpdateList;













