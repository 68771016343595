import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Button,
  Typography,
  Card,
  CardContent,
  Grid,
  Box,
  Stack,
  Chip,
  Divider,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Badge
} from "@mui/material";
import {
  Email,
  Phone,
  Language,
  Business,
  LocationOn,
  Person,
  Work,
  AttachMoney,
  Description,
  Note,
  CalendarToday,
  AccountCircle,
  Edit,
  Message
} from "@mui/icons-material";
import { Vortex } from "react-loader-spinner";



const ClientDetail = () => {
  const { id } = useParams();
  const [clientDetails, setClientDetails] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const fetchClientDetails = async () => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      setError("No authentication token found. Please log in again.");
      setIsLoading(false);
      return;
    }


    try {
      const response = await fetch(`https://api.myntask.com/client/profile/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });


      if (!response.ok) {
        throw new Error("Failed to fetch client details");
      }


      const data = await response.json();
      console.log("Client Data:", data);


      setClientDetails(data); // Set the full API response
    } catch (error) {
      console.error("Error fetching client details:", error.message);
      setError("Unable to fetch client details. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    fetchClientDetails();
  }, [id]);



  //..........Loading Spinner ..........................................


  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }



  const { profile, invoice, due_invoices, project_by_status, total_earnings, total_project } =
    clientDetails;


  const profileDetails = profile && profile.length > 0 ? profile[0] : {};


  const getStatusChipColor = (status) => {
    switch (status?.toLowerCase()) {
      case "pending":
        return "orange";
      case "done":
        return "green";
      case "draft":
        return "gray";
      case "failed":
        return "red";
      default:
        return "blue";
    }
  };


  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Client Details
      </Typography>

      <Grid container spacing={3}>
        {/* Left Column - Company Information (70%) */}
        <Grid item xs={12} md={8}>
          <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
            <Stack direction="row" alignItems="center" spacing={2} mb={3}>
              {profileDetails.company_logo ? (
                <Avatar
                  src={profileDetails.company_logo}
                  alt={profileDetails.company_name}
                  sx={{ width: 80, height: 80 }}
                />
              ) : (
                <Avatar sx={{ width: 80, height: 80, bgcolor: 'primary.main' }}>
                  <Business sx={{ fontSize: 40 }} />
                </Avatar>
              )}
              <Box>
                <Typography variant="h5">{profileDetails.company_name || "No company name"}</Typography>
                <Typography variant="body2" color="text.secondary">
                  Added by: {profileDetails.added_by_user}
                </Typography>
              </Box>
            </Stack>

            <Divider sx={{ my: 2 }} />

            <Grid container spacing={3}>
              {/* Company Details */}
              <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>
                  <Business sx={{ verticalAlign: 'middle', mr: 1 }} />
                  Company Information
                </Typography>

                <List dense>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: 'primary.light' }}>
                        <Language />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Website"
                      secondary={profileDetails.website || "Not specified"}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: 'primary.light' }}>
                        <LocationOn />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Address"
                      secondary={profileDetails.address || "Not specified"}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: 'primary.light' }}>
                        <LocationOn />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="City/State"
                      secondary={`${profileDetails.city || "N/A"}, ${profileDetails.state || "N/A"}`}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: 'primary.light' }}>
                        <LocationOn />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Postal Code"
                      secondary={profileDetails.postal_code || "Not specified"}
                    />
                  </ListItem>
                </List>
              </Grid>

              {/* Financial & Project Stats */}
              <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>
                  <AttachMoney sx={{ verticalAlign: 'middle', mr: 1 }} />
                  Financial Summary
                </Typography>

                <List dense>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: 'success.light' }}>
                        <Work />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Total Projects"
                      secondary={total_project || 0}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: 'success.light' }}>
                        <AttachMoney />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Total Earnings"
                      secondary={`$${total_earnings || 0}`}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: 'success.light' }}>
                        <Description />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Total Invoices"
                      secondary={invoice || 0}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: 'error.light' }}>
                        <Description />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Due Invoices"
                      secondary={due_invoices || 0}
                      secondaryTypographyProps={{ color: 'error.main' }}
                    />
                  </ListItem>
                </List>
              </Grid>
            </Grid>

            <Divider sx={{ my: 2 }} />

            {/* Project Status */}
            <Typography variant="h6" gutterBottom>
              <Work sx={{ verticalAlign: 'middle', mr: 1 }} />
              Project Status
            </Typography>

            <Grid container spacing={2} sx={{ mt: 1 }}>
              {Object.entries(project_by_status || {}).map(([status, count]) => (
                <Grid item xs={6} sm={4} key={status}>
                  <Paper sx={{ p: 2, textAlign: 'center' }}>
                    <Typography variant="h6">{count}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      {status.charAt(0).toUpperCase() + status.slice(1)}
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>

        {/* Right Column - Client Personal Information (30%) */}
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
              <Typography variant="h5">
                <Person sx={{ verticalAlign: 'middle', mr: 1 }} />
                Contact Person
              </Typography>
              {/* <Button startIcon={<Edit />} size="small">
                Edit
              </Button> */}
            </Stack>

            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              // badgeContent={
              //   <Avatar
              //     src={profileDetails.added_by_image}
              //     sx={{ width: 24, height: 24, border: '2px solid white' }}
              //   />
              // }
              >
                <Avatar
                  src={profileDetails.image || "https://via.placeholder.com/150"}
                  sx={{ width: 100, height: 100 }}
                />
              </Badge>
            </Box>

            <Typography variant="h6" align="center" gutterBottom>
              {profileDetails.user_name || "N/A"}
            </Typography>

            <List dense>
              <ListItem>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: 'info.light' }}>
                    <Email />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Email"
                  secondary={profileDetails.email || "Not specified"}
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: 'info.light' }}>
                    <Phone />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Phone"
                  secondary={profileDetails.mobile || "Not specified"}
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: 'info.light' }}>
                    <AccountCircle />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Gender"
                  secondary={profileDetails.gender || "Not specified"}
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: 'info.light' }}>
                    <Work />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="Office"
                  secondary={profileDetails.office || "Not specified"}
                />
              </ListItem>
            </List>

            <Divider sx={{ my: 2 }} />

            <Typography variant="h6" gutterBottom>
              <Note sx={{ verticalAlign: 'middle', mr: 1 }} />
              Quick Actions
            </Typography>

            <Stack spacing={1}>
              <Button
                variant="contained"
                startIcon={<Message />}
                fullWidth
                onClick={() => alert("Send Message functionality not implemented yet.")}
              >
                Send Message
              </Button>
              <Button
                variant="outlined"
                startIcon={<Description />}
                fullWidth
                onClick={() => alert("Create Invoice functionality not implemented yet.")}
              >
                Create Invoice
              </Button>
              <Button
                variant="outlined"
                startIcon={<Work />}
                fullWidth
                // onClick={() => navigate("/addProject")}
                onClick={() => alert("Add Project functionality not implemented yet.")}
              >
                Add Project
              </Button>
            </Stack>
          </Paper>

          {/* Notes Section */}
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              <Note sx={{ verticalAlign: 'middle', mr: 1 }} />
              Notes
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {profileDetails.notes || "No notes available. Click edit to add notes."}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};


export default ClientDetail;






