import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  Box,
} from '@mui/material';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';


const Expense = ({ projectId, projectName }) => {
  const [expenses, setExpenses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [statusTotals, setStatusTotals] = useState(null);
  const [openModal, setOpenModal] = useState(false); // Modal open state
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredExpenses, setFilteredExpenses] = useState([]);
  const navigate = useNavigate();
  const [selectedStatus, setSelectedStatus] = useState('');
  const [isLoadingApply, setIsLoadingApply] = useState(false); // Loading state for apply button
  const [selectedExpenseIds, setSelectedExpenseIds] = useState([]); // Track selected expenses
  const [bulkAction, setBulkAction] = useState(''); // Track bulk action (Edit or Delete)
  const [statusOptions, setStatusOptions] = useState([]);
  const [error, setError] = useState('');


  // Function to fetch expenses
  const fetchExpenses = async () => {
    const token = localStorage.getItem('authToken');
    const url = `https://api.myntask.com/project/expense/${projectId}`;
    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });


      if (!response.ok) {
        throw new Error('Failed to fetch expenses');
      }


      const data = await response.json();
      console.log('Expenses API Response:', data);


      setExpenses(data.expenses);
      setFilteredExpenses(data.expenses); // Initialize the filtered list
      setStatusTotals(data.status_totals);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching expenses:', error);
      setLoading(false);
    }
  };


  // Call fetchExpenses when projectId changes
  useEffect(() => {
    fetchExpenses();
  }, [projectId]);


  const handleViewExpense = (expenseId) => {
    navigate(`/view-expense/${expenseId}`);
  };


  // Search Handler
  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    // Filter the expenses based on the search term
    const filtered = expenses.filter((expense) =>
      expense.item_name.toLowerCase().includes(term) ||
      expense.name.toLowerCase().includes(term) ||
      expense.purchase_from?.toLowerCase().includes(term) ||
      expense.status?.toLowerCase().includes(term)
    );
    setFilteredExpenses(filtered);
  };


  // Data for Pie Chart (Make sure statusTotals is available)
  let totalAmount = 0;
  let pieData = [];
  if (statusTotals) {
    totalAmount =
      parseFloat(statusTotals.approved.replace(/[^0-9.-]+/g, "")) +
      parseFloat(statusTotals.paid.replace(/[^0-9.-]+/g, "")) +
      parseFloat(statusTotals.pending.replace(/[^0-9.-]+/g, "")) -
      parseFloat(statusTotals.rejected.replace(/[^0-9.-]+/g, "")) ;
    pieData = [
      { name: 'Pending', value: parseFloat(statusTotals.pending.replace(/[^0-9.-]+/g, "")) },
      { name: 'Rejected', value: parseFloat(statusTotals.rejected.replace(/[^0-9.-]+/g, "")) },
      { name: 'Paid', value: parseFloat(statusTotals.paid.replace(/[^0-9.-]+/g, "")) },
      { name: 'Approved', value: parseFloat(statusTotals.approved.replace(/[^0-9.-]+/g, "")) },
      { name: 'Total Expenses', value: totalAmount }
    ];
  }


  // Colors for PieChart
  const COLORS = ['#FFBB28', '#f53933', '#62a341', '#0088FE', '#e833f5'];


  // Open and Close Modal Handlers
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);


  // useEffect to fetch status options (Always Called at the Top)
  useEffect(() => {
    const fetchStatusOptions = async () => {
      const token = localStorage.getItem('authToken');
      // Handle token check inside the function.
      if (!token) {
        setError('No token found');
        return;
      }


      try {
        const response = await fetch('https://api.myntask.com/expenses/status', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });


        if (!response.ok) {
          throw new Error(`Failed to fetch status options. Status: ${response.status}`);
        }


        const data = await response.json();
        setStatusOptions(data);
      } catch (err) {
        console.error('Error fetching status options:', err);
        setError('Failed to load status options.');
      }
    };


    fetchStatusOptions();
  }, []); // Runs once on mount


  // useEffect to watch changes in statusOptions
  useEffect(() => {
    console.log("Updated Status Options:", statusOptions);
  }, [statusOptions]);


  const handleStatusDropdown = (event) => {
    setSelectedStatus(event.target.value);
    setBulkAction(''); // Clear bulk action when status is selected
  };


  // Handling changes to the checkbox selection
  const handleCheckboxChange = (expenseId) => {
    setSelectedExpenseIds((prevSelected) =>
      prevSelected.includes(expenseId)
        ? prevSelected.filter((id) => id !== expenseId)
        : [...prevSelected, expenseId]
    );
  };


  const handleStatusChange = async (selectedExpenseIds, newStatus) => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('No token found');
      Swal.fire('Error', 'No token found', 'error');
      return;
    }


    try {
      const requestBody = {
        expense_id: selectedExpenseIds,
        status: newStatus,
      };


      const response = await fetch('https://api.myntask.com/expenses/status_update', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });

      console.log("Request Body ", requestBody);
      if (!response.ok) {
        throw new Error('Failed to update status');
      }


      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Status updated successfully',
        timer: 2000,
        showConfirmButton: false,
      });


      fetchExpenses(); // Re-fetch the expenses after a successful update
    } catch (err) {
      console.error('Error updating status:', err);
      setError('Failed to update status.');
      Swal.fire('Error', 'Failed to update status', 'error');
    }
  };






  const handleApplyBulkAction = async () => {
    if (!bulkAction && !selectedStatus) {
      alert('Please select an action or status to apply.');
      return;
    }

    setIsLoadingApply(true); // Set loading state to true

    try {

      if (selectedStatus) {
        await handleStatusChange(selectedExpenseIds, selectedStatus);
      }
      // Show a single success alert after the bulk action is complete
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: ' Selected Status update successfully',
        timer: 2000,
        showConfirmButton: false,
      });

      setSelectedExpenseIds([]); // Clear selected expenses
      setBulkAction(''); // Reset dropdown
      setSelectedStatus(''); // Reset status selection
    } catch (error) {
      console.error('Error applying bulk action:', error);
      Swal.fire('Error', 'Failed to apply bulk action.', 'error');
    } finally {
      setIsLoadingApply(false); // Hide loading state
    }
  };


  const handleDownloadPdf = () => {
    const doc = new jsPDF();
    const tableColumn = ["Index", "Item Name", "Price", "Employees", "Purchased From", "Purchase Date", "Status"];
    const tableRows = [];
    // Convert the expenses data into rows
    filteredExpenses.forEach((expense, index) => {
      const rowData = [
        index + 1,
        expense.item_name,
        expense.price,
        expense.name,
        expense.purchase_from,
        expense.purchase_date,
        expense.status
      ];
      tableRows.push(rowData);
    });


    doc.text(`${projectName} - Expenses`, 14, 10); // Title at the top


    // AutoTable with pagination (30 rows per page)
    doc.autoTable({
      head: [tableColumn], // Column headers
      body: tableRows, // Table data
      startY: 20, // Start position
      theme: 'grid', // Grid style table
      headStyles: {
        fillColor: '#598ad9', // Custom header background color (Blue)
        textColor: 255, // White text
        fontStyle: 'bold'
      },
      margin: { top: 20 },
      pageBreak: 'auto', // Automatically create new pages
      showHead: 'everyPage', // Show header on each page
    });


    doc.save(`${projectName}-Expenses.pdf`);
  };


  const handleExportToExcel = () => {
    if (expenses.length === 0) {
      Swal.fire('No data', 'No table data available to export.', 'info');
      return;
    }


    // Extract all table data, matching the displayed structure
    const exportData = filteredExpenses.map((expense, index) => ({
      Index: index + 1,
      "Item Name": expense.item_name,
      Price: expense.price,
      Employees: expense.name,
      "Purchased From": expense.purchase_from,
      "Purchase Date": expense.purchase_date,
      Status: expense.status,
    }));


    // Create a worksheet from the extracted data
    const worksheet = XLSX.utils.json_to_sheet(exportData);


    // Add column headers styled like the table
    const headerNames = [
      "Index",
      "Item Name",
      "Price",
      "Employees",
      "Purchased From",
      "Purchase Date",
      "Status",
    ];
    XLSX.utils.sheet_add_aoa(worksheet, [headerNames], { origin: "A1" });


    // Create a workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Table Data');


    // Trigger file download
    XLSX.writeFile(workbook, 'TableData.xlsx');
  };


  return (
    <Box style={{ padding: '10px 30px' }}>
      {/* Conditional rendering for loading state */}
      {loading ? (
        <Typography>Loading expenses...</Typography>
      ) : (
        <>
          <div className="top-controls">
            <h1 className="title">
              {projectName} - Expenses
              <div className="underline"></div>
            </h1>

          </div>
          <div className="top-controls">
            <div className="filters-and-search">
              <button className="border-button" onClick={handleDownloadPdf}>
                Download PDF
              </button>
              <button className="border-button" onClick={handleExportToExcel}>
                Export
              </button>
              <input
                type="text"
                placeholder="Search by name or email..."
                value={searchTerm}
                onChange={handleSearch}
                className="searchbar"
              />
            </div>
          </div>


          {/* Status Summary Section with Pie Chart and Text Summary */}
          {statusTotals && (
            <Box mb={4} p={2} border={1} borderRadius="8px" borderColor="#ddd" bgcolor="#f5f5f5">
              <Typography variant="h6" gutterBottom>Status Summary</Typography>
              <Box display="flex" justifyContent="space-around" alignItems="center">
                {/* Pie Chart Section */}
                <ResponsiveContainer width="50%" height={200}>
                  <PieChart>
                    <Pie
                      data={pieData}
                      cx="40%"
                      cy="50%"
                      labelLine={false}
                      label={({ name, value }) => `${name}: ₹${value.toLocaleString()}`}
                      outerRadius={60}
                      innerRadius={35}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {pieData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip formatter={(value) => `₹${value.toLocaleString()}`} />
                  </PieChart>
                </ResponsiveContainer>


                {/* Status Summary Text Section */}
                <Box ml={4}>
                  <Typography variant="body1" color="textPrimary" gutterBottom>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: '4px' }}>
                      <div>
                        <div><strong>Pending: </strong></div>
                        <div><strong>Approved: </strong></div>
                        <div><strong>Rejected: </strong></div>
                        <div><strong>Paid: </strong></div>
                        <div><strong>Total Amount: </strong></div>
                      </div>
                      <div>
                        <div> ₹ {parseFloat(statusTotals.pending.replace(/[^0-9.-]+/g, "")).toLocaleString()}</div>
                        <div> ₹ {parseFloat(statusTotals.approved.replace(/[^0-9.-]+/g, "")).toLocaleString()}</div>
                        <div> ₹ {parseFloat(statusTotals.rejected.replace(/[^0-9.-]+/g, "")).toLocaleString()}</div>
                        <div> ₹ {parseFloat(statusTotals.paid.replace(/[^0-9.-]+/g, "")).toLocaleString()}</div>
                        <div> ₹ {totalAmount.toLocaleString()}</div>
                      </div>
                    </div>
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          <div className="top-controls" style={{ justifyContent: 'flex-end' }}>

            <div className="buttons-row">
              {selectedExpenseIds.length > 0 && (
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <select onChange={handleStatusDropdown} value={selectedStatus} className="color-button">
                    <option value="">Select Status</option>
                    {statusOptions.length > 0 ? (
                      statusOptions.map((status, index) => (
                        <option key={index} value={status.value || status}>
                          {status.label || (typeof status === 'string' && status.charAt(0).toUpperCase() + status.slice(1))}
                        </option>
                      ))
                    ) : (
                      <option disabled>Loading...</option>
                    )}
                  </select>
                  <button onClick={handleApplyBulkAction} className="color-button" disabled={isLoadingApply}>
                    {isLoadingApply ? 'Loading...' : 'Apply'}
                  </button>
                </div>
              )}
            </div>
          </div>


          {/* Expenses Table */}
          <div id="expensesTable">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Index</th>
                  <th>Item Name</th>
                  <th>Price</th>
                  <th>Employees</th>
                  <th>Purchased From</th>
                  <th style={{ textAlign: 'center' }}>Purchase Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {filteredExpenses.length === 0 ? (
                  <tr>
                    <td colSpan={7} style={{ textAlign: 'center', padding: '16px' }}>
                      No expenses available
                    </td>
                  </tr>
                ) : (
                  filteredExpenses.map((expense, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedExpenseIds.includes(expense.expenses_id)}
                          onChange={() => handleCheckboxChange(expense.expenses_id)}
                        />
                      </td>
                      <td>{index + 1}</td>
                      <td style={{ cursor: 'pointer' }} onClick={() => handleViewExpense(expense.expenses_id)}>
                        <span>{expense.item_name}</span>
                      </td>
                      <td>{`₹ ${expense.price}`}</td>
                      <td style={{ padding: '8px 16px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img src={expense.user_avatar} alt={expense.name} className="image" />
                          <div>
                            <div>{expense.name}</div>
                            <div>{expense.designation_name}</div>
                          </div>
                        </div>
                      </td>
                      <td>{expense.purchase_from}</td>
                      <td>{expense.purchase_date}</td>
                      <td>
                        <div className="status-container">
                          <span
                            className="status-dot"
                            style={{
                              backgroundColor:
                                expense.status === 'paid'
                                  ? '#4caf50'
                                  : expense.status === 'pending'
                                    ? '#ff9800'
                                    : expense.status === 'approved'
                                      ? '#2196f3'
                                      : '#f44336',
                            }}
                          ></span>
                          <span>{expense.status}</span>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </>
      )}
    </Box>
  );
};


export default Expense;





