import React, { useEffect, useState } from 'react';
import './PurchaseForm.css'; // Import the CSS file for styling
import Swal from 'sweetalert2';
import { Box, Button, Modal } from '@mui/material';
import iconImage from '../assets/Icon.png';
import { MdDelete } from 'react-icons/md';

const PurchaseForm = () => {
    const [projects, setProjects] = useState([]);
    const [categories, setCategories] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [openCategoryModal, setOpenCategoryModal] = useState(false);
    const [isSubCategoryDropdownOpen, setIsSubCategoryDropdownOpen] = useState(false);
    const [selectedCategoryId, setSelectedCategoryId] = useState('');
    const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
    const [newCategory, setNewCategory] = useState('');
    const [subCategories, setSubCategories] = useState([]);
    const [openSubCategoryModal, setOpenSubCategoryModal] = useState(false);
    const [subCategoryName, setSubCategoryName] = useState('');
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        project_id: '',
        priority: '',
        expected_delivery_date: '',
        payment_type: '',
        category_id: '0',
        sub_category_id: '0',
        vendor_id: '',
        vendor_type: '',
        vendor_location: '',
        amount: '',
        tax: '0',
        other_expenses: '0',
        total_amount: '',
        upload_invoice: null,
        // terms: false,
        // remarks: ''
    });


    const fetchCategories = async () => {
        const token = localStorage.getItem('authToken');
        try {
            const response = await fetch('https://api.myntask.com/product/category', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`Network response was not ok. Status: ${response.status}`);
            }

            const data = await response.json();
            setCategories(data); // Assuming the response is an array of categories
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('authToken');
        const fetchProjects = async () => {

            console.log('Fetching projects...');
            try {
                const response = await fetch('https://api.myntask.com/task/project_list', {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setProjects(data || []);
            } catch (error) {
                console.error('Error fetching projects:', error);
                setProjects([]);
            }
        };


        const fetchVendors = async () => {
            try {
                const response = await fetch('https://api.myntask.com/purchase_order/vendor_company', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                if (Array.isArray(data)) {
                    setVendors(data);
                } else {
                    console.error('API response is not an array:', data);
                    setVendors([]); // Fallback
                }
            } catch (error) {
                console.error('Error fetching vendors:', error);
            }
        };

        fetchProjects();
        fetchCategories();
        fetchVendors();
    }, []);


    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleVendorChange = (e) => {
        const selectedVendorId = e.target.value;
    
        // Find the selected vendor from the vendors list
        const selectedVendor = vendors.find(vendor => vendor.id.toString() === selectedVendorId);
    
        // Update form data with the selected vendor ID and address
        setFormData((prevData) => ({
            ...prevData,
            vendor_id: selectedVendorId,
            vendor_location: selectedVendor?.address || "" // Use vendor's address or empty string
        }));
    };

    
    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        formDataToSend.append("project_id", formData.project_id);
        formDataToSend.append("category_id", formData.category_id);
        formDataToSend.append("sub_category_id", formData.sub_category_id);
        formDataToSend.append("vendor_id", formData.vendor_id);
        formDataToSend.append("amount", formData.amount);
        formDataToSend.append("tax", formData.tax);
        formDataToSend.append("other_expenses", formData.other_expenses);
        formDataToSend.append("total_amount", formData.total_amount);
        formDataToSend.append("expected_delivery_date", formData.expected_delivery_date);
        formDataToSend.append("priority", formData.priority);
        formDataToSend.append("payment_type", formData.payment_type);
        formDataToSend.append("vendor_location", formData.vendor_location);
        formDataToSend.append("vendor_type", formData.vendor_type);

        if (formData.upload_invoice) {
            formDataToSend.append("upload_invoice", formData.upload_invoice);
        }

        // Log the data before sending
        console.log("Data being sent to API:");
        for (let pair of formDataToSend.entries()) {
            console.log(pair[0], pair[1]);
        }

        try {
            const response = await fetch("https://api.myntask.com/purchaseApproval/save", {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                },
                body: formDataToSend,
            });

            // Log the raw response
            console.log("API Response Status:", response.status);

            const responseData = await response.json();

            // Log full response data
            console.log("API Response Data:", responseData);

            if (!response.ok) {
                throw new Error(responseData.message || "Failed to save purchase approval");
            }

            Swal.fire({
                icon: "success",
                title: "Purchase Approval Saved!",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
            });

        } catch (error) {
            console.error("Error saving purchase approval:", error);

            Swal.fire({
                icon: "error",
                title: "Error saving purchase approval!",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
            });
        }
    };

    const handleAddCategory = () => {
        setOpenCategoryModal(true);
    };


    const handleSaveCategory = async () => {
        if (!newCategory.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Validation Error',
                text: 'Category name is required!',
                timer: 1500, // Automatically closes after 3 seconds
                showConfirmButton: false, // Hide the confirmation button
            });
            return;
        }
        const token = localStorage.getItem('authToken');
        if (!token) {
            Swal.fire({
                icon: 'error',
                title: 'Authentication Error',
                text: 'Token not found in local storage!',
                timer: 1500, // Automatically closes after 3 seconds
                showConfirmButton: false, // Hide the confirmation button
            });
            return;
        }
        const payload = {
            name: newCategory.trim(),
        };
        try {
            const response = await fetch('https://api.myntask.com/purchaseApproval/addcategory', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(payload),
            });
            // Check if the response is JSON
            const contentType = response.headers.get('Content-Type');
            if (contentType && contentType.includes('application/json')) {
                const data = await response.json();
                if (response.ok) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'Category saved successfully!',
                        timer: 1500, // Automatically closes after 3 seconds
                        showConfirmButton: false, // Hide the confirmation button
                    });
                    fetchCategories();
                    setNewCategory('');
                    setOpenCategoryModal(false);
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: data.message || 'Failed to save category!',
                    });
                }
            } else {
                // Handle plain text response
                const text = await response.text();
                if (response.ok) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: text,
                        timer: 1500, // Automatically closes after 3 seconds
                        showConfirmButton: false, // Hide the confirmation button
                    });
                    fetchCategories();
                    setNewCategory('');
                    setOpenCategoryModal(false);
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: text,
                    });
                }
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: `Something went wrong: ${error.message}`,
                timer: 1500, // Automatically closes after 3 seconds
                showConfirmButton: false, // Hide the confirmation button
            });
        }
    };

    const handleDeleteCategory = async (categoryId) => {
        const result = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Yes, delete it!",
        });

        if (result.isConfirmed) {
            try {
                const response = await fetch(`https://api.myntask.com/purchaseApproval/deletecategory/${categoryId}`, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    },
                });

                if (!response.ok) {
                    throw new Error("Failed to delete asset type");
                }
                fetchCategories();
                // Show success message in toast style
                Swal.fire({
                    icon: "success",
                    title: "Deleted!",
                    text: "category deleted successfully!",
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                });
            } catch (error) {
                console.error("Error deleting categoryss:", error);
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: "Failed to delete category!",
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                });
            }
        }
    };

    const handleCategoryChange = (event) => {
        const categoryId = event.target.value; // Extract selected category ID
        setFormData((prevState) => ({ ...prevState, category_id: categoryId }));
        setSelectedCategoryId(categoryId);
        // fetchSubCategories(categoryId); // Uncomment if needed to fetch subcategories
        setIsCategoryDropdownOpen(false);
    };
    

    const fetchSubCategories = async (categoryId) => {
        try {
            const token = localStorage.getItem("authToken");
            const response = await fetch(`https://api.myntask.com/product/sub_category/${categoryId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (!response.ok) {
                throw new Error("Failed to fetch client sub-categories");
            }
            const data = await response.json();
            setSubCategories(data); // ✅ Ensure state updates immediately
        } catch (error) {
            console.error("Error fetching sub-categories:", error);
        }
    };



    // UseEffect to call fetchSubCategories when selectedCatlegoryId changes
    useEffect(() => {
        if (selectedCategoryId) {
            fetchSubCategories(selectedCategoryId);
        }
    }, [selectedCategoryId]); // Dependency array

    const handleDeleteSubCategory = async (subcategoryId) => {
        const result = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Yes, delete it!",
        });

        if (result.isConfirmed) {
            try {
                const response = await fetch(`https://api.myntask.com/purchaseApproval/deletesubcategory/${subcategoryId}`, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    },
                });

                if (!response.ok) {
                    throw new Error("Failed to delete asset type");
                }
                fetchSubCategories();
                // Show success message in toast style
                Swal.fire({
                    icon: "success",
                    title: "Deleted!",
                    text: "category deleted successfully!",
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                });
            } catch (error) {
                console.error("Error deleting categoryss:", error);
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: "Failed to delete category!",
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                });
            }
        }
    };


    // const handleSubCategoryChange = (subCategoryId) => {
    //     setFormData((prevState) => ({ ...prevState, sub_category_id: subCategoryId }));
    //     setIsSubCategoryDropdownOpen(false);
    // };

    const handleSubCategoryChange = (event) => {
        const subCategoryId = event.target.value; // Extract selected sub-category ID
        setFormData((prevState) => ({ ...prevState, sub_category_id: subCategoryId }));
    };
    
    

    const handleAddSubCategory = () => {
        setOpenSubCategoryModal(true); // Open the modal
    };


    const handleSaveSubCategory = async () => {
        if (!subCategoryName.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Validation Error',
                text: 'Sub-category name is required!',
            });
            return;
        }
        const token = localStorage.getItem('authToken');
        if (!token) {
            Swal.fire({
                icon: 'error',
                title: 'Authentication Error',
                text: 'Token not found in local storage!',
            });
            return;
        }
        const payload = {
            name: subCategoryName.trim(),
            purchase_category_id: selectedCategoryId,  // Send the selected category ID
        };
        setLoading(true);
        try {
            const response = await fetch('https://api.myntask.com/purchaseApproval/addsubcategory', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(payload),
            });

            const contentType = response.headers.get('Content-Type');
            let data;
            if (contentType && contentType.includes('application/json')) {
                data = await response.json();
            } else {
                data = await response.text();
            }

            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Sub-category saved successfully!',
                });

                // **Fetch updated sub-category list BEFORE closing modal**
                await fetchSubCategories(selectedCategoryId);

                // Clear input field & close modal
                setSubCategoryName('');
                setOpenSubCategoryModal(false);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: data.message || 'Failed to save sub-category!',
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: `Something went wrong: ${error.message}`,
            });
        } finally {
            setLoading(false);
        }
    };

    const handleExpenseChange = (event) => {
        const { name, value } = event.target;
    
        // Update formData for amount, tax, and other expenses
        setFormData((prevState) => {
            const updatedData = { ...prevState, [name]: value };
    
            // Calculate and update total amount automatically
            updatedData.total_amount = calculateTotalAmount(updatedData);
    
            return updatedData;
        });
    };
    
    // Function to calculate Total Amount
    const calculateTotalAmount = (data) => {
        const amount = parseFloat(data.amount) || 0;
        const tax = parseFloat(data.tax) || 0;
        const otherExpenses = parseFloat(data.other_expenses) || 0;
    
        return amount + tax + otherExpenses; // Sum up all values
    };
    

    return (
        <div className="main-container-body">
            {/* <div className="top-controls">
                <h1 className='title'>Add Payment
                    <div className="underline"></div>
                </h1>
            </div> */}
             <div className="add-lead-form" style={{maxWidth: '1000px'}}>
        <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Add Payment</h1>

            <div className="lead-section">
          <div className="lead-section-heading">Basic Information</div>
          <div className="lead-form-group">
                    <label className="lead-form-label">Project Name:</label>
                    <select
                        className='lead-form-input'
                        id="project"
                        name="project_id"
                        value={formData.project_id}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Select Project</option>
                        {projects.map((project) => (
                            <option key={project.id} value={project.id}>
                                {project.project_name}
                            </option>
                        ))}

                    </select>
                </div>
                <div className="lead-form-row">
                <div className="lead-form-group">
    <label className='lead-form-label'>Payment Type:</label>
    <select
        className="lead-form-input"
        id="paymentType"
        name="payment_type"
        value={formData.payment_type}
        onChange={handleChange}
    >
        <option value="">Select Payment Type</option>
        <option value="Advance">Advance</option>
        <option value="Running_payment">Running Payment</option>
        <option value="Final_payment">Final Payment</option>
    </select>
</div>

{/* Conditionally render Expected Delivery Date when 'Advance' is selected */}
{formData.payment_type === "Advance" && (
    <div className="lead-form-group">
        <label className='lead-form-label'>Expected Delivery Date:</label>
        <input
            className="lead-form-input"
            type="date"
            id="expectedDelivery"
            name="expected_delivery_date"
            value={formData.expected_delivery_date}
            onChange={handleChange}
        />
    </div>
)}

                </div>
                <div className="lead-form-row">
                    <div className="lead-form-group">
                        <label className='lead-form-label'>Category:</label>
                        <div className="categoryDropdownContainer" style={{ border: "none" }}>
                        <select
            className='lead-form-input'
            id="category"
            name="category_id"
            value={formData.category_id}
            onChange={handleCategoryChange}
            required
        >
            <option value="">Select Category</option>
            {categories.map((category) => (
                <option key={category.id} value={category.id}>
                    {category.category_name}
                </option>
            ))}
        </select>
        <button onClick={handleAddCategory} className='color-button' style={{ marginLeft: '10px', marginRight:'0px'}} >
                                Add
                            </button>
                                            </div>

                        {/* <div className="categoryDropdownContainer" style={{ border: "none" }}>
                            <div
                                className="custom-dropdown"
                                style={{ marginRight: "10px" }}
                                onClick={() => setIsCategoryDropdownOpen(!isCategoryDropdownOpen)}
                            >
                                <div className="selected-category">
                                   
                                    {selectedCategoryId
                                        ? categories.find((cat) => cat.id === selectedCategoryId)?.name || "Select Category"
                                        : "Select Category"}
                                   
                                </div>


                                {isCategoryDropdownOpen && (
                                    <div className="dropdown-options">
                                        {categories.map((category) => (
                                            <div key={category.id} className="dropdown-item">
                                                <span
                                                    className="dropdown-text"
                                                    onClick={() => {
                                                        handleCategoryChange(category.id);
                                                        setIsCategoryDropdownOpen(false); // Close dropdown after selection
                                                    }}
                                                >
                                                    {category.name}
                                                </span>
                                                <Button
                                                    className="delete-button"
                                                    onClick={(e) => {
                                                        e.stopPropagation(); // Prevent closing dropdown when delete button is clicked
                                                        handleDeleteCategory(category.id);
                                                    }}
                                                >
                                                    <MdDelete />
                                                </Button>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <button onClick={handleAddCategory} className='color-button' type='button'>
                                Add
                            </button>
                        </div> */}
                    </div>

                    <div className="lead-form-group">
                        <label className='lead-form-label'>Sub Category:</label>
                        <div className="categoryDropdownContainer" style={{ border: "none" }}>
                            {/* <div
                                className='custom-dropdown '
                                style={{ marginRight: "10px" }}
                                onClick={() => setIsSubCategoryDropdownOpen(!isSubCategoryDropdownOpen)}
                            >
                                <div className="selected-category">
                                    {formData.sub_category_id
                                        ? subCategories.find(
                                            (subCat) => subCat.id === formData.sub_category_id
                                        )?.name || "Select Sub-Category"
                                        : "Select Sub-Category"}
                                </div>


                                {isSubCategoryDropdownOpen && (
                                    <div className="dropdown-options">
                                        {subCategories.map((subCategory) => (
                                            <div key={subCategory.id} className="dropdown-item">
                                                <div
                                                    className='dropdown-text'
                                                    onClick={() =>
                                                        handleSubCategoryChange(subCategory.id)
                                                    }
                                                >
                                                    {subCategory.name}
                                                </div>
                                                <Button
                                                    className="delete-button" style={{ width: '20px' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation(); // Prevent dropdown closing on delete
                                                        handleDeleteSubCategory(subCategory.id);
                                                    }}
                                                >
                                                    <MdDelete />
                                                </Button>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div> */}
                           <select
    className="lead-form-input"
    id="sub_category"
    name="sub_category_id"
    value={formData.sub_category_id || ""}
    onChange={handleSubCategoryChange} // Pass function reference directly
    required
>
    <option value="">Select Sub-Category</option>
    {subCategories.map((subCategory) => (
        <option key={subCategory.id} value={subCategory.id}>
            {subCategory.category_name}
        </option>
    ))}
</select>


                            <button onClick={handleAddSubCategory} className="color-button" style={{ marginLeft: '10px', marginRight:'0px'}}>
                                Add
                            </button>
                        </div>
                    </div>
                </div>
                </div>

                <div className="lead-section">
                <div className="lead-section-heading">Vendor Details</div>
                <div className="lead-form-row">
                    <div className="lead-form-group">
                        <label className='lead-form-label'>Vendor Name:</label>
                        <select
                            className='lead-form-input'
                            id="vendor"
                            name="vendor_id"
                            value={formData.vendor_id}
                            onChange={handleVendorChange}
                            required
                        >
                            <option value="">Select Vendor</option>
                            {vendors.map((vendor) => (
                                <option key={vendor.id} value={vendor.id}>
                                    {vendor.company_name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="lead-form-group">
                        <label className='lead-form-label'>Vendor Type:</label>
                        <select
                            className='lead-form-input'
                            id="vendorType"
                            name="vendor_type"
                            value={formData.vendor_type}
                            onChange={handleChange}

                        >
                            <option value="">Select Vendor Type</option>
                            <option value="Regular">Regular</option>
                            <option value="New Vendor">New Vendor</option>
                        </select>
                    </div>
                </div>
                <div className="lead-form-row">
                    <div className="lead-form-group">
                        <label className='lead-form-label'>Vendor Location:</label>
                        <input
                            className='lead-form-input'
                            type="text"
                            id="vendorLocation"
                            name="vendor_location"
                            placeholder="Enter Vendor Location"
                            value={formData.vendor_location}
                            onChange={handleChange}

                        />
                    </div>

                </div>
                </div>

                {/* <div className="lead-form-row">
                    <div className="lead-form-group">
                        <label htmlFor="purchaseReq">Purchase Requisition:</label>
                        <select className='lead-form-input' id="purchaseReq" name="purchaseReq" value={formData.purchaseReq} onChange={handleChange}>
                            <option value="Default">No Purchase Requisition</option>
                        </select>
                    </div>
                    <div className="lead-form-group">
                        <label htmlFor="poNumber">PO Number:</label>
                        <select className='lead-form-input' id="poNumber" name="poNumber" value={formData.poNumber} onChange={handleChange}>
                            <option value="Default">No PO Number</option>
                        </select>
                    </div>
                </div> */}

              
<div className="lead-section">
    <div className="lead-section-heading">Amount Details</div>
    
    <div className="lead-form-row">
        <div className="lead-form-group">
            <label className='lead-form-label'>Amount (₹):</label>
            <input 
                className="lead-form-input" 
                type="number" 
                id="amount" 
                name="amount" 
                placeholder="Enter Amount" 
                value={formData.amount} 
                onChange={handleExpenseChange} // Use new function
                required 
            />
        </div>
        <div className="lead-form-group">
            <label className='lead-form-label'>Tax (₹):</label>
            <input 
                className="lead-form-input" 
                type="number" 
                id="tax" 
                name="tax" 
                placeholder="Enter Tax Amount" 
                value={formData.tax} 
                onChange={handleExpenseChange} // Use new function
            />
        </div>
    </div>

    <div className="lead-form-row">
        <div className="lead-form-group">
            <label className='lead-form-label'>Other Expenses (₹):</label>
            <input 
                className="lead-form-input" 
                type="number" 
                id="other_expenses" 
                name="other_expenses" 
                placeholder="Enter Other Expenses" 
                value={formData.other_expenses} 
                onChange={handleExpenseChange} // Use new function
            />
        </div>
        <div className="lead-form-group">
            <label className='lead-form-label'>Total Amount (₹):</label>
            <input 
                className="lead-form-input" 
                type="number" 
                id="totalAmount" 
                name="total_amount" 
                placeholder="Total Amount" 
                value={formData.total_amount} 
                readOnly // Make it read-only since it's auto-calculated
            />
        </div>
    </div>

    <div className="lead-form-row">
    <div className="lead-form-group">
                        <label className='lead-form-label'>Priority:</label>
                        <select className='lead-form-input' id="priority" name="priority" value={formData.priority} onChange={handleChange} required>
                            <option value="">Select Priority</option>
                            <option value="High">High</option>
                            <option value="Medium">Medium</option>
                            <option value="Low">Low</option>
                        </select>
                    </div>
    <div className="lead-form-group">
                    <label className='lead-form-label'>Upload Invoice/Quotation:</label>
                    <input className='lead-form-input' type="file" id="upload" name="upload_invoice" onChange={(e) => setFormData({ ...formData, upload_invoice: e.target.files[0] })} />
                </div>
        </div>
</div>

              
                {/* <div className="lead-form-group">
                    <label htmlFor="terms">Agree to Terms & Conditions:</label>
                    <input  type="checkbox" id="terms" name="terms" checked={formData.terms} onChange={handleChange}  /> I agree
                </div> */}
                {/* <div className="lead-form-group">
                    <label htmlFor="remarks">Remarks:</label>
                    <textarea id="remarks" name="remarks" rows="3" placeholder="Enter Remarks" value={formData.remarks} onChange={handleChange}></textarea>
                </div> */}
                <div className="form-action">
                <button
  className="color-button"
  type="button"
  onClick={() => window.history.back()}
>
  Cancel
</button>
                    <button type="submit" className="color-button" onClick={handleSubmit}>
                        Save
                    </button>
                </div>
          </div>

            <Modal
                open={openCategoryModal}
                onClose={() => setOpenCategoryModal(false)}
            >
                <Box className="designation-modal">
                    {/* Header with title and close button */}
                    <div className="designation-header">
                        <h1 className="designation-title">Add Category</h1>
                        <img
                            src={iconImage}
                            alt="Close"
                            className="designation-close"
                            onClick={() => setOpenCategoryModal(false)}
                        />
                    </div>
                    {/* Form Section */}
                    <div className="designation-form">
                        <div className="form-group">
                            <input
                                type="text"
                                name="newCategory"
                                value={newCategory}
                                onChange={(e) => setNewCategory(e.target.value)}
                                placeholder="Category Name"
                                className="designation-input"
                                required
                            />
                        </div>


                        <button
                            className="designation-save"
                            onClick={handleSaveCategory}
                        disabled={loading}
                        >
                            {loading ? 'Saving...' : 'Save'}
                          
                        </button>
                    </div>
                </Box>
            </Modal>


             <Modal open={openSubCategoryModal} onClose={() => setOpenSubCategoryModal(false)}>
                            <Box className="designation-modal">
                                {/* Modal Header */}
                                <div className="designation-header">
                                    <h1 className="designation-title">Add Sub-Category</h1>
                                    <img
                                        src={iconImage} // Replace 'iconImage' with your close icon image
                                        alt="Close"
                                        className="designation-close"
                                        onClick={() => setOpenSubCategoryModal(false)}
                                    />
                                </div>
                                {/* Modal Form */}
                                <div className="designation-form">
                                    <div className="form-group">
                                        <label>Category</label>
                                        <select
                                            className="designation-input"
                                            value={selectedCategoryId}
                                            onChange={handleCategoryChange}
                                        >
                                            <option value="">Select Category</option>
                                            {/* Render categories dynamically */}
                                            {categories.map((category) => (
                                                <option key={category.id} value={category.id}>
                                                    {category.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            name="subCategoryName"
                                            value={subCategoryName}
                                            onChange={(e) => setSubCategoryName(e.target.value)}
                                            placeholder="Sub-Category Name"
                                            className="designation-input"
                                            required
                                        />
                                    </div>
                                    <button
                                        className="designation-save"
                                        onClick={handleSaveSubCategory}
                                        disabled={loading}
                                    >
                                        {loading ? 'Saving...' : 'Save'}
                                    </button>
                                </div>
                            </Box>
                        </Modal>

        </div>
    );
};

export default PurchaseForm;